import React, { Component } from 'react';
import './TownhallEleven.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Townhallelevendemo from './TownhallElevenScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class Townhalleleven extends Component {

  componentDidMount () {
    window.townhallelevendemo = new Townhallelevendemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="th11-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 11 Base Layouts and Links</title>
            <meta name="description" content="The best TH 11 Base Layouts in one big filterable Gallery! Add the best War Bases, Trophy Bases, Farm Bases, Fun Bases and LegendsLeague Bases directly into your game via the import link." />
            <meta name="keywords" content="th 11, ths 11, th eleven, townhall 11, townhall 11 base, th 11 links, links, th 11 base design, th 11 bases, th 11 warbase, warbase, base for war, clan war base, clanwar base th 11, war base th 11, townhall 11 war base, townhall 11 base for war, th 11 trophybase, trophybase, base for trophys, trophy base th 11, townhall 11 trophy base, townhall 11 base for trophy, th 11 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 11, townhall 11 push base, townhall 11 base for pushing, th 11 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 11, townhall 11 farm base, townhall 11 base for farming, troll bases, th 11 troll base, th 11 fun base, th 11 funny base, th 11 trolling, funny th 11 base, best th 11 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 11 island base, ring base, th 11 ring base, teaser, th 11 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 11 links, th 11 base link, th 11 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="th11-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th11-container-about">
            <div className="th11-row-about">
              <div className="th11-title-container">
                <h1 className="th11-about-title">Townhall 11 Base Layouts</h1>

                <div className="switch-container-th11">
                  <LinkButton to="/Townhall-8" className="switch-buttons-th11 switch-to-th8-11">
                    <div className="switch-buttons-text-11">Lower</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-9" className="switch-buttons-th11 switch-to-th9-11">
                    <div className="switch-buttons-text-11">TH 9</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-10" className="switch-buttons-th11 switch-to-th10-11">
                    <div className="switch-buttons-text-11">TH 10</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-11" className="switch-buttons-th11 switch-to-th11-11">
                    <div className="switch-buttons-text-11">TH 11</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-12" className="switch-buttons-th11 switch-to-th12-11">
                    <div className="switch-buttons-text-11">Higher</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th11-container-about">
            <div className="th11-row-about">
              <div className="col-6@sm th11-filters-group-wrap">
                <div className="th11-filters-group filters-group-right">
                  <p className="th11-filter-label th11-filter-color">Filter</p>
                  <div className="btn-group th11-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad height={200} offset={100} once>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th11-container-about">
            <div id="grid" className="th11-row-about my-shuffle-th11-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2020-05-15" data-title="Townhall 11 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-11">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext.jpg"
                        srcSet="https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1024w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 11 | Base Layouts and Links" alt="Townhall 11 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 11</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044617.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044617.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044617.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044617.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044617.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0S0OFpkvVzHpnv4QwcnR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-075</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044438.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044438.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044438.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044438.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044438.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0SQQTXzWJlC4fYjgJ4XE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-074</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044257.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044257.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044257.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044257.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044257.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0Rp72r0FLJz-G6-lTtLC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-073</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044048.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044048.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044048.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044048.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-044048.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0Q8xUZJC6YlvengBF1Hb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-072</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043717.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043717.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043717.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043717.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043717.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0PuvUWkHwz8SndKkCbhU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-071</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043458.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043458.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043458.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043458.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043458.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0O6VK8Sioj7u67SDc8lO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-070</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043249.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043249.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043249.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043249.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043249.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0OKgyhnMXHHf0Xyv6yMH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-069</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043035.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043035.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043035.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043035.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-043035.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAIt0Nb1u0EL_5BvRhiGccB4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-068</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042828.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042828.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042828.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042828.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042828.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0MwV35WL5cJOTWrzo4r0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-067</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042709.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042709.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042709.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042709.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042709.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAIt0MTtUsjCgHrpteNEZzNc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-066</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042543.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042543.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042543.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042543.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042543.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0L2zQlDNCMXq9-gueMZb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-065</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042328.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042328.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042328.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042328.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042328.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0LHAYcPIUWQAYDGvQtw0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-064</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042215.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042215.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042215.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042215.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-042215.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAIt0Kt5E1n2ANDFZRUAHXdK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-063</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042019.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042019.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042019.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042019.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-042019.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0KDwkmgxI1eHDqntNBcf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-062</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041840.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041840.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041840.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041840.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041840.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0JiOufiztX8kLCLhY5Vf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041711.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041711.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041711.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041711.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041711.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0JCjhFT-iFPMAAA4N3do" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041555.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041555.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041555.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041555.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041555.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0IpE-IBunP_hYiWGPAZh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041331.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041331.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041331.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041331.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041331.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0H17s-267TgFsk1sfqOB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041119.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041119.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041119.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041119.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-041119.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0HHmrsgDUamjhYm0u9ZO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040941.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040941.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040941.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040941.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040941.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0GlksG25MgxJcXO7sOXH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040759.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040759.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040759.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040759.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040759.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0GCHpxDuUsqQjhV-3ceS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040253.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040253.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040253.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040253.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040253.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0EXgaOEI6tF6aydyRKVR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040055.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040055.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040055.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040055.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-040055.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0DuZhFGTRJbJ4vEAMA2W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035923.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035923.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035923.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035923.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035923.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0DQIwPqQNlDs992HKwJ9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035745.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035745.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035745.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035745.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035745.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0Cvt7HoI36sKMMV9kYpm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035625.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035625.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035625.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035625.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035625.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0CTibHKmAQKXbqwaVF2o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035405.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035405.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035405.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035405.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035405.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0BkdgFVT17TnLq5h8_sC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035301.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035301.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035301.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035301.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035301.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0BO054_6r0w7N1jOZ-2c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035137.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035137.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035137.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035137.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-035137.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0Ax6hbUwqjMvMpFalbYv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034935.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034935.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034935.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034935.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034935.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAIt0AKfijyp2f1qcTg_7EwD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034754.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034754.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034754.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034754.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034754.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItz_npya7eWV2hL4MUeCbm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-034554.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-034554.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-034554.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-034554.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-034554.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItz-9xAtdMHURNsPcBNUnB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034210.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034210.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034210.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034210.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-034210.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItz9x3p81-Kk6qpAfC3gCJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033910.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033910.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033910.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033910.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033910.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItz84BrSEBMGguswDVY2cS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033733.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033733.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033733.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033733.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-033733.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItz8UvD0733DoZSdCC4Ttz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033624.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033624.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033624.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033624.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033624.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItz78-M2YDjrmKpOuN7pYd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033348.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033348.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033348.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033348.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033348.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItz7JyFj73aReNhDQeP3ma" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033223.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033223.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033223.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033223.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-033223.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItz6tuGgdH1_fMifZl5w9B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-030334.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-030334.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-030334.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-030334.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-030334.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzx4zesfpg9dLDK1u_bWv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-030127.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-030127.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-030127.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-030127.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-030127.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzxMGa_oAKTeDnSyE55qi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025952.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025952.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025952.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025952.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025952.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzwtm4SULCXkrgEbnYEXU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025652.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025652.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025652.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025652.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025652.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzv0hGhPDD9_zDio25DdS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025536.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025536.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025536.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025536.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025536.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzvcH1MiHLlmp25KRuRdy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-025428.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-025428.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-025428.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-025428.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-025428.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzvF_xIaDcf2_Wd0gBxbi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025234.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025234.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025234.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025234.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025234.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzuhhh4D81e3FFDopIpxu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025049.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025049.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025049.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025049.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-025049.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzuAHQeBJwlKN6iCFYkCn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024901.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024901.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024901.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024901.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024901.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzteWPEd75qbNXY2HorWl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024651.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024651.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024651.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024651.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024651.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzs2AfGX3DCtX2XrQ5xol" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024505.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024505.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024505.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024505.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024505.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzsT4GziP_E-QRBYJtN7I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024219.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024219.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024219.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024219.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024219.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzrfjqetqTWsvpOVxzkCU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024058.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024058.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024058.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024058.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-024058.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzrGKzTavMhWFTItDN_f0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023851.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023851.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023851.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023851.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023851.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzqeQlJEt3EypKUoXww5x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023628.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023628.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023628.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023628.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-023628.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzpwkFE4ysmtz-NqUh-g6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-023456.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-023456.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-023456.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-023456.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-023456.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzpTohcCNdPvASg3N0O5K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022756.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022756.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022756.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022756.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022756.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItznOwpBqLIJTSWtsY30FI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022528.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022528.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022528.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022528.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022528.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzmhW9al7SB8-laI5AMjz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022346.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022346.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022346.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022346.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-022346.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzmB0Xi_5Zk2kdcrEYFBc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021945.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021945.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021945.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021945.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021945.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzk5P03jhYup0gdomsOFr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021654.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021654.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021654.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021654.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-021654.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzkECDfRR8zoHyfk0d26_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-021428.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-021428.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-021428.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-021428.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-021428.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzjUvFZgB5nmHKiZRnpP1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015718.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015718.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015718.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015718.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015718.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzeYeopdVRB38KozXD-EV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015330.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015330.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015330.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015330.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015330.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzdR2iIWB8h2FJzT15N5Q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015122.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015122.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015122.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015122.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-015122.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzcrkUyv4XQsKIzITPMeh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014846.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014846.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014846.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014846.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014846.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzb8ZAuZapEIuUfx4CWJ6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014041.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014041.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014041.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014041.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-014041.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzZoof-sWengwhYqys-nK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-013744.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-013744.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-013744.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-013744.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-013744.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzYzJPyNInQSlArAfgFeu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013217.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013217.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013217.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013217.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013217.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzXObs2o2BUW2o2KP2HJW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013001.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013001.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013001.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013001.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-013001.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzWkuyb9qRPC2J7PZTty8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012751.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012751.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012751.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012751.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012751.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzV8FbbHgsyyugWMhsGGR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012402.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012402.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012402.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012402.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012402.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzU4h6Rr2BGQoDb8NpBuR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012010.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012010.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012010.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012010.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-012010.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzTx82arFZoEQgChkFQ2D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004956.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004956.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004956.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004956.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004956.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzLVEtY6M_vb54qBuArpK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004735.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004735.jpg 320w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004735.jpg 640w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004735.jpg 1920w, https://townhall-11.clashbases.de/11_War/Townhall-11-Base-Layouts-20221026-004735.jpg 2430w"
                        title="Townhall 11 War Base" alt="Townhall 11 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAAEQAAAAItzKswcnRxmS1UzCZIZYQa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004601.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004601.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004601.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004601.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004601.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzKPN8vuz9dEvyoKNmCJ_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004431.jpg"
                        srcSet="https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004431.jpg 320w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004431.jpg 640w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004431.jpg 1920w, https://townhall-11.clashbases.de/11_Farm/Townhall-11-Base-Layouts-20221026-004431.jpg 2430w"
                        title="Townhall 11 Farm Base" alt="Townhall 11 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAAEQAAAAItzJ1qwapxVdyn4tgw-ii-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">11-001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2022-10-10" data-title="Townhall 11 | The Archive">
              <a href="https://ClashBases.de/Archive-Townhall-11" target="_blank" rel="noopener noreferrer">
                <div className="picture-item__inner townhall-showcase-13">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <img
                          src="https://assets.clashbases.de/Backgrounds/02Archive_1024w.png"
                          srcSet="https://assets.clashbases.de/Backgrounds/02Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/02Archive_1024w.png 1024w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 1920w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 2430w"
                          title="Townhall 11 | The Archive" alt="Townhall 11 | The Archive" />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><div className="link-color font-size-picture-descriptions">Visit the ClashBases archive</div><p className="index-number font-size-picture-descriptions">for all old TH 11 Base Layouts</p></figcaption>
                  </div>
                </div>
              </a>
            </figure>

            <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>

          <div className="homebase-paragraph-container">

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="video-ad-container" adID="cb5c0bc7-f4be-4ce9-90e4-f22d34d43e50" />
            </figure>

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">All the best Townhall 11 Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              Browse through our huge collection of clash of clans townhall 11 base layouts with links!<br />
              Competitive War base, legends league base, trophy base, farm base or just a casual base for aesthetics, we got them all. Find your favorite th 11 base build and import it directly into your game. <br /><br />
              Feel free to send in your own th 11 base layouts! You will be showcased inside the gallery with your name and player tag, and you will be filterable through the contributor tag!<br /><br />
              <br />
              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default Townhalleleven;
