import React from 'react';
import { Link } from 'react-router-dom';
import './Toolbar.css';
import '../SideDrawer/SideDrawerButton';
import SideDrawerButton from '../SideDrawer/SideDrawerButton';

const toolbar = props => {

    const { classN, scroll, bgColor, position, index, opacity, drawerClickHandler } = props;

    if(scroll > 250 && classN !== null) {
        if(document.getElementsByClassName('toolbar').length > 1) {
            document.getElementsByClassName('toolbar')[0].classList.add(classN);
        }
    };

    return <header
    className="toolbar"
    style={{background: bgColor ? bgColor : null,
     position: position ? position : null,
     zIndex: index ? index : null,
     opacity: opacity ? opacity : null }}>
        <nav className="toolbar-nav">
            <div>
                <SideDrawerButton click={drawerClickHandler}/>
            </div>
            <div className="toolbar-logo"><Link to="/"><img alt="ClashBases - Base Layout and Link Collection Logo" src='https://assets.clashbases.de/Logos/normal-white-logo.png' /></Link></div>
            <div className="toolbar-small-logo"><Link to="/"><img alt="ClashBases - Base Layout and Link Collection Logo" src='https://assets.clashbases.de/Logos/small-white-logo.png' /></Link></div>
            <div className="toolbar-links">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <div className="dropdown-toolbar">
                      <li><Link to='/Wiki'>Clash Wiki</Link></li>
                        <div className="dropdown-toolbar-content">
                          <Link to='/Wiki-HomeVillage'>Home Village</Link>
                          <Link to='/Wiki-BuilderVillage'>Builder Village</Link>
                          <Link to='/Wiki'>Know How</Link>
                        </div>
                      </div>
                    <div className="dropdown-toolbar">
                    <li><Link to='/Townhall-Selection'>Base Layouts</Link></li>
                      <div className="dropdown-toolbar-content">
                        <Link to='/Archive-Townhall-14'>The Archive</Link>
                        <Link to='/BuilderBase'>Builder Base</Link>
                        <Link to='/Townhall-15'>Townhall 15</Link>
                        <Link to='/Townhall-14'>Townhall 14</Link>
                        <Link to='/Townhall-13'>Townhall 13</Link>
                        <Link to='/Townhall-12'>Townhall 12</Link>
                        <Link to='/Townhall-11'>Townhall 11</Link>
                        <Link to='/Townhall-10'>Townhall 10</Link>
                        <Link to='/Townhall-9'>Townhall 9</Link>
                        <Link to='/Townhall-8'>Townhall 8</Link>
                        <Link to='/Townhall-7'>Townhall 7</Link>
                        <Link to='/Townhall-6'>Townhall 6</Link>
                      </div>
                    </div>
                    <li><Link to='/About'>About</Link></li>
                </ul>
            </div>
            <div className="discord-toolbar-logo-container"><a href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer"><img alt="ClashBases - Base Layout and Link Collection Discord Server" className="discord-toolbar-logo" src="https://assets.clashbases.de/Logos/discordlogo_150w.png" /></a></div>
        </nav>
    </header>
};

export default toolbar;
