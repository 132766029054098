import React, { Component } from 'react';
import './TownhallFifteen.scss';
import Townhallfifteendemo from './TownhallFifteenScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class Townhallfifteen extends Component {

  componentDidMount () {
    window.townhallthirteendemo = new Townhallfifteendemo(document.getElementById('grid'));
  }

  zoom = mediumZoom ({background:"rgba(0, 0, 0, 0.5)"})


  render() {
    return (

      <section className="th15-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 15 Base Layouts and Links</title>
            <meta name="description" content="NEW Townhall 14 Base Layouts with Import Links! Browse through our Gallery and import your favorite TH 14 War Bases, Farm Bases, Trophy Bases and Legends League Bases directly into your Game!" />
            <meta name="keywords" content="th 14, ths 14, th thirteen, townhall 14, townhall 14 base, th 14 links, links, th 14 base design, th 14 bases, th 14 warbase, warbase, base for war, clan war base, clanwar base th 14, war base th 14, townhall 10 war base, townhall 10 base for war, th 14 trophybase, trophybase, base for trophys, trophy base th 14, townhall 10 trophy base, townhall 10 base for trophy, th 14 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 14, townhall 10 push base, townhall 10 base for pushing, th 14 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 14, townhall 14 farm base, townhall 14 base for farming, troll bases, th 14 troll base, th 14 fun base, th 14 funny base, th 14 trolling, funny th 14 base, best th 14 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 14 island base, ring base, th 14 ring base, teaser, th 14 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 14 links, th 14 base link, th 14 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="th15-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th15-container-about">
            <div className="th15-row-about">
              <div className="th15-title-container">
                <h1 className="th15-about-title">Townhall <span style={{color: 'rgb(185 5 154)'}}>15</span> Base Layouts</h1>

                <div className="switch-container-th15">
                  <LinkButton to="/Townhall-11" className="switch-buttons-th15 switch-to-th11-15">
                    <div className="switch-buttons-text-15">Lower</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-12" className="switch-buttons-th15 switch-to-th12-15">
                    <div className="switch-buttons-text-15">TH 12</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-13" className="switch-buttons-th15 switch-to-th13-15">
                    <div className="switch-buttons-text-15">TH 13</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-14" className="switch-buttons-th15 switch-to-th14-15">
                    <div className="switch-buttons-text-15">TH 14</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-15" className="switch-buttons-th15 switch-to-th15-15">
                    <div className="switch-buttons-text-15">TH 15</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th15-container-about">
            <div className="th15-row-about">
              <div className="col-6@sm th15-filters-group-wrap">
                <div className="th15-filters-group filters-group-right">
                  <p className="th15-filter-label th15-filter-color">Filter</p>
                  <div className="btn-group th15-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th15-container-about">

            <div id="grid" className="th15-row-about my-shuffle-th15-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003917.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003917.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003917.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003917.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003917.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcTBO7KzbabLA5_oKFz9pR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 15 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-003608.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-003608.jpg 320w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-003608.jpg 640w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-003608.jpg 1920w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-003608.jpg 2430w"
                          title="Townhall 15 Farm Base" alt="Townhall 15 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AHV%3AAAAAGQAAAAIcTAUSsmHXuepKZNfL__iN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003251.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003251.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003251.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003251.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-003251.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS_ZdFTSk46tYEA5LuHgk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002953.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002953.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002953.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002953.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002953.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS-hCKaMBZRnA7RyjLhch" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002656.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002656.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002656.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002656.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-002656.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS9sVwuEiUignWkJ2sgiq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-001944.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-001944.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-001944.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-001944.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-001944.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS7uKr6SDGzFpOKwUXMc3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 15 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-001244.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-001244.jpg 320w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-001244.jpg 640w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-001244.jpg 1920w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-001244.jpg 2430w"
                          title="Townhall 15 Farm Base" alt="Townhall 15 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AHV%3AAAAAGQAAAAIcS5z_Bfl2hd_JEi4mFLGJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000646.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000646.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000646.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000646.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000646.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS4IcFRSoD3R5oph_8_ET" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 15 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000126.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000126.jpg 320w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000126.jpg 640w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000126.jpg 1920w, https://townhall-6.clashbases.de/15_War/Townhall-15-Base-Layouts-20221026-000126.jpg 2430w"
                          title="Townhall 15 War Base" alt="Townhall 15 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AWB%3AAAAAGQAAAAIcS2p3cB99mkhf7zUHQL4k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 15 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-000031.jpg"
                          srcSet="https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-000031.jpg 320w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-000031.jpg 640w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-000031.jpg 1920w, https://townhall-6.clashbases.de/15_Farm/Townhall-15-Base-Layouts-20221026-000031.jpg 2430w"
                          title="Townhall 15 Farm Base" alt="Townhall 15 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH15%3AHV%3AAAAAGQAAAAIcS2YM05jcbLeUui23TDir" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">15-001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New</p>
                    </div>
                  </div>
                </div>
              </figure>

            <div className="col-1@sm col-1@xs my-sizer-element"></div>
          </div>
        </div>



        <div className="homebase-paragraph-container">

          <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
            <Ad adType="video-ad-container" adID="cb5c0bc7-f4be-4ce9-90e4-f22d34d43e50" />
          </figure>

          <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
            <div className="discord-container">
              <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
              <div className="discord-message-container">
                <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
              </div>
            </div>
          </a>

          <h1 className="homebase-paragraph-title">Brand-Spanking-New Townhall 15 Base Layouts<br /></h1>
          <p className="homebase-paragraph">
            Browse through our new collection of clash of clans townhall 15 base layouts with links!<br />
            War base, Trophy base, farm base or just a casual base for aesthetics, we got them all. Find your favorite th 15 base build and import it directly into your game. <br /><br /><br />
          We will keep adding to this collection of th 15 base layouts with links over the next couple of weeks, so make sure to keep on checking in!<br /><br />

            You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
          </p>
        </div>



        </div>

      </section>

    );
  };
};

export default Townhallfifteen;
