import React from 'react';
import { Link } from 'react-router-dom';
import './Showcase.css';
import '../1_MainBases/A12_TownhallTwelve/TownhallTwelve.scss';
import {Helmet} from "react-helmet";


const showcase = props => (

  <section className="showcase">
    <Helmet>
      <meta charSet="utf-8" />
      <title>ClashBases - Clash of Clans Base Layouts</title>
      <meta name="description" content="Your go-to source for the best base Layouts in Clash of Clans. Filter through our up-to-date gallery and add your favorite bases via the link provided directly into the game!" />
      <meta name="keywords" content="Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, tonwhall 12, th12, townhall 11, th11, clan wars, clan war base, clanwar base, war base, cw, league, builder base, night base, builder base 9, builder base 8, farm, farming, farming base, loot, legend, legend league, titan, titan league, trophy, trophy base, trophybase, troll, trollbase, mobile game, gaming, base builder, link, shared base base, link" />
    </Helmet>

        <div className="showcase-img">
          <div className="showcase-darken">
          <div className="showcase-container-outer">
            <div className="showcase-container-middle">
              <div className="showcase-container-inner">
                <div className="link-container-text"></div>
                <div className="link-container">
                  <Link to="/Townhall-Selection"><button className="get-started">Clash Bases</button></Link>
                </div>
                <div className="link-container">
                  <Link to="/Wiki"><button className="get-started2">Wiki</button></Link>
                </div>
                <div className="link-container">
                  <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer"><button className="get-started3">Patreon</button></a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>


  </section>

);

export default showcase;
