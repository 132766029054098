import React from 'react';
import { Link as LinkToAnchor } from "react-scroll";
import { Link } from 'react-router-dom';
import './Wiki.css';
import {Helmet} from "react-helmet";



const Introhomebasewiki = props => (

  <section className="wiki-home">
    <Helmet>
      <meta charSet="utf-8" />
      <title>ClashBases Wiki - Home Village</title>
      <meta name="description" content="" />
      <meta name="keywords" content="" />
    </Helmet>

      <div className="wiki-img">

        <div className="wiki-container-outer">
          <div className="wiki-container-middle">
            <div className="wiki-container-inner">

              <div className="introHomebase-container">
                <div className="introHomebase-subcontainer">
                  <LinkToAnchor to="Buildings" smooth={true} offset={-70}><button className="introlink-4">Buildings</button></LinkToAnchor>
                </div>
                <div className="introHomebase-subcontainer">
                  <LinkToAnchor to="Spells" smooth={true} offset={-70}><button className="introlink-5">Spells</button></LinkToAnchor>
                </div>
                <div className="introHomebase-subcontainer">
                  <LinkToAnchor to="Troops" smooth={true} offset={-70}><button className="introlink-6">Troops</button></LinkToAnchor>
                </div>
                <div className="wiki-back">
                  <Link to="/Wiki"><button className="introlink-back"><span>&#10229;</span></button></Link>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>


  </section>

);

export default Introhomebasewiki;
