import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class ArchiveTownhalleleven extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 11</title>
            <meta name="description" content="Welcome to the ClashBases TH 11 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(255, 255, 255)'}}>11</span></h1>

                <div className="switch-container-archive">
                  <LinkButton to="/Townhall-11" className="switch-buttons-archive switch-to-archive">
                    <div>Switch back to Current TH 11 Base Layouts</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">
            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2020-05-15" data-title="Townhall 11 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-11">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext.jpg"
                        srcSet="https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1024w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-11-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 11 | Base Layouts and Links" alt="Townhall 11 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 11</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-181134_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-181134_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-181134_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-181134_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-181134_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPbOdtkur2PkHIlanpycV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E088</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-180438_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-180438_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-180438_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-180438_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-180438_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPYOvWjO5bzlujm2N8T6S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E087</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-175724_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-175724_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-175724_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-175724_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-175724_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPVD68Tn0gDzPZcaIuAec" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E086</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174956_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174956_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174956_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174956_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174956_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPR3d5mTguO8Mkom8JPpD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E085</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174538_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174538_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174538_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174538_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-174538_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPP-JBH_DbGD0OOsg1gwd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E084</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173758_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173758_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173758_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173758_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173758_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPMnNWh9ghTuzRXauGxqX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E083</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173143_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173143_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173143_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173143_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-173143_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPJzqL4omtdOv_tt2S6bY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E082</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-06-10" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-172553_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-172553_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-172553_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-172553_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-172553_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base" alt="Townhall 11 | Farm Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAALAAAAAGMPHOg2b6Epsc3K7pOPpcf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E081</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-171636_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-171636_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-171636_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-171636_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-171636_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMPDD_-z0D0ZoMHd1fLo6Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E080</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-06-10" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-170843_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-170843_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-170843_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-170843_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-13-Base-Layout-20200601-170843_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base" alt="Townhall 11 | Farm Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAALAAAAAGMO_e56OiqzV15-L3izfBh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E079</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-165902_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-165902_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-165902_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-165902_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-13-Base-Layout-20200601-165902_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGMO7AuIq41-kTGVRES6Crj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E078</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200501-002447_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200501-002447_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200501-002447_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200501-002447_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200501-002447_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHbWgmpxh-mltqjbU_3PfR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E066</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-05-01" data-title="Townhall 11 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-20200501-001550_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-20200501-001550_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-20200501-001550_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-20200501-001550_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-20200501-001550_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base" alt="Townhall 11 | Farm Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AHV%3AAAAALAAAAAGHbTVWqAhOvwFLBpEoV18J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E065</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-235022_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-235022_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-235022_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-235022_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-235022_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHbPy-lKN6bXSpIqProy2d" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E064</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-223804_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-223804_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-223804_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-223804_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-223804_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHawVAsARh9RirFJWcEJkp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E063</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-222918_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-222918_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-222918_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-222918_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-222918_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHatI1ZZrFrwQ4k27ZZRQq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E062</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-221605_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-221605_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-221605_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-221605_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-221605_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHaoVRpleGLIosUoi1aQyb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-214922_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-214922_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-214922_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-214922_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-214922_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHaegfjscgQ7zsJhaxri8A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 11 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-212310_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-212310_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-212310_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-212310_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-20200430-212310_1920w.jpg 2430w"
                        title="Townhall 11 | War Base" alt="Townhall 11 | War Base" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAALAAAAAGHaUzhBrHoqy_T63LI6axf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-21-23-08-47674_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-21-23-08-47674_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-21-23-08-47674_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-21-23-08-47674_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-21-23-08-47674_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHOnEcORuJPJo1TD7-WVlcb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E058</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-01-39-15-15145_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-01-39-15-15145_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-01-39-15-15145_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-01-39-15-15145_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Townhall-11-Base-Layout-2020-03-31-01-39-15-15145_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHOnEzJpW-JQNGET6kUvT55" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E057</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 11 farm Base by Hölle auf Erden">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-2020-03-31-21-23-08-9654_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-2020-03-31-21-23-08-9654_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-2020-03-31-21-23-08-9654_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-2020-03-31-21-23-08-9654_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Townhall-11-Base-Layout-2020-03-31-21-23-08-9654_1920w.jpg 2430w"
                        title="Townhall 11 Farm Base by Hölle auf Erden" alt="Townhall 11 Farm Base by Hölle auf Erden" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHG2a9kndBUGAQH31B4cHQS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E056</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=RGP9GV90" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Hölle auf Erden</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-09" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-23-59-20-767_com.supercell_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-23-59-20-767_com.supercell_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-23-59-20-767_com.supercell_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-23-59-20-767_com.supercell_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-23-59-20-767_com.supercell_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHG2a9kndBUGAQH31B4cHQS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E055</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-09" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-10-00-57-03-518_com.supercell_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-10-00-57-03-518_com.supercell_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-10-00-57-03-518_com.supercell_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-10-00-57-03-518_com.supercell_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-10-00-57-03-518_com.supercell_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHG2tIBU2TFQtHYmi4-at4b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E054</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-09" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-16-29-24-104_com.supercell_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-16-29-24-104_com.supercell_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-16-29-24-104_com.supercell_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-16-29-24-104_com.supercell_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-16-29-24-104_com.supercell_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHG1-Jgv0lRfTKrVBviz1Cn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E053</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-09" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-10-16-10-359_com.supercell_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-10-16-10-359_com.supercell_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-10-16-10-359_com.supercell_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-10-16-10-359_com.supercell_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-10-16-10-359_com.supercell_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHGwkwl8ErPOLph9O_oeLpV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E052</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-09" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-11-43-24-859_com.supercell_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-11-43-24-859_com.supercell_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-11-43-24-859_com.supercell_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-11-43-24-859_com.supercell_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-09-11-43-24-859_com.supercell_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHGxKAi2dZ1NY0Kp7_ptvMa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E051</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-50-45-528_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-50-45-528_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-50-45-528_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-50-45-528_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-50-45-528_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHGtFH3pGVmGZZcrWMa6v-8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E050</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-38-01-037_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-38-01-037_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-38-01-037_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-38-01-037_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-38-01-037_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHGtDUgdCzn1YZp5kRzh2fe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E049</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 11 War Base by Wayward_Duke">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-39-53-226_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-39-53-226_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-39-53-226_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-39-53-226_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_2020-02-08-23-39-53-226_1920w.jpg 2430w"
                        title="Townhall 11 War Base by Wayward_Duke" alt="Townhall 11 War Base by Wayward_Duke" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH11%3AWB%3AAAAACAAAAAHGtC5Lq5Fg6FqQyri55atV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E048</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=VY2LJQGU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Wayward_Duke</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-30" data-title="Anti 2 Star Tetra-Island War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224827_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224827_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224827_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224827_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224827_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Tetra-Island Base." alt="Townhall 11 | War Base Anti 2 Star Tetra-Island Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0uK9zVOi7n94gtCL5sweB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-30" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235043_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235043_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235043_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235043_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235043_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lO0oeW7zvAdO0n4FwLuh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-30" data-title="Anti 2 Star Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-224457_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-224457_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-224457_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-224457_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-224457_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy Base Anti 2 Star." alt="Townhall 11 | Trophy Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0uJ2ZZLnIZuvTuJ9vhhhS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-30" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000034_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000034_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000034_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000034_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000034_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star by eVe VISION." alt="Townhall 11 | War Base Anti 3 Star by eVe VISION." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lRiGSWRlsZy44BKldnCd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p> {/* by eVe VISION */}
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-29" data-title="Anti 2 Star Centralized Townhall Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224145_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224145_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224145_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224145_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224145_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Anti 2 Star Centralized Townhall." alt="Townhall 11 | Farm Base Anti 2 Star Centralized Townhall." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0uI30WyZwPcDgRO1qJ-WH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-29" data-title="Anti 2 Star Centralized Townhall Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-222034_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-222034_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-222034_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-222034_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-222034_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Anti 2 Star Centralized Townhall." alt="Townhall 11 | Farm Base Anti 2 Star Centralized Townhall." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0uCG0aYuCsSMAzRkxfeCk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-29" data-title="Symmetrical Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000944_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000944_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000944_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000944_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000944_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Symmetrical Anti 3 Star." alt="Townhall 11 | War Base Symmetrical Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lUFSYLJ8Uj5kepZFNen3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-29" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221226_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221226_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221226_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221226_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221226_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star." alt="Townhall 11 | War Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0t_kOt5d6kWngkSTjMlTh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-29" data-title="Anti 2 Star Corridor War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-162708_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-162708_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-162708_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-162708_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-162708_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Anti Bowler." alt="Townhall 11 | War Base Anti 2 Star Anti Bowler." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0rvyZxXnwc6Bom6l_Fl5V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-25" data-title="Central Corridor Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-220906_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-220906_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-220906_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-220906_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-220906_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy Base Central Corridor." alt="Townhall 11 | Trophy Base Central Corridor." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0t-c87snrwT1u66cdYBaL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-29" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-160713_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-160713_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-160713_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-160713_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-160713_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Anti Bats." alt="Townhall 11 | War Base Anti 2 Star Anti Bats." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0rlF7lG3-qvbef5RlECXf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-29" data-title="War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-212855_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-212855_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-212855_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-212855_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-212855_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base." alt="Townhall 11 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0tw1UysR1S7LL1EDPzD9V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 2 Star Tetra-Box Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-190335_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-190335_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-190335_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-190335_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-190335_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Tetra-Box Base." alt="Townhall 11 | War Base Anti 2 Star Tetra-Box Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0s6w3QOI1y4QF_xIJZ4Xp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 2 Star Southern Teaser War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-184655_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-184655_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-184655_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-184655_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-184655_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Southern Teaser." alt="Townhall 11 | War Base Anti 2 Star Southern Teaser." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0szn7hB1yOCDqfGPY3S1x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 2 Star Circle War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-183259_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-183259_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-183259_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-183259_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-183259_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star Circle Base." alt="Townhall 11 | War Base Anti 2 Star Circle Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0strjsOPbkrayMUfLuGHl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 3 Star Southern Teslafarm War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-180718_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-180718_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-180718_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-180718_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-180718_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star Southern Teslafarm." alt="Townhall 11 | War Base Anti 3 Star Southern Teslafarm." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0sh9tuLKtRtmkWXqJF5b9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-174606_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-174606_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-174606_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-174606_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-174606_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0sYHuRU3c0-ztb9W7uHgC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Anti 3 Star Protected Townhall War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-173059_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-173059_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-173059_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-173059_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-173059_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star Protected Townhall." alt="Townhall 11 | War Base Anti 3 Star Protected Townhall." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0sQz_gNUanBLTObDQnn0X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Standard War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-171717_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-171717_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-171717_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-171717_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-171717_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Standard." alt="Townhall 11 | War Base Standard." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0sKDJHvJPcCay0k8Th16y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-170043_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-170043_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-170043_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-170043_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-170043_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star." alt="Townhall 11 | War Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0sBmVpAp31hXLKDjd26LU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-164422_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-164422_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-164422_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-164422_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-164422_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star." alt="Townhall 11 | War Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0r5Bws7cxHGgNYoGbFCrh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Anti Bowler War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221019_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221019_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221019_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221019_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221019_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti Bowler." alt="Townhall 11 | War Base Anti Bowler." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0t-4tUjpJRFbavY7B-CSz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="War Base Anti 3 Star">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-220311_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-220311_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-220311_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-220311_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-220311_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0t8nTgHkI5Q8fNOCE9sce" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-154429_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-154429_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-154429_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-154429_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-154429_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0rZEOhGzbQ9ztx4PN1OUV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "farm"]' data-date-created="2019-06-26" data-title="Empty Centralized Townhall">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005915_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005915_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005915_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005915_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005915_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy/Farm Base Empty Centralized Townhall Base." alt="Townhall 11 | Trophy/Farm Base Empty Centralized Townhall Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lipd7nj8iUB7xes58_by" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">T/F</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "farm"]' data-date-created="2019-06-25" data-title="Anti 2 Star Trophy/Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005349_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005349_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005349_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005349_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-005349_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy/Farm Base Anti 2 Star." alt="Townhall 11 | Trophy/Farm Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lhFxdQ5fZuZi1Sr7MCZ1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">T/F</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-25" data-title="Symmetrical Standard Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-005128_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-005128_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-005128_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-005128_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-005128_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Symmetrical Standard Circe Base." alt="Townhall 11 | Farm Base Symmetrical Standard Circe Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lgWLULpnw0apcvR6QwhC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2019-06-25" data-title="Symmetrical Stretched Farm/Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004941_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004941_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004941_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004941_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004941_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm/Trophy Base Stretched Anti 2 Star Island Base." alt="Townhall 11 | Farm/Trophy Base Stretched Anti 2 Star Island Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lf0crcM22McU37hHTyvh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">F/T</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-25" data-title="Fortified Townhall Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-004633_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-004633_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-004633_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-004633_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-004633_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy Base Fortified Townhall." alt="Townhall 11 | Trophy Base Fortified Townhall." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0le5vkq8wvsK7F8OivJ4U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Townhall 11 Ring War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224300_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224300_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224300_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224300_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-224300_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Ring Base." alt="Townhall 11 | War Base Ring Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0uJTbCNxglM88yJmUTDYT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Central Island Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003731_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003731_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003731_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003731_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003731_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Central Island Base." alt="Townhall 11 | Farm Base Central Island Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lcI_5qSV9JV-iyoGLAV2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Square Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003342_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003342_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003342_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003342_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-003342_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Square Centralized Eagle." alt="Townhall 11 | Farm Base Square Centralized Eagle." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0la-naqyqH_NhQTLDYu42" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Compact Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002221_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002221_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002221_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002221_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002221_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Compact Anti 2 Star." alt="Townhall 11 | Farm Base Compact Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lXqS1RrUNsZUs3wdqA-7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Box Farming Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002019_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002019_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002019_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002019_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-002019_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy Base Fortified Townhall." alt="Townhall 11 | Trophy Base Fortified Townhall." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lXErI6rdlqYjdugpqwC8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-24" data-title="Island Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-001845_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-001845_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-001845_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-001845_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Trophy/Screenshot_20190703-001845_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Trophy Base Tetra-Island Base." alt="Townhall 11 | Trophy Base Tetra-Island Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lWmJBtCTIwldQKq3K1Wn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Standard Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001519_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001519_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001519_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001519_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001519_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Standard Anti 2 Star." alt="Townhall 11 | Farm Base Standard Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lVo3LmYaxw_n_q47bQbC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-23" data-title="Circle Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001154_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001154_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001154_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001154_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-001154_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Symmetrical Circle Base." alt="Townhall 11 | Farm Base Symmetrical Circle Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lUtu8OpT5rObj-hnXid4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-23" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221436_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221436_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221436_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221436_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-221436_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star." alt="Townhall 11 | War Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0uAV_iLGAVzteyrhMbvUx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-23" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000529_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000529_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000529_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000529_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190703-000529_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star by eVe Phönix." alt="Townhall 11 | War Base Anti 3 Star by eVe Phönix." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lS7RaNPsYgqGMvaPtEgD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p> {/* by eVe Phönix */}
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-23" data-title="Southern Teaser Style Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004414_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004414_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004414_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004414_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-004414_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Southern Teaser Style." alt="Townhall 11 | Farm Base Southern Teaser Style." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0leL8nCzZWTJFCDDigTrP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235814_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235814_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235814_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235814_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235814_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lQ6oPr-hTnmTFblODQ-9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti 2 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235443_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235443_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235443_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235443_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235443_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 2 Star." alt="Townhall 11 | War Base Anti 2 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lQCVoO-JaU5bWqJ2cTH5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235319_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235319_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235319_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235319_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-235319_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lPmXjEm4g3nJWKoSYpyJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["fun"]' data-date-created="2019-06-22" data-title="Building Lineup Townhall 11">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224602_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224602_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224602_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224602_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190703-224602_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Fun Base Building Lineup." alt="Townhall 11 | Fun Base Building Lineup." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0uKLcSEX156pkFXKsDhXv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fun</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-22" data-title="Dark Elixir Protector Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190702-234510_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190702-234510_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190702-234510_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190702-234510_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-Farm/Screenshot_20190702-234510_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | Farm Base Centralized Dark Elixir." alt="Townhall 11 | Farm Base Centralized Dark Elixir." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AHV%3AAAAAOwAAAAE0lNXLpLC5HO97Z7-AVEKM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-233411_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-233411_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-233411_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-233411_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-233411_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lKRO5Own-PoYQcNwJdFa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti 3 Star War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-232627_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-232627_Clash_of_Clans_640w.jpg 320w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-232627_Clash_of_Clans_1024w.jpg 640w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-232627_Clash_of_Clans_1920w.jpg 1920w, https://townhall-11.clashbases.de/Townhall-11-War/Screenshot_20190702-232627_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 11 | War Base Anti 3 Star." alt="Townhall 11 | War Base Anti 3 Star." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH11%3AWB%3AAAAAOwAAAAE0lIHpOaJ4vZsLpZfS2cU9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#E001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 11</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>



          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 11 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhalleleven;
