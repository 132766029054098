import React, { Component } from 'react';
import './Archive.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LazyLoad from 'react-lazyload';
import LinkButton from '../../LinkButton'
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class ArchiveTownhalltwelve extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }

  render() {
    return (

      <section>
        <div className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 12</title>
            <meta name="description" content="Welcome to the ClashBases TH 12 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

            <div className="container-about">
              <div className="row-about">
                <div className="archive-title-container">
                  <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(21, 115, 209)'}}>12</span></h1>

                  <div className="switch-container-archive">
                    <LinkButton to="/Townhall-12" className="switch-buttons-archive switch-to-archive">
                      <div>Switch back to Current TH 12 Base Layouts</div>
                    </LinkButton>
                  </div>

                </div>
              </div>
            </div>

            <div className="container-about">
              <div className="row-about">
                <div className="col-6@sm filters-group-wrap">
                  <div className="filters-group filters-group-right">
                    <p className="filter-label filter-color">Filter</p>
                    <div className="btn-group filter-options">
                      <button className="btn btn--primary" data-group="war">War</button>
                      <button className="btn btn--primary" data-group="trophy">Trophy</button>
                      <button className="btn btn--primary" data-group="farm">Farm</button>
                      <button className="btn btn--primary" data-group="world">World</button>
                    </div>
                  </div>
                </div>

                <div className="col-6@sm Patreon-Ad-container">
                  <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                  <LazyLoad throttle={250} offset={500}>
                    <img
                      src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                      srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                      title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                  </LazyLoad>
                  </a>
                </div>

              </div>
            </div>

            </div>

            <div className="archive-container-about">

              <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2019-12-10" data-title="Townhall 12 | Base Layouts and Links">
                <div className="picture-item__inner townhall-showcase-12">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://assets.clashbases.de/Backgrounds/townhall-12-wallpaper-notext.jpg"
                          srcSet="https://assets.clashbases.de/Backgrounds/townhall-12-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-12-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-12-wallpaper-notext_1920w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-12-wallpaper-notext_1920w.jpg 2430w"
                          title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 12</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2021-04-19" data-title="Townhall 12 War Base by Noobmaster69">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-BaseLayout-20210420-025324_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-BaseLayout-20210420-025324_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-BaseLayout-20210420-025324_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-BaseLayout-20210420-025324_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-BaseLayout-20210420-025324_1920w.jpg 2430w"
                          title="Townhall 12 War Base by Noobmaster69" alt="Townhall 12 War Base by Noobmaster69" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AWB%3AAAAACwAAAAHr1NE6FnpBItnG7b8ooFHX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H202</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=PLJUQ9YCY" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Noobmaster69</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-163636_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-163636_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-163636_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-163636_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-163636_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base." alt="Townhall 12 | Strong War Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTxrsfw6kMzaCwdJ4sxOA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H201</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Trophy Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-162124_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-162124_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-162124_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-162124_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-162124_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Trophy Base." alt="Townhall 12 | Strong Trophy Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTqwHN8ipFhCJRcDb2r49" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H200</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Trophy Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161830_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161830_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161830_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161830_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161830_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Trophy Base." alt="Townhall 12 | Strong Trophy Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTpcEMiLusy05dBdLKF1N" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H199</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Trophy Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161717_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161717_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161717_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161717_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-161717_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Trophy Base." alt="Townhall 12 | Strong Trophy Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTo5kbTrKqpF4YGc792x4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H198</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Farm Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161523_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161523_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161523_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161523_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161523_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Farm Base." alt="Townhall 12 | Strong Farm Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJToBYzMKTbSlXo4eru_ke" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H197</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Farm Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161437_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161437_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161437_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161437_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161437_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Farm Base." alt="Townhall 12 | Strong Farm Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTnqt7Kun39jxv6wP-loX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H196</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Farm Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161356_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161356_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161356_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161356_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Townhall-12-Base-Layout-Nov19-20191115-161356_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Farm Base." alt="Townhall 12 | Strong Farm Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTnXEV6wECuyDz_AEmXUu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H195</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-161023_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-161023_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-161023_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-161023_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-161023_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base Ring Base." alt="Townhall 12 | Strong War Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTltY2ewHWY1gGmgcdn7K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H194</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160849_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160849_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160849_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160849_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160849_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base Ring Base." alt="Townhall 12 | Strong War Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTlAPzpOyqgiPfv9k8iI6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H193</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160758_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160758_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160758_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160758_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160758_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base." alt="Townhall 12 | Strong War Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTkmOd7YomUOQbaHpuleR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H192</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160544_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160544_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160544_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160544_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160544_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base Ring Base." alt="Townhall 12 | Strong War Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTjjiXCjQn59DWJn2xV-8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H191</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160508_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160508_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160508_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160508_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160508_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base Ring Base." alt="Townhall 12 | Strong War Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTjQBNaYGAGFSca0H-jRp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H190</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Trophy Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160420_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160420_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160420_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160420_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160420_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Trophy Base Ring Base." alt="Townhall 12 | Strong Trophy Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTi5CRv8ab_6-7gQiqVC7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H189</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong Trophy Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160234_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160234_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160234_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160234_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Townhall-12-Base-Layout-Nov19-20191115-160234_1920w.jpg 2430w"
                          title="Townhall 12 | Strong Trophy Base Ring Base." alt="Townhall 12 | Strong Trophy Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTiDZYtRlHOj2SvY5FGIt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H188</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base Ring Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160015_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160015_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160015_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160015_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-160015_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base Ring Base." alt="Townhall 12 | Strong War Base Ring Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTg9ddGdCAFWBOyIBocsF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H187</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155809_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155809_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155809_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155809_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155809_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base." alt="Townhall 12 | Strong War Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGJTgT9O1eraOdPGu98spEF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H186</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-17" data-title="Townhall 12 | Strong War Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155500_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155500_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155500_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155500_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Townhall-12-Base-Layout-Nov19-20191115-155500_1920w.jpg 2430w"
                          title="Townhall 12 | Strong War Base." alt="Townhall 12 | Strong War Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGJTekyy-HPPbH9wQkSmzAW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H185</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["world"]' data-date-created="2019-10-28" data-title="All Clash of Clans World Championship Base Layouts!">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="/Pictures/clashofclans_wc_newsannouncement.png"
                          srcSet="/Pictures/clashofclans_wc_newsannouncement.png 300w"
                          title="All Clash of Clans World Championship Base Layouts!" alt="All Clash of Clans World Championship Base Layouts!" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Clash World</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Oct. 2019</p>
                      <p className="index-number font-size-picture-descriptions">25 - 27</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Wei from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-041526_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-041526_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-041526_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-041526_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-041526_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Wei from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Wei from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHyQtvZwXuwiE1k4zkvm2u" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H184</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Eryam from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-033108_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-033108_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-033108_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-033108_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-033108_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Eryam from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Eryam from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHyBYoME4hSxeDiIl_qY-r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H183</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by LP from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-031226_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-031226_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-031226_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-031226_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-031226_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by LP from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by LP from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHx6_AWrZ8c0y9U-n_PP33" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H182</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Hugo Stiglitz from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-030058_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-030058_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-030058_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-030058_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-030058_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Hugo Stiglitz from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Hugo Stiglitz from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHx3BC5Nm80l-bIXNeDvVh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H181</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by XC from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-023556_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-023556_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-023556_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-023556_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-023556_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by XC from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by XC from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxusA6pTm_pyO6AA_4tvS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H180</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Lenaide from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-022526_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-022526_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-022526_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-022526_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-022526_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Lenaide from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Lenaide from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxrJsfKqG_Lu8cerk_5Dg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H179</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Juan from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-021341_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-021341_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-021341_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-021341_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-021341_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Juan from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Juan from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxnUbuewEojm_UDeeaTET" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H178</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by TryHard from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-015014_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-015014_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-015014_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-015014_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-015014_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by TryHard from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by TryHard from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxf7xt8OxKPU_y7Kv0XlN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H177</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Youzi Pi from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-013645_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-013645_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-013645_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-013645_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-013645_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Youzi Pi from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Youzi Pi from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxbqdx9qCjWmGU5ZZmR6C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H176</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Synthé from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-010214_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-010214_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-010214_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-010214_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-010214_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Synthé from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 3 by Synthé from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxRAzEoNiVRPW_6v9FDft" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H175</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 3</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Synthé from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-003723_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-003723_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-003723_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-003723_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-003723_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Synthé from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Synthé from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxJnbbF2IlVrLKrkaVOFO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H174</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Juan from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-002419_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-002419_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-002419_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-002419_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-002419_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Juan from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Juan from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxF9bNh1nYMZXlM7T9-e-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H173</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by TryHard from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-001128_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-001128_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-001128_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-001128_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191103-001128_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by TryHard from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by TryHard from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHxCOqdjyHsplvYsl_b5IS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H172</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by XC from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-235927_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-235927_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-235927_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-235927_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-235927_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by XC from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by XC from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHw-7NQD5Krd42FDl-4Zxj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H171</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Lenaide from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-234706_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-234706_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-234706_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-234706_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-234706_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Lenaide from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Lenaide from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHw7xfW56PFn4ZkcXeJ9YA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H170</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Wei from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-233642_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-233642_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-233642_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-233642_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-233642_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Wei from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Wei from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHw5HoBzx7R6DgpFvW89rj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H169</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Hugo Stiglitz from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-121145_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-121145_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-121145_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-121145_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-121145_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Hugo Stiglitz from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Hugo Stiglitz from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHtJwEK7e0iP85u1pZK61o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H168</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by LP from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-120453_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-120453_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-120453_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-120453_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-120453_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by LP from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by LP from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHtG1tER_pelrD-yXYfEw4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H167</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Eryam from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-115510_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-115510_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-115510_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-115510_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-115510_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Eryam from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Eryam from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHtCyUv6xCyyWyX435MRMd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H166</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Youzi Pi from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-113059_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-113059_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-113059_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-113059_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-113059_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Youzi Pi from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 2 by Youzi Pi from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHs4031lQGj9K2bF4Y_mdA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H165</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 2</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Juan from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102805_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102805_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102805_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102805_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102805_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Juan from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Juan from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHsepJha-ZS8yTxc2wK9yU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H164</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Lenaide from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102118_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102118_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102118_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102118_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-102118_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Lenaide from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Lenaide from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHsb2TtlHCVRXYbfL31-Vp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H163</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by LP from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-094636_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-094636_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-094636_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-094636_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-094636_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by LP from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by LP from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHsNYSoE4w0eShN6-7kzgi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H162</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by TryHard from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-093600_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-093600_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-093600_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-093600_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-093600_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by TryHard from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by TryHard from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHsI7WklhNltcThVN_ZHzM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H161</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by XC from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-090832_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-090832_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-090832_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-090832_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-090832_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by XC from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by XC from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHr9h6R9M-qXbUmLAm2CyU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H160</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Hugo Stiglitz from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-082751_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-082751_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-082751_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-082751_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-082751_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Hugo Stiglitz from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Hugo Stiglitz from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGHrvLk3Ssh49u0LaEqnG9E" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H159</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Youzi Pi from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-081535_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-081535_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-081535_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-081535_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-081535_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Youzi Pi from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Youzi Pi from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHrq7jDOE7n2N3-SUIFrUa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H158</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Eryam from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-074746_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-074746_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-074746_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-074746_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-074746_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Eryam from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Eryam from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHrhoyFExYOgbb9PbOFsiW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H157</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Wei from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-072722_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-072722_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-072722_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-072722_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-072722_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Wei from NOVA." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Wei from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHrbC1jtdvlr4qP1aim3UM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H156</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-11-01" data-title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Synthé from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-071620_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-071620_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-071620_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-071620_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191102-071620_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Synthé from MCES." alt="Townhall 12 | Clash of Clans World Championship Finals, War Base 1 by Synthé from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHrXc1vutxKJc0rhmy4oKD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H155</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Final Round 1</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-091938_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-091938_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-091938_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-091938_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-091938_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHOQa2Z9b1dIQP0F7PUIrp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H154</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from Team QUESO.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-085222_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-085222_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-085222_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-085222_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-085222_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from Team QUESO." alt="Townhall 12 | Clash of Clans World Championship, War Base by Wei from Team QUESO." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHOHQSRg26dnL4uRjoDQNm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H153</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-082637_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-082637_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-082637_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-082637_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-082637_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHN-ww1b40fNBON2VZH1Y5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H152</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team QUESO.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-080241_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-080241_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-080241_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-080241_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-080241_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team QUESO." alt="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team QUESO." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHN28lD4bwu9rBpkG1J1N6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H151</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by LP from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-074152_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-074152_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-074152_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-074152_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-074152_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by LP from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by LP from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHNwKoptA6YscYOMM25HxN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H150</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team QUESO.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-071733_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-071733_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-071733_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-071733_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-071733_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team QUESO." alt="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team QUESO." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHNoTBzclkAgHIoT9Fg2go" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H149</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-062452_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-062452_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-062452_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-062452_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-062452_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHNWU2N8j_CpT1tD3owZ_c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H148</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Maicol DP from Team QUESO.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-055747_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-055747_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-055747_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-055747_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-055747_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Maikol DP from Team QUESO." alt="Townhall 12 | Clash of Clans World Championship, War Base by Maicol DP from Team QUESO." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHNMn3IUherHh68VP6qqXj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H147</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-053649_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-053649_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-053649_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-053649_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-053649_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHNFb0LxnJ4aZbgskH4mvg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H146</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team QUESO.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-051156_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-051156_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-051156_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-051156_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-051156_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team QUESO." alt="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team QUESO." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHM8uz5Jx2BSawAoTLmSrs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H145</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-030444_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-030444_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-030444_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-030444_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-030444_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHMVwelZuNflFj3yjpNebG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H144</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-024659_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-024659_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-024659_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-024659_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-024659_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHMQX1EM6Gn_rRXbhfX-me" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H143</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-022513_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-022513_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-022513_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-022513_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-022513_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHMJ4-jNyHBHrI3PJhg1ce" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H142</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-021030_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-021030_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-021030_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-021030_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-021030_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHMFob202nMRDnzDpGKLxs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H141</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-014524_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-014524_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-014524_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-014524_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-014524_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGHL-gmCTPDJyf2wYOxyKnr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H140</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-013035_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-013035_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-013035_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-013035_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-013035_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHL6RWpiBHQ903HL599bcP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H139</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Eryam from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-010450_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-010450_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-010450_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-010450_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-010450_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Eryam from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Eryam from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHLy2WT3TU-Bd_JH2MC709" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H138</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-005552_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-005552_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-005552_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-005552_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-005552_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHLwMaoE-zYRSXOuG8OEgN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H137</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-003145_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-003145_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-003145_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-003145_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-003145_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHLpeJmPQ9vFy11XBde7nk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H136</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-28" data-title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-002417_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-002417_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-002417_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-002417_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191029-002417_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGHLnXLgbUXUduQTVvD_-s7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H135</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Semi Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by TAD from ShenZhou.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-230243_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-230243_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-230243_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-230243_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-230243_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by TAD from ShenZhou." alt="Townhall 12 | Clash of Clans World Championship, War Base by TAD from ShenZhou." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5fcNjilwh-kJ6MRoDkTL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H134</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Shen Zhou</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Maicol DP from Team Queso.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-224949_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-224949_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-224949_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-224949_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-224949_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Maicol DP from Team Queso." alt="Townhall 12 | Clash of Clans World Championship, War Base by Maicol DP from Team Queso." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5bcJJcl3xStNaiSR2VMB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H133</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by PaulLeong from ShenZhou.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-222837_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-222837_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-222837_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-222837_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-222837_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by PaulLeong from ShenZhou." alt="Townhall 12 | Clash of Clans World Championship, War Base by PaulLeong from ShenZhou." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5U0Xb8gl94fCM8ZvsdIL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H132</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Shen Zhou</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Rigotorres23 from Team Queso.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-221133_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-221133_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-221133_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-221133_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-221133_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Rigotorres23 from Team Queso." alt="Townhall 12 | Clash of Clans World Championship, War Base by Rigotorres23 from Team Queso." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5PcBEckutvzE33_Tqqq6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H131</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Hai from ShenZhou.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-215359_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-215359_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-215359_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-215359_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-215359_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Hai from ShenZhou." alt="Townhall 12 | Clash of Clans World Championship, War Base by Hai from ShenZhou." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5JnmRzb0gopL6gpbZn1Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H130</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Shen Zhou</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team Queso.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-213637_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-213637_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-213637_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-213637_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-213637_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team Queso." alt="Townhall 12 | Clash of Clans World Championship, War Base by Oskivm from Team Queso." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG5DzGIzjCHrMOraXRUEx1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H129</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Catwalk from ShenZhou.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-212041_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-212041_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-212041_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-212041_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-212041_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Catwalk from ShenZhou." alt="Townhall 12 | Clash of Clans World Championship, War Base by Catwalk from ShenZhou." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4-Tu2pG__SnWoaJQyZJk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H128</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Shen Zhou</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team Queso.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-210453_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-210453_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-210453_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-210453_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-210453_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team Queso." alt="Townhall 12 | Clash of Clans World Championship, War Base by fed from Team Queso." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG44xdwNXJ7VTnQ6mIojB4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H127</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Wolf from ShenZhou.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-204458_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-204458_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-204458_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-204458_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-204458_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Wolf from ShenZhou." alt="Townhall 12 | Clash of Clans World Championship, War Base by Wolf from ShenZhou." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4xiVlO8j3Ypa7HYC8522" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H126</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Shen Zhou</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team Queso.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-202732_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-202732_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-202732_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-202732_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-202732_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team Queso." alt="Townhall 12 | Clash of Clans World Championship, War Base by Almualin from Team Queso." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4q_Cwokzgl99U0_W4xYL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H125</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team Queso</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-193341_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-193341_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-193341_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-193341_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-193341_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Youzi Pi from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4VPHDaZNN3MkqiLorg3w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H124</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by PSH from Vatang.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-191735_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-191735_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-191735_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-191735_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-191735_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by PSH from Vatang." alt="Townhall 12 | Clash of Clans World Championship, War Base by PSH from Vatang." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4Ok0IJKrkSXyZk_A_Ywy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H123</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Vatang</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Lp from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-190101_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-190101_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-190101_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-190101_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-190101_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Lp from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Lp from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG4HC2CIu36YrgU2TljRHG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H122</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Urek from Vatang.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-184441_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-184441_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-184441_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-184441_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-184441_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Urek from Vatang." alt="Townhall 12 | Clash of Clans World Championship, War Base by Urek from Vatang." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3_eGDoxG7a4u9DPhCXni" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H121</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Vatang</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-182324_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-182324_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-182324_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-182324_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-182324_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Juan from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG31Loy4awexWPWzciVnC-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H120</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by LansLota from Vatang.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-180705_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-180705_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-180705_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-180705_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-180705_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by LansLota from Vatang." alt="Townhall 12 | Clash of Clans World Championship, War Base by LansLota from Vatang." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3tKg3lQ5lWFSQSBE_Dzb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H119</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Vatang</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-175154_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-175154_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-175154_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-175154_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-175154_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by Wei from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3lYuseycx0siAhOaLxfe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H118</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by JungHun from Vatang.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-173550_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-173550_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-173550_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-173550_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-173550_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by JungHun from Vatang." alt="Townhall 12 | Clash of Clans World Championship, War Base by JungHun from Vatang." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3c-CmhkMWxDI1HPPtgMk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H117</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Vatang</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-171935_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-171935_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-171935_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-171935_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-171935_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA." alt="Townhall 12 | Clash of Clans World Championship, War Base by XC from NOVA." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3UWzBoALLCKOWy0wS45m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H116</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Team NOVA</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Caudron from Vatang.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-170233_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-170233_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-170233_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-170233_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-170233_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Caudron from Vatang." alt="Townhall 12 | Clash of Clans World Championship, War Base by Caudron from Vatang." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGG3LL5umK7bme32dKRE0FK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H115</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Vatang</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-095047_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-095047_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-095047_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-095047_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-095047_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Synthé from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGzwjmkKTFW29R55ydg8Tf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H114</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by EREN-CPB from QueeN Walkers.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-092816_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-092816_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-092816_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-092816_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-092816_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by EREN-CPB from QueeN Walkers." alt="Townhall 12 | Clash of Clans World Championship, War Base by EREN-CPB from QueeN Walkers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGzmSTK8laLOrUi6vUigmk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H113</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">QueeN Walkers</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-085950_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-085950_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-085950_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-085950_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-085950_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Lenaide from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGzZar_KJ-xpEFODRH15ot" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H112</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by stadra from QueeN Walkers.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-083725_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-083725_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-083725_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-083725_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-083725_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by stadra from QueeN Walkers." alt="Townhall 12 | Clash of Clans World Championship, War Base by stadra from QueeN Walkers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGzPNg89c44g-7Vi_PJH-2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H111</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">QueeN Walkers</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by ERYAM from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-081920_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-081920_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-081920_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-081920_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-081920_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by ERYAM from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by ERYAM from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGzHFVUMTQCs9hZ-mOaDub" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H110</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Klaus from QueeN Walkers.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-075911_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-075911_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-075911_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-075911_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-075911_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Klaus from QueeN Walkers." alt="Townhall 12 | Clash of Clans World Championship, War Base by Klaus from QueeN Walkers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGy95d8OylvVd8pUanFZgV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H109</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">QueeN Walkers</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-074235_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-074235_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-074235_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-074235_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-074235_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by Hugo Stiglitz from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGy2ZeldeVZMUxW9UknIY8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H108</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Yuta14 from QueeN Walkers.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-072519_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-072519_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-072519_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-072519_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-072519_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Yuta14 from QueeN Walkers." alt="Townhall 12 | Clash of Clans World Championship, War Base by Yuta14 from QueeN Walkers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGyueEtOlK_U3JRupw6ZkE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H107</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">QueeN Walkers</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-070650_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-070650_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-070650_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-070650_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-070650_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES." alt="Townhall 12 | Clash of Clans World Championship, War Base by TryHard from MCES." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGylxfwH4W9fwGHGPSTpYA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H106</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">MCES</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-26" data-title="Townhall 12 | Clash of Clans World Championship, War Base by GAKU from QueeN Walkers.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-064857_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-064857_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-064857_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-064857_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-064857_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by GAKU from QueeN Walkers." alt="Townhall 12 | Clash of Clans World Championship, War Base by GAKU from QueeN Walkers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGydT2bU15kBeiscGtfS1r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H105</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">QueeN Walkers</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Bernaul from INTZ.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-020713_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-020713_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-020713_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-020713_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-020713_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Bernaul from INTZ." alt="Townhall 12 | Clash of Clans World Championship, War Base by Bernaul from INTZ." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwn11T-3J-QWYPsD3JYGx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H104</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">INTZ</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-014916_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-014916_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-014916_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-014916_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-014916_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by BUMM from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwhQu2f88rvane2ns7kdx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H103</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Caetano from INTZ.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-012423_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-012423_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-012423_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-012423_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-012423_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Caetano from INTZ." alt="Townhall 12 | Clash of Clans World Championship, War Base by Caetano from INTZ." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwYX02B9JMtZPbgmfDExV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H102</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">INTZ</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-010610_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-010610_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-010610_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-010610_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-010610_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by Itzu from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwSBz-JYSIQGF0ZHkvN7m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H101</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Celinho from INTZ.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-004823_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-004823_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-004823_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-004823_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-004823_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Celinho from INTZ." alt="Townhall 12 | Clash of Clans World Championship, War Base by Celinho from INTZ." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwMY9XiKltGarSTHcVp7F" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H100</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">INTZ</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-003115_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-003115_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-003115_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-003115_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-003115_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by Vale from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwGq8n5qodO6WGeKLPBBa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H099</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by Marinaul from INTZ.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-001005_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-001005_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-001005_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-001005_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191026-001005_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by Marinaul from INTZ." alt="Townhall 12 | Clash of Clans World Championship, War Base by Marinaul from INTZ." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGwAWkHK4hhqgo8C-BMfMj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H098</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">INTZ</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-235000_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-235000_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-235000_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-235000_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-235000_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by eVe Cech from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGv6YNkKTLUNlpocOnRoXl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H097</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by LOOP from INTZ.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-232322_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-232322_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-232322_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-232322_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-232322_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by LOOP from INTZ." alt="Townhall 12 | Clash of Clans World Championship, War Base by LOOP from INTZ." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGvyW6xNzYOENt0Pke9fxE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H096</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">INTZ</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "world"]' data-date-created="2019-10-25" data-title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-230136_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-230136_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-230136_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-230136_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-World-Championship-20191025-230136_1920w.jpg 2430w"
                          title="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming." alt="Townhall 12 | Clash of Clans World Championship, War Base by eVe Maxi from Tribe Gaming." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGGvrpsewlH0Dvl9E0ICG6C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H095</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Tribe Gaming</p>
                      <p className="index-number font-size-picture-descriptions">Quarter Final</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031520_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031520_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031520_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031520_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031520_1920w.jpg 2430w"
                          title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKqFxyXEk6h1-uYVeVkdR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H094</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031429_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031429_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031429_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031429_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031429_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKpycsvfm8CaaMn2qQ64w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H093</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031330_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031330_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031330_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031330_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031330_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKpcXZRw7xJiA34skQR2a" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H092</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031233_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031233_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031233_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031233_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031233_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKpF0ro7DqIy2XmHXMXWs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H091</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031139_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031139_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031139_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031139_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031139_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKoxLLIS1rTCPToEy6UZH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H090</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031029_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031029_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031029_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031029_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-031029_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKoX23xc_xKrO6BuOiwWY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H089</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030933_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030933_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030933_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030933_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030933_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKoBd2m9ykfhPFdO14fZQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H088</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030825_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030825_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030825_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030825_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-030825_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 2." alt="Townhall 12 | War Base, August Qualifier Day 2." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKnniHnNc4oZjtEP4VvWo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H087</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024356_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024356_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024356_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024356_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024356_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 1." alt="Townhall 12 | War Base, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKeodK9L3Hdz7_QihP3yc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H086</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024308_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024308_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024308_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024308_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024308_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 1." alt="Townhall 12 | War Base, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKeXb3DJVZnK4LXNG5UJi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H085</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024218_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024218_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024218_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024218_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024218_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 1." alt="Townhall 12 | War Base, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKeGF1-jTPG-Oc5scCugR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H084</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024050_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024050_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024050_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024050_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-024050_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 1." alt="Townhall 12 | War Base, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKdmQlERx9ZXNpVPmcL0i" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H083</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base, August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023954_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023954_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023954_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023954_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023954_1920w.jpg 2430w"
                          title="Townhall 12 | War Base, August Qualifier Day 1." alt="Townhall 12 | War Base, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKdQOcgUuFQcV2q8xGF2K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H082</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by team Nova August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023829_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023829_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023829_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023829_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023829_1920w.jpg 2430w"
                          title="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." alt="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKcv6O3gChjSXFqK32fq1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H081</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by team Nova August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023730_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023730_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023730_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023730_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023730_1920w.jpg 2430w"
                          title="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." alt="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKcclud1HzXvwxuJpReXk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H080</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by team Nova August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023634_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023634_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023634_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023634_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023634_1920w.jpg 2430w"
                          title="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." alt="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKcHeiMFcv3s7nKgU42Is" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H079</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by team Nova August Qualifier Day 1">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023535_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023535_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023535_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023535_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-023535_1920w.jpg 2430w"
                          title="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." alt="Townhall 12 | War Base by team NOVA, August Qualifier Day 1." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKbveKBYckpbwoYsrUJFU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H078</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by team Nova">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015819_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015819_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015819_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015819_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015819_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by team NOVA." alt="Townhall 12 | War Base by team NOVA." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKPmJr_SuBs6PtXZQJBFp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H077</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base by God from team INTZ">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015520_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015520_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015520_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015520_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015520_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by God from team INTZ." alt="Townhall 12 | War Base by God from team INTZ." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKOoxcl0ijUIZAOvpFOrz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H076</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015156_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015156_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015156_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015156_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015156_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKNgTGy4u7_WaSShn49p_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H075</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015032_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015032_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015032_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015032_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-015032_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKMUlB06MfR5GY6DHvjBk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H074</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014905_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014905_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014905_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014905_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014905_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKLwgOhprEvOm_VYvu3-F" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H073</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014711_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014711_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014711_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014711_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014711_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKLLnoB0hDYgyl8V0s1-i" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H072</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014550_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014550_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014550_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014550_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014550_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKKucWt8bBC268SXEWp0G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H071</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014409_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014409_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014409_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014409_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014409_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKKMZzWB2ygVti2mAkDlf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H070</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014318_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014318_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014318_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014318_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014318_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKJ58J3UrhjdxyFumNrHu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H069</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014134_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014134_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014134_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014134_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014134_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKJVS_v9F-vLol_djTCU3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H068</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014023_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014023_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014023_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014023_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-014023_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKI7aewbYygpnh4RGDHor" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H067</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013915_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013915_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013915_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013915_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013915_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKIl5Y5jI6Bmc8p4E78F0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H066</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013737_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013737_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013737_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013737_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013737_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKIFz47YesN_XuyXkLQiT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H065</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013625_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013625_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013625_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013625_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013625_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKHs289re7vuKIkbN1fZv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H064</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013418_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013418_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013418_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013418_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013418_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKHFX3ScT2_bGSLZLVfXd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H063</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013247_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013247_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013247_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013247_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013247_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKGc8pVAV3JXhKd8ZGxAm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H062</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013022_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013022_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013022_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013022_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-013022_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGAKFsS52hp-SCNTHJoQoRz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H061</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-09-07" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-012845_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-012845_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-012845_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-012845_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-Warbase-Clash-of-Clans-20190907-012845_1920w.jpg 2430w"
                            title="Townhall 12 | War Base." alt="Townhall 12 | War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAGAKFJM-3wtvcD4TubJEufX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H060</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-08-02" data-title="TH 12 War Base Anti Everything by bladders from Proc, July World Championsip Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035440_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035440_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035440_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035440_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035440_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti Everything by bladders from Proc, July World Championsip Qualifiers." alt="Townhall 12 | War Base Anti Everything by bladders from Proc, July World Championsip Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wc2Xjb8B6UBR0PEyHMUM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H059</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-08-02" data-title="TH 12 Trophy Base Anti 2 Star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-035308_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-035308_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-035308_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-035308_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-035308_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Anti 2 Star." alt="Townhall 12 | Trophy Base Anti 2 Star." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wY_XrrlD39XUHRtSdwtq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H058</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>


                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-08-02" data-title="TH 12 War Base Anti 3 Star by LP from NOVA, July Wold Championship Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035015_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035015_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035015_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035015_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-035015_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti 3 Star by LP from NOVA, July Wold Championship Qualifiers." alt="Townhall 12 | War Base Anti 3 Star by LP from NOVA, July Wold Championship Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wY_XrrlD39XUHRtSdwtq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H057</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-08-01" data-title="TH 12 War Base Anti 3 Star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-034702_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-034702_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-034702_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-034702_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-034702_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti 3 Star." alt="Townhall 12 | War Base Anti 3 Star." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wXl3UxyAi9s7fLHkG-_Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H056</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-08-01" data-title="TH 12 Trophy Base Anti 2 Star Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034256_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034256_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034256_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034256_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034256_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Anti 2 Star Island Base" alt="Townhall 12 | Trophy Base Anti 2 Star Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wV2gR2DmY1iezYUTOtN2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H055</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-08-01" data-title="TH 12 Trophy Base Anti 3 Star Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034013_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034013_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034013_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034013_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Th12-TrophyBase-Screenshot_20190803-034013_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Standard-Style Island Base" alt="Townhall 12 | Trophy Base Standard-Style Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wUqVYuS5z_R8duQzMFcd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H054</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-08-01" data-title="TH 12 War Base Anti 3 Star Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-IslandBase-Screenshot_20190803-033732_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-IslandBase-Screenshot_20190803-033732_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-IslandBase-Screenshot_20190803-033732_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-IslandBase-Screenshot_20190803-033732_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-IslandBase-Screenshot_20190803-033732_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Standard-Style Island Base" alt="Townhall 12 | War Base Standard-Style Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wTloQB5U2qKFzENonV5Z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H053</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-08-01" data-title="TH 12 Farm Base Anti 3 Star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033304_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033304_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033304_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033304_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033304_1920w.jpg 2430w"
                            title="Townhall 12 | Farm Base Anti 3 Star" alt="Townhall 12 | Farm Base Standard-Style Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wRqw2-wUYaQHERpHcgLi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H052</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-07-30" data-title="TH 12 Farm Base Standard-Style Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033401_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033401_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033401_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033401_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033401_1920w.jpg 2430w"
                            title="Townhall 12 | Farm Base Standard-Style Base" alt="Townhall 12 | Farm Base Standard-Style Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wSF3YA3RfvtSQQh0kkBG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H051</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-07-30" data-title="TH 12 Farm Base Ring-Style Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033008_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033008_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033008_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033008_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Th12-FarmBase-Screenshot_20190803-033008_1920w.jpg 2430w"
                            title="Townhall 12 | Farm Base Ring-Style Base" alt="Townhall 12 | Farm Base Ring-Style Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wQfONwfD_kbvRhKnvCYQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H050</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-30" data-title="TH 12 War Base Vertical Anti 3 star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032756_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032756_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032756_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032756_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032756_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Vertical Anti 3 Stars" alt="Townhall 12 | War Vertical Anti 3 Stars." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF6wPkFXsmBDjAqAbPipjcP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H049</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-30" data-title="TH 12 War Base Protected Townhall Anti 3 star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032611_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032611_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032611_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032611_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-032611_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Protected Townhall Anti 3 Stars" alt="Townhall 12 | War BaseProtected Townhall Anti 3 Stars." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF6wPLhGBxAhsgdScQQQN-k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H048</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-30" data-title="TH 12 War Base Anti 3 star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031120_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031120_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031120_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031120_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031120_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Island Style Anti 3 Stars" alt="Townhall 12 | War Base Island Style Anti 3 Stars." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF6wI0tjSp69jYFzPzz_iWD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H047</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-30" data-title="TH 12 War Base Anti 3 star">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031046_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031046_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031046_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031046_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-031046_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Horizontal Anti 3 Stars" alt="Townhall 12 | War Base Horizontal Anti 3 Stars." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF6wIkLpmmVTO3ThhW9iJHD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H046</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-30" data-title="TH 12 War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-030951_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-030951_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-030951_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-030951_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Th12-WarBase-Screenshot_20190803-030951_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti 3 Stars" alt="Townhall 12 | War Base Anti 3 Stars." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wINJWQx-WwcM76kelpNj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H045</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-10" data-title="TH 12 War Base by WOJCIECO from Polish Power. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-172000_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-172000_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-172000_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-172000_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-172000_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base by WOJCIECO from Polish Power. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3nzwzlcc9zuT3aJfLqSOi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H044</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-07-10" data-title="TH 12 Trophy Base Legends League Anti 2 Star.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030544_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030544_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030544_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030544_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030544_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Legends League Anti 2 Star." alt="Townhall 12 | Trophy Base Legends League Anti 2 Star." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rVFcPOFKDOhKVH5LGsAh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H043</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-10" data-title="TH 12 War Base by Itzu.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190715-030309_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190715-030309_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190715-030309_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190715-030309_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190715-030309_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by Itzu." alt="Townhall 12 | War Base by Itzu." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rUF6AbeVFEJkR-ilkAKd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H042</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-09" data-title="TH 12 War Base by Taiko from Top of Japan. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-184028_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-184028_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-184028_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-184028_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-184028_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base by Taiko from Top of Japan. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3ocCQmu_CI7rNQDf5-nim" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H041</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-09" data-title="Itzu's Anti-League War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190622-044222_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190622-044222_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190622-044222_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190622-044222_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190622-044222_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti Clan-League." alt="Townhall 12 | War Base Anti Clan-League." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAFz0_w4s88rysnz_Eht5N4R" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H040</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-08" data-title="Compact War Base with Hard to Reach Townhall">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-170757_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-170757_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-170757_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-170757_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-170757_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base with Hard to Reach Townhall." alt="Townhall 12 | War Base with Hard to Reach Townhall." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF0L03_xVEwQO77CxGyxV8G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H039</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-07-08" data-title="TH 12 Trophy Base Centralized Towhhall.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025252_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025252_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025252_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025252_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025252_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Centralized Townhall." alt="Townhall 12 | Trophy Base Centralized Townhall." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rP_gnQzt9TSc7mRAJ8xK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H038</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-08" data-title="Tetra-Compartment Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213655_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213655_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213655_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213655_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213655_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Tetra-Compartment Island Base." alt="Townhall 12 | War Base Tetra-Compartment Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF1SI_6J-KwchscpiYcmX7b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H037</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-07" data-title="TH 12 War Base by CAETANO from INTZ. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-180846_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-180846_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-180846_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-180846_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-180846_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base by CAETANO from INTZ. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3oNFHny68Xf9j5ofONRaC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H036</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-07" data-title="TH 12 War Base by JANE from Polish Power. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-174208_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-174208_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-174208_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-174208_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-174208_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by JANE from Polish Power. World Championship, June Qualifiers." alt="Townhall 12 | War Base by JANE from Polish Power. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3n_yp5RJ8GOO9ECMKyzSC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H035</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-07-06" data-title="TH 12 Trophy Base Island Base.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030933_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030933_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030933_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030933_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-030933_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | Trophy Base Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rWr30fuAYvVnAu3hdP90" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H034</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-06" data-title="TH 12 War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-165424_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-165424_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-165424_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-165424_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-165424_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3nlySF5YhmH1sooVyJjUU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H033</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-06" data-title="TH 12 War Base by TakiLouRobiShow from Polish Power. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-163055_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-163055_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-163055_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-163055_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-163055_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by TakiLouRobiShow from Polish Power. World Championship, June Qualifiers." alt="Townhall 12 | War Base by TakiLouRobiShow from Polish Power. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3nYrxf-p5h0U_WelHdFtT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H032</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-06" data-title="TH 12 War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-160513_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-160513_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-160513_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-160513_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-160513_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3nKAx2aVFatqp0TEIv4zA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H031</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-06" data-title="TH 12 Compartment War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154301_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154301_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154301_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154301_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154301_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Compartment War Base." alt="Townhall 12 | War Base Compartment War Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3m9P0cqMCJcxLz7_2KFnZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H030</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-04" data-title="TH 12 War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154130_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154130_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154130_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154130_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-154130_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by EREN-CPB from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3m8aBRWGNJxwrJpWazUdk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H029</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-04" data-title="TH 12 War Base by Stadra from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-151057_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-151057_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-151057_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-151057_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-151057_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by Stadra from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by Stadra from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3mrDGCHlRC-5b0aogbEN4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H028</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-04" data-title="TH 12 War Base by Yuta14 from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150710_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150710_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150710_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150710_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150710_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by Yuta14 from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by Yuta14 from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3mpefkJ--pOA4BMmx635-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H027</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-04" data-title="TH 12 War Base by EARTH from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150202_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150202_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150202_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150202_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-150202_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by EARTH from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by EARTH from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3ml_-1T4DYAAGdnre4rJ_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H026</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-07-04" data-title="TH 12 War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-145655_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-145655_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-145655_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-145655_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190714-145655_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers." alt="Townhall 12 | War Base by GAKU from QueeN Walkers. World Championship, June Qualifiers." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF3mjJCyRVb_6hXLFnNirXE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H025</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-29" data-title="TH 12 Trophy Base.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025004_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025004_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025004_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025004_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025004_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Centralized Eagle." alt="Townhall 12 | Trophy Base Centralized Eagle." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rO206isyqzsPBQLgnKSV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H024</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-28" data-title="Symmetrical Northern Lure">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-214551_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-214551_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-214551_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-214551_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-214551_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base Symmetrical Northern Lure." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF1SMDVgoXrQL8GQbcUt-TB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H023</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-28" data-title="Northern Teaser Trophy Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190629-214220_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190629-214220_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190629-214220_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190629-214220_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190629-214220_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Northern Teaser." alt="Townhall 12 | Trophy Base Northern Teaser." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF1SK2151dj_zpXbRz0RDu2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H022</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "trophy"]' data-date-created="2019-06-28" data-title="Anti Bowler Anti Bats">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213952_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213952_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213952_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213952_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213952_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Anti Bowler Anti Bats." alt="Townhall 12 | War Base Anti Bowler Anti Bats." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF1SKFj3Are4TU_MlZk2Xra" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H021</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-27" data-title="Open Center Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213359_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213359_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213359_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213359_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190629-213359_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base Open Center Corridor Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF1SH-33ppm7GwYvxRGc2VB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H020</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-27" data-title="Symmetrical Boxbase">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190629-213123_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190629-213123_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190629-213123_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190629-213123_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190629-213123_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | Farm Base Symmetrical Boxbase." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF1SHCgPLrKWBVRDy52BxtO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H019</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Semi-Exposed Townhall War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-184855_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-184855_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-184855_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-184855_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-184855_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Semi-Exposed TOwnhall." alt="Townhall 12 | War Base Semi-Exposed TOwnhall." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF0M5FYG3NvvVQ8d69isRwF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H018</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-25" data-title="Elongated Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-012508_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-012508_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-012508_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-012508_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-012508_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | Trophy/Home Base Elongated Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0co6sG9Yohxy2dt-V-dWA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H017</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-25" data-title="Square Farm Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190625-012619_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190625-012619_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190625-012619_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190625-012619_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190625-012619_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Farm Base Compact Square Layout." alt="Townhall 12 | Farm Base Compact Square Layout." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0cpq7496xO8RpV_ff-WG3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H016</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-25" data-title="Southern Teaser">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-004758_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-004758_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-004758_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-004758_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190625-004758_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy/Home Base Southern Teaser." alt="Townhall 12 | Trophy/Home Base Southern Teaser." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/no?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0cYXwN8etz8MIldMDglg0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H015</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Di-Compartment Island Farm Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190623-191438_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190623-191438_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190623-191438_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190623-191438_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190623-191438_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Farm/Home Base Di-Compartment Island Base." alt="Townhall 12 | Farm/Home Base Di-Compartment Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0NJut9F3tD4wSEfx0EJoc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H014</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-24" data-title="TH 12 Trophy Base Island Base.">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025134_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025134_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025134_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025134_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190715-025134_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Island Style." alt="Townhall 12 | Trophy Base Island Style." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rPeMzGz56QVtKifVUV8B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H013</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-23" data-title="Compact Northern Tease Trophy Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190622-044124_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190622-044124_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190622-044124_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190622-044124_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190622-044124_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Base Compact Northern Tease." alt="Townhall 12 | Trophy Base Compact Northern Tease." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAFz08zIms4FFGrJRvOwVunj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H012</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-23" data-title="Triple Compartment Island Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190228_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190228_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190228_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190228_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190228_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base Tri-Compartment Island Base." alt="Townhall 12 | War Base Tri-Compartment Island Base." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAFzkh9FJO2Q4dfIU_BwFTOS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H011</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-23" data-title="Central Corridor War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190333_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190333_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190333_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190333_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190333_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base with Central Corridor." alt="Townhall 12 | War Base with Central Corridor." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAFzke67ab9BgrmOpEy6Xfnp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H010</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Stretched Thin War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-190911_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-190911_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-190911_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-190911_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-190911_Clash_of_Clans_1920w.jpg 2430w"
                            title="" alt="Townhall 12 | War Base with lots of Empty Space inbetween." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color" >Expired Link</a><p className="index-number font-size-picture-descriptions">#H009</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Isolated Townhall Compartment">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-182748_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-182748_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-182748_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-182748_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-182748_Clash_of_Clans_1920w.jpg 2430w"
                            title="Isolated Townhall Compartment" alt="Townhall 12 | War Base with an Isolated Townhall Compartment." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF0Ms96erQSiV9N517VmA18" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H008</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-21" data-title="Northern Power House">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-173801_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-173801_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-173801_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-173801_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-173801_Clash_of_Clans_1920w.jpg 2430w"
                            title="Northern Power House" alt="Townhall 12 | War Base Northern Powerhouse." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF0MKfXlf2wwtfoGw-X8xE2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H007</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-20" data-title="Tri-Compartment Trophy Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190426_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190426_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190426_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190426_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Trophy/Screenshot_20190615-190426_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Trophy Home Base with 3 Compartments." alt="Townhall 12 | Trophy Home Base with 3 Compartments." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAFzkePvXo4hpyQxqNY2J3eM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H006</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-20" data-title="Open TH Compartment War Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190116_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190116_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190116_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190116_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190116_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base with Attraktive TH Compartment Entry." alt="Townhall 12 | War Base with Attraktive TH Compartment Entry." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAFzkGHQ-5Fimq6t7b-rvHQF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H005</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-19" data-title="4 Island Base for war">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-164001_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-164001_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-164001_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-164001_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190623-164001_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base. 4 Compartment Island Base Layout." alt="Townhall 12 | War Base. 4 Compartment Island Base Layout." zoom={this.zoom}  />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AWB%3AAAAAGQAAAAF0LfIQLMJHlJXAi7iPo0w1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H004</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-19" data-title="Central Corridor Warbase">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190201_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190201_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190201_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190201_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190201_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base with Central Corridor." alt="Townhall 12 | War Base with Central Corridor." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color" >Expired Link</a><p className="index-number font-size-picture-descriptions">#H003</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-19" data-title="Solid Warbase">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190303_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190303_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190303_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190303_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-War/Screenshot_20190615-190303_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | War Base with Centralised Eagle Artillery and Clan Castle." alt="Townhall 12 | War Base with Centralised Eagle Artillery and Clan Castle." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAFzkkVaK02P1T6ti22jEXEq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#H002</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-18" data-title="Island-ish Style Farm Base">
                  <div className="picture-item__inner">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <Image
                            src="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190620-160146_Clash_of_Clans_640w.jpg"
                            srcSet="https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190620-160146_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190620-160146_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190620-160146_Clash_of_Clans_1920w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Farm/Screenshot_20190620-160146_Clash_of_Clans_1920w.jpg 2430w"
                            title="Townhall 12 | Farm/Home Base Island-ish Style." alt="Townhall 12 | Farm/Home Base Island-ish Style." zoom={this.zoom} />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><a className="link-color" >Expired Link</a><p className="index-number font-size-picture-descriptions">#H001</p></figcaption>
                      <div>
                        <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                        <p className="index-number font-size-picture-descriptions">TH 12</p>
                      </div>
                    </div>
                  </div>
                </figure>

                <div className="col-1@sm col-1@xs my-sizer-element"></div>
              </div>
            </div>
          </div>



          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 12 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhalltwelve;
