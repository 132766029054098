import React from 'react';
import './Showcase.css';

const information = props => (

<section classname="baseShowcase-container">

  <div className="baseShowcase-container-top">
    <div className="column"><img src='https://assets.clashbases.de/Backgrounds/BaseExample1.jpg' alt="" width="100%"/></div>
    <div className="column"><img src='https://assets.clashbases.de/Backgrounds/BaseExample2.jpg' alt="" width="100%"/></div>
    <div className="column"><img src='https://assets.clashbases.de/Backgrounds/BaseExample3.jpg' alt="" width="100%"/></div>
    <div className="column"><img src='https://assets.clashbases.de/Backgrounds/BaseExample4.jpg' alt="" width="100%"/></div>
  </div>

    <div className="information-container">

      <div className="info-about">
        <div className="info-about-container">
          <h3 className="info-about-title">Let's talk about this project</h3>
        </div>
        <p className="info-about-text">This is my attempt at creating a comprehensive base collection for all things clash.< br/> Together with the community, we've worked on collecting thousands of base layouts so that you, the players, can have access to them all for free.< br/>< br/> Anytime, anywhere.< br/>< br/> Join me on Discord to keep the conversation about our favorite game going!</p>
      </div>

      <div className="information-container-top">

        <div className="container-box">
          <div className="flex-vh-center">
            <h3 className="info-cap orange-color">Beautiful Gallery</h3>
          </div>
          <p className="info-text">Spend your time playing the game. You don't have to stress about designing your own layout.</p>
        </div>

        <div className="container-box">
          <div className="flex-vh-center">
            <h3 className="info-cap orange-color">Comprehensive</h3>
          </div>
          <p className="info-text">Thousands of bases and their links at the tip of your fingers.</p>
        </div>

        <div className="container-box">
          <div className="flex-vh-center">
            <h3 className="info-cap orange-color">Delightful designs</h3>
          </div>
          <p className="info-text">War, trophy, farm, pro, legend's league and custom build bases all brought to you by yours truly.</p>
        </div>

        <div className="container-box">
          <div className="flex-vh-center">
            <h3 className="info-cap orange-color">Up to Date</h3>
          </div>
          <p className="info-text">Always expanding and upgrading, I aim to bring you an all inclusive experience.</p>
        </div>

      </div>


    </div>


  </section>

);

export default information;
