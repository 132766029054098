import React, { Component } from 'react';
import './Archive.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class ArchiveTownhallfourteen extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom ({background:"rgba(0, 0, 0, 0.5)"})


  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 14</title>
            <meta name="description" content="Welcome to the ClashBases TH 14 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(43, 143, 13)'}}>14</span></h1>

                <div className="switch-container-archive">
                  <LinkButton to="/Townhall-14" className="switch-buttons-archive switch-to-archive">
                    <div>Switch back to Current TH 14 Base Layouts</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">

            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2019-12-10" data-title="Townhall 14 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-13">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg"
                        srcSet="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_320w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg 1024w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 14 | Base Layouts and Links" alt="Townhall 14 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 14</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015534_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015534_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015534_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015534_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015534.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIWFahRa3A6SIxOY8weUm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F160</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Trophy</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015431_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015431_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015431_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015431_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015431.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIVz6gLZPnPTvt0VM-QDy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F159</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210914-015332_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210914-015332_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210914-015332_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210914-015332_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210914-015332.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHpIVj__W_nDwqoPgc7QgUr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F158</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Trophy</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015224_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015224_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015224_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015224_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015224.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIVRaRGbv66GNUaUNcfZx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F157</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015102_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015102_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015102_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015102_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-015102.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIU6n7BeJ2X8xqtGbHb9z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F156</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-014628_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-014628_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-014628_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-014628_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-014628.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHpITrv81okcSRqfxRAhV4P" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F155</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014509_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014509_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014509_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014509_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014509.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpITV2QtqJ4nhkNNIoDNju" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F154</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014340_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014340_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014340_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014340_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014340.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIS9FgqiktIWhVTvfhKeU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F153</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014219_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014219_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014219_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014219_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014219.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpISndBhFTIeqM2-BPXmUt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F152</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014112_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014112_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014112_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014112_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-014112.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpISUySCMJGKTj_pSNCgGr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F151</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013925_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013925_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013925_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013925_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013925.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHpIR6Qm1ThZQFMJ1DY-UxT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F150</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013807_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013807_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013807_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013807_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013807.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIRlSuowDN86t79zbvqu2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F149</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013611_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013611_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013611_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013611_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013611.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIRHfFbGUgtDlPBWQdnmd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F148</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013528_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013528_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013528_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013528_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013528.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIQ65a7fnwck9AIYfzSRR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F147</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013419_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013419_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013419_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013419_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013419.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIQphE7iwK_kTaPZtx9Zd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F146</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013310_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013310_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013310_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013310_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-013310.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIQVLVSRCE-3bI0wbIlPm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F145</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013126_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013126_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013126_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013126_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210914-013126.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHpIP6cM_IcUana_-OVGOVB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F144</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012955_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012955_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012955_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012955_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012955.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIPjRGzDlvnJxTviTR73s" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F143</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012846_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012846_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012846_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012846_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012846.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIPUL0NxSP8T5Q-0aqqzm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F142</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012653_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012653_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012653_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012653_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210914-012653.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHpIOxpGvGtgclPHkHeV0rn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F141</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011930_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011930_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011930_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011930_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011930.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUvpGYy3EkYWHvKdjlfA-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F120</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011804_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011804_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011804_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011804_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011804.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUwN7UuQzRaEbLa2uPdg6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F119</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Trophy</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011722_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011722_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011722_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011722_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011722.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUvpGYy3EkYWHvKdjlfA-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F118</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011619_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011619_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011619_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011619_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011619.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHhUvZR5uiOGMd7pbz85r23" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F117</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011518_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011518_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011518_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011518_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011518.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUvIJLBYWN4zP8P0Sa2CV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F116</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011421_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011421_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011421_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011421_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-011421.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHhUu5pHQWtsnUq_UGgxrp1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F115</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Trophy</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011327_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011327_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011327_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011327_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011327.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUuq8LKcU1kJp6uMbhlml" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F114</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011231_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011231_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011231_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011231_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011231.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUuce8gxiwforL_7urDjo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F113</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011151_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011151_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011151_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011151_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-011151.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHhUuR3HJX1GkgjdNVIAvXN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F112</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011042_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011042_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011042_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011042_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-011042.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUuBOSsZvOuT_na38-fYT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F111</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-010923_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-010923_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-010923_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-010923_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210720-010923.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHhUtqxRTFL0ziSI0SeiD20" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F110</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010759_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010759_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010759_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010759_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010759.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUtYjUPJ0ZwD4PNYEIAib" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F109</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010650_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010650_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010650_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010650_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010650.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUtCMilypOGc_477ahbuq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F108</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-010601_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-010601_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-010601_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-010601_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210720-010601.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHhUs1corWO61tfM6DgNUDz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F107</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Trophy</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010509_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010509_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010509_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010509_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010509.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUsomMlau4J2WRlRr6j2y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F106</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010405_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010405_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010405_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010405_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010405.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUsXrGmPh7rrEEPf3c2DK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F105</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010305_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010305_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010305_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010305_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010305.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUsIvMccwhWeRYuQK6ftP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F104</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010209_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010209_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010209_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010209_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010209.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUr4Q2slZqkmL12Dx0hAe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F103</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010005_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010005_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010005_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010005_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-010005.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUrW1a-y6i4tKZpRY68zO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F102</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-005809_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-005809_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-005809_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-005809_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210720-005809.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHhUq35-5jN8S3C5yZhkLjm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F101</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002644_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002644_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002644_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002644_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002644.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVLvBtTXQxfATyKnWlWKa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F100</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002558_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002558_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002558_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002558_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002558.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVLjM-cpXCW68CIkMVC7J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F099</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002509_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002509_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002509_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002509_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002509.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVLXC9Y7cjnZ4BoitQvvD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F098</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-002420_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-002420_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-002420_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-002420_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-002420.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVLH8acOd3EdDtj-58Ux0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F097</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002343_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002343_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002343_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002343_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002343.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVK-kYZJDzDAFvPQlI5tg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F096</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-002240_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-002240_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-002240_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-002240_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-002240.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVKtn4ujJhgUCvVSyTfrJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F095</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002139_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002139_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002139_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002139_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002139.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVKd-8IvfGAZKL7QW-4Ua" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F094</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002102_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002102_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002102_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002102_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002102.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVKUR0_IzzfTCz48xQuXL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F093</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002018_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002018_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002018_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002018_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-002018.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVKJkAfdkX1UY19vHfkOy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F092</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001905_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001905_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001905_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001905_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001905.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVJ2CqY7-fJt8vvr382ya" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F091</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001823_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001823_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001823_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001823_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001823.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVJrdbrfm3JA0oXjURtqd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F090</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001721_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001721_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001721_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001721_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001721.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVJZAC8uQ_kDrwB6kS1EW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F089</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001637_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001637_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001637_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001637_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001637.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVJOQaxyNFPvOp5k4-E5y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F088</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001603_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001603_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001603_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001603_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001603.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVJFmucZUWp-ISKn2VsIR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F087</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001518_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001518_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001518_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001518_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-001518.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVI5Tr9lwohCb7-qkRPOg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F086</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001307_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001307_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001307_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001307_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001307.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVIWImTcqwMaiSt3ruPmH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F085</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001225_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001225_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001225_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001225_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-001225.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVIJNqXYkaIedatRxB6Un" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F084</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001029_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001029_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001029_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001029_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-001029.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVHrhBwUXbrEj4zj8Vhff" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F083</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000945_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000945_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000945_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000945_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000945.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVHf5hVxE50bKGJthqAL5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F082</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-000840_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-000840_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-000840_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-000840_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210621-000840.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdVHO5cxlSHgYXwpr3xp91" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F081</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-07-20" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000659_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000659_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000659_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000659_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000659.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVG1kaV4xK9GQGBMvjsrl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F080</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-07-20" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-000527_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-000527_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-000527_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-000527_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210621-000527.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVGdUnAwf0HVTXbfD1Ir3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F079</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-07-20" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000428_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000428_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000428_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000428_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210621-000428.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHdVGOKdPyEqUMq9bRl1EBX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F078</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210619_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210619_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210619_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210619_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210619.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdUVFk667uRy5Cj6pkXw_T" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F077</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-07-20" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210509_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210509_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210509_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210509_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210620-210509.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHdUUvtVWFtsMssmn6SZrV1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F076</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035518_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035518_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035518_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035518_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035518.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrIcDimaGL2CPnW7z_T7B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F075</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035210_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035210_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035210_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035210_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035210.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrGoWTI-9c27N2idCKbYd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F074</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-035115_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-035115_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-035115_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-035115_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-035115.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZrFP_5akGLJE9g-dyFu4x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F073</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035024_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035024_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035024_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035024_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-035024.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrE4UnLdmfv1BAMcpGPDz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F072</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034944_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034944_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034944_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034944_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034944.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZrEl9mqL6NL7X1qJrWI78" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F071</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034834_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034834_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034834_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034834_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034834.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZrEF3rNvyLZPTSogkp1p7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F070</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034556_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034556_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034556_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034556_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-034556.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZrDBcljCUYLkEYYMmrFbn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F069</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034517_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034517_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034517_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034517_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034517.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrCw00s5Xdkv_06vlbwke" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F068</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034431_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034431_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034431_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034431_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034431.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrCc7Ls2S8WglsrpLEB62" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F067</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034347_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034347_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034347_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034347_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034347.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrCKoMQ7fbzyWDzhpFecN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F066</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034256_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034256_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034256_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034256_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034256.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrBy2wDBZFV-BjxWWugK6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F065</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034206_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034206_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034206_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034206_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034206.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrBdqYC_2kJI_C2ixeORN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F064</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-034123_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-034123_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-034123_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-034123_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-034123.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZrBKtGQJUWdOAWhP-nkAf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F063</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034011_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034011_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034011_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034011_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-034011.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrAs7UAHFGxU5uZiD9sO9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F062</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033913_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033913_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033913_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033913_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033913.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZrAUG3iatDESG7xveEwyA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033533_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033533_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033533_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033533_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033533.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq-x8y-7Qa80MCFoimkia" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033450_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033450_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033450_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033450_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033450.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq-gd8mXrJYiMSWx-yiiG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033245_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033245_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033245_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033245_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033245.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq9oOfjFlvq2KJk2qN6Jb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-033208_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-033208_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-033208_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-033208_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-033208.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq9XLfntwWHZEhO2Ao_AN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033124_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033124_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033124_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033124_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-033124.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq9EVUFD9DQXLjJVQdGwJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033009_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033009_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033009_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033009_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-033009.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq8iwvNq6uL0WtLzh4ARO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032903_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032903_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032903_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032903_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032903.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq8G8VKrMjmtvPiClXBVQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032825_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032825_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032825_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032825_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032825.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq72p98Tnkh_esyoAVsgy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032732_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032732_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032732_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032732_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032732.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq7gTmHnEpcAYJ0JBI-iX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032603_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032603_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032603_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032603_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032603.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq663pXfO-SnMd_omBdFU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032522_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032522_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032522_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032522_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032522.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq6qShuJHpnGGex5KKaqQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-032353_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-032353_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-032353_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-032353_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-032353.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq6B9N2-AGDP-gOSR3h-A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032129_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032129_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032129_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032129_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032129.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq5FAdynFBJG0AkcAFiTd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032039_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032039_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032039_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032039_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-032039.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq4zREX6-ouIPQGooGduU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032004_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032004_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032004_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032004_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-032004.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq4i1xkLnLZ2lMF2mYF_7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031903_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031903_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031903_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031903_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031903.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq4KkyRFnWRfpkz1u5Izh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-031739_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-031739_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-031739_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-031739_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-Base-Layout-20210526-031739.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq3kvEwPf92-4G1cwXug-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031615_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031615_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031615_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031615_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031615.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq3DplHLoOXXTRgypknAO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031528_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031528_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031528_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031528_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210526-031528.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHZq2wPVo8HGzqh0TPa6Tx6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031356_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031356_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031356_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031356_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210526-031356.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHZq2IidcZOM1hJSwsEREgB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164730_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164730_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164730_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164730_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164730.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTnJ9uIZNyQ-rnZNh4I16x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164039_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164039_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164039_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164039_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-164039.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTnGlDT1UtH8VareU0Xe0K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-163824_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-163824_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-163824_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-163824_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-163824.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTnFdpt32hmvxcM6IWsZyZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-163042_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-163042_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-163042_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-163042_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-163042.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTnBqgtOgr99NPWHFu45kN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162445_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162445_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162445_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162445_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162445.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTm-tUXtYqGL8gLr9DJqgI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162154_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162154_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162154_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162154_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-162154.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTm9VmU1ZYMhrEUm-kYXjO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-162044_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-162044_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-162044_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-162044_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-162044.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTm8wjdWymDANeMicMGt9U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-161049_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-161049_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-161049_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-161049_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-161049.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTm3yPpsku-J432wwn8MO4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-160908_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-160908_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-160908_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-160908_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-BaseLayout-20210417-160908.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHT4DtRmq_S73Gp3D6hGs1V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160358_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160358_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160358_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160358_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160358.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTm0bLArxkvjF1A2M3vPYm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160041_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160041_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160041_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160041_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-160041.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTmyy-olzywud9GE7SxfG6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155845_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155845_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155845_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155845_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155845.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTmxzK8mwQ124kwp5irQJi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155715_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155715_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155715_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155715_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155715.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTmxAmIkFBFhYRGtOPL_DE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155440_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155440_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155440_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155440_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155440.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTmvrr-LbFRC595yLuPI1v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155057_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155057_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155057_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155057_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-155057.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTmttT62taF64PfaEDa6qf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-033146_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-033146_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-033146_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-033146_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-033146.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYl5_4JOkClEl1b9XyVJX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-032624_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-032624_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-032624_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-032624_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-032624.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYj_Oqlr0-hieYsZZQor8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030906_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030906_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030906_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030906_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030906.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYd4LdxIu8VIBxbhuuaOt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030154_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030154_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030154_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030154_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-030154.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYbX9UBnpwXtZNVY7IOnR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-025613_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-025613_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-025613_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-025613_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-025613.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYZZAAUff22kwEaTWc2mS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-024436_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-024436_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-024436_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-024436_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-024436.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYVVLfs4klXDCHRb9XVde" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023702_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023702_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023702_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023702_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023702.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYSwUXNnZBGbPnRjG58yC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023119_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023119_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023119_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023119_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-023119.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYQ1_7ZH1gZDXcyhEJBJb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-022417_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-022417_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-022417_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-022417_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-022417.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYOahYNih268xU9uyAMFi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-021509_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-021509_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-021509_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-021509_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-021509.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYLbAH8stp0XPazmKp8Ka" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-020552_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-020552_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-020552_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-020552_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-020552.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYIPJQx2VkzDnBsIGgUYz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015936_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015936_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015936_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015936_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015936.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYGN5IVQm6j3YucRWVsLc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015304_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015304_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015304_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015304_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-015304.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYEKDTOesLc3PVlaoFCL3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-014547_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-014547_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-014547_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-014547_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210416-014547.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTYB1UiOw-ZGNwEpJ-nHK1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210416-013734_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210416-013734_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210416-013734_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210416-013734_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210416-013734.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTX_QQnnvJe8eCusT9p_2r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-13-04" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-165642_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-165642_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-165642_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-165642_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-BaseLayout-20210417-165642.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTnOecZ-05fdpQspll7x5r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-13-04" data-title="Townhall 14 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-165341_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-165341_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-165341_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-165341_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Trophy/Townhall-14-BaseLayout-20210417-165341.jpg 2430w"
                        title="Townhall 14 Trophy Base" alt="Townhall 14 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTnM9DcvNByRxIrlyVfHh5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-13-04" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191733_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191733_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191733_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191733_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191733.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTEZnIT1RjxmNmJzaxX44-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-13-04" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191150_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191150_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191150_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191150_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-191150.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTEXVIY7K9atqYvVcHzxvp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-13-04" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190342_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190342_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190342_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190342_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190342.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTETgmvRNKmQKBf8WPrEkn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-13-04" data-title="Townhall 14 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190302_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190302_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190302_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190302_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Farm/Townhall-14-Base-Layout-20210413-190302.jpg 2430w"
                        title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTESNlypIMZsaI5L3AxBIi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-13-04" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-185053_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-185053_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-185053_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-185053_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-185053.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTESNlypIMZsaI5L3AxBIi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-13-04" data-title="Townhall 14 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-183851_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-183851_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-183851_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-183851_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-War/Townhall-14-Base-Layout-20210413-183851.jpg 2430w"
                        title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAHTER7jiyw-4GH8T8sJRJe3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fun</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["fun"]' data-date-created="2021-13-04" data-title="Townhall 14 Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-183025_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-183025_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-183025_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-183025_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-183025.jpg 2430w"
                        title="Townhall 14 Fun Base" alt="Townhall 14 Fun Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTEFq58aWku5O_QRpb9qQe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fun</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["fun"]' data-date-created="2021-13-04" data-title="Townhall 14 Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-165814_640w.jpg"
                        srcSet="https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-165814_640w.jpg 320w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-165814_1024w.jpg 640w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-165814_1920w.jpg 1920w, https://townhall-14.clashbases.de/Townhall-14-Fun/Townhall-14-Base-Layout-20210413-165814.jpg 2430w"
                        title="Townhall 14 Fun Base" alt="Townhall 14 Fun Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAHTD_97C2wk0qXVfnR8Hgka" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fun</p>
                    <p className="index-number font-size-picture-descriptions">TH 14</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="col-1@sm col-1@xs my-sizer-element"></div>
          </div>
        </div>



        <div className="archive-paragraph-container">

          <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
            <div className="discord-container">
              <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
              <div className="discord-message-container">
                <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
              </div>
            </div>
          </a>

          <h1 className="archive-paragraph-title">Welcome to the Th 14 Archive<br /></h1>
          <p className="archive-paragraph">
            The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

            All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

            Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
          </p>
        </div>



        </div>

      </section>

    );
  };
};

export default ArchiveTownhallfourteen;
