import React from 'react';
import './About.css';
import {Helmet} from "react-helmet";

const about = props => (
  <section className="about-page">

    <Helmet>
      <meta charSet="utf-8" />
      <title>What Clashbases is all about</title>
      <meta name="description" content="What Clash Bases is all about. Keep on striving for perfection! Our ever-growing collection of base layouts will surely blow your mind! Easy to use, quick, clean and effective!" />
      <meta name="keywords" content="about, about us, about me, about aris protopapas, about clash bases, about clashbases, clash of clans, clash, clash bases, clashbases.de, th 12, th 11, th 10, th 9, collections, links, layouts" />
      <meta name="coverage" content="Worldwide" />
      <meta name="distribution" content="Global" />
    </Helmet>

    <div className="about-container-outer">
      <div className="about-container-middle">
        <div className="about-container-inner">

          <div className="about-container-inner-c">
            <h1 className="about-w-title">Let's talk about the Website</h1>
            <p className="about-w-text"><span className="orange-text">This</span> website started out as a fun side project back in May 2019. Its purpose was to teach me how to program, and it was supposed to be a place for me to store my war base layouts. <br /><br /><span className="orange-text">It</span> went live on June 22nd 2019. After presenting it ingame over the global chatrooms (back when they still were a thing) to players, it gathered a lot of interest, and quickly grew into a much much larger undertaking. <br /><br /><span className="orange-text">The</span> increasing demand came with many problems attached: server and data costs became more expensive, there was an increasing number of compatibility issues and bugs that needed to be resolved, and there was an overwhelming pressure for more content. It did get quite hard for a while, especially seeing as I was going at it alone, but somehow it all worked out. With the help from friends, and with an additional two accounts I created, I was able to fill in the missing townhall levels, and bring you a lot more content.<br /><br /> <span className="orange-text">Now,</span> almost two years later, my collection has grown to over 1500 base layouts, the site is now hosted on proper heavy duty servers, and we've built a small, yet quickly growing amazing community of roughly four thousand people over on Discord. I am incredibly happy to be able to present my little creation, to an ever increasing number of you. I can't believe we've cracked the 36k daily users mark.<br /><br /> <span className="orange-text">The</span> amount of interest is truly mind blowing to me, and I am grateful to every single one of you! </p>
          </div>


        </div>
      </div>
    </div>

  </section>
);

export default about;
