import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";





class ArchiveTownhallseven extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-7" />
            <title>The Archive - Townhall 7</title>
            <meta name="description" content="Welcome to the ClashBases TH 14 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(70, 108, 30)'}}>7</span></h1>

                  <div className="switch-container-archive">
                    <LinkButton to="/Townhall-7" className="switch-buttons-archive switch-to-archive">
                      <div>Switch back to Current TH 7 Base Layouts</div>
                    </LinkButton>
                  </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">
            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004221_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004221_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004221_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004221_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004221.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17XQ_T3HNvv1gbtDD2jjK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003732_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003732_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003732_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003732_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003732.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17V645w1TNpsm3XyZlLND" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003233_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003233_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003233_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003233_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003233.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17VGCcGPuww0NVCFLKHiv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-002657_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-002657_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-002657_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-002657_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-002657.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17T5QZ-XW42fUF4bHe72I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-001444_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-001444_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-001444_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-001444_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-001444.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17RTCCZHSmG5dwc3tpUa2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-000533_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-000533_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-000533_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-000533_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-000533.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17PXyVZAADo_qxNP4klUv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-235225_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-235225_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-235225_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-235225_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-235225.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17MsxEvuXJr5TcxHY_m5G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234811_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234811_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234811_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234811_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234811.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17L2PZxmsl9rWU7q70D5T" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234322_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234322_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234322_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234322_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234322.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17K6lD5P3BOmayvXDxNfj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234207_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234207_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234207_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234207_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234207.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17KqeM_QnTROmWMSWplbN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234042_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234042_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234042_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234042_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-234042.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17KYDXQGo9s928DzNxWfw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-233943_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-233943_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-233943_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-233943_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-233943.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17KKYt35VJQZYYGIy7a1p" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="ad-container" adID="ccb47432-59d3-485b-a86a-ae8a9fa9a95a" />
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233754_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233754_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233754_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233754_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233754.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17J0k1bMAN6M8M17hGhAz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233502_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233502_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233502_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233502_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233502.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17JRJqAoUFOT5iXosVOCf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004035_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004035_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004035_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004035_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004035.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17W37JdkiGnZahzGPAQS1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233116_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233116_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233116_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233116_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233116.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17IgGa7aeIk_ncJYBDvh4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233009_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233009_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233009_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233009_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-233009.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17ISU1B8o7_RGb8I9fG17" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232918_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232918_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232918_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232918_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232918.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17IIwY7K1Urn50CBvO-Zp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232543_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232543_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232543_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232543_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-232543.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17HdDP_TQbjpwaCvgheDR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-232030_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-232030_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-232030_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-232030_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-232030.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17GXFtj2Yd_4iWNr26y0d" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231847_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231847_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231847_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231847_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231847.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17GAjENTe0g4rgQqQehTV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231556_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231556_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231556_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231556_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231556.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Link expired</a><p className="index-number font-size-picture-descriptions">#N038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231507_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231507_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231507_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231507_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231507.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17FPV2N2t7-jCTIPWHYvn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231318_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231318_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231318_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231318_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-231318.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17E37glJ8UH2GXQjjU-TE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-231123_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-231123_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-231123_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-231123_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-231123.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17Ed9vHlgzcwUgwN6Dbz6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230849_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230849_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230849_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230849_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230849.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17D-mFuL3VE_wSc6VQzxm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230820_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230820_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230820_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230820_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-230820.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17DdROgLB--QrmHsqaDFh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230620_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230620_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230620_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230620_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230620.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17DdROgLB--QrmHsqaDFh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230326_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230326_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230326_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230326_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230326.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17C6n8-JFr3XI7xhbRYte" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230032_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230032_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230032_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230032_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-230032.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17CR84CVCmqWzB4JoDeOL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004318_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004318_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004318_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004318_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-004318.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17XdmDUZrsuWtTyo-Y1nA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225838_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225838_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225838_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225838_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225838.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17B5CgD40ex8XGVZCTHqV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225735_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225735_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225735_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225735_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225735.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17BrXBhvp_g8X10L5sajs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225449_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225449_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225449_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225449_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-225449.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17BJHLikH7HO77Mho-qYa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225158_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225158_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225158_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225158_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225158.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17Aja3OIIboTelkO_9LKu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225028_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225028_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225028_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225028_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-225028.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17AQsmfZn7_5rjm_fSrj-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="ad-container" adID="651f41b8-f7b9-4767-b18b-cb93f5a00928" />
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224937_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224937_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224937_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224937_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224937.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17AGPQWLkyAwl3PrYfILq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003946_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003946_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003946_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003946_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200219-003946.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD17WstJKkOqUSXG6Gkwb1i" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224805_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224805_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224805_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224805_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224805.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD16_zBWS1XX5NKqrw0xB-q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224616_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224616_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224616_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224616_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224616.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD16_bkkwp9Ay-sJCqon56g" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-224352_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-224352_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-224352_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-224352_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-224352.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD16-7Po5Us0G1-EUab7YqI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224306_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224306_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224306_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224306_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-224306.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD16-w2Ih6yZHAUVUOHxuqz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>


            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223915_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223915_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223915_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223915_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223915.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD16-BcTIoC8W1Ygd93jyqN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-223808_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-223808_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-223808_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-223808_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-223808.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD169ztoDZkbysACpX22bF0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223556_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223556_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223556_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223556_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-223556.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD169YxBRtwFv-mBla6GKmj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222916_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222916_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222916_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222916_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222916.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD168ExbYHUFzH7ggd2Mhpk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222754_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222754_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222754_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222754_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222754.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD167wyobps1V89a8QnUvLD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222426_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222426_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222426_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222426_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222426.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD167FYR8c24yElRLq3LO-G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222257_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222257_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222257_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222257_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-222257.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD166zLr3KOAACut8Io4QrT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222158_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222158_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222158_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222158_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-222158.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD166nJnzRvaRwmZhts4uFK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221952_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221952_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221952_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221952_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221952.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD166KfBVxH7ytKRML3DkH-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221755_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221755_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221755_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221755_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221755.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD165vkzBJGD2O8_8RC9YUO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221546_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221546_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221546_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221546_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221546.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD165UnGsEUh7GeTe22BcfL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221430_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221430_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221430_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221430_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221430.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD165DOVQGZ-xGEZg6ZUgne" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221251_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221251_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221251_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221251_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200218-221251.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD164sUmTOcHsNMb8G67pAU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221121_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221121_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221121_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221121_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Townhall-7-Base-Layout-Screenshot_20200218-221121.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD164YEq0yM7tXTgXFnQ7rt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003612_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003612_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003612_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003612_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003612.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17V645w1TNpsm3XyZlLND" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-18" data-title="Townhall 7 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003520_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003520_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003520_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003520_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-War/Townhall-7-Base-Layout-Screenshot_20200219-003520.jpg 2430w"
                        title="Townhall 7 War Base" alt="Townhall 7 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AWB%3AAAAAVgAAAAD17VuZlqdelTwLTs5s932k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-18" data-title="Townhall 7 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-7.clashbases.de/Townhall-7-Farm/Screenshot_20200218-220644_640w.jpg"
                        srcSet="https://townhall-7.clashbases.de/Townhall-7-Farm/Screenshot_20200218-220644_640w.jpg 320w, https://townhall-7.clashbases.de/Townhall-7-Farm/Screenshot_20200218-220644_1024w.jpg 640w, https://townhall-7.clashbases.de/Townhall-7-Farm/Screenshot_20200218-220644_1920w.jpg 1920w, https://townhall-7.clashbases.de/Townhall-7-Farm/Screenshot_20200218-220644.jpg 2430w"
                        title="Townhall 7 Farm Base." alt="Townhall 7 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH7%3AHV%3AAAAAVgAAAAD163XXPnCIuU2nXfbSFMDt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#N001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 7</p>
                  </div>
                </div>
              </div>
            </figure>

            </div>
          </div>

          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 7 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhallseven;
