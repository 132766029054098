import React, { Component } from 'react';
import './JustForFun.scss';
import '../JustForFun/JustForFun.scss';
import Justforfundemo from './JustForFunScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";


class Justforfun extends Component {

  componentDidMount () {
    window.justforfundemo = new Justforfundemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="fun-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Fun Base Layouts and Links</title>
            <meta name="description" content="60+ Fun Base Layouts in one big filterable Gallery! Add the craziest and most imaginative Clash Art Base Layouts directly into your game via the import link." />
            <meta name="keywords" content="fun base, fun, troll, troll base, troll base layouts, fun base layout, fun base link, th 12 fun base, th 12 troll base, townhall 12 fun base, import link, fun base link, troll base link, clash art" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="fun-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="fun-container-about">
            <div className="fun-row-about">
              <div className="fun-title-container">
                <h1 className="fun-about-title">Our Favorite <span style={{color: '#ffc000'}}>Fun</span> Base Layouts</h1>

              </div>
            </div>
            <div className="builder-row-about">
              <div className="col-6@sm fun-filters-group-wrap">
                <div className="fun-filters-group fun-filters-group-right">
                  <p className="fun-filter-label fun-filter-color">Filter</p>
                  <div className="btn-group filter-options">
                    <button className="btn btn--primary" data-group="th 13">Th 13</button>
                    <button className="btn btn--primary" data-group="th 12">Th 12</button>
                    <button className="btn btn--primary" data-group="th 11">Th 11</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="fun-container-about">
            <div id="grid" className="fun-row-about my-shuffle-fun-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Christmas Tree Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002514_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002514_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002514_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002514_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00251_1600w.jpg 2430w"
                        title="Townhall 12 | Christmas Tree Fun Base." alt="Townhall 12 | Christmas Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqWxnpjFv6PXSYs_D4R_R" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Christmas</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Unicorn Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013602_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013602_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013602_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013602_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01360_1600w.jpg 2430w"
                        title="Townhall 12 | Unicorn Fun Base." alt="Townhall 12 | Unicorn Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqrE9jV1KDDBdKQAWgT_U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Unicorn</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Skullking Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013456_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013456_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013456_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013456_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01345_1600w.jpg 2430w"
                        title="Townhall 12 | Skullking Fun Base." alt="Townhall 12 | Skullking Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqqwM5gBAQON_HTxU1c57" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Skullking</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Dragon Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013345_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013345_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013345_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013345_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01334_1600w.jpg 2430w"
                        title="Townhall 12 | Dragon Fun Base." alt="Townhall 12 | Dragon Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqqchGDhL3eILJXtzHeOZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Dragon</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Stormtrooper Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013306_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013306_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013306_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013306_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01330_1600w.jpg 2430w"
                        title="Townhall 12 | Stormtrooper Fun Base." alt="Townhall 12 | Stormtrooper Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqqQ8cvjWk1qMJ6pj3rB0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Stormtrooper</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013219_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013219_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013219_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013219_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01321_1600w.jpg 2430w"
                        title="Townhall 12 | Fun Base." alt="Townhall 12 | Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqqA3g462PioW2xRWd-Ns" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Fun</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Spider Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013139_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013139_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013139_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013139_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01313_1600w.jpg 2430w"
                        title="Townhall 12 | Spider Fun Base." alt="Townhall 12 | Spider Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqp047A4ovIHEQMWYxUBG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Spider</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Helicopter Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013057_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013057_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013057_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013057_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01305_1600w.jpg 2430w"
                        title="Townhall 12 | Helicopter Fun Base." alt="Townhall 12 | Helicopter Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqpoFP4eyIBKRGKEkozig" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Helicopter</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Heart & Arrow Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013012_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013012_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013012_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-013012_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01301_1600w.jpg 2430w"
                        title="Townhall 12 | Heart & Arrow Fun Base." alt="Townhall 12 | Heart & Arrow Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqpaRtsPDXC1BeEQMPuBu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Heart/Arrow</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Bird Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012934_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012934_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012934_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012934_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01293_1600w.jpg 2430w"
                        title="Townhall 12 | Bird Fun Base." alt="Townhall 12 | Bird Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqpOIDnwf1_u-mfIUh1a2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">DoubleBird</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012849_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012849_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012849_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012849_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01284_1600w.jpg 2430w"
                        title="Townhall 12 | Fun Base." alt="Townhall 12 | Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqo_lkLdQysENdOa_V1Mr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Fun</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Sword Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012704_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012704_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012704_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012704_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01270_1600w.jpg 2430w"
                        title="Townhall 12 | Sword Fun Base." alt="Townhall 12 | Sword Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqoh_KFc37XZm0cxDqa8Z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Sword</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="ad-container" adID="ccb47432-59d3-485b-a86a-ae8a9fa9a95a" />
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Scorpion Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012533_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012533_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012533_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012533_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01253_1600w.jpg 2430w"
                        title="Townhall 12 | Scorpion Fun Base." alt="Townhall 12 | Scorpion Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqoCd0Oeu_EUFkk30ZWbm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Scorpion</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Pikachu Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012411_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012411_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012411_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-012411_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01241_1600w.jpg 2430w"
                        title="Townhall 12 | Pikachu Fun Base." alt="Townhall 12 | Pikachu Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqnprhj075GeaqnS_BKCs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Pikachu</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Bunny Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011732_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011732_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011732_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011732_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01173_1600w.jpg 2430w"
                        title="Townhall 12 | Bunny Fun Base." alt="Townhall 12 | Bunny Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqlvqDB-pYCl5P47B6Oen" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Bunny</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Dreamworks Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011631_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011631_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011631_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011631_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01163_1600w.jpg 2430w"
                        title="Townhall 12 | Dreamworks Fun Base." alt="Townhall 12 | Dreamworks Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqlbusZCA3JQzHKp3KsrL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Dreamworks</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="M&M's Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011523_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011523_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011523_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011523_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01152_1600w.jpg 2430w"
                        title="Townhall 12 | M&M's Fun Base." alt="Townhall 12 | M&M's Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqlHrSknwm0AXCA8XtDvQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">M&M's</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Avengers Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011428_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011428_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011428_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011428_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01142_1600w.jpg 2430w"
                        title="Townhall 12 | Avengers Fun Base." alt="Townhall 12 | Avengers Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqk5JjGJT6DPDvEz0TQEG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Avengers</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Rocket Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011324_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011324_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011324_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011324_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01132_1600w.jpg 2430w"
                        title="Townhall 12 | Rocket Fun Base." alt="Townhall 12 | Rocket Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqkimo0wpt1cjktRbnXa6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Rocket</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Skull Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011216_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011216_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011216_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011216_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01121_1600w.jpg 2430w"
                        title="Townhall 12 | Skull Fun Base." alt="Townhall 12 | Skull Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqkOu1mW3y52jmHm76rHc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Skull</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Eagle Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011034_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011034_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011034_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-011034_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01103_1600w.jpg 2430w"
                        title="Townhall 12 | Eagle Fun Base." alt="Townhall 12 | Eagle Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqjv8w2VnNkrBWHx0euLS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Eagle</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Helicopter Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-010906_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-010906_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-010906_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-010906_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-01090_1600w.jpg 2430w"
                        title="Townhall 12 | Helicopter Fun Base." alt="Townhall 12 | Helicopter Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqjV2oDKxf8WWMMkkUV7v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Helicopter</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Shisha Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005731_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005731_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005731_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005731_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00573_1600w.jpg 2430w"
                        title="Townhall 12 | Shisha Fun Base." alt="Townhall 12 | Shisha Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqgGiKrIREc_V5BW3UBSo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Shisha</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Queen Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005555_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005555_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005555_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-005555_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00555_1600w.jpg 2430w"
                        title="Townhall 12 | Queen Fun Base." alt="Townhall 12 | Queen Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqfiT-C8ZV5RX8kkH0PZe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Queen</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Little Tank Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-003745_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-003745_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-003745_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-003745_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00374_1600w.jpg 2430w"
                        title="Townhall 12 | Little Tank Fun Base." alt="Townhall 12 | Little Tank Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqaTXcC48n5mwlvRPctZy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Tank</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Iron Man Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002415_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002415_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002415_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002415_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00241_1600w.jpg 2430w"
                        title="Townhall 12 | Iron Man Fun Base." alt="Townhall 12 | Iron Man Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqWfDpz0fUZLymZD6A6Lm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Iron Man</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Gun Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002326_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002326_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002326_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002326_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00232_1600w.jpg 2430w"
                        title="Townhall 12 | Gun Fun Base." alt="Townhall 12 | Gun Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLqWRfjy6LXnFvaGtGtFAM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Gun</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Crown Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002238_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002238_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002238_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-002238_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191203-00223_1600w.jpg 2430w"
                        title="Townhall 12 | Crown Fun Base." alt="Townhall 12 | Crown Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLeou5e2an-P3nzzNsFNmC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Crown</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-12-01" data-title="Christmas Tree Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191201-134156_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191201-134156_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191201-134156_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191201-134156_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Townhall-12-FunBase-Dec-20191201-13415_1600w.jpg 2430w"
                        title="Townhall 12 | Christmas Tree Fun Base." alt="Townhall 12 | Christmas Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAGLeou5e2an-P3nzzNsFNmC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Christmas</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Bart Simpson Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BartSimpson-FunBase-20190902-041557_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BartSimpson-FunBase-20190902-041557_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BartSimpson-FunBase-20190902-041557_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BartSimpson-FunBase-20190902-041557_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BartSimpson-FunBase-20190902-04155_1600w.jpg 2430w"
                        title="Townhall 12 | Bart Simpson Fun Base." alt="Townhall 12 | Bart Simpson Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g-zirMtkDk6JNUEB5KIU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Bart</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Jumping Deer Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-JumpingDeer-FunBase-20190902-041252_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-JumpingDeer-FunBase-20190902-041252_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-JumpingDeer-FunBase-20190902-041252_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-JumpingDeer-FunBase-20190902-041252_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-JumpingDeer-FunBase-20190902-04125_1600w.jpg 2430w"
                        title="Townhall 12 | Jumping Deer Fun Base." alt="Townhall 12 | Jumping Deer Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g9yuwkQOgD8etKxzHTH6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Deer</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Twisty Heart Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-TwistyHeart-FunBase-20190902-041037_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-TwistyHeart-FunBase-20190902-041037_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-TwistyHeart-FunBase-20190902-041037_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-TwistyHeart-FunBase-20190902-041037_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-TwistyHeart-FunBase-20190902-04103_1600w.jpg 2430w"
                        title="Townhall 12 | Twisty Heart Fun Base." alt="Townhall 12 | Twisty Heart Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g9Bi-75JBEU4wRG5PVOV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Twisty Heart</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>


            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Vortex Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Vortex-FunBase-20190902-040837_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Vortex-FunBase-20190902-040837_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Vortex-FunBase-20190902-040837_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Vortex-FunBase-20190902-040837_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Vortex-FunBase-20190902-04083_1600w.jpg 2430w"
                        title="Townhall 12 | Vortex Fun Base." alt="Townhall 12 | Vortex Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g8VPdebQg8ySa1x77eqD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Vortex</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Snoopy Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Snoopy-FunBase-20190902-040717_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Snoopy-FunBase-20190902-040717_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Snoopy-FunBase-20190902-040717_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Snoopy-FunBase-20190902-040717_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Snoopy-FunBase-20190902-04071_1600w.jpg 2430w"
                        title="Townhall 12 | Snoopy Fun Base." alt="Townhall 12 | Snoopy Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g76CglR-U4HuRaXtE63X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Snoopy</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Sword Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Sword-FunBase-20190902-040611_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Sword-FunBase-20190902-040611_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Sword-FunBase-20190902-040611_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Sword-FunBase-20190902-040611_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Sword-FunBase-20190902-04061_1600w.jpg 2430w"
                        title="Townhall 12 | Sword Fun Base." alt="Townhall 12 | Sword Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g7hIIrzalXu_vkIUYW3U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Sword</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Birds Holding Hands Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BirdsHoldingHands-FunBase-20190902-040253_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BirdsHoldingHands-FunBase-20190902-040253_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BirdsHoldingHands-FunBase-20190902-040253_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BirdsHoldingHands-FunBase-20190902-040253_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BirdsHoldingHands-FunBase-20190902-04025_1600w.jpg 2430w"
                        title="Townhall 12 | Birds Holding Hands Fun Base." alt="Townhall 12 | Birds Holding Hands Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Birds</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="ad-container" adID="ccb47432-59d3-485b-a86a-ae8a9fa9a95a" />
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Offline Chrome TRex Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-OfflineChromeTrex-FunBase-20190902-040100_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-OfflineChromeTrex-FunBase-20190902-040100_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-OfflineChromeTrex-FunBase-20190902-040100_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-OfflineChromeTrex-FunBase-20190902-040100_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-OfflineChromeTrex-FunBase-20190902-04010_1600w.jpg 2430w"
                        title="Townhall 12 | Offline Chrome TRex Fun Base." alt="Townhall 12 | Offline Chrome TRex Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g52LQ-d4eEpN9THw8Nwk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Chrome TRex</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Baby Dragon Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BabyDragon-FunBase-20190902-035646_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BabyDragon-FunBase-20190902-035646_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BabyDragon-FunBase-20190902-035646_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BabyDragon-FunBase-20190902-035646_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-BabyDragon-FunBase-20190902-03564_1600w.jpg 2430w"
                        title="Townhall 12 | Baby Dragon Fun Base." alt="Townhall 12 | Baby Dragon Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g4fE59JBqEs3IOKrfrc7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Baby Dragon</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Heart Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Heart-FunBase-20190902-035501_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Heart-FunBase-20190902-035501_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Heart-FunBase-20190902-035501_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Heart-FunBase-20190902-035501_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Heart-FunBase-20190902-03550_1600w.jpg 2430w"
                        title="Townhall 12 | Heart Fun Base." alt="Townhall 12 | Heart Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g38Vt5s2VHRfRsn3WRXY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Heart</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Three Star Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-ThreeStars-FunBase-20190902-035118_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-ThreeStars-FunBase-20190902-035118_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-ThreeStars-FunBase-20190902-035118_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-ThreeStars-FunBase-20190902-035118_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-ThreeStars-FunBase-20190902-03511_1600w.jpg 2430w"
                        title="Townhall 12 | Three Star Fun Base." alt="Townhall 12 | Three Star Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g2v0GSDq-uzN2HGGrEcQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Three Stars</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Face of Buddha Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FaceOfBuddha-FunBase-20190902-034657_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FaceOfBuddha-FunBase-20190902-034657_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FaceOfBuddha-FunBase-20190902-034657_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FaceOfBuddha-FunBase-20190902-034657_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FaceOfBuddha-FunBase-20190902-03465_1600w.jpg 2430w"
                        title="Townhall 12 | Face of Buddha Fun Base." alt="Townhall 12 | Face of Buddha Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g1WAzmTaHN1iSqJZu2rz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Buddha</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Rising Dragon Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Dragon-Base-20190902-034436_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Dragon-Base-20190902-034436_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Dragon-Base-20190902-034436_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Dragon-Base-20190902-034436_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Dragon-Base-20190902-03443_1600w.jpg 2430w"
                        title="Townhall 12 | Rising Dragon Fun Base." alt="Townhall 12 | Rising Dragon Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_g0mhuAwRe9bHyA117pzS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Dragon</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Shinnosuke Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Shinnosuke-FunBase-20190902-034203_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Shinnosuke-FunBase-20190902-034203_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Shinnosuke-FunBase-20190902-034203_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Shinnosuke-FunBase-20190902-034203_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Shinnosuke-FunBase-20190902-03420_1600w.jpg 2430w"
                        title="Townhall 12 | Shinnosuke Fun Base." alt="Townhall 12 | Shinnosuke Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Shinnosuke</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Uncle Thoonag Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-UncleThoonag-FunBase-20190902-034012_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-UncleThoonag-FunBase-20190902-034012_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-UncleThoonag-FunBase-20190902-034012_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-UncleThoonag-FunBase-20190902-034012_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-UncleThoonag-FunBase-20190902-03401_1600w.jpg 2430w"
                        title="Townhall 12 | Uncle Thoonag Fun Base." alt="Townhall 12 | Uncle Thoonag Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Uncle Thoonag</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Baymax BIG Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Baymax-FunBase-20190902-033830_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Baymax-FunBase-20190902-033830_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Baymax-FunBase-20190902-033830_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Baymax-FunBase-20190902-033830_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Baymax-FunBase-20190902-03383_1600w.jpg 2430w"
                        title="Townhall 12 | Baymax BIG Fun Base." alt="Townhall 12 | Baymax BIG Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF_gysNXVvZvbnNST74MFbZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Baymax</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Popode X Hamster Fun Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-WeirdMouse-FunBase-20190902-033633_640w.jpg"
                        srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-WeirdMouse-FunBase-20190902-033633_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-WeirdMouse-FunBase-20190902-033633_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-WeirdMouse-FunBase-20190902-033633_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-WeirdMouse-FunBase-20190902-03363_1600w.jpg 2430w"
                        title="Townhall 12 | Popode X Hamster Fun Base." alt="Townhall 12 | Popode X Hamster Fun Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs">Popode</p>
                    <p className="index-number">TH 12</p>
                  </div>
                </div>
              </div>
            </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-09-01" data-title="Manchester United Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Manchester-FunBase-20190902-033238_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Manchester-FunBase-20190902-033238_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Manchester-FunBase-20190902-033238_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Manchester-FunBase-20190902-033238_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-Manchester-FunBase-20190902-03323_1600w.jpg 2430w"
                          title="Townhall 12 | Manchester United Football Club Base." alt="Townhall 12 | Manchester United Football Club Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F016</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Manchester U.</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-08-01" data-title="Super Mario Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-SuperMario-Screenshot_20190803-034543_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-SuperMario-Screenshot_20190803-034543_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-SuperMario-Screenshot_20190803-034543_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-SuperMario-Screenshot_20190803-034543_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-SuperMario-Screenshot_20190803-03454_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Super Mario Design." alt="Townhall 12 | Fun Base Super Mario Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wXB2C1m2dShjAh9rXnJe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F015</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Super Mario</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-08" data-title="TH 12 Fun Base Pikachu Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025756_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025756_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025756_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025756_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025756_Clash_of_Clan_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Pikachu Pokemon Base." alt="Townhall 12 | Fun Base Pikachu Pokemon Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rR8i2Cfrm8IFo48ezpnz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F014</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Pikachu</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-06-27" data-title="Windmill Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190629-212502_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190629-212502_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190629-212502_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190629-212502_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190629-212502_Clash_of_Clan_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Windmill Design." alt="Townhall 12 | Fun Base Windmill Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F013</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Windmill</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-06-20" data-title="Homer Home Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190620-160023_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190620-160023_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190620-160023_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190620-160023_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190620-160023_Clash_of_Clan_1600w.jpg 2430w"
                          title="Townhall 12 | Just for Fun Home Base as Homer Simpsons Face" alt="Townhall 12 | Just for Fun Home Base as Homer Simpsons Face" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAFzk1dGlJUHpoq4271dO4Hs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F012</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Homer</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-08-01" data-title="Android Robot Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Android-Screenshot_20190803-035607_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Android-Screenshot_20190803-035607_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Android-Screenshot_20190803-035607_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Android-Screenshot_20190803-035607_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Android-Screenshot_20190803-03560_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Android Robot Design." alt="Townhall 12 | Fun Base Android Robot Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F011</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Android</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-30" data-title="Dragon Ball Z Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-DragonBallZ-Screenshot_20190803-033833_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-DragonBallZ-Screenshot_20190803-033833_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-DragonBallZ-Screenshot_20190803-033833_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-DragonBallZ-Screenshot_20190803-033833_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-DragonBallZ-Screenshot_20190803-03383_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Dragon Ball Z Design." alt="Townhall 12 | Fun Base Dragon Ball Z Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wT_68rLIaR6IKEz0Vhgt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Dragon Ball</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-06-21" data-title="TH 12 Fun Base Teddy Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025408_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025408_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025408_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025408_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025408_Clash_of_Clan_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Teddy Bear Base." alt="Townhall 12 | Fun Base Teddy Bear Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rQf2zJS4I1WHVLhn56ty" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Teddy Bear</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-08" data-title="TH 12 Fun Base Heart Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025629_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025629_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025629_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025629_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025629_Clash_of_Clan_1600w.jpg 2430w"
                          title="TH 12 Fun Base Heart Base." alt="Townhall 12 | Fun Base Teddy Beating Heart Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF3rRZliswjPMQ7GuXBWXpI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Heart</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-07" data-title="TH 12 Fun Base Doraemon Base.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025931_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025931_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025931_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025931_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-025931_Clash_of_Clan_1600w.jpg 2430w"
                          title="TH 12 Fun Base Doraemon Base." alt="Townhall 12 | Fun Base Doraemon Anime Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Doraemon</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12", "collaborator"]' data-date-created="2019-08-01" data-title="Butterfly Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Buttefly-Screenshot8172635499_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Buttefly-Screenshot8172635499_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Buttefly-Screenshot8172635499_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Buttefly-Screenshot8172635499_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Buttefly-Screenshot817263549_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Butterfly Design." alt="Townhall 12 | Fun Base Butterfly Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Butterfly</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-30" data-title="Unicorn Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Unicorn-Screenshot_20190803-034112_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Unicorn-Screenshot_20190803-034112_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Unicorn-Screenshot_20190803-034112_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Unicorn-Screenshot_20190803-034112_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Unicorn-Screenshot_20190803-03411_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Unicorn Design." alt="Townhall 12 | Fun Base Unicorn Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF6wVHmyyww41G-N2jT2q0C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Unicorn</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-06-24" data-title="Dancing Figurines">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005707_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005707_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005707_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005707_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005707_Clash_of_Clan_1600w.jpg 2430w"
                          title="Dancing Figurines" alt="Townhall 12 | Fun Base Layout Dancing Couple." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/no?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0ccUQBroFwzXwqjnjpXoi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Dancing</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-09" data-title="TH 12 Fun Japanese Sign for Home.">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-030119_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-030119_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-030119_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-030119_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190715-030119_Clash_of_Clan_1600w.jpg 2430w"
                          title="TH 12 Fun Japanese Sign for Home." alt="Townhall 12 | Fun Base Japanese Sign for Home." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Home</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-07-30" data-title="Wall Art Fun Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Screenshot_20190803-034408_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Screenshot_20190803-034408_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Screenshot_20190803-034408_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Screenshot_20190803-034408_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Th12-FunBase-Screenshot_20190803-03440_1600w.jpg 2430w"
                          title="Townhall 12 | Fun Base Wall Art Design." alt="Townhall 12 | Fun Base Wall Art Design." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" >Expired</a><p className="index-number">#F002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Fun</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["th12"]' data-date-created="2019-06-25" data-title="Easy">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005833_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005833_Clash_of_Clans_640w.jpg 320w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005833_Clash_of_Clans_1024w.jpg 640w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005833_Clash_of_Clans_1600w.jpg 1920w, https://townhall-12.clashbases.de/Townhall-12-Fun/Screenshot_20190625-005833_Clash_of_Clan_1600w.jpg 2430w"
                          title="Easy" alt="Townhall 12 | Fun Base Layout E.A.S.Y" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color" href="https://link.clashofclans.com/no?action=OpenLayout&id=TH12%3AHV%3AAAAAGQAAAAF0cc4VU1AHWa3F2_gmeF39" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number">#F001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs">Easy</p>
                      <p className="index-number">TH 12</p>
                    </div>
                  </div>
                </div>
              </figure>

              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>

          <div className="homebase-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">All the best Art, Fun and Troll Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              Browse through our huge collection of clash of clans art, fun and troll base layouts with links!<br />
              Pikachu base or rather a teddy bear base layout, we got them all. Find your favorite base build and import it directly into your game. <br /><br />

              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
          </div>

          </div>

      </section>

    );
  };
};

export default Justforfun;
