import React, { Component } from 'react';
import './TownhallTen.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Townhalltendemo from './TownhallTenScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class Townhallten extends Component {

  componentDidMount () {
    window.townhalltendemo = new Townhalltendemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="th10-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 10 Base Layouts and Links</title>
            <meta name="description" content="100+ amazing TH 10 Base Layouts in one big filterable Gallery! Add the best War Bases, Trophy Bases, Farm Bases, Fun Bases and LegendsLeague Bases directly into your game via the import link." />
            <meta name="keywords" content="th 10, ths 10, th ten, townhall 10, townhall 10 base, th 10 links, links, th 10 base design, th 10 bases, th 10 warbase, warbase, base for war, clan war base, clanwar base th 10, war base th 10, townhall 10 war base, townhall 10 base for war, th 10 trophybase, trophybase, base for trophys, trophy base th 10, townhall 10 trophy base, townhall 10 base for trophy, th 10 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 10, townhall 10 push base, townhall 10 base for pushing, th 10 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 10, townhall 10 farm base, townhall 10 base for farming, troll bases, th 10 troll base, th 10 fun base, th 10 funny base, th 10 trolling, funny th 10 base, best th 10 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 10 island base, ring base, th 10 ring base, teaser, th 10 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 11 links, th 12 base link, th 11 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="th10-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th10-container-about">
            <div className="th10-row-about">
              <div className="th10-title-container">
                <h1 className="th10-about-title">Townhall <span style={{color: 'rgb(215, 4, 33)'}}>10</span> Base Layouts</h1>

                <div className="switch-container-th10">
                  <LinkButton to="/Townhall-8" className="switch-buttons-th10 switch-to-th8-10">
                    <div className="switch-buttons-text-10">Lower</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-9" className="switch-buttons-th10 switch-to-th9-10">
                    <div className="switch-buttons-text-10">TH 9</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-10" className="switch-buttons-th10 switch-to-th10-10">
                    <div className="switch-buttons-text-10">TH 10</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-11" className="switch-buttons-th10 switch-to-th11-10">
                    <div className="switch-buttons-text-10">TH 11</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-12" className="switch-buttons-th10 switch-to-th12-10">
                    <div className="switch-buttons-text-10">Higher</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th10-container-about">
            <div className="th10-row-about">
              <div className="col-6@sm th10-filters-group-wrap">
                <div className="th10-filters-group filters-group-right">
                  <p className="th10-filter-label th10-filter-color">Filter</p>
                  <div className="btn-group th10-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th10-container-about">

            <div id="grid" className="th10-row-about my-shuffle-th10-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2020-05-14" data-title="Townhall 10 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-10">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext.jpg"
                        srcSet="https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1600w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 10 | Base Layouts and Links" alt="Townhall 10 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 10</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2022-10-10" data-title="Townhall 10 | The Archive">
              <a href="https://ClashBases.de/Archive-Townhall-10" target="_blank" rel="noopener noreferrer">
                <div className="picture-item__inner townhall-showcase-13">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <img
                          src="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg"
                          srcSet="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_320w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg 1024w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 2430w"
                          title="Townhall 10 | The Archive" alt="Townhall 10 | The Archive" />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><div className="link-color font-size-picture-descriptions" >Visit The Archive</div><p className="index-number font-size-picture-descriptions">All old TH 10 Base Layouts</p></figcaption>
                  </div>
                </div>
              </a>
            </figure>


              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>



          <div className="homebase-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">All the best Townhall 10 Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              Browse through our huge collection of clash of clans townhall 10 base layouts with links!<br />
              War base, Trophy base, farm base or just a casual base for aesthetics, we got them all. Find your favorite th 10 base build and import it directly into your game. <br /><br /><br />
              As a townhall 10 you want to generally focus on protecting yourself from the 3 star attacks. Because of that, the most common war bases are the anti 3 star bases that have the townhall on the outside.<br />
              By having the townhall on the outside, it is also much harder for the attacker to use the battle machines against you.<br /><br />

              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default Townhallten;
