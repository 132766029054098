import React from 'react';
import './../Wiki.css';
import { Link as LinkToAnchor } from "react-scroll";
import Collapsible from 'react-collapsible';
import LazyLoad from 'react-lazyload';
import './carousel.css';
import { Carousel } from 'react-responsive-carousel';
import Ad from "../../Ad/ad";


// TROOPS - ELIXIR TROOPS

const TriggerBarbarian = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon barbarian"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Barbarian</span></div>
</div>;
const TriggerArcher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon archer"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Archer</span></div>
</div>;
const TriggerGiant = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon giant"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Giant</span></div>
</div>;
const TriggerGoblin = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon goblin"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Goblin</span></div>
</div>;
const TriggerWallBreaker = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon wallbreaker"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Wall Breaker</span></div>
</div>;
const TriggerBalloon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon balloon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Balloon</span></div>
</div>;
const TriggerWizard = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon wizard"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Wizard</span></div>
</div>;
const TriggerHealer = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon healer"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Healer</span></div>
</div>;
const TriggerDragon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon dragon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Dragon</span></div>
</div>;
const TriggerPekka = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon pekka"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">P.E.K.K.A</span></div>
</div>;
const TriggerBabyDragon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon babydragon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Baby Dragon</span></div>
</div>;
const TriggerMiner = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon miner"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Miner</span></div>
</div>;
const TriggerElectroDragon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon electrodragon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Electro Dragon</span></div>
</div>;
const TriggerYeti = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon yeti"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Yeti</span></div>
</div>;

// TROOPS - DARK TROOPS

const TriggerMinion = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon minion"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Minion</span></div>
</div>;
const TriggerHogRider = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon hogrider"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Hog Rider</span></div>
</div>;
const TriggerValkyrie = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon valkyrie"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Valkyrie</span></div>
</div>;
const TriggerGolem = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon golem"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Golem</span></div>
</div>;
const TriggerWitch = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon witch"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Witch</span></div>
</div>;
const TriggerLavaHound = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon lavahound"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Lava Hound</span></div>
</div>;
const TriggerLavaPup = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon lavapup"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Lava Pup</span></div>
</div>;
const TriggerBowler = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon bowler"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Bowler</span></div>
</div>;
const TriggerIceGolem = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon icegolem"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Ice Golem</span></div>
</div>;
const TriggerHeadHunter = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon headhunter"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Headhunter</span></div>
</div>;

// TROOPS - SUPER TROOPS

const TriggerSuperBarbarian = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superbarbarian"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Barbarian</span></div>
</div>;
const TriggerSuperArcher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superarcher"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Archer</span></div>
</div>;
const TriggerSuperGiant = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon supergiant"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Giant</span></div>
</div>;
const TriggerSneakyGoblin = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon sneakygoblin"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Sneaky Goblin</span></div>
</div>;
const TriggerSuperWallBreaker = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superwallbreaker"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Wall Breaker</span></div>
</div>;
const TriggerSuperWizard = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superwizard"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Wizard</span></div>
</div>;
const TriggerInfernoDragon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon infernodragon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Inferno Dragon</span></div>
</div>;
const TriggerSuperMinion = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superminion"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Minion</span></div>
</div>;
const TriggerSuperValkyrie = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon supervalkyrie"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Valkyrie</span></div>
</div>;
const TriggerSuperWitch = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon superwitch"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super Witch</span></div>
</div>;
const TriggerBigBoy = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon bigboy"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Big Boy</span></div>
</div>;
const TriggerIceHound = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon icehound"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Ice Hound</span></div>
</div>;
const TriggerIcePup = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon icepup"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Ice Pup</span></div>
</div>;

// TROOPS - SIEGE MACHINES

const TriggerWallWrecker = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon wallwrecker"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Wall Wrecker</span></div>
</div>;
const TriggerBattleBlimp = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon battleblimp"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Battle Blimp</span></div>
</div>;
const TriggerStoneSlammer = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon stoneslammer"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Stone Slammer</span></div>
</div>;
const TriggerSiegeBarracks = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon siegebarracks"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Siege Barracks</span></div>
</div>;
const TriggerLogLauncher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon loglauncher"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Log Launcher</span></div>
</div>;

// TROOPS - HEROES

const TriggerBarbarianKing = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon barbarianking"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Barbarian King</span></div>
</div>;
const TriggerArcherQueen = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon archerqueen"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Archer Queen</span></div>
</div>;
const TriggerGrandWarden = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon grandwarden"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Grand Warden</span></div>
</div>;
const TriggerRoyalChampion = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon royalchampion"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Royal Champion</span></div>
</div>;

// TROOPS - PETS

const TriggerLassi = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon lassi"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">L.A.S.S.I.</span></div>
</div>;
const TriggerElectroOwl = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon electroowl"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Electro Owl</span></div>
</div>;
const TriggerMightyYak = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon mightyyak"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Mighty Yak</span></div>
</div>;
const TriggerUnicorn = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon unicorn"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Unicorn</span></div>
</div>;




const hometroops  = props => (

      <section className="homebasewiki">

        <div className="hometext-container">
          <div className="hometext-info-container">
            <div className="hometext-about">

            </div>
          </div>
        </div>

        <div className="homebasewiki-container">

            <div className="homebasewiki-buildings-container">

                <div className="hometroopsbackground">
                  <h1 className="homebasewiki-buildings-maintitel" id="Troops">Troops</h1>
                </div>

                <h2 className="homebasewiki-buildings-titel" id="ElixirTroops">Elixir Troops</h2>
                {/* NAVBAR - TROOPS - ELIXIR */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* BARBARIAN */}
                <Collapsible trigger={<TriggerBarbarian />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Barbarian' src='https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv08.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>45</td><td>8</td><td>15</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>54</td><td>11</td><td>30</td><td>25 k</td><td>6 h</td></tr>
                        <tr><td>3</td><td>3</td><td>65</td><td>14</td><td>60</td><td>100 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>5</td><td>78</td><td>18</td><td>100</td><td>300 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>6</td><td>95</td><td>23</td><td>150</td><td>1 m</td><td>1,5 d</td></tr>
                        <tr><td>6</td><td>7</td><td>110</td><td>26</td><td>200</td><td>2 m</td><td>2,5 d</td></tr>
                        <tr><td>7</td><td>8</td><td>145</td><td>30</td><td>250</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>9</td><td>205</td><td>34</td><td>300</td><td>5 m</td><td>7 d</td></tr>
                        <tr><td>9</td><td>10</td><td>230</td><td>38</td><td>350</td><td>9,5 m</td><td>12 d</td></tr>
                        <tr><td>10</td><td>11</td><td>240</td><td>42</td><td>400</td><td>15 m</td><td>14 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ARCHER */}
                <Collapsible trigger={<TriggerArcher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Archer' src='https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>20</td><td>7</td><td>30</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>23</td><td>9</td><td>60</td><td>40 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>3</td><td>28</td><td>12</td><td>120</td><td>160 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>33</td><td>16</td><td>200</td><td>480 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>40</td><td>20</td><td>300</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>7</td><td>44</td><td>22</td><td>400</td><td>2,5 m</td><td>2,5 d</td></tr>
                        <tr><td>7</td><td>8</td><td>48</td><td>25</td><td>500</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>9</td><td>52</td><td>28</td><td>600</td><td>5,5 m</td><td>7 d</td></tr>
                        <tr><td>9</td><td>10</td><td>56</td><td>31</td><td>700</td><td>10 m</td><td>12 d</td></tr>
                        <tr><td>10</td><td>11</td><td>60</td><td>34</td><td>800</td><td>15,5 m</td><td>14 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GOBLIN */}
                <Collapsible trigger={<TriggerGoblin />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Goblin' src='https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv08.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>25</td><td>11</td><td>25</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>30</td><td>14</td><td>40</td><td>50 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>3</td><td>36</td><td>19</td><td>60</td><td>200 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>46</td><td>24</td><td>80</td><td>600 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>56</td><td>32</td><td>100</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>7</td><td>76</td><td>42</td><td>150</td><td>2,5 m</td><td>2,5 d</td></tr>
                        <tr><td>7</td><td>8</td><td>101</td><td>52</td><td>200</td><td>4 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>10</td><td>126</td><td>62</td><td>250</td><td>9,5 m</td><td>7 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GIANT */}
                <Collapsible trigger={<TriggerGiant />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Giant' src='https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>300</td><td>11</td><td>150</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>360</td><td>14</td><td>300</td><td>50 k</td><td>9 h</td></tr>
                        <tr><td>3</td><td>4</td><td>430</td><td>19</td><td>750</td><td>200 k</td><td>18 h</td></tr>
                        <tr><td>4</td><td>5</td><td>520</td><td>24</td><td>1500</td><td>600 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>720</td><td>31</td><td>2250</td><td>1,5 m</td><td>2,5 d</td></tr>
                        <tr><td>6</td><td>7</td><td>940</td><td>43</td><td>3000</td><td>2,5 m</td><td>4 d</td></tr>
                        <tr><td>7</td><td>8</td><td>1280</td><td>50</td><td>3500</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>8</td><td>9</td><td>1500</td><td>57</td><td>4000</td><td>6 m</td><td>9 d</td></tr>
                        <tr><td>9</td><td>10</td><td>1850</td><td>60</td><td>4500</td><td>10,5 m</td><td>14 d</td></tr>
                        <tr><td>10</td><td>11</td><td>2000</td><td>72</td><td>5000</td><td>15 m</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* WALL BREAKER */}
                <Collapsible trigger={<TriggerWallBreaker />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv07-09.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv07-09.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv07-09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Wall</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>20</td><td>6</td><td>240</td><td>600</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>24</td><td>10</td><td>400</td><td>800</td><td>100 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>4</td><td>29</td><td>15</td><td>600</td><td>1 k</td><td>250 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>35</td><td>20</td><td>800</td><td>1,2 k</td><td>750 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>53</td><td>43</td><td>1720</td><td>1,4 k</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>8</td><td>72</td><td>55</td><td>2200</td><td>1,6 k</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>7</td><td>9</td><td>82</td><td>66</td><td>2640</td><td>1,8 k</td><td>7,5 m</td><td>7 d</td></tr>
                        <tr><td>8</td><td>10</td><td>92</td><td>75</td><td>3000</td><td>2 k</td><td>11,5 m</td><td>11 d</td></tr>
                        <tr><td>9</td><td>11</td><td>112</td><td>86</td><td>3440</td><td>2,2 k</td><td>14 m</td><td>15 d</td></tr>
                        <tr><td>10</td><td>12</td><td>130</td><td>94</td><td>3760</td><td>2,4 k</td><td>16 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BALLOON */}
                <Collapsible trigger={<TriggerBalloon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Balloon' src='https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>150</td><td>25</td><td>25</td><td>1,75 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>180</td><td>32</td><td>32</td><td>2,25 k</td><td>150 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>4</td><td>216</td><td>48</td><td>48</td><td>2,75 k</td><td>450 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>280</td><td>72</td><td>72</td><td>3,5 k</td><td>900 k</td><td>2 d</td></tr>
                        <tr><td>5</td><td>6</td><td>390</td><td>108</td><td>108</td><td>4 k</td><td>1,8 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>7</td><td>545</td><td>162</td><td>162</td><td>4,5 k</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>7</td><td>9</td><td>690</td><td>198</td><td>198</td><td>5 k</td><td>7,5 m</td><td>8 d</td></tr>
                        <tr><td>8</td><td>10</td><td>840</td><td>236</td><td>268</td><td>5,5 k</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>9</td><td>11</td><td>940</td><td>256</td><td>322</td><td>6 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* WIZARD */}
                <Collapsible trigger={<TriggerWizard />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Wizard' src='https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>75</td><td>50</td><td>1 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>3</td><td>90</td><td>70</td><td>1,4 k</td><td>150 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>4</td><td>108</td><td>90</td><td>1,8 k</td><td>350 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>130</td><td>125</td><td>2,2 k</td><td>650 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>156</td><td>170</td><td>2,6 k</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>7</td><td>175</td><td>185</td><td>3 k</td><td>2,6 m</td><td>4 d</td></tr>
                        <tr><td>7</td><td>8</td><td>190</td><td>200</td><td>3,4 k</td><td>5 m</td><td>6 d</td></tr>
                        <tr><td>8</td><td>9</td><td>210</td><td>215</td><td>3,8 k</td><td>8 m</td><td>9 d</td></tr>
                        <tr><td>9</td><td>10</td><td>230</td><td>230</td><td>4,2 k</td><td>10 m</td><td>13 d</td></tr>
                        <tr><td>10</td><td>11</td><td>250</td><td>245</td><td>4,6 k</td><td>15 m</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* HEALER */}
                <Collapsible trigger={<TriggerHealer />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv05-06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Healer' src='https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv07.png'/><p className="legend">Level 7</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Heal</td><td className="orange-font">Heal</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(per s)</td><td>(on Hero)</td><td>-</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>500</td><td>35</td><td>19</td><td>5 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>600</td><td>42</td><td>23</td><td>6 k</td><td>500 k</td><td>1,5 h</td></tr>
                        <tr><td>3</td><td>6</td><td>840</td><td>55</td><td>30</td><td>8 k</td><td>1 m</td><td>2,5 d</td></tr>
                        <tr><td>4</td><td>7</td><td>1200</td><td>65</td><td>35</td><td>10 k</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>5</td><td>9</td><td>1500</td><td>72</td><td>48</td><td>14 k</td><td>9,5 m</td><td>11,7 d</td></tr>
                        <tr><td>6</td><td>11</td><td>1600</td><td>72</td><td>56</td><td>17 k</td><td>14,5 m</td><td>15 d</td></tr>
                        <tr><td>7</td><td>12</td><td>1700</td><td>72</td><td>62</td><td>20 k</td><td>17 m</td><td>17 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* DRAGON */}
                <Collapsible trigger={<TriggerDragon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Dragon' src='https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv08.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>1900</td><td>140</td><td>10 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>2100</td><td>160</td><td>12 k</td><td>1,75 m</td><td>1,5 d</td></tr>
                        <tr><td>3</td><td>6</td><td>2300</td><td>180</td><td>14 k</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>4</td><td>7</td><td>2600</td><td>210</td><td>16 k</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>5</td><td>8</td><td>3100</td><td>240</td><td>18 k</td><td>6 m</td><td>7 d</td></tr>
                        <tr><td>6</td><td>9</td><td>3400</td><td>270</td><td>20 k</td><td>8 m</td><td>9 d</td></tr>
                        <tr><td>7</td><td>10</td><td>3900</td><td>310</td><td>22 k</td><td>10 m</td><td>14 d</td></tr>
                        <tr><td>8</td><td>11</td><td>4200</td><td>330</td><td>24 k</td><td>15 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* PEKKA */}
                <Collapsible trigger={<TriggerPekka />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='P.E.K.K.A' src='https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>2800</td><td>240</td><td>14 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>3100</td><td>270</td><td>16 k</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>3</td><td>6</td><td>3500</td><td>310</td><td>18 k</td><td>2,25 m</td><td>3,5 d</td></tr>
                        <tr><td>4</td><td>7</td><td>4000</td><td>360</td><td>20 k</td><td>3,2 m</td><td>4,5 d</td></tr>
                        <tr><td>5</td><td>8</td><td>4700</td><td>410</td><td>22,5 k</td><td>4,5 m</td><td>6 d</td></tr>
                        <tr><td>6</td><td>8</td><td>5200</td><td>470</td><td>25 k</td><td>6 m</td><td>7 d</td></tr>
                        <tr><td>7</td><td>9</td><td>5700</td><td>540</td><td>27,5 k</td><td>9 m</td><td>10 d</td></tr>
                        <tr><td>8</td><td>10</td><td>6300</td><td>610</td><td>30 k</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>9</td><td>11</td><td>6700</td><td>680</td><td>32,5 k</td><td>15,5 m</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BABY DRAGON */}
                <Collapsible trigger={<TriggerBabyDragon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='clash canon' src='https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv08.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>1200</td><td>75</td><td>5 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>1300</td><td>85</td><td>6 k</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>3</td><td>8</td><td>1400</td><td>95</td><td>7 k</td><td>3,5 m</td><td>5 d</td></tr>
                        <tr><td>4</td><td>8</td><td>1500</td><td>105</td><td>8 k</td><td>3,5 m</td><td>7 d</td></tr>
                        <tr><td>5</td><td>9</td><td>1600</td><td>115</td><td>9 k</td><td>7 m</td><td>9 d</td></tr>
                        <tr><td>6</td><td>10</td><td>1700</td><td>125</td><td>10 k</td><td>9 m</td><td>12 d</td></tr>
                        <tr><td>7</td><td>11</td><td>1800</td><td>135</td><td>11 k</td><td>15 m</td><td>15,5 d</td></tr>
                        <tr><td>8</td><td>12</td><td>1900</td><td>145</td><td>12 k</td><td>17 m</td><td>16,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* MINER */}
                <Collapsible trigger={<TriggerMiner />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv05-06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Miner' src='https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv07.png'/><p className="legend">Level 7</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>550</td><td>80</td><td>4,2 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>610</td><td>88</td><td>4,8 k</td><td>3,5 m</td><td>3 d</td></tr>
                        <tr><td>3</td><td>8</td><td>670</td><td>96</td><td>5,2 k</td><td>4,5 m</td><td>5 d</td></tr>
                        <tr><td>4</td><td>9</td><td>730</td><td>104</td><td>5,6 k</td><td>6 m</td><td>7,5 d</td></tr>
                        <tr><td>5</td><td>9</td><td>800</td><td>112</td><td>6 k</td><td>8 m</td><td>10,5 d</td></tr>
                        <tr><td>6</td><td>10</td><td>900</td><td>120</td><td>6,4 k</td><td>10,5 m</td><td>14 d</td></tr>
                        <tr><td>7</td><td>11</td><td>1000</td><td>128</td><td>6,8 k</td><td>14 m</td><td>15,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ELECTRO DRAGON */}
                <Collapsible trigger={<TriggerElectroDragon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Electro Dragon' src='https://assets.clashbases.de/Troop-Icons/Electro-Dragon/ElectroDragon-lv01-03.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Electro Dragon' src='https://assets.clashbases.de/Troop-Icons/Electro-Dragon/ElectroDragon-lv01-03.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Electro Dragon' src='https://assets.clashbases.de/Troop-Icons/Electro-Dragon/ElectroDragon-lv01-03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Electro Dragon' src='https://assets.clashbases.de/Troop-Icons/Electro-Dragon/ElectroDragon-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>3200</td><td>240</td><td>50 x6</td><td>28 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>3700</td><td>270</td><td>55 x6</td><td>32 k</td><td>9 m</td><td>10 d</td></tr>
                        <tr><td>3</td><td>10</td><td>4200</td><td>300</td><td>60 x6</td><td>36 k</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>4</td><td>11</td><td>4500</td><td>330</td><td>65 x6</td><td>40 k</td><td>16 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* YETI */}
                <Collapsible trigger={<TriggerYeti />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Yeti' src='https://assets.clashbases.de/Troop-Icons/Yeti/Yeti-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Yeti' src='https://assets.clashbases.de/Troop-Icons/Yeti/Yeti-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Yeti' src='https://assets.clashbases.de/Troop-Icons/Yeti/Yeti-lv03.png'/><p className="legend">Level 3</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Summon</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>2900</td><td>230</td><td>8</td><td>19 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>3200</td><td>250</td><td>9</td><td>21 k</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>3</td><td>11</td><td>3500</td><td>270</td><td>10</td><td>23 k</td><td>15 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="DarkTroops">Dark Troops</h2>
                {/* NAVBAR - TROOPS - DARK */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* MINION */}
                <Collapsible trigger={<TriggerMinion />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv07-08.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv07-08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Minion' src='https://assets.clashbases.de/Troop-Icons/Minion/Minion-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>55</td><td>35</td><td>4</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>60</td><td>38</td><td>5</td><td>3 k</td><td>1 d</td></tr>
                        <tr><td>3</td><td>6</td><td>66</td><td>42</td><td>6</td><td>7 k</td><td>1,5 d</td></tr>
                        <tr><td>4</td><td>6</td><td>72</td><td>46</td><td>7</td><td>15 k</td><td>2 d</td></tr>
                        <tr><td>5</td><td>7</td><td>78</td><td>50</td><td>8</td><td>25 k</td><td>3 d</td></tr>
                        <tr><td>6</td><td>8</td><td>84</td><td>54</td><td>9</td><td>40 k</td><td>4,5 d</td></tr>
                        <tr><td>7</td><td>9</td><td>90</td><td>58</td><td>10</td><td>90 k</td><td>7 d</td></tr>
                        <tr><td>8</td><td>10</td><td>96</td><td>62</td><td>11</td><td>150 k</td><td>14 d</td></tr>
                        <tr><td>9</td><td>11</td><td>102</td><td>66</td><td>12</td><td>250 k</td><td>15,5 d</td></tr>
                        <tr><td>10</td><td>12</td><td>108</td><td>70</td><td>13</td><td>300 k</td><td>16,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* HOG RIDER */}
                <Collapsible trigger={<TriggerHogRider />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Hog Rider' src='https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>270</td><td>60</td><td>30</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>312</td><td>70</td><td>34</td><td>5 k</td><td>1,3 d</td></tr>
                        <tr><td>3</td><td>6</td><td>360</td><td>80</td><td>38</td><td>9 k</td><td>2 d</td></tr>
                        <tr><td>4</td><td>6</td><td>415</td><td>92</td><td>42</td><td>16 k</td><td>2,5 d</td></tr>
                        <tr><td>5</td><td>7</td><td>480</td><td>105</td><td>48</td><td>30 k</td><td>4 d</td></tr>
                        <tr><td>6</td><td>8</td><td>590</td><td>118</td><td>60</td><td>50 k</td><td>5 d</td></tr>
                        <tr><td>7</td><td>9</td><td>700</td><td>135</td><td>80</td><td>100 k</td><td>7,5 d</td></tr>
                        <tr><td>8</td><td>10</td><td>810</td><td>148</td><td>100</td><td>150 k</td><td>11,5 d</td></tr>
                        <tr><td>9</td><td>10</td><td>890</td><td>161</td><td>120</td><td>240 k</td><td>14 d</td></tr>
                        <tr><td>10</td><td>11</td><td>970</td><td>174</td><td>140</td><td>280 k</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* VALKYRIE */}
                <Collapsible trigger={<TriggerValkyrie />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>750</td><td>94</td><td>50</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>800</td><td>106</td><td>65</td><td>8 k</td><td>2,3 d</td></tr>
                        <tr><td>3</td><td>7</td><td>850</td><td>119</td><td>80</td><td>12 k</td><td>3 d</td></tr>
                        <tr><td>4</td><td>7</td><td>900</td><td>133</td><td>100</td><td>25 k</td><td>4 d</td></tr>
                        <tr><td>5</td><td>8</td><td>1100</td><td>148</td><td>130</td><td>45 k</td><td>6 d</td></tr>
                        <tr><td>6</td><td>9</td><td>1200</td><td>163</td><td>160</td><td>90 k</td><td>8,5 d</td></tr>
                        <tr><td>7</td><td>10</td><td>1450</td><td>178</td><td>190</td><td>175 k</td><td>13 d</td></tr>
                        <tr><td>8</td><td>11</td><td>1650</td><td>193</td><td>220</td><td>260 k</td><td>16 d</td></tr>
                        <tr><td>9</td><td>12</td><td>1850</td><td>208</td><td>250</td><td>310 k</td><td>17 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GOLEM */}
                <Collapsible trigger={<TriggerGolem />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Golem' src='https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>5100</td><td>35</td><td>200</td><td>350</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>5400</td><td>40</td><td>250</td><td>400</td><td>10 k</td><td>2,5 d</td></tr>
                        <tr><td>3</td><td>7</td><td>5700</td><td>45</td><td>300</td><td>450</td><td>20 k</td><td>3 d</td></tr>
                        <tr><td>4</td><td>7</td><td>6000</td><td>50</td><td>350</td><td>500</td><td>30 k</td><td>4 d</td></tr>
                        <tr><td>5</td><td>8</td><td>6300</td><td>55</td><td>425</td><td>550</td><td>50 k</td><td>5 d</td></tr>
                        <tr><td>6</td><td>9</td><td>6600</td><td>60</td><td>500</td><td>600</td><td>75 k</td><td>7 d</td></tr>
                        <tr><td>7</td><td>9</td><td>6900</td><td>65</td><td>575</td><td>650</td><td>110 k</td><td>8 d</td></tr>
                        <tr><td>8</td><td>10</td><td>7200</td><td>70</td><td>650</td><td>700</td><td>160 k</td><td>10,5 d</td></tr>
                        <tr><td>9</td><td>10</td><td>7500</td><td>75</td><td>725</td><td>750</td><td>200 k</td><td>14 d</td></tr>
                        <tr><td>10</td><td>11</td><td>8000</td><td>80</td><td>800</td><td>800</td><td>270 k</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Witch */}
                <Collapsible trigger={<TriggerWitch />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Witch' src='https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Witch' src='https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Witch' src='https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Witch' src='https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Witch' src='https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>300</td><td>100</td><td>125</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>320</td><td>110</td><td>150</td><td>50 k</td><td>5 d</td></tr>
                        <tr><td>3</td><td>8</td><td>400</td><td>140</td><td>175</td><td>80 k</td><td>6,5 d</td></tr>
                        <tr><td>4</td><td>9</td><td>440</td><td>160</td><td>225</td><td>130 k</td><td>9,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>480</td><td>180</td><td>275</td><td>200 k</td><td>14 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Skeletons</td><td className="orange-font">max Skeletons</td></tr>
                        <tr><td>1</td><td>4</td><td>6</td></tr>
                        <tr><td>2</td><td>4</td><td>8</td></tr>
                        <tr><td>3</td><td>4</td><td>10</td></tr>
                        <tr><td>4</td><td>4</td><td>12</td></tr>
                        <tr><td>5</td><td>4</td><td>14</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* LAVAHOUND */}
                <Collapsible trigger={<TriggerLavaHound />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Lava Hound' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv06.png'/><p className="legend">Level 6</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>6100</td><td>10</td><td>100</td><td>390</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>6500</td><td>12</td><td>150</td><td>450</td><td>35 k</td><td>2,5 d</td></tr>
                        <tr><td>3</td><td>7</td><td>6800</td><td>14</td><td>200</td><td>510</td><td>60 k</td><td>5 d</td></tr>
                        <tr><td>4</td><td>7</td><td>7200</td><td>16</td><td>250</td><td>570</td><td>120 k</td><td>9 d</td></tr>
                        <tr><td>5</td><td>8</td><td>7600</td><td>18</td><td>300</td><td>630</td><td>190 k</td><td>14 d</td></tr>
                        <tr><td>6</td><td>9</td><td>8000</td><td>20</td><td>350</td><td>750</td><td>270 k</td><td>16 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lavapups on death</td></tr>
                        <tr><td>1</td><td>8</td></tr>
                        <tr><td>2</td><td>10</td></tr>
                        <tr><td>3</td><td>12</td></tr>
                        <tr><td>4</td><td>14</td></tr>
                        <tr><td>5</td><td>16</td></tr>
                        <tr><td>6</td><td>18</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* LAVA PUP */}
                <Collapsible trigger={<TriggerLavaPup />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Lava Pup' src='https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaPup-lv01.png'/><p className="legend">Level 1</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Dmg/attack</td></tr>
                        <tr><td>1</td><td>50</td><td>35</td><td>35</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BOWLER */}
                <Collapsible trigger={<TriggerBowler />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Bowler' src='https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Bowler' src='https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Bowler' src='https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Bowler' src='https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Bowler' src='https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>290</td><td>60</td><td>70</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>310</td><td>70</td><td>95</td><td>75 k</td><td>6 d</td></tr>
                        <tr><td>3</td><td>9</td><td>350</td><td>80</td><td>115</td><td>125 k</td><td>9 d</td></tr>
                        <tr><td>4</td><td>10</td><td>390</td><td>90</td><td>140</td><td>200 k</td><td>14 d</td></tr>
                        <tr><td>5</td><td>11</td><td>430</td><td>96</td><td>175</td><td>280 k</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ICEGOLEM */}
                <Collapsible trigger={<TriggerIceGolem />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Ice Golem' src='https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv05-06.png'/><p className="legend">Level 6</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>2600</td><td>24</td><td>220</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>2800</td><td>28</td><td>240</td><td>80 k</td><td>5 d</td></tr>
                        <tr><td>3</td><td>9</td><td>3000</td><td>32</td><td>260</td><td>120 k</td><td>8 d</td></tr>
                        <tr><td>4</td><td>10</td><td>3200</td><td>36</td><td>280</td><td>160 k</td><td>10,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>3400</td><td>40</td><td>300</td><td>200 k</td><td>14 d</td></tr>
                        <tr><td>6</td><td>12</td><td>3600</td><td>44</td><td>320</td><td>320 k</td><td>17 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Freeze - Attack</td><td className="orange-font">Freeze - Defense</td></tr>
                        <tr><td>1</td><td>4 s</td><td>2 s</td></tr>
                        <tr><td>2</td><td>4,75 s</td><td>2,25 s</td></tr>
                        <tr><td>3</td><td>5,5 s</td><td>2,5 s</td></tr>
                        <tr><td>4</td><td>6,25 s</td><td>2,75 s</td></tr>
                        <tr><td>5</td><td>7 s</td><td>3 s</td></tr>
                        <tr><td>6</td><td>7,5 s</td><td>3,25 s</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* HEADHUNTER */}
                <Collapsible trigger={<TriggerHeadHunter />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Headhunter' src='https://assets.clashbases.de/Troop-Icons/Headhunter/Headhunter-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Headhunter' src='https://assets.clashbases.de/Troop-Icons/Headhunter/Headhunter-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Headhunter' src='https://assets.clashbases.de/Troop-Icons/Headhunter/Headhunter-lv03.png'/><p className="legend">Level 3</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>360</td><td>105</td><td>100</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>400</td><td>115</td><td>120</td><td>180 k</td><td>14 d</td></tr>
                        <tr><td>3</td><td>11</td><td>440</td><td>125</td><td>140</td><td>240 k</td><td>16 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg Heroes</td><td className="orange-font">Speed -</td><td className="orange-font">Attack Rate -</td></tr>
                        <tr><td>1</td><td>420</td><td>40%</td><td>55%</td></tr>
                        <tr><td>2</td><td>460</td><td>42%</td><td>60%</td></tr>
                        <tr><td>3</td><td>500</td><td>44%</td><td>65%</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="SuperTroops">Super Troops</h2>
                {/* NAVBAR - TROOPS - SUPER */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* SUPER BARBARIAN */}
                <Collapsible trigger={<TriggerSuperBarbarian />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Barbarian' src='https://assets.clashbases.de/Troop-Icons/Super-Barbarian/SuperBarbarian-lv08-10.png'/><p className="legend">Levels 8-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>8</td><td>1000</td><td>180</td><td>1,5 k</td></tr>
                        <tr><td>9</td><td>1100</td><td>200</td><td>1,75 k</td></tr>
                        <tr><td>10</td><td>1200</td><td>220</td><td>2 k</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Skill</td><td className="orange-font">Dmg Increase</td><td className="orange-font">Speed Increase</td></tr>
                        <tr><td>8</td><td>8 s</td><td>70%</td><td>16</td></tr>
                        <tr><td>9</td><td>8 s</td><td>70%</td><td>16</td></tr>
                        <tr><td>10</td><td>8 s</td><td>70%</td><td>16</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER ARCHER */}
                <Collapsible trigger={<TriggerSuperArcher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Archer' src='https://assets.clashbases.de/Troop-Icons/Super-Archer/SuperArcher-lv08-10.png'/><p className="legend">Levels 8-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>8</td><td>450</td><td>120</td><td>7,2 k</td></tr>
                        <tr><td>9</td><td>510</td><td>132</td><td>8,4 k</td></tr>
                        <tr><td>10</td><td>550</td><td>144</td><td>9,6 k</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SNEAKY GOBLIN */}
                <Collapsible trigger={<TriggerSneakyGoblin />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Sneaky Goblin' src='https://assets.clashbases.de/Troop-Icons/Sneaky-Goblin/SneakyGoblin-lv07-08.png'/><p className="legend">Levels 7-8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Skill</td><td className="orange-font">Cost</td></tr>
                        <tr><td>7</td><td>600</td><td>160</td><td>5 s</td><td>600</td></tr>
                        <tr><td>8</td><td>750</td><td>190</td><td>5 s</td><td>750</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER GIANT */}
                <Collapsible trigger={<TriggerSuperGiant />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Giant' src='https://assets.clashbases.de/Troop-Icons/Super-Giant/SuperGiant-lv09-10.png'/><p className="legend">Levels 9-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Wall Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>9</td><td>4000</td><td>130</td><td>520</td><td>9 k</td></tr>
                        <tr><td>10</td><td>4200</td><td>140</td><td>560</td><td>10 k</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER WALL BREAKER */}
                <Collapsible trigger={<TriggerSuperWallBreaker />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Wall Breaker' src='https://assets.clashbases.de/Troop-Icons/Super-Wall-Breaker/SuperWallBreaker-lv07-10.png'/><p className="legend">Levels 7-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Wall Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>7</td><td>350</td><td>78</td><td>213</td><td>8.680</td><td>7,2 k</td></tr>
                        <tr><td>8</td><td>400</td><td>100</td><td>250</td><td>14.000</td><td>8 k</td></tr>
                        <tr><td>9</td><td>450</td><td>120</td><td>288</td><td>16.320</td><td>8,8 k</td></tr>
                        <tr><td>10</td><td>475</td><td>130</td><td>313</td><td>17.720</td><td>9,6 k</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER WIZARD */}
                <Collapsible trigger={<TriggerSuperWizard />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Wizard' src='https://assets.clashbases.de/Troop-Icons/Super-Wizard/SuperWizard-lv09-10.png'/><p className="legend">Level 9-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>9</td><td>450</td><td>220</td><td>10,5 k</td></tr>
                        <tr><td>10</td><td>500</td><td>240</td><td>11,5 k</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Primary Target</td><td className="orange-font">Secondary Target</td></tr>
                        <tr><td>-</td><td>(damage per attack)</td><td>(chain damage per target)</td></tr>
                        <tr><td>9</td><td>440</td><td>176</td></tr>
                        <tr><td>10</td><td>480</td><td>192</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* INFERNO DRAGON */}
                <Collapsible trigger={<TriggerInfernoDragon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Inferno Dragon' src='https://assets.clashbases.de/Troop-Icons/Inferno-Dragon/InfernoDragon-lv06-08.png'/><p className="legend">Levels 6-8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>6</td><td>1900</td><td>75 - 1.500</td><td>15 k</td></tr>
                        <tr><td>7</td><td>2050</td><td>78 - 1.580</td><td>16,5 k</td></tr>
                        <tr><td>8</td><td>2200</td><td>82 - 1.660</td><td>18 k</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER MINION */}
                <Collapsible trigger={<TriggerSuperMinion />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Minion' src='https://assets.clashbases.de/Troop-Icons/Super-Minion/SuperMinion-lv08-10.png'/><p className="legend">Levels 8-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>8</td><td>1500</td><td>300</td><td>66</td></tr>
                        <tr><td>9</td><td>1600</td><td>325</td><td>72</td></tr>
                        <tr><td>10</td><td>1700</td><td>350</td><td>78</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER VALKYRIE */}
                <Collapsible trigger={<TriggerSuperValkyrie />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Valkyrie' src='https://assets.clashbases.de/Troop-Icons/Super-Valkyrie/SuperValkyrie-lv07-09.png'/><p className="legend">Levels 7-9</p></div>

                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td></tr>
                        <tr><td>7</td><td>2000</td><td>250</td><td>475</td></tr>
                        <tr><td>8</td><td>2300</td><td>300</td><td>550</td></tr>
                        <tr><td>9</td><td>2500</td><td>325</td><td>625</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SUPER WITCH */}
                <Collapsible trigger={<TriggerSuperWitch />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super Witch' src='https://assets.clashbases.de/Troop-Icons/Super-Witch/SuperWitch-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Summon</td><td className="orange-font">Cost</td></tr>
                        <tr><td>5</td><td>3200</td><td>360</td><td>1 Big Boy</td><td>915</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BIG BOY */}
                <Collapsible trigger={<TriggerBigBoy />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Big Boy' src='https://assets.clashbases.de/Troop-Icons/Super-Witch/BigBoy-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Dmg/attack</td></tr>
                        <tr><td>5</td><td>4100</td><td>350</td><td>700</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ICE HOUND */}
                <Collapsible trigger={<TriggerIceHound />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Ice Hound' src='https://assets.clashbases.de/Troop-Icons/Ice-Hound/IceHound-lv05-06.png'/><p className="legend">Levels 5-6</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td></tr>
                        <tr><td>5</td><td>9500</td><td>10</td><td>freeze</td><td>840</td></tr>
                        <tr><td>6</td><td>10000</td><td>15</td><td>freeze</td><td>1 k</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Icepups</td><td className="orange-font">Freeze (Att)</td><td className="orange-font">Freeze (Def)</td></tr>
                        <tr><td>-</td><td>(on death)</td><td>(on death)</td><td>(on death)</td></tr>
                        <tr><td>5</td><td>10</td><td>3,5 s</td><td>3,5 s</td></tr>
                        <tr><td>6</td><td>12</td><td>4,25 s</td><td>4,25 s</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ICE PUP */}
                <Collapsible trigger={<TriggerIcePup />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Ice Pup' src='https://assets.clashbases.de/Troop-Icons/Ice-Hound/IcePup-lv01.png'/><p className="legend">Level 1</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Dmg/attack</td></tr>
                        <tr><td>1</td><td>50</td><td>35</td><td>35</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="SiegeMachines">Siege Machines</h2>
                {/* NAVBAR - TROOPS - SIEGE MACHINES */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* WALL WRECKER */}
                <Collapsible trigger={<TriggerWallWrecker />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Wall Wrecker' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Wall-Wrecker/WallWrecker-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Wall Wrecker' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Wall-Wrecker/WallWrecker-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Wall Wrecker' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Wall-Wrecker/WallWrecker-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Wall Wrecker' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Wall-Wrecker/WallWrecker-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Wall</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>5300</td><td>250</td><td>2,5 k</td><td>100 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>5700</td><td>300</td><td>3 k</td><td>100 k</td><td>6 m</td><td>8 d</td></tr>
                        <tr><td>3</td><td>10</td><td>6100</td><td>350</td><td>3,5 k</td><td>100 k</td><td>8 m</td><td>10 d</td></tr>
                        <tr><td>4</td><td>11</td><td>6500</td><td>400</td><td>4 k</td><td>100 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>


                {/* BATTLE BLIMP */}
                <Collapsible trigger={<TriggerBattleBlimp />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Battle Blimp' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Battle-Blimp/BattleBlimp-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Battle Blimp' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Battle-Blimp/BattleBlimp-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Battle Blimp' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Battle-Blimp/BattleBlimp-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Battle Blimp' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Battle-Blimp/BattleBlimp-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Deatd</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>3000</td><td>100</td><td>1,4 k</td><td>100 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>3500</td><td>140</td><td>1,4 k</td><td>100 k</td><td>6 m</td><td>8 d</td></tr>
                        <tr><td>3</td><td>10</td><td>4000</td><td>180</td><td>1,4 k</td><td>100 k</td><td>8 m</td><td>10 d</td></tr>
                        <tr><td>4</td><td>11</td><td>4500</td><td>220</td><td>1,4 k</td><td>100 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* STONE SLAMMER */}
                <Collapsible trigger={<TriggerStoneSlammer />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Stone Slammer' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Stone-Slammer/StoneSlammer-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Stone Slammer' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Stone-Slammer/StoneSlammer-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Stone Slammer' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Stone-Slammer/StoneSlammer-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Stone Slammer' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Stone-Slammer/StoneSlammer-lv04.png'/><p className="legend">Level 4</p></div>

                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>5600</td><td>400</td><td>100 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>5900</td><td>500</td><td>100 k</td><td>6 m</td><td>8 d</td></tr>
                        <tr><td>3</td><td>10</td><td>6200</td><td>600</td><td>100 k</td><td>8 m</td><td>10 d</td></tr>
                        <tr><td>4</td><td>11</td><td>6500</td><td>700</td><td>100 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SIEGE BARRACKS */}
                <Collapsible trigger={<TriggerSiegeBarracks />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Siege Barracks' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Siege-Barracks/SiegeBarracks-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Siege Barracks' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Siege-Barracks/SiegeBarracks-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Siege Barracks' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Siege-Barracks/SiegeBarracks-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Siege Barracks' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Siege-Barracks/SiegeBarracks-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td colspan="2" className="orange-font">Summons</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>Pekkas</td><td>Wizards</td><td>-</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>3300</td><td>1</td><td>6</td><td>100 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>3700</td><td>1</td><td>8</td><td>100 k</td><td>8 m</td><td>10 d</td></tr>
                        <tr><td>3</td><td>10</td><td>4100</td><td>1</td><td>10</td><td>100 k</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>4</td><td>11</td><td>4500</td><td>1</td><td>12</td><td>100 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* LOG LAUNCHER */}
                <Collapsible trigger={<TriggerLogLauncher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Log Launcher' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Log-Launcher/LogLauncher-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Log Launcher' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Log-Launcher/LogLauncher-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Log Launcher' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Log-Launcher/LogLauncher-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Log Launcher' src='https://assets.clashbases.de/Troop-Icons/Siege-Icons/Log-Launcher/LogLauncher-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(point blank)</td><td>-</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>4000</td><td>140</td><td>2,9 k</td><td>100 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>10</td><td>4400</td><td>160</td><td>3 k</td><td>100 k</td><td>8 m</td><td>10 d</td></tr>
                        <tr><td>3</td><td>10</td><td>4800</td><td>180</td><td>3,1 k</td><td>100 k</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>4</td><td>11</td><td>5200</td><td>200</td><td>3,2 k</td><td>100 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="Heroes">Heroes</h2>
                {/* NAVBAR - TROOPS - HEROES */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* BARBARIAN KING */}
                <Collapsible trigger={<TriggerBarbarianKing />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Barbarian King' src='https://assets.clashbases.de/Hero-Icons/Barbarian-King/Barbarian_King_3D_preview.png'/><p className="legend">Preview</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Skill</td><td className="orange-font">Regen</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>1700</td><td>120</td><td>-</td><td>10 m</td><td>5 k</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>1742</td><td>122</td><td>-</td><td>10 m</td><td>6 k</td><td>4  h</td></tr>
                        <tr><td>3</td><td>7</td><td>1786</td><td>124</td><td>-</td><td>10 m</td><td>7 k</td><td>6 h</td></tr>
                        <tr><td>4</td><td>7</td><td>1830</td><td>127</td><td>-</td><td>10 m</td><td>8 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>7</td><td>1875</td><td>129</td><td>1</td><td>12 m</td><td>10 k</td><td>10 h</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>8</td><td>1923</td><td>132</td><td>1</td><td>12 m</td><td>11 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>8</td><td>1971</td><td>135</td><td>1</td><td>12 m</td><td>12 k</td><td>14 h</td></tr>
                        <tr><td>8</td><td>8</td><td>2020</td><td>137</td><td>1</td><td>12 m</td><td>13 k</td><td>16 h</td></tr>
                        <tr><td>9</td><td>8</td><td>2071</td><td>140</td><td>1</td><td>12 m</td><td>14 k</td><td>18 h</td></tr>
                        <tr><td>10</td><td>8</td><td>2123</td><td>143</td><td>2</td><td>14 m</td><td>15 k</td><td>20 h</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>9</td><td>2176</td><td>146</td><td>2</td><td>14 m</td><td>17 k</td><td>22 h</td></tr>
                        <tr><td>12</td><td>9</td><td>2230</td><td>149</td><td>2</td><td>14 m</td><td>19 k</td><td>1 d</td></tr>
                        <tr><td>13</td><td>9</td><td>2286</td><td>152</td><td>2</td><td>14 m</td><td>21 k</td><td>1,3 d</td></tr>
                        <tr><td>14</td><td>9</td><td>2343</td><td>155</td><td>2</td><td>14 m</td><td>23 k</td><td>1,6 d</td></tr>
                        <tr><td>15</td><td>9</td><td>2402</td><td>158</td><td>3</td><td>16 m</td><td>25 k</td><td>2 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>9</td><td>2462</td><td>161</td><td>3</td><td>16 m</td><td>27 k</td><td>2 d</td></tr>
                        <tr><td>17</td><td>9</td><td>2523</td><td>164</td><td>3</td><td>16 m</td><td>29 k</td><td>2 d</td></tr>
                        <tr><td>18</td><td>9</td><td>2586</td><td>168</td><td>3</td><td>16 m</td><td>31 k</td><td>2 d</td></tr>
                        <tr><td>19</td><td>9</td><td>2651</td><td>171</td><td>3</td><td>16 m</td><td>33 k</td><td>2 d</td></tr>
                        <tr><td>20</td><td>9</td><td>2717</td><td>174</td><td>4</td><td>18 m</td><td>35 k</td><td>3 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>9</td><td>2785</td><td>178</td><td>4</td><td>18 m</td><td>37 k</td><td>3 d</td></tr>
                        <tr><td>22</td><td>9</td><td>2855</td><td>181</td><td>4</td><td>18 m</td><td>39 k</td><td>3 d</td></tr>
                        <tr><td>23</td><td>9</td><td>2926</td><td>185</td><td>4</td><td>18 m</td><td>41 k</td><td>3 d</td></tr>
                        <tr><td>24</td><td>9</td><td>2999</td><td>189</td><td>4</td><td>18 m</td><td>43 k</td><td>3 d</td></tr>
                        <tr><td>25</td><td>9</td><td>3074</td><td>193</td><td>5</td><td>20 m</td><td>45 k</td><td>3,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>9</td><td>3151</td><td>196</td><td>5</td><td>20 m</td><td>48 k</td><td>3,5 d</td></tr>
                        <tr><td>27</td><td>9</td><td>3230</td><td>200</td><td>5</td><td>20 m</td><td>51 k</td><td>3,5 d</td></tr>
                        <tr><td>28</td><td>9</td><td>3311</td><td>204</td><td>5</td><td>20 m</td><td>54 k</td><td>3,5 d</td></tr>
                        <tr><td>29</td><td>9</td><td>3394</td><td>208</td><td>5</td><td>20 m</td><td>57 k</td><td>3,5 d</td></tr>
                        <tr><td>30</td><td>9</td><td>3478</td><td>213</td><td>6</td><td>22 m</td><td>60 k</td><td>4 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>31</td><td>10</td><td>3565</td><td>217</td><td>6</td><td>22 m</td><td>63 k</td><td>4 d</td></tr>
                        <tr><td>32</td><td>10</td><td>3655</td><td>221</td><td>6</td><td>22 m</td><td>66 k</td><td>4 d</td></tr>
                        <tr><td>33</td><td>10</td><td>3746</td><td>226</td><td>6</td><td>22 m</td><td>69 k</td><td>4 d</td></tr>
                        <tr><td>34</td><td>10</td><td>3840</td><td>230</td><td>6</td><td>22 m</td><td>72 k</td><td>4 d</td></tr>
                        <tr><td>35</td><td>10</td><td>3936</td><td>235</td><td>7</td><td>24 m</td><td>75 k</td><td>5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>36</td><td>10</td><td>4034</td><td>239</td><td>7</td><td>24 m</td><td>80 k</td><td>5 d</td></tr>
                        <tr><td>37</td><td>10</td><td>4135</td><td>244</td><td>7</td><td>24 m</td><td>85 k</td><td>5 d</td></tr>
                        <tr><td>38</td><td>10</td><td>4238</td><td>249</td><td>7</td><td>24 m</td><td>90 k</td><td>5 d</td></tr>
                        <tr><td>39</td><td>10</td><td>4344</td><td>254</td><td>7</td><td>24 m</td><td>95 k</td><td>5 d</td></tr>
                        <tr><td>40</td><td>10</td><td>4453</td><td>259</td><td>8</td><td>26 m</td><td>100 k</td><td>6 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>41</td><td>11</td><td>4564</td><td>275</td><td>8</td><td>26 m</td><td>105 k</td><td>6 d</td></tr>
                        <tr><td>42</td><td>11</td><td>4678</td><td>281</td><td>8</td><td>26 m</td><td>110 k</td><td>6 d</td></tr>
                        <tr><td>43</td><td>11</td><td>4795</td><td>387</td><td>8</td><td>26 m</td><td>120 k</td><td>6 d</td></tr>
                        <tr><td>44</td><td>11</td><td>4915</td><td>293</td><td>8</td><td>26 m</td><td>130 k</td><td>6 d</td></tr>
                        <tr><td>45</td><td>11</td><td>5038</td><td>299</td><td>9</td><td>28 m</td><td>140 k</td><td>6,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>46</td><td>11</td><td>5164</td><td>305</td><td>9</td><td>28 m</td><td>150 k</td><td>6,5 d</td></tr>
                        <tr><td>47</td><td>11</td><td>5293</td><td>312</td><td>9</td><td>28 m</td><td>160 k</td><td>6,5 d</td></tr>
                        <tr><td>48</td><td>11</td><td>5425</td><td>318</td><td>9</td><td>28 m</td><td>170 k</td><td>6,5 d</td></tr>
                        <tr><td>49</td><td>11</td><td>5561</td><td>325</td><td>9</td><td>28 m</td><td>180 k</td><td>6,5 d</td></tr>
                        <tr><td>50</td><td>11</td><td>5700</td><td>332</td><td>10</td><td>30 m</td><td>190 k</td><td>6,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>51</td><td>12</td><td>5843</td><td>339</td><td>10</td><td>30 m</td><td>200 k</td><td>7 d</td></tr>
                        <tr><td>52</td><td>12</td><td>5990</td><td>346</td><td>10</td><td>30 m</td><td>203 k</td><td>7 d</td></tr>
                        <tr><td>53</td><td>12</td><td>6140</td><td>353</td><td>10</td><td>30 m</td><td>206 k</td><td>7 d</td></tr>
                        <tr><td>54</td><td>12</td><td>6294</td><td>361</td><td>10</td><td>30 m</td><td>209 k</td><td>7 d</td></tr>
                        <tr><td>55</td><td>12</td><td>6452</td><td>369</td><td>11</td><td>32 m</td><td>212 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>56</td><td>12</td><td>6614</td><td>377</td><td>11</td><td>32 m</td><td>215 k</td><td>7 d</td></tr>
                        <tr><td>57</td><td>12</td><td>6780</td><td>385</td><td>11</td><td>32 m</td><td>218 k</td><td>7 d</td></tr>
                        <tr><td>58</td><td>12</td><td>6950</td><td>393</td><td>11</td><td>32 m</td><td>221 k</td><td>7 d</td></tr>
                        <tr><td>59</td><td>12</td><td>7124</td><td>401</td><td>11</td><td>32 m</td><td>224 k</td><td>7 d</td></tr>
                        <tr><td>60</td><td>12</td><td>7303</td><td>410</td><td>12</td><td>34 m</td><td>227 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>61</td><td>12</td><td>7486</td><td>418</td><td>12</td><td>34 m</td><td>230 k</td><td>7 d</td></tr>
                        <tr><td>62</td><td>12</td><td>7673</td><td>426</td><td>12</td><td>34 m</td><td>233 k</td><td>7 d</td></tr>
                        <tr><td>63</td><td>12</td><td>7865</td><td>435</td><td>12</td><td>34 m</td><td>236 k</td><td>7 d</td></tr>
                        <tr><td>64</td><td>12</td><td>8062</td><td>444</td><td>12</td><td>34 m</td><td>239 k</td><td>7 d</td></tr>
                        <tr><td>65</td><td>12</td><td>8264</td><td>453</td><td>13</td><td>36 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>66</td><td>13</td><td>8470</td><td>462</td><td>13</td><td>36 m</td><td>250 k</td><td>7,5 d</td></tr>
                        <tr><td>67</td><td>13</td><td>8680</td><td>471</td><td>13</td><td>36 m</td><td>260 k</td><td>7,5 d</td></tr>
                        <tr><td>68</td><td>13</td><td>8890</td><td>480</td><td>13</td><td>36 m</td><td>270 k</td><td>7,5 d</td></tr>
                        <tr><td>69</td><td>13</td><td>9100</td><td>490</td><td>13</td><td>36 m</td><td>280 k</td><td>7,5 d</td></tr>
                        <tr><td>70</td><td>13</td><td>9300</td><td>500</td><td>14</td><td>38 m</td><td>290 k</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>71</td><td>13</td><td>9500</td><td>510</td><td>14</td><td>38 m</td><td>292 k</td><td>8 d</td></tr>
                        <tr><td>72</td><td>13</td><td>9700</td><td>520</td><td>14</td><td>38 m</td><td>294 k</td><td>8 d</td></tr>
                        <tr><td>73</td><td>13</td><td>9900</td><td>530</td><td>14</td><td>38 m</td><td>296 k</td><td>8 d</td></tr>
                        <tr><td>74</td><td>13</td><td>10.100</td><td>540</td><td>14</td><td>38 m</td><td>298 k</td><td>8 d</td></tr>
                        <tr><td>75</td><td>13</td><td>10.300</td><td>550</td><td>15</td><td>40 m</td><td>300 k</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>76</td><td>14</td><td>10.490</td><td>559</td><td>15</td><td>40 m</td><td>305 k</td><td>8 d</td></tr>
                        <tr><td>77</td><td>14</td><td>10.680</td><td>568</td><td>15</td><td>40 m</td><td>310 k</td><td>8 d</td></tr>
                        <tr><td>78</td><td>14</td><td>10.870</td><td>577</td><td>15</td><td>40 m</td><td>315 k</td><td>8 d</td></tr>
                        <tr><td>79</td><td>14</td><td>11.060</td><td>586</td><td>15</td><td>40 m</td><td>320 k</td><td>8 d</td></tr>
                        <tr><td>80</td><td>14</td><td>11.250</td><td>595</td><td>16</td><td>42 m</td><td>325 k</td><td>8 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="6">Iron Fist (ability)</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg</td><td className="orange-font">Speed</td><td className="orange-font">Health</td><td className="orange-font">Ability</td><td className="orange-font">Summons</td></tr>
                        <tr><td ></td><td>(increase)</td><td>(increase)</td><td>(recovery)</td><td>(Time)</td><td>(Barbs)</td></tr>
                        <tr><td>1</td><td>56</td><td>18</td><td>500</td><td>10 s</td><td>6</td></tr>
                        <tr><td>2</td><td>101</td><td>19</td><td>620</td><td>10 s</td><td>8</td></tr>
                        <tr><td>3</td><td>147</td><td>20</td><td>752</td><td>10 s</td><td>10</td></tr>
                        <tr><td>4</td><td>195</td><td>21</td><td>899</td><td>10 s</td><td>12</td></tr>
                        <tr><td>5</td><td>245</td><td>22</td><td>1.063</td><td>10 s</td><td>14</td></tr>
                        <tr><td>6</td><td>298</td><td>23</td><td>1.247</td><td>10 s</td><td>16</td></tr>
                        <tr><td>7</td><td>354</td><td>24</td><td>1.455</td><td>10 s</td><td>18</td></tr>
                        <tr><td>8</td><td>414</td><td>25</td><td>1.692</td><td>10 s</td><td>20</td></tr>
                        <tr><td>9</td><td>478</td><td>26</td><td>1.963</td><td>10 s</td><td>22</td></tr>
                        <tr><td>10</td><td>546</td><td>27</td><td>2.263</td><td>10 s</td><td>24</td></tr>
                        <tr><td>11</td><td>618</td><td>28</td><td>2.592</td><td>10 s</td><td>26</td></tr>
                        <tr><td>12</td><td>694</td><td>29</td><td>2.980</td><td>10 s</td><td>28</td></tr>
                        <tr><td>13</td><td>770</td><td>30</td><td>3.400</td><td>10 s</td><td>30</td></tr>
                        <tr><td>14</td><td>846</td><td>31</td><td>3.850</td><td>10 s</td><td>32</td></tr>
                        <tr><td>15</td><td>922</td><td>32</td><td>4.300</td><td>10 s</td><td>34</td></tr>
                        <tr><td>16</td><td>990</td><td>33</td><td>4.700</td><td>10 s</td><td>36</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ARCHER QUEEN */}
                <Collapsible trigger={<TriggerArcherQueen />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Archer Queen' src='https://assets.clashbases.de/Hero-Icons/Archer-Queen/Archer_Queen_3D_preview.png'/><p className="legend">Preview</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Skill</td><td className="orange-font">Regen</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>9</td><td>725</td><td>160</td><td>-</td><td>10 m</td><td>10 k</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>740</td><td>164</td><td>-</td><td>10 m</td><td>11 k</td><td>4  h</td></tr>
                        <tr><td>3</td><td>9</td><td>755</td><td>168</td><td>-</td><td>10 m</td><td>12 k</td><td>6 h</td></tr>
                        <tr><td>4</td><td>9</td><td>771</td><td>172</td><td>-</td><td>10 m</td><td>13 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>9</td><td>787</td><td>176</td><td>1</td><td>12 m</td><td>15 k</td><td>10 h</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>9</td><td>804</td><td>181</td><td>1</td><td>12 m</td><td>16 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>9</td><td>821</td><td>185</td><td>1</td><td>12 m</td><td>17 k</td><td>14 h</td></tr>
                        <tr><td>8</td><td>9</td><td>838</td><td>190</td><td>1</td><td>12 m</td><td>18 k</td><td>16 h</td></tr>
                        <tr><td>9</td><td>9</td><td>856</td><td>194</td><td>1</td><td>12 m</td><td>19 k</td><td>18 h</td></tr>
                        <tr><td>10</td><td>9</td><td>874</td><td>199</td><td>2</td><td>14 m</td><td>20 k</td><td>20 h</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>9</td><td>892</td><td>204</td><td>2</td><td>14 m</td><td>22 k</td><td>22 h</td></tr>
                        <tr><td>12</td><td>9</td><td>911</td><td>209</td><td>2</td><td>14 m</td><td>24 k</td><td>1 d</td></tr>
                        <tr><td>13</td><td>9</td><td>930</td><td>215</td><td>2</td><td>14 m</td><td>26 k</td><td>1,3 d</td></tr>
                        <tr><td>14</td><td>9</td><td>949</td><td>220</td><td>2</td><td>14 m</td><td>28 k</td><td>1,6 d</td></tr>
                        <tr><td>15</td><td>9</td><td>969</td><td>226</td><td>3</td><td>16 m</td><td>30 k</td><td>2 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>9</td><td>990</td><td>231</td><td>3</td><td>16 m</td><td>32 k</td><td>2 d</td></tr>
                        <tr><td>17</td><td>9</td><td>1010</td><td>237</td><td>3</td><td>16 m</td><td>34 k</td><td>2 d</td></tr>
                        <tr><td>18</td><td>9</td><td>1032</td><td>243</td><td>3</td><td>16 m</td><td>36 k</td><td>2 d</td></tr>
                        <tr><td>19</td><td>9</td><td>1053</td><td>249</td><td>3</td><td>16 m</td><td>38 k</td><td>2 d</td></tr>
                        <tr><td>20</td><td>9</td><td>1076</td><td>255</td><td>4</td><td>18 m</td><td>40 k</td><td>3 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>9</td><td>1098</td><td>262</td><td>4</td><td>18 m</td><td>42 k</td><td>3 d</td></tr>
                        <tr><td>22</td><td>9</td><td>1121</td><td>268</td><td>4</td><td>18 m</td><td>44 k</td><td>3 d</td></tr>
                        <tr><td>23</td><td>9</td><td>1145</td><td>275</td><td>4</td><td>18 m</td><td>46 k</td><td>3 d</td></tr>
                        <tr><td>24</td><td>9</td><td>1169</td><td>282</td><td>4</td><td>18 m</td><td>48 k</td><td>3 d</td></tr>
                        <tr><td>25</td><td>9</td><td>1193</td><td>289</td><td>5</td><td>20 m</td><td>50 k</td><td>3,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>9</td><td>1218</td><td>296</td><td>5</td><td>20 m</td><td>53 k</td><td>3,5 d</td></tr>
                        <tr><td>27</td><td>9</td><td>1244</td><td>304</td><td>5</td><td>20 m</td><td>56 k</td><td>3,5 d</td></tr>
                        <tr><td>28</td><td>9</td><td>1270</td><td>311</td><td>5</td><td>20 m</td><td>59 k</td><td>3,5 d</td></tr>
                        <tr><td>29</td><td>9</td><td>1297</td><td>319</td><td>5</td><td>20 m</td><td>62 k</td><td>3,5 d</td></tr>
                        <tr><td>30</td><td>9</td><td>1324</td><td>327</td><td>6</td><td>22 m</td><td>65 k</td><td>4 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>31</td><td>10</td><td>1352</td><td>335</td><td>6</td><td>22 m</td><td>68 k</td><td>4 d</td></tr>
                        <tr><td>32</td><td>10</td><td>1380</td><td>344</td><td>6</td><td>22 m</td><td>71 k</td><td>4 d</td></tr>
                        <tr><td>33</td><td>10</td><td>1409</td><td>352</td><td>6</td><td>22 m</td><td>74 k</td><td>4 d</td></tr>
                        <tr><td>34</td><td>10</td><td>1439</td><td>361</td><td>6</td><td>22 m</td><td>77 k</td><td>4 d</td></tr>
                        <tr><td>35</td><td>10</td><td>1469</td><td>370</td><td>7</td><td>24 m</td><td>80 k</td><td>5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>36</td><td>10</td><td>1500</td><td>379</td><td>7</td><td>24 m</td><td>85 k</td><td>5 d</td></tr>
                        <tr><td>37</td><td>10</td><td>1539</td><td>389</td><td>7</td><td>24 m</td><td>90 k</td><td>5 d</td></tr>
                        <tr><td>38</td><td>10</td><td>1564</td><td>398</td><td>7</td><td>24 m</td><td>95 k</td><td>5 d</td></tr>
                        <tr><td>39</td><td>10</td><td>1597</td><td>408</td><td>7</td><td>24 m</td><td>100 k</td><td>5 d</td></tr>
                        <tr><td>40</td><td>10</td><td>1630</td><td>419</td><td>8</td><td>26 m</td><td>105 k</td><td>6 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>41</td><td>11</td><td>1664</td><td>429</td><td>8</td><td>26 m</td><td>115 k</td><td>6 d</td></tr>
                        <tr><td>42</td><td>11</td><td>1699</td><td>440</td><td>8</td><td>26 m</td><td>120 k</td><td>6 d</td></tr>
                        <tr><td>43</td><td>11</td><td>1735</td><td>451</td><td>8</td><td>26 m</td><td>125 k</td><td>6 d</td></tr>
                        <tr><td>44</td><td>11</td><td>1771</td><td>462</td><td>8</td><td>26 m</td><td>135 k</td><td>6 d</td></tr>
                        <tr><td>45</td><td>11</td><td>1809</td><td>474</td><td>9</td><td>28 m</td><td>145 k</td><td>6,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>46</td><td>11</td><td>1847</td><td>486</td><td>9</td><td>28 m</td><td>155 k</td><td>6,5 d</td></tr>
                        <tr><td>47</td><td>11</td><td>1885</td><td>498</td><td>9</td><td>28 m</td><td>165 k</td><td>6,5 d</td></tr>
                        <tr><td>48</td><td>11</td><td>1925</td><td>510</td><td>9</td><td>28 m</td><td>175 k</td><td>6,5 d</td></tr>
                        <tr><td>49</td><td>11</td><td>1965</td><td>523</td><td>9</td><td>28 m</td><td>185 k</td><td>6,5 d</td></tr>
                        <tr><td>50</td><td>11</td><td>2007</td><td>536</td><td>10</td><td>30 m</td><td>195 k</td><td>6,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>51</td><td>12</td><td>2058</td><td>547</td><td>10</td><td>30 m</td><td>200 k</td><td>7 d</td></tr>
                        <tr><td>52</td><td>12</td><td>2110</td><td>558</td><td>10</td><td>30 m</td><td>204 k</td><td>7 d</td></tr>
                        <tr><td>53</td><td>12</td><td>2163</td><td>570</td><td>10</td><td>30 m</td><td>208 k</td><td>7 d</td></tr>
                        <tr><td>54</td><td>12</td><td>2218</td><td>582</td><td>10</td><td>30 m</td><td>212 k</td><td>7 d</td></tr>
                        <tr><td>55</td><td>12</td><td>2274</td><td>594</td><td>11</td><td>32 m</td><td>216 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>56</td><td>12</td><td>2331</td><td>606</td><td>11</td><td>32 m</td><td>220 k</td><td>7 d</td></tr>
                        <tr><td>57</td><td>12</td><td>2390</td><td>619</td><td>11</td><td>32 m</td><td>224 k</td><td>7 d</td></tr>
                        <tr><td>58</td><td>12</td><td>2450</td><td>632</td><td>11</td><td>32 m</td><td>228 k</td><td>7 d</td></tr>
                        <tr><td>59</td><td>12</td><td>2512</td><td>645</td><td>11</td><td>32 m</td><td>232 k</td><td>7 d</td></tr>
                        <tr><td>60</td><td>12</td><td>2575</td><td>658</td><td>12</td><td>34 m</td><td>236 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>61</td><td>12</td><td>2639</td><td>671</td><td>12</td><td>34 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>62</td><td>12</td><td>2705</td><td>684</td><td>12</td><td>34 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>63</td><td>12</td><td>2773</td><td>698</td><td>12</td><td>34 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>64</td><td>12</td><td>2842</td><td>712</td><td>12</td><td>34 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>65</td><td>12</td><td>2913</td><td>726</td><td>13</td><td>36 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>66</td><td>13</td><td>2980</td><td>739</td><td>13</td><td>36 m</td><td>250 k</td><td>7,5 d</td></tr>
                        <tr><td>67</td><td>13</td><td>3040</td><td>751</td><td>13</td><td>36 m</td><td>260 k</td><td>7,5 d</td></tr>
                        <tr><td>68</td><td>13</td><td>3095</td><td>762</td><td>13</td><td>36 m</td><td>270 k</td><td>7,5 d</td></tr>
                        <tr><td>69</td><td>13</td><td>3145</td><td>772</td><td>13</td><td>36 m</td><td>280 k</td><td>7,5 d</td></tr>
                        <tr><td>70</td><td>13</td><td>3190</td><td>781</td><td>14</td><td>38 m</td><td>290 k</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>71</td><td>13</td><td>3230</td><td>789</td><td>14</td><td>38 m</td><td>292 k</td><td>8 d</td></tr>
                        <tr><td>72</td><td>13</td><td>3270</td><td>796</td><td>14</td><td>38 m</td><td>294 k</td><td>8 d</td></tr>
                        <tr><td>73</td><td>13</td><td>3310</td><td>802</td><td>14</td><td>38 m</td><td>296 k</td><td>8 d</td></tr>
                        <tr><td>74</td><td>13</td><td>3350</td><td>808</td><td>14</td><td>38 m</td><td>298 k</td><td>8 d</td></tr>
                        <tr><td>75</td><td>13</td><td>3390</td><td>814</td><td>15</td><td>40 m</td><td>300 k</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>76</td><td>14</td><td>3425</td><td>820</td><td>15</td><td>40 m</td><td>306 k</td><td>8 d</td></tr>
                        <tr><td>77</td><td>14</td><td>3460</td><td>825</td><td>15</td><td>40 m</td><td>312 k</td><td>8 d</td></tr>
                        <tr><td>78</td><td>14</td><td>3495</td><td>830</td><td>15</td><td>40 m</td><td>318 k</td><td>8 d</td></tr>
                        <tr><td>79</td><td>14</td><td>3530</td><td>835</td><td>15</td><td>40 m</td><td>324 k</td><td>8 d</td></tr>
                        <tr><td>80</td><td>14</td><td>3565</td><td>840</td><td>16</td><td>42 m</td><td>330 k</td><td>8 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="5">Royal Cloak (ability)</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg </td><td className="orange-font">Health</td><td className="orange-font">Ability</td><td className="orange-font">Summoned</td></tr>
                        <tr><td>-</td><td>(increase)</td><td>(Recovery)</td><td>(duration)</td><td>Archers</td></tr>
                        <tr><td>1</td><td>300</td><td>150</td><td>3,6 s</td><td>5</td></tr>
                        <tr><td>2</td><td>355</td><td>175</td><td>3,8 s</td><td>6</td></tr>
                        <tr><td>3</td><td>416</td><td>200</td><td>4 s</td><td>7</td></tr>
                        <tr><td>4</td><td>483</td><td>225</td><td>4,2 s</td><td>8</td></tr>
                        <tr><td>5</td><td>557</td><td>250</td><td>4,4 s</td><td>9</td></tr>
                        <tr><td>6</td><td>638</td><td>275</td><td>4,6 s</td><td>10</td></tr>
                        <tr><td>7</td><td>725</td><td>300</td><td>4,8 s</td><td>11</td></tr>
                        <tr><td>8</td><td>819</td><td>325</td><td>5 s</td><td>12</td></tr>
                        <tr><td>9</td><td>920</td><td>350</td><td>5,2 s</td><td>13</td></tr>
                        <tr><td>10</td><td>1.020</td><td>375</td><td>5,4 s</td><td>14</td></tr>
                        <tr><td>11</td><td>1.120</td><td>400</td><td>5,6 s</td><td>15</td></tr>
                        <tr><td>12</td><td>1.220</td><td>425</td><td>5,8 s</td><td>16</td></tr>
                        <tr><td>13</td><td>1.300</td><td>450</td><td>6 s</td><td>17</td></tr>
                        <tr><td>14</td><td>1.370</td><td>475</td><td>6,2 s</td><td>18</td></tr>
                        <tr><td>15</td><td>1.430</td><td>500</td><td>6,4 s</td><td>19</td></tr>
                        <tr><td>16</td><td>1.480</td><td>525</td><td>6,6 s</td><td>20</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GRAND WARDEN */}
                <Collapsible trigger={<TriggerGrandWarden />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Grand Warden' src='https://assets.clashbases.de/Hero-Icons/Grand-Warden/Grand_Warden_3D_preview.png'/><p className="legend">Preview</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Regen</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>11</td><td>1000</td><td>50</td><td>20 m</td><td>2 m</td><td>-</td></tr>
                        <tr><td>2</td><td>11</td><td>1021</td><td>52</td><td>20 m</td><td>2,25 m</td><td>4 h</td></tr>
                        <tr><td>3</td><td>11</td><td>1042</td><td>54</td><td>20 m</td><td>2,5 m</td><td>8 h</td></tr>
                        <tr><td>4</td><td>11</td><td>1064</td><td>56</td><td>20 m</td><td>2,75 m</td><td>12 h</td></tr>
                        <tr><td>5</td><td>11</td><td>1086</td><td>58</td><td>22 m</td><td>3 m</td><td>1 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>11</td><td>1108</td><td>60</td><td>22 m</td><td>3,3 m</td><td>1,5 d</td></tr>
                        <tr><td>7</td><td>11</td><td>1131</td><td>63</td><td>22 m</td><td>3,75 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>11</td><td>1155</td><td>66</td><td>22 m</td><td>4,5 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>11</td><td>1180</td><td>69</td><td>22 m</td><td>5,25 m</td><td>4 d</td></tr>
                        <tr><td>10</td><td>11</td><td>1206</td><td>72</td><td>24 m</td><td>6 m</td><td>4,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>11</td><td>1233</td><td>75</td><td>24 m</td><td>7 m</td><td>5 d</td></tr>
                        <tr><td>12</td><td>11</td><td>1261</td><td>78</td><td>24 m</td><td>7,5 m</td><td>5,5 d</td></tr>
                        <tr><td>13</td><td>11</td><td>1290</td><td>82</td><td>24 m</td><td>8 m</td><td>6 d</td></tr>
                        <tr><td>14</td><td>11</td><td>1320</td><td>86</td><td>24 m</td><td>8,4 m</td><td>6 d</td></tr>
                        <tr><td>15</td><td>11</td><td>1350</td><td>90</td><td>26 m</td><td>8,8 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>11</td><td>1380</td><td>94</td><td>26 m</td><td>9,1 m</td><td>7 d</td></tr>
                        <tr><td>17</td><td>11</td><td>1410</td><td>98</td><td>26 m</td><td>9,4 m</td><td>7 d</td></tr>
                        <tr><td>18</td><td>11</td><td>1440</td><td>102</td><td>26 m</td><td>9,6 m</td><td>7 d</td></tr>
                        <tr><td>19</td><td>11</td><td>1470</td><td>106</td><td>26 m</td><td>9,8 m</td><td>7 d</td></tr>
                        <tr><td>20</td><td>11</td><td>1500</td><td>110</td><td>28 m</td><td>10 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>12</td><td>1530</td><td>115</td><td>28 m</td><td>10 m</td><td>-</td></tr>
                        <tr><td>22</td><td>12</td><td>1561</td><td>120</td><td>28 m</td><td>10,2 m</td><td>7 d</td></tr>
                        <tr><td>23</td><td>12</td><td>1593</td><td>125</td><td>28 m</td><td>10,4 m</td><td>7 d</td></tr>
                        <tr><td>24</td><td>12</td><td>1625</td><td>130</td><td>28 m</td><td>10,6 m</td><td>7 d</td></tr>
                        <tr><td>25</td><td>12</td><td>1658</td><td>136</td><td>30 m</td><td>10,8 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>12</td><td>1692</td><td>142</td><td>30 m</td><td>11 m</td><td>7 d</td></tr>
                        <tr><td>27</td><td>12</td><td>1726</td><td>148</td><td>30 m</td><td>11,2 m</td><td>7 d</td></tr>
                        <tr><td>28</td><td>12</td><td>1761</td><td>154</td><td>30 m</td><td>11,4 m</td><td>7 d</td></tr>
                        <tr><td>29</td><td>12</td><td>1797</td><td>161</td><td>30 m</td><td>11,6 m</td><td>7 d</td></tr>
                        <tr><td>30</td><td>12</td><td>1833</td><td>168</td><td>32 m</td><td>11,8 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>31</td><td>12</td><td>1870</td><td>175</td><td>32 m</td><td>12 m</td><td>-</td></tr>
                        <tr><td>32</td><td>12</td><td>1908</td><td>182</td><td>32 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>33</td><td>12</td><td>1947</td><td>190</td><td>32 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>34</td><td>12</td><td>1986</td><td>198</td><td>32 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>35</td><td>12</td><td>2026</td><td>206</td><td>34 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>36</td><td>12</td><td>2067</td><td>215</td><td>34 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>37</td><td>12</td><td>2109</td><td>224</td><td>34 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>38</td><td>12</td><td>2152</td><td>233</td><td>34 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>39</td><td>12</td><td>2196</td><td>243</td><td>34 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>40</td><td>12</td><td>2240</td><td>253</td><td>36 m</td><td>12 m</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>41</td><td>13</td><td>2260</td><td>260</td><td>36 m</td><td>12 m</td><td>7,5 d</td></tr>
                        <tr><td>42</td><td>13</td><td>2280</td><td>266</td><td>36 m</td><td>12,5 m</td><td>7,5 d</td></tr>
                        <tr><td>43</td><td>13</td><td>2300</td><td>275</td><td>36 m</td><td>13 m</td><td>7,5 d</td></tr>
                        <tr><td>44</td><td>13</td><td>2320</td><td>275</td><td>36 m</td><td>13,5 m</td><td>7,5 d</td></tr>
                        <tr><td>45</td><td>13</td><td>2340</td><td>279</td><td>38 m</td><td>14 m</td><td>7,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>46</td><td>13</td><td>2360</td><td>283</td><td>38 m</td><td>14,5 m</td><td>8 d</td></tr>
                        <tr><td>47</td><td>13</td><td>2380</td><td>287</td><td>38 m</td><td>15 m</td><td>8 d</td></tr>
                        <tr><td>48</td><td>13</td><td>2400</td><td>291</td><td>38 m</td><td>15,5 m</td><td>8 d</td></tr>
                        <tr><td>49</td><td>13</td><td>2420</td><td>295</td><td>38 m</td><td>16 m</td><td>8 d</td></tr>
                        <tr><td>50</td><td>13</td><td>2440</td><td>299</td><td>40 m</td><td>16,5 m</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>51</td><td>14</td><td>2460</td><td>303</td><td>40 m</td><td>17 m</td><td>8 d</td></tr>
                        <tr><td>52</td><td>14</td><td>2480</td><td>307</td><td>40 m</td><td>17,5 m</td><td>8 d</td></tr>
                        <tr><td>53</td><td>14</td><td>2500</td><td>311</td><td>40 m</td><td>18 m</td><td>8 d</td></tr>
                        <tr><td>54</td><td>14</td><td>2520</td><td>315</td><td>40 m</td><td>18,5 m</td><td>8 d</td></tr>
                        <tr><td>55</td><td>14</td><td>2540</td><td>319</td><td>42 m</td><td>19 m</td><td>8 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th>Lv</th><th colspan="3">Life Aura</th><th colspan="2">Eternal Tome</th></tr>
                        <tr><td>-</td><td>(ability lv)</td><td>(HP +)</td><td>(max HP +)</td><td>(ability lv)</td><td>(duration)</td></tr>
                        <tr><td>1</td><td>1</td><td>20 %</td><td>70</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>21,1 %</td><td>76</td><td>-</td><td>-</td></tr>
                        <tr><td>3</td><td>3</td><td>22,2 %</td><td>82</td><td>-</td><td>-</td></tr>
                        <tr><td>4</td><td>4</td><td>23,3 %</td><td>88</td><td>-</td><td>-</td></tr>
                        <tr><td>5</td><td>5</td><td>24,4 %</td><td>94</td><td>1</td><td>3,5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>6</td><td>25,6 %</td><td>101</td><td>1</td><td>3,5 s</td></tr>
                        <tr><td>7</td><td>7</td><td>26,8 %</td><td>108</td><td>1</td><td>3,5 s</td></tr>
                        <tr><td>8</td><td>8</td><td>28,1 %</td><td>116</td><td>1</td><td>3,5 s</td></tr>
                        <tr><td>9</td><td>9</td><td>29,5 %</td><td>125</td><td>1</td><td>3,5 s</td></tr>
                        <tr><td>10</td><td>10</td><td>31 %</td><td>135</td><td>2</td><td>4 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>11</td><td>32,6 %</td><td>146</td><td>2</td><td>4 s</td></tr>
                        <tr><td>12</td><td>12</td><td>34,3 %</td><td>158</td><td>2</td><td>4 s</td></tr>
                        <tr><td>13</td><td>13</td><td>36,1 %</td><td>171</td><td>2</td><td>4 s</td></tr>
                        <tr><td>14</td><td>14</td><td>38 %</td><td>185</td><td>2</td><td>4 s</td></tr>
                        <tr><td>15</td><td>15</td><td>40 %</td><td>200</td><td>3</td><td>4,5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>16</td><td>42 %</td><td>215</td><td>3</td><td>4,5 s</td></tr>
                        <tr><td>17</td><td>17</td><td>44 %</td><td>230</td><td>3</td><td>4,5 s</td></tr>
                        <tr><td>18</td><td>18</td><td>46 %</td><td>245</td><td>3</td><td>4,5 s</td></tr>
                        <tr><td>19</td><td>19</td><td>48 %</td><td>260</td><td>3</td><td>4,5 s</td></tr>
                        <tr><td>20</td><td>20</td><td>50 %</td><td>275</td><td>4</td><td>5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>21</td><td>52 %</td><td>290</td><td>4</td><td>5 s</td></tr>
                        <tr><td>22</td><td>22</td><td>54 %</td><td>305</td><td>4</td><td>5 s</td></tr>
                        <tr><td>23</td><td>23</td><td>56 %</td><td>320</td><td>4</td><td>5 s</td></tr>
                        <tr><td>24</td><td>24</td><td>58 %</td><td>335</td><td>4</td><td>5 s</td></tr>
                        <tr><td>25</td><td>25</td><td>60 %</td><td>350</td><td>5</td><td>5,5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>26</td><td>62 %</td><td>365</td><td></td><td>5,5 s</td></tr>
                        <tr><td>27</td><td>27</td><td>64 %</td><td>380</td><td>5</td><td>5,5 s</td></tr>
                        <tr><td>28</td><td>28</td><td>66 %</td><td>395</td><td>5</td><td>5,5 s</td></tr>
                        <tr><td>29</td><td>29</td><td>68 %</td><td>410</td><td>5</td><td>5,5 s</td></tr>
                        <tr><td>30</td><td>30</td><td>70 %</td><td>425</td><td>6</td><td>6 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>31</td><td>31</td><td>72 %</td><td>440</td><td>6</td><td>6 s</td></tr>
                        <tr><td>32</td><td>32</td><td>74 %</td><td>455</td><td>6</td><td>6 s</td></tr>
                        <tr><td>33</td><td>33</td><td>76 %</td><td>470</td><td>6</td><td>6 s</td></tr>
                        <tr><td>34</td><td>34</td><td>78 %</td><td>485</td><td>6</td><td>6 s</td></tr>
                        <tr><td>35</td><td>35</td><td>80 %</td><td>500</td><td>7</td><td>6,5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>36</td><td>36</td><td>82 %</td><td>515</td><td>7</td><td>6,5 s</td></tr>
                        <tr><td>37</td><td>37</td><td>84 %</td><td>530</td><td>7</td><td>6,5 s</td></tr>
                        <tr><td>38</td><td>38</td><td>86 %</td><td>545</td><td>7</td><td>6,5 s</td></tr>
                        <tr><td>39</td><td>39</td><td>88 %</td><td>560</td><td>7</td><td>6,5 s</td></tr>
                        <tr><td>40</td><td>40</td><td>90 %</td><td>575</td><td>8</td><td>7 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>41</td><td>40</td><td>90 %</td><td>575</td><td>8</td><td>7 s</td></tr>
                        <tr><td>42</td><td>40</td><td>90 %</td><td>575</td><td>8</td><td>7 s</td></tr>
                        <tr><td>43</td><td>40</td><td>90 %</td><td>575</td><td>8</td><td>7 s</td></tr>
                        <tr><td>44</td><td>40</td><td>90 %</td><td>575</td><td>8</td><td>7 s</td></tr>
                        <tr><td>45</td><td>40</td><td>90 %</td><td>575</td><td>9</td><td>7,5 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>46</td><td>40</td><td>90 %</td><td>575</td><td>9</td><td>7,5 s</td></tr>
                        <tr><td>47</td><td>40</td><td>90 %</td><td>575</td><td>9</td><td>7,5 s</td></tr>
                        <tr><td>48</td><td>40</td><td>90 %</td><td>575</td><td>9</td><td>7,5 s</td></tr>
                        <tr><td>49</td><td>40</td><td>90 %</td><td>575</td><td>9</td><td>7,5 s</td></tr>
                        <tr><td>50</td><td>40</td><td>90 %</td><td>575</td><td>10</td><td>8 s</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>51</td><td>40</td><td>90 %</td><td>575</td><td>10</td><td>8 s</td></tr>
                        <tr><td>52</td><td>40</td><td>90 %</td><td>575</td><td>10</td><td>8 s</td></tr>
                        <tr><td>53</td><td>40</td><td>90 %</td><td>575</td><td>10</td><td>8 s</td></tr>
                        <tr><td>54</td><td>40</td><td>90 %</td><td>575</td><td>10</td><td>8 s</td></tr>
                        <tr><td>55</td><td>40</td><td>90 %</td><td>575</td><td>11</td><td>8,5 s</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ROYAL CHAMPION */}
                <Collapsible trigger={<TriggerRoyalChampion />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Royal Champion' src='https://assets.clashbases.de/Hero-Icons/Royal-Champion/Royal_Champion_3D.png'/><p className="legend">Preview</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Skill</td><td className="orange-font">Regen</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>13</td><td>2950</td><td>374</td><td>-</td><td>30 m</td><td>120 k</td><td>-</td></tr>
                        <tr><td>2</td><td>13</td><td>3000</td><td>383</td><td>-</td><td>30 m</td><td>130 k</td><td>1 d</td></tr>
                        <tr><td>3</td><td>13</td><td>3050</td><td>392</td><td>-</td><td>30 m</td><td>140 k</td><td>1,5 d</td></tr>
                        <tr><td>4</td><td>13</td><td>3100</td><td>401</td><td>-</td><td>30 m</td><td>150 k</td><td>2 d</td></tr>
                        <tr><td>5</td><td>13</td><td>3150</td><td>410</td><td>1</td><td>32 m</td><td>160 k</td><td>2,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>13</td><td>3200</td><td>418</td><td>1</td><td>32 m</td><td>170 k</td><td>3 d</td></tr>
                        <tr><td>7</td><td>13</td><td>3250</td><td>426</td><td>1</td><td>32 m</td><td>180 k</td><td>3,5 d</td></tr>
                        <tr><td>8</td><td>13</td><td>3300</td><td>434</td><td>1</td><td>32 m</td><td>190 k</td><td>4 d</td></tr>
                        <tr><td>9</td><td>13</td><td>3350</td><td>442</td><td>1</td><td>32 m</td><td>200 k</td><td>4,5 d</td></tr>
                        <tr><td>10</td><td>13</td><td>3400</td><td>450</td><td>2</td><td>34 m</td><td>210 k</td><td>5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>13</td><td>3450</td><td>458</td><td>2</td><td>34 m</td><td>220 k</td><td>5,5 d</td></tr>
                        <tr><td>12</td><td>13</td><td>3500</td><td>466</td><td>2</td><td>34 m</td><td>230 k</td><td>6 d</td></tr>
                        <tr><td>13</td><td>13</td><td>3550</td><td>474</td><td>2</td><td>34 m</td><td>235 k</td><td>6,5 d</td></tr>
                        <tr><td>14</td><td>13</td><td>3600</td><td>482</td><td>2</td><td>34 m</td><td>240 k</td><td>7 d</td></tr>
                        <tr><td>15</td><td>13</td><td>3650</td><td>490</td><td>3</td><td>36 m</td><td>245 k</td><td>7 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>11</td><td>3700</td><td>498</td><td>3</td><td>36 m</td><td>250 k</td><td>7,5 d</td></tr>
                        <tr><td>17</td><td>11</td><td>3750</td><td>506</td><td>3</td><td>36 m</td><td>255 k</td><td>7,5 d</td></tr>
                        <tr><td>18</td><td>11</td><td>3800</td><td>514</td><td>3</td><td>36 m</td><td>260 k</td><td>7,5 d</td></tr>
                        <tr><td>19</td><td>11</td><td>3850</td><td>522</td><td>3</td><td>36 m</td><td>265 k</td><td>7,5 d</td></tr>
                        <tr><td>20</td><td>11</td><td>3900</td><td>530</td><td>4</td><td>38 m</td><td>270 k</td><td>7,5 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>13</td><td>3940</td><td>535</td><td>4</td><td>38 m</td><td>275 k</td><td>8 d</td></tr>
                        <tr><td>22</td><td>13</td><td>3980</td><td>540</td><td>4</td><td>38 m</td><td>280 k</td><td>8 d</td></tr>
                        <tr><td>23</td><td>13</td><td>4020</td><td>545</td><td>4</td><td>38 m</td><td>285 k</td><td>8 d</td></tr>
                        <tr><td>24</td><td>13</td><td>4060</td><td>550</td><td>4</td><td>38 m</td><td>290 k</td><td>8 d</td></tr>
                        <tr><td>25</td><td>13</td><td>4100</td><td>555</td><td>5</td><td>40 m</td><td>295 k</td><td>8 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>14</td><td>4140</td><td>560</td><td>5</td><td>40 m</td><td>300 k</td><td>8 d</td></tr>
                        <tr><td>27</td><td>14</td><td>4180</td><td>565</td><td>5</td><td>40 m</td><td>305 k</td><td>8 d</td></tr>
                        <tr><td>28</td><td>14</td><td>4220</td><td>570</td><td>5</td><td>40 m</td><td>310 k</td><td>8 d</td></tr>
                        <tr><td>29</td><td>14</td><td>4260</td><td>575</td><td>5</td><td>40 m</td><td>315 k</td><td>8 d</td></tr>
                        <tr><td>30</td><td>14</td><td>4300</td><td>580</td><td>6</td><td>42 m</td><td>320 k</td><td>8 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="4">Seeking Shield (ability)</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg</td><td className="orange-font">Health Recovery</td><td className="orange-font">Targets</td></tr>
                        <tr><td>1</td><td>1260</td><td>1700</td><td>4</td></tr>
                        <tr><td>2</td><td>1460</td><td>2000</td><td>4</td></tr>
                        <tr><td>3</td><td>1660</td><td>2300</td><td>4</td></tr>
                        <tr><td>4</td><td>1860</td><td>2600</td><td>4</td></tr>
                        <tr><td>5</td><td>1960</td><td>2900</td><td>4</td></tr>
                        <tr><td>6</td><td>2060</td><td>3200</td><td>4</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="Pets">Pets</h2>
                {/* NAVBAR - TROOPS - PETS */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirTroops" smooth={true} offset={-70}>Elixir</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkTroops" smooth={true} offset={-70}>Dark</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SuperTroops" smooth={true} offset={-70}>Super</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="SiegeMachines" smooth={true} offset={-70}>Siege</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Heroes" smooth={true} offset={-70}>Heroes</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Pets" smooth={true} offset={-70}>Pets</LinkToAnchor></li>
                    </div>
                </nav>

                {/* LASSI */}
                <Collapsible trigger={<TriggerLassi />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='L.A.S.S.I.' src='https://assets.clashbases.de/Troop-Icons/Pet-Icons/LASSI_field.png'/><p className="legend">Levels 1-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>14</td><td>2700</td><td>150</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>14</td><td>2800</td><td>160</td><td>115 k</td><td>3 d</td></tr>
                        <tr><td>3</td><td>14</td><td>2900</td><td>170</td><td>130 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>14</td><td>3000</td><td>180</td><td>145 k</td><td>5 d</td></tr>
                        <tr><td>5</td><td>14</td><td>3100</td><td>190</td><td>160 k</td><td>5,5 d</td></tr>
                        <tr><td>6</td><td>14</td><td>3200</td><td>200</td><td>175 k</td><td>6 d</td></tr>
                        <tr><td>7</td><td>14</td><td>3300</td><td>210</td><td>190 k</td><td>6,5 d</td></tr>
                        <tr><td>8</td><td>14</td><td>3400</td><td>220</td><td>205 k</td><td>7 d</td></tr>
                        <tr><td>9</td><td>14</td><td>3500</td><td>230</td><td>220 k</td><td>7,5 d</td></tr>
                        <tr><td>10</td><td>14</td><td>3600</td><td>240</td><td>235 k</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ELECTRO OWL */}
                <Collapsible trigger={<TriggerElectroOwl />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Electro Owl' src='https://assets.clashbases.de/Troop-Icons/Pet-Icons/Electro_Owl_field.png'/><p className="legend">Levels 1-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>14</td><td>1600</td><td>100</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>14</td><td>1700</td><td>105</td><td>135 k</td><td>3 d</td></tr>
                        <tr><td>3</td><td>14</td><td>1800</td><td>110</td><td>150 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>14</td><td>1900</td><td>115</td><td>165 k</td><td>5 d</td></tr>
                        <tr><td>5</td><td>14</td><td>2000</td><td>120</td><td>180 k</td><td>5,5 d</td></tr>
                        <tr><td>6</td><td>14</td><td>2100</td><td>125</td><td>195 k</td><td>6 d</td></tr>
                        <tr><td>7</td><td>14</td><td>2200</td><td>130</td><td>210 k</td><td>6,5 d</td></tr>
                        <tr><td>8</td><td>14</td><td>2300</td><td>135</td><td>225 k</td><td>7 d</td></tr>
                        <tr><td>9</td><td>14</td><td>2400</td><td>140</td><td>240 k</td><td>7,5 d</td></tr>
                        <tr><td>10</td><td>14</td><td>2500</td><td>145</td><td>255 k</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* MIGHTY YAK */}
                <Collapsible trigger={<TriggerMightyYak />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Mighty Yak' src='https://assets.clashbases.de/Troop-Icons/Pet-Icons/Mighty_Yak_field.png'/><p className="legend">Levels 1-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>14</td><td>3750</td><td>60</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>14</td><td>4000</td><td>64</td><td>165 k</td><td>3 d</td></tr>
                        <tr><td>3</td><td>14</td><td>4250</td><td>68</td><td>185 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>14</td><td>4500</td><td>72</td><td>205 k</td><td>5 d</td></tr>
                        <tr><td>5</td><td>14</td><td>4750</td><td>76</td><td>225 k</td><td>5,5 d</td></tr>
                        <tr><td>6</td><td>14</td><td>4950</td><td>80</td><td>245 k</td><td>6 d</td></tr>
                        <tr><td>7</td><td>14</td><td>5100</td><td>84</td><td>255 k</td><td>6,5 d</td></tr>
                        <tr><td>8</td><td>14</td><td>5250</td><td>88</td><td>265 k</td><td>7 d</td></tr>
                        <tr><td>9</td><td>14</td><td>5400</td><td>92</td><td>275 k</td><td>7,5 d</td></tr>
                        <tr><td>10</td><td>14</td><td>5550</td><td>96</td><td>285 k</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* UNICORN */}
                <Collapsible trigger={<TriggerUnicorn />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Unicorn' src='https://assets.clashbases.de/Troop-Icons/Pet-Icons/Unicorn_field.png'/><p className="legend">Levels 1-10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Heal/s</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>14</td><td>1400</td><td>50</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>14</td><td>1450</td><td>53</td><td>210 k</td><td>3 d</td></tr>
                        <tr><td>3</td><td>14</td><td>1500</td><td>56</td><td>220 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>14</td><td>1550</td><td>59</td><td>230 k</td><td>5 d</td></tr>
                        <tr><td>5</td><td>14</td><td>1600</td><td>62</td><td>240 k</td><td>5,5 d</td></tr>
                        <tr><td>6</td><td>14</td><td>1675</td><td>65</td><td>250 k</td><td>6 d</td></tr>
                        <tr><td>7</td><td>14</td><td>1725</td><td>68</td><td>260 k</td><td>6,5 d</td></tr>
                        <tr><td>8</td><td>14</td><td>1800</td><td>71</td><td>270 k</td><td>7 d</td></tr>
                        <tr><td>9</td><td>14</td><td>1875</td><td>74</td><td>280 k</td><td>7,5 d</td></tr>
                        <tr><td>10</td><td>14</td><td>1950</td><td>77</td><td>290 k</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

            </div>

        </div>

        <div className="hometext-container">
          <div className="hometext-info-container">
            <div className="hometext-about">

            </div>
          </div>
        </div>

      </section>

);

export default hometroops;
