import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class ArchiveTownhallten extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 10</title>
            <meta name="description" content="Welcome to the ClashBases TH 10 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(215, 4, 33)'}}>10</span></h1>

                <div className="switch-container-archive">
                  <LinkButton to="/Townhall-10" className="switch-buttons-archive switch-to-archive">
                    <div>Switch back to Current TH 10 Base Layouts</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">

            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2020-05-14" data-title="Townhall 10 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-10">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext.jpg"
                        srcSet="https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1600w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-10-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 10 | Base Layouts and Links" alt="Townhall 10 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 10</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-025324_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-025324_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-025324_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-025324_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-025324.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEwF500A1-nKtCFXjymcc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R162</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-025031_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-025031_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-025031_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-025031_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-025031.jpg 2430w"
                        title="Townhall 10 Farm Base" alt="Townhall 10 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAHlEvF6nltAwTHUI7CEgtDw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R161</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024516_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024516_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024516_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024516_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024516.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEtW0Z9-ViAL0V--6vr0z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R160</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024214_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024214_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024214_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024214_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024214.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEsZTH-DQYqYsCsk-_dL-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R159</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024049_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024049_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024049_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024049_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-024049.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEr85MUymnqRRY92IQnqj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R158</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-023443_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-023443_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-023443_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-023443_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-023443.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEqC5C69ZrDVwXKNyZBdh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R157</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-023021_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-023021_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-023021_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-023021_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-023021.jpg 2430w"
                        title="Townhall 10 Farm Base" alt="Townhall 10 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAHlEol0RBwrnJ-4UzjEGdPa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R156</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021923_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021923_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021923_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021923_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021923.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlElEsynnT-XDzsIwABOhx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R155</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-021609_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-021609_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-021609_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-021609_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-021609.jpg 2430w"
                        title="Townhall 10 Trophy Base" alt="Townhall 10 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAHlEkHzfd4KV_D-r6UyKA0W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R154</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021149_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021149_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021149_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021149_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021149.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEina9C-lRv8SWOE0pyzY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R153</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021023_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021023_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021023_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021023_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-021023.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEiLtdKO61rvKfbss5GLd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R152</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-020401_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-020401_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-020401_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-020401_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-020401.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEgMEo5P9yN_M-ZCb9mKm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R151</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-020225_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-020225_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-020225_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-020225_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-BaseLayout-20210420-020225.jpg 2430w"
                        title="Townhall 10 Farm Base" alt="Townhall 10 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAHlEfplq3zp86hh0J2jb55V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R150</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-015645_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-015645_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-015645_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-015645_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-BaseLayout-20210420-015645.jpg 2430w"
                        title="Townhall 10 Trophy Base" alt="Townhall 10 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAHlEd722E8CKF0fabCdyTK3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R149</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015224_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015224_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015224_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015224_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015224.jpg 2430w"
                        title="Townhall 10 War Base" alt="Townhall 10 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAHlEcon_sRRezAXJercUwZM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R148</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163744_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163744_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163744_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163744_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163744_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAG3PGAWti9MGRNLMlLscU4k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R147</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163322_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163322_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163322_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163322_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-163322_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAG3PDwrVl-jvjcvlYNdCHj0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R146</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-06-10" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200530-163228_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200530-163228_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200530-163228_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200530-163228_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200530-163228_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAG3PDS4p44FJlgeJq4EPL6h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R145</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">Th 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015012_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015012_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015012_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015012_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-BaseLayout-20210420-015012_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAG3PCU__NIqS0cJOn_ZRIoB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R144</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-162953_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-162953_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-162953_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-162953_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200530-162953_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAG3PB7yXsFkv_EKX0E0GnZw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R143</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-03" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-002924_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-002924_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-002924_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-002924_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-002924_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGy46d7rnzsR-R3Xgq5FbiP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R142</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-03" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-000507_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-000507_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-000507_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-000507_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200503-000507_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGy4yQxEEsIxuC1g7-pqCgF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R141</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-03" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-233701_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-233701_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-233701_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-233701_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-233701_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGy4o58RP18B3Yg8iJwpJEo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R140</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-03" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-225210_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-225210_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-225210_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-225210_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-225210_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGy4ZmTdLoMmNd5P21IBwM8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R139</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-03" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-214041_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-214041_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-214041_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-214041_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200502-214041_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGy4AZaUp5YqyfzmERa9jPI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R138</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-175713_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-175713_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-175713_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-175713_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-175713_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwy6dvLTQwzZXb0f7cOYaf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R137</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-01" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200421-180354_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200421-180354_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200421-180354_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200421-180354_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-20200421-180354_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGwy9tcla-llQgz4vJioEgk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R136</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173953_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173953_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173953_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173953_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173953_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwyxn16Rb9urRi_ZobgNhf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R135</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173733_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173733_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173733_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173733_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173733_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwywbVPlV-Nup7MgOZoIkp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R134</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173627_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173627_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173627_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173627_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173627_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwyv3wqSJh3K9s96OBw8yH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R133</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173519_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173519_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173519_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173519_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173519_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwyvR89LjZt4hr00zlV_d8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R132</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173223_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173223_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173223_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173223_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-173223_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwytwdjoe5pc_fJsXCvf_-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R131</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172607_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172607_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172607_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172607_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172607_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwyqeWbfAAWYUsj5m8gCrz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R130</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-21" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172203_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172203_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172203_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172203_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-20200421-172203_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGwyoZFf9Wm3vieQ8Ojt9Dp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R129</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-21" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-20200421-171504_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-20200421-171504_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-20200421-171504_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-20200421-171504_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-20200421-171504_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGwyktE2l7ERdL6rmRXRUAF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R128</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy", "contributor"]' data-date-created="2020-01-19" data-title="Townhall 10 Farm Base by Phoenician">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base by Phoenician" alt="Townhall 10 Farm Base by Phoenician" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAFAAAAAGVxOXtGjAz4d_b6koZmc1o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions font-size-picture-descriptions">#R127</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=YJCGVVU0" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Phoenician</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWqmKxdFnKUlkN_4Cibq3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R126</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-234030_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-234030_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-234030_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-234030_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-234030_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb96Z3DJ8zDy9h0U35KrzX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R125</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-01" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-233849_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-233849_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-233849_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-233849_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-233849_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGb958CyDvMD_IhkbJm7uLs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R124</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233421_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233421_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233421_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233421_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233421_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb94rL8vLZEgTdhnGtEWr1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R123</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233257_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233257_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233257_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233257_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233257_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb94ajlyD-MfloYJI2Odqc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R122</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233123_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233123_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233123_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233123_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-233123_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb933lIt5m0iswSjX1fG8P" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R121</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-01" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-232328_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-232328_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-232328_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-232328_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-232328_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGb91r1s9R5KWU1UcdRY_8H" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R120</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231929_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231929_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231929_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231929_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231929_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb90mXnQIyOq359BJGGVt_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R119</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231639_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231639_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231639_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231639_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231639_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9z7z1FeNf_RHaCncbBL7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R118</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231302_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231302_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231302_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231302_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-231302_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9y3VntJAxL5cKOHB3PLe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R117</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-01" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Dec19-20191202-230806_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Dec19-20191202-230806_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Dec19-20191202-230806_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Dec19-20191202-230806_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Dec19-20191202-230806_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGb9xjofZsU9aVcsXd8Zoan" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R116</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225922_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225922_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225922_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225922_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225922_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9vQlytoWIyBY5sDZA2Oh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R115</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225721_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225721_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225721_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225721_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225721_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9usx6sAwX8qEuMnC77M8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R114</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225450_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225450_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225450_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225450_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-225450_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9uRByaxgCBaQyQCMAciC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R113</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-01" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-223442_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-223442_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-223442_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-223442_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-223442_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGb9ojZkn8RWgpAIeL_Co5K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R112</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-223149_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-223149_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-223149_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-223149_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-223149_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9nw60Q123eFZ3j6TeEWz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R111</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-01" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222824_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222824_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222824_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222824_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222824_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9m1FSHhjyXbm01MPzgRQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R110</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-01" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222639_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222639_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222639_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222639_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Dec19-20191202-222639_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGb9mgoBYB1nPi4LD4xC75X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R109</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222541_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222541_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222541_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222541_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222541_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9mFaLcsO5TP3bWFLnG5u" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R108</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222427_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222427_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222427_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222427_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222427_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9lwEoxKpgyPcF__bZN7e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R107</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-01" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222340_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222340_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222340_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222340_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Dec19-20191202-222340_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGb9litHdCpjpMsSpF3X-nY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R106</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190633_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190633_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190633_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190633_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190633_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrao_FQT6FPKWwC51NLH7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R105</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-190511_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-190511_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-190511_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-190511_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-190511_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbraHOm7dmgqI3T6pY6-0V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R104</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190121_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190121_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190121_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190121_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190121_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrYuQ3uNgFROWbcc91Khs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R103</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190013_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190013_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190013_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190013_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-190013_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrYSvzz-NE26Kp9zRQsN4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R102</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-185528_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-185528_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-185528_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-185528_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-185528_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbrWd7InPpUd9mxdVZOv3m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R101</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184821_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184821_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184821_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184821_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184821_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrTvQRvutJhf96HfvBKVr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R100</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184718_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184718_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184718_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184718_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184718_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrTVlNYlReqqzLtp8y7WZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R099</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184615_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184615_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184615_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184615_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184615_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrS7f83evEfEMsHIaxEBc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R098</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184437_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184437_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184437_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184437_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184437_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrSSklNbLGYJAm9x1w9k2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R097</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184325_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184325_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184325_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184325_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184325_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrR0eZlLVO0Gv1rxLHYMJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R096</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184239_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184239_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184239_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184239_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184239_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrRhEFMhs7bTllJ1oaRCh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R095</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184151_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184151_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184151_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184151_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184151_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrROPA25rOZnaRIBfzVTH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R094</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184034_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184034_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184034_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184034_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-184034_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrQteBGzaKaLwLdtLe38l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R093</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183940_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183940_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183940_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183940_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183940_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrQUVux6qmenWZ2pyXNSO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R092</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183851_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183851_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183851_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183851_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183851_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrQBBEiIQ1sVwNDUKLbYM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R091</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183720_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183720_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183720_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183720_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183720_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrPYuTGv2JNuhaRjkW8-k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R090</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183558_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183558_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183558_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183558_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183558_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrO0cJddTMkcScvXdfRuK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R089</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183447_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183447_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183447_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183447_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183447_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrOXhjDr3Mng0fwHFwQQI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R088</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183344_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183344_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183344_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183344_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183344_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrN9GDKtviPdLr8sIuUK-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R087</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183252_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183252_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183252_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183252_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183252_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrNn2qwEuufz9-xH5oP02" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R086</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183137_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183137_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183137_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183137_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183137_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrNH6HHiu4TpxUVDRneWO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R085</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183001_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183001_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183001_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183001_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-183001_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrMjDRLUd3XzDBMED_8br" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R084</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182640_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182640_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182640_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182640_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182640_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrLJEnwbJBDePjupawxZx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R083</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182522_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182522_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182522_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182522_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182522_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrKomI7MeS3enNtNc48V2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R082</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182428_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182428_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182428_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182428_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-182428_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrKRaOHOQDFGNax7sI0MN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R081</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-182107_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-182107_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-182107_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-182107_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-182107_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbrI573sw70IWJ9_lNhYFX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R080</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181941_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181941_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181941_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181941_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181941_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrITRj8z-gYlVZJtRIYru" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R079</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181849_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181849_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181849_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181849_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181849_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrH95vFAbvnLv37rFbIzc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R078</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181723_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181723_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181723_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181723_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181723_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrHYU_WU6qPhrlmGr-yRV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R077</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181356_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181356_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181356_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181356_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181356_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrF7BeQ74HQ-xg31-2Rkv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R076</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181128_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181128_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181128_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181128_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-181128_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrE4RGrMUBusaLnz8EZPs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R075</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180738_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180738_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180738_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180738_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180738_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrDSEJHe4xhhMDJVHu4PH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R074</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180503_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180503_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180503_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180503_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180503_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrCKSenumjLcfLyWGL81_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R073</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-11-30" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180234_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180234_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180234_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180234_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-Base-Layout-Nov19-20191130-180234_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGbrBWy-VJWmYXqIbLidgTS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R072</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-30" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-180019_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-180019_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-180019_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-180019_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-180019_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbrAR-0Nt1vJelYm0DfrUc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R071</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175856_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175856_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175856_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175856_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175856_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbq_iSVSC6XQbBH7GOAh28" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R070</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175543_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175543_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175543_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175543_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175543_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbq-Lkmv7_hmURSssfmO5J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R069</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-11-30" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-175424_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-175424_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-175424_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-175424_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Base-Layout-Nov19-20191130-175424_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbq9n-RHQKPgXHRwu6_OsH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R068</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175142_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175142_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175142_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175142_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175142_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbq8ha4d49-Q1km5J9kHrP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R067</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-11-30" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175009_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175009_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175009_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175009_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Base-Layout-Nov19-20191130-175009_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGbq738rnGN1fHjXtTuhpO5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R066</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215352_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215352_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215352_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215352_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215352_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW47M7HJX0t_l6DEwS_9G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R065</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215155_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215155_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215155_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215155_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215155_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW4VjexcXlaoRPISQL3Us" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R064</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212030_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212030_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212030_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212030_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212030_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWuncKxD5jPJ5fH4Bol_8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R063</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215004_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215004_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215004_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215004_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-215004_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW3yQlNKPLKG98A8EjjMd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R062</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214916_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214916_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214916_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214916_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214916_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVW3gtkagUx1UeTZs-7RN6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214843_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214843_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214843_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214843_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214843_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW3Wm7ehbl1Auf3j7mCKx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-13" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-214410_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-214410_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-214410_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-214410_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-214410_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVW2AbwVCM0oduU8JdcvEf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211409_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211409_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211409_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211409_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211409_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWspX5qHEymKL0vcQFBtE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214229_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214229_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214229_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214229_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214229_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVW1gnt184N7vMBi72tJ79" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214119_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214119_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214119_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214119_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214119_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW1KuMbkvoAq8sYNvRzsP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214038_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214038_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214038_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214038_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-214038_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW08Jn4NyGK0GSJdRpO7g" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213923_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213923_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213923_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213923_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213923_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVW0iuQYNwTTRpRyj4bemT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210844_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210844_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210844_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210844_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210844_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWq8L3O3HcGDD258DKqPb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-13" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213727_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213727_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213727_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213727_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213727_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWz8wQo-XY97KRda1gVcn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213517_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213517_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213517_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213517_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-213517_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWzQHu24Jp6B5dP9s33G5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-13" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213401_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213401_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213401_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213401_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-213401_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWy3eCtak0NIMWDH3eZso" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-213110_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-213110_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-213110_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-213110_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-213110_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWx_oTDjGz02it7lOd8uS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212638_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212638_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212638_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212638_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212638_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWwjeRu4oFRzcK_q_AZBH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-13" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-212407_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-212407_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-212407_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-212407_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-212407_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWvvBLKTrwkBbOU6mfdjV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212253_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212253_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212253_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212253_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-212253_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWvXztXwL9HLxpw_r7as7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211051_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211051_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211051_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211051_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211051_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWrn3EfQfJBAzxMXVcvBG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-211936_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-211936_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-211936_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-211936_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-211936_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWuUQhya_laDeq5JDRuoD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-13" data-title="Townhall 10 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-211832_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-211832_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-211832_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-211832_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Townhall-10-Trophy-Base-ClashofClans-20191013-211832_1920w.jpg 2430w"
                        title="Townhall 10 Trophy Base." alt="Townhall 10 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVWuAYb34g2L3YPdMGN6UD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211740_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211740_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211740_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211740_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211740_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWtuoM1InObm2r3iChtqO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211647_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211647_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211647_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211647_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211647_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWtdhc2gd02oDwc3rDsK7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211604_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211604_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211604_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211604_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211604_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWtQakTArLcMbWwzOy2cx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211507_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211507_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211507_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211507_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211507_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWs8hpK3oyCBrkyA9laUX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214324_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214324_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214324_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214324_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-214324_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVW1ySG51O8mYQ75RymL_7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211322_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211322_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211322_1024w.jpg 640w,https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211322_1024w.jpg  1024w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211322_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWsaWCBy1Q1F2xBJvRtyL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211136_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211136_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211136_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211136_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211136_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWr23pSUhKUm2j6a-yKya" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-13" data-title="Townhall 10 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-215041_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-215041_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-215041_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-215041_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Townhall-10-Farm-Base-ClashofClans-20191013-215041_1920w.jpg 2430w"
                        title="Townhall 10 Farm Base." alt="Townhall 10 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGVW39LgQ0JFXAlYRxhCHyP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211013_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211013_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211013_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211013_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-211013_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWrbTGCbc0UQ9uI1xM0bQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210740_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWqmKxdFnKUlkN_4Cibq3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210539_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210539_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210539_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210539_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210539_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWp9vPrlVGiQcT1fJz95y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210237_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210237_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210237_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210237_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210237_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWo_y0H3g8D8PWkkHRvEu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-13" data-title="Townhall 10 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210042_640w.jpg"
                        srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210042_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210042_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210042_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Townhall-10-War-Base-ClashofClans-20191013-210042_1920w.jpg 2430w"
                        title="Townhall 10 War Base." alt="Townhall 10 War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGVWoY9Dadm1hAFT91fkA8h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 10</p>
                  </div>
                </div>
              </div>
            </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2019-08-01" data-title="Th 10 Farm Base by anonymus">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Th10-FarmBase-Screenshot9957362514_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Th10-FarmBase-Screenshot9957362514_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Th10-FarmBase-Screenshot9957362514_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Th10-FarmBase-Screenshot9957362514_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Th10-FarmBase-Screenshot9957362514_1920w.jpg 2430w"
                          title="Townhall 10 | Farm Base by anonymus." alt="Townhall 10 | Farm Base by anonymus." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAACgAAAAGg7lkz3mSranLJkBwEB6Ty" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R029</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2019-08-01" data-title="Th 10 Farm Base by Deere">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190719-003413_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190719-003413_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190719-003413_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190719-003413_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190719-003413_1920w.jpg 2430w"
                          title="Townhall 10 | Farm Base by Deere." alt="Townhall 10 | Farm Base by Deere." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAABAAAAAHD7g1N5JxQEeyMAXb3D8kg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R028</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2019-08-01" data-title="Th 10 Trophy Base Anti 2 Star Island Base by Deere">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Th10-TrophyBase-Screenshot_20190719-004309_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Th10-TrophyBase-Screenshot_20190719-004309_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Th10-TrophyBase-Screenshot_20190719-004309_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Th10-TrophyBase-Screenshot_20190719-004309_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Th10-TrophyBase-Screenshot_20190719-004309_1920w.jpg 2430w"
                          title="Townhall 10 | Trophy Base Anti 2 Star Island Base by Deere." alt="Townhall 10 | Trophy Base Anti 2 Star Island Base by Deere." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAABAAAAAHD7i7Q_ZQEWpQMP247tzU5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R027</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2019-08-01" data-title="Th 10 War Base Anti 2 Star Island Base by Deere">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-IslandBase-Screenshot_20190719-004145_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-IslandBase-Screenshot_20190719-004145_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-IslandBase-Screenshot_20190719-004145_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-IslandBase-Screenshot_20190719-004145_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-IslandBase-Screenshot_20190719-004145_1920w.jpg 2430w"
                          title="Townhall 10 | War Base Anti 2 Star Island Base by Deere." alt="Townhall 10 | War Base Anti 2 Star Island Base by Deere." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAABAAAAAHD7i7Q_ZQEWpQMP247tzU5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R026</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2019-08-01" data-title="Th 10 War Base Anti 3 Star by Deere">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-Screenshot_20190719-004014_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-Screenshot_20190719-004014_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-Screenshot_20190719-004014_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-Screenshot_20190719-004014_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Th10-WarBase-Screenshot_20190719-004014_1920w.jpg 2430w"
                          title="Townhall 10 | War Base Anti 3 Star by Deere." alt="Townhall 10 | War Base Anti 3 Star by Deere." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAABAAAAAHD7iXdpCWu8vaj9Uoop5We" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R025</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-09-26" data-title="Standard Trophy Base TH10">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-164130_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-164130_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-164130_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-164130_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-164130_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Trophy Base Base Standard Solid Base." alt="Townhall 10 Trophy Base Base Standard Solid Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE1AyKRW8Qy_KyhroejXNH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R024</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-26" data-title="Anti Bowler">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-164531_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-164531_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-164531_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-164531_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-164531_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti Bowler Anti 3 Star." alt="Townhall 10 War Base Anti Bowler Anti 3 Star." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE1DSAdzrQNjQJaymI38qg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R023</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Solid Warbase">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163814_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163814_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163814_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163814_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163814_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Standard Solid Warbase." alt="Townhall 10 War Base Standard Solid Warbase." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE0-x-LzYB8Kb0PjmWtfyL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R022</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Island Base Centralized Townhall 10">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163338_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163338_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163338_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163338_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163338_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Island Base Centralized Townhall." alt="Townhall 10 War Base Island Base Centralized Townhall." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE08AjU1Z0FYRxhIUjZdsC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R021</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Anti Laloon War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163122_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163122_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163122_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163122_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-163122_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti Laloon, exposed Townhall." alt="Townhall 10 War Base Anti Laloon, exposed Townhall." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE06sxu09FuuYVDBT7b8xy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R020</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-25" data-title="Corridor Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162650_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162650_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162650_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162650_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162650_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Centralized Corridor." alt="Townhall 10 War Base Centralized Corridor." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE0365vWWI7pGolnasdpba" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R019</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Standard Centralized Townhall">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-162430_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-162430_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-162430_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-162430_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-162430_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Farm Base Centralized Townhall." alt="Townhall 10 Farm Base Centralized Townhall." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE02f6PrWvBWKXtiEss4dW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R018</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-24" data-title="Anti 3 Star Island Inferno">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162231_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162231_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162231_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162231_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-162231_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti 2 Star Island Infernos." alt="Townhall 10 War Base Anti 2 Star Island Infernos." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE01T-m3BDx0FJUWxt83zu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R017</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-24" data-title="Anti 2 Star Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-161549_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-161549_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-161549_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-161549_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-161549_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Trophy Base Anti 2 Star." alt="Townhall 10 Trophy Base Anti 2 Star." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE0xFCNl8GdUzyFuCCBo0L" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R016</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-24" data-title="Farm Base Dark Elixir Protection">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-161417_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-161417_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-161417_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-161417_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190627-161417_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Farm Base Dark Elixir Protection." alt="Townhall 10 Farm Base Dark Elixir Protection." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE0wLNw0WDYCtLH0-5Omkr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R015</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-23" data-title="Anti 3 Star War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-161056_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-161056_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-161056_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-161056_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-161056_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti 3 Outside Townhall." alt="Townhall 10 War Base Anti 3 Outside Townhall." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE0uRo9iIvETjDJNNBMyOb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R014</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-23" data-title="Trophy Island Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160758_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160758_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160758_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160758_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160758_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Trophy Base Anti 2 Island Base." alt="Townhall 10 Trophy Base Anti 2 Island Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE0sWi4WPXXzHiH2ikq7k6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R013</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>


              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-23" data-title="Arrow Trophy Base, Anti 2">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160529_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160529_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160529_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160529_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190627-160529_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Trophy Base Anti 2 Arrow Base." alt="Townhall 10 Trophy Base Anti 2 Arrow Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGE0qvXzMufcGQypWHH4Pn8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R012</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-23" data-title="Anti Bat, Anti 3 Star">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-160132_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-160132_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-160132_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-160132_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190627-160132_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti 3 Anti Bats." alt="Townhall 10 War Base Anti 3 Anti Bats." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGE0okud1gEgUsXqgNJE4zH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R011</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Arrow Base TH 10">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-174448_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-174448_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-174448_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-174448_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-174448_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Arrow Layout." alt="Townhall 10 War Base Arrow Layout." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEeY5-w3ltbxo7IWJs8ojG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-06-22" data-title="Anti 2 Star Townhall 10 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190625-160353_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190625-160353_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190625-160353_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190625-160353_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Trophy/Screenshot_20190625-160353_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Anti 2 Star Trophy Base." alt="Townhall 10 Anti 2 Star Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGEdQr6ihbBdLD0YV7JkIw7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Tetra-Compartment Island Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-163555_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-163555_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-163555_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-163555_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-163555_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Tetra-Compartment Island Base." alt="Townhall 10 War Base Tetra-Compartment Island Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEdn85f2aRsyt36CQ4ppIj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-22" data-title="Centralized Townhall 10">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160611_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160611_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160611_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160611_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160611_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Farm Base Centralized Townhall." alt="Townhall 10 Farm Base Centralized Townhall." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGEdSeC9YNoy9hR6PdjaILa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Centralized Townhall 10 Warbase">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-180405_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-180405_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-180405_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-180405_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-180405_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Centralized Townhall Semi-Island Base." alt="Townhall 10 War Base Centralized Townhall Semi-Island Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEelzL8vC_OzrDFSNBZfkh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Anti Miner, Anti 2 Star">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-171103_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-171103_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-171103_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-171103_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-171103_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Anti Miner and Anti 2 Star Base." alt="Townhall 10 Anti Miner and Anti 2 Star Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEeBVaLteWH5u0oPB2t3Qv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Townhall 10 Anti Everything">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-172651_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-172651_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-172651_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-172651_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-172651_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Anti Everything." alt="Townhall 10 War Base Anti Everything." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEeMyLbkojFdMnpcJDikIG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-06-22" data-title="Empty center Surprise War Base, Anti Bowler">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-165151_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-165151_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-165151_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-165151_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-War/Screenshot_20190625-165151_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 War Base Empty Center Surprise, Anti Bowler." alt="Townhall 10 War Base Empty Center Surprise, Anti Bowler." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AWB%3AAAAAEQAAAAGEdzrYSvrIHqgErqdmHBOb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-22" data-title="Anti Pekkabobat">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160951_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160951_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160951_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160951_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160951_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Farm Base Anti Pekkabobat." alt="Townhall 10 Farm Base Anti Pekkabobat." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGEdU9JIE97SMPtJGQnCSWh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-06-22" data-title="Hybrid War and Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160759_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160759_Clash_of_Clans_640w.jpg 320w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160759_Clash_of_Clans_1024w.jpg 640w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160759_Clash_of_Clans_1920w.jpg 1920w, https://townhall-10.clashbases.de/Townhall-10-Farm/Screenshot_20190625-160759_Clash_of_Clans_1920w.jpg 2430w"
                          title="Townhall 10 Hybrid War and Farm Base Anti 3 Star." alt="Townhall 10 Hybrid War and Farm Base Anti 3 Star." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH10%3AHV%3AAAAAEQAAAAGEdTr7uq9uz2a1q3SXmU0x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#R001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 10</p>
                    </div>
                  </div>
                </div>
              </figure>

              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>



          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 10 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhallten;
