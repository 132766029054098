import React, { Component } from 'react';
import './TownhallEight.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Townhalleightdemo from './TownhallEightScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class Townhalleight extends Component {

  componentDidMount () {
    window.townhalleightdemo = new Townhalleightdemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="th8-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 8 Base Layouts and Links</title>
            <meta name="description" content="The Best Townhall 8 Layouts with Working Links! Browse through our huge gallery and find your favorite war base or farm base layout directly into the game!" />
            <meta name="keywords" content="th 8, ths 8, th ten, townhall 8, townhall 8 base, th 8 links, links, th 8 base design, th 8 bases, th 8 warbase, warbase, base for war, clan war base, clanwar base th 8, war base th 8, townhall 8 war base, townhall 8 base for war, th 8 trophybase, trophybase, base for trophys, trophy base th 8, townhall 8 trophy base, townhall 8 base for trophy, th 8 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 8, townhall 8 push base, townhall 8 base for pushing, th 8 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 8, townhall 8 farm base, townhall 8 base for farming, troll bases, th 8 troll base, th 8 fun base, th 8 funny base, th 8 trolling, funny th 8 base, best th 8 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 8 island base, ring base, th 8 ring base, teaser, th 8 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 11 links, th 12 base link, th 11 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="th8-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th8-container-about">
            <div className="th8-row-about">

              <div className="th8-title-container">
                <h1 className="th8-about-title">Townhall <span style={{color: 'rgb(142, 84, 84)'}}>8</span> Base Layouts</h1>

                <div className="switch-container-th8">
                  <LinkButton to="/Townhall-8" className="switch-buttons-th8 ">
                    <div className="switch-buttons-text-8">-</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-6" className="switch-buttons-th8 switch-to-th6-8">
                    <div className="switch-buttons-text-8">TH 6</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-7" className="switch-buttons-th8 switch-to-th7-8">
                    <div className="switch-buttons-text-8">TH 7</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-8" className="switch-buttons-th8 switch-to-th8-8">
                    <div className="switch-buttons-text-8">TH 8</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-9" className="switch-buttons-th8 switch-to-th9-8">
                    <div className="switch-buttons-text-8">Higher</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th8-container-about">
            <div className="th8-row-about">
              <div className="col-6@sm th8-filters-group-wrap">
                <div className="th8-filters-group filters-group-right">
                  <p className="th8-filter-label th8-filter-color">Filter</p>
                  <div className="btn-group th8-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th8-container-about">
            <div id="grid" className="th8-row-about my-shuffle-th8-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025313.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025313.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025313.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025313.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025313.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_2RnwjtFEi24YJwCMsuJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-052</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025200.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025200.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025200.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025200.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025200.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_1hee3qc7ITun9p0FUMq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-051</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025052.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025052.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025052.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025052.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-025052.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_1K2qjx_pjKZmxxsVwK3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-050</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024941.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024941.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024941.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024941.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024941.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_0zqEAo2td0RCbDV_TTb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-049</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024201.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024201.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024201.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024201.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024201.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_ymoCNumhk2vWjFBdaVH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-048</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024112.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024112.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024112.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024112.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-024112.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_yMwHfSfF0YSKJcHIm4o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-047</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-024010.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-024010.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-024010.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-024010.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-024010.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_x5YceFcdcYO-jrOVS-f" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-046</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023904.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023904.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023904.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023904.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023904.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_xjcIlL7wH8Ari2hkQAb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-045</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023730.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023730.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023730.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023730.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023730.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_xFfvk20FttN4BGtENhV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-044</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-023604.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-023604.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-023604.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-023604.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-023604.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_wn8y6kCYAzb4ZxRgFRI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-043</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023500.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023500.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023500.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023500.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-023500.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_wSfW5qT3-_eZgOJ-vTJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-042</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021910.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021910.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021910.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021910.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021910.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_sGdyV6CGwBcqUiLI5PZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-041</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021759.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021759.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021759.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021759.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021759.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_rJoKRvplveQfvnW8h3Q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-040</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021708.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021708.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021708.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021708.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021708.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_q4qHC9abVtf8hY8dH-T" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-039</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021451.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021451.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021451.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021451.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021451.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_qLsp2n8i-TWPgPttz69" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-038</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021245.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021245.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021245.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021245.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021245.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_pkXRCWf_Wv76bdc6w18" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-037</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021032.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021032.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021032.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021032.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-021032.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_o8S2rwjGaFz3pw8C4SA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-036</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020856.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020856.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020856.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020856.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020856.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_ocPTLtitMz4oWTO5Xrh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-035</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020749.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020749.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020749.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020749.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020749.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_oHOArD-JMk5da13Jop1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-034</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020659.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020659.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020659.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020659.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-020659.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_n4UHkD7Vbt-BnJSp0ET" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-033</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020554.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020554.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020554.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020554.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020554.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_niyASnBNg3i8BYlgXSs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-032</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020437.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020437.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020437.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020437.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-020437.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_nLoH-7SKoRN8ZWOvjjS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-031</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015759.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015759.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015759.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015759.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015759.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_lVlRRnMHEy6gi9wW1UU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-030</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015652.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015652.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015652.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015652.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015652.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_k9i48a7Z1R-nmoDak9l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-029</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015528.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015528.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015528.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015528.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015528.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_kjcOg3s5mJXNIPdvt-e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-028</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015425.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015425.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015425.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015425.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015425.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_kQQ6mFTQgkxLPLR6XVw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-027</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015303.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015303.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015303.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015303.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-015303.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_j21fqvrokhUNg2cuUuQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-026</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015122.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015122.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015122.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015122.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-015122.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_jWVbx5_2VrAJC83cr3k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-025</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014935.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014935.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014935.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014935.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014935.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_i1YR4bAZ3fQWgjdqTgR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-024</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014847.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014847.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014847.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014847.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014847.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_in1IWPVb9lCmr5vBogC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-023</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014755.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014755.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014755.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014755.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014755.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_iXGnxvL2yULEW3hmp28" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-022</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014706.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014706.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014706.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014706.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014706.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_iI3-bwnQ-oYCNI4uegm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-021</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014510.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014510.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014510.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014510.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014510.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_hi777y4wwqyBzgqRV_q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-020</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014413.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014413.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014413.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014413.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014413.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_hQ-BS0rOsz-UFfAD00h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-019</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014300.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014300.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014300.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014300.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014300.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_g7lR_TpuPcGZV2h4fmT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-018</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014139.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014139.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014139.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014139.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-014139.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_gjfdgV4sfyaIU4iz-7b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-017</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014047.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014047.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014047.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014047.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-014047.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_gUCECtzcBd-WM8IvXwG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-016</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013922.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013922.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013922.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013922.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013922.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_f7QbFR8q_MIbTnKA9_7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-015</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013804.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013804.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013804.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013804.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013804.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_fh40qL2GKKwwrz1HyoK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-014</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013642.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013642.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013642.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013642.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013642.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_fIpOfSOJ625N9isoawM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-013</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013454.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013454.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013454.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013454.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013454.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_eop228GGnqOTpGGduN1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-012</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013407.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013407.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013407.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013407.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-013407.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_ebT_fIZQfjcNNX6SByk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-011</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013155.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013155.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013155.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013155.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013155.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_dyBdmdOsoOXNLKT4c4l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013112.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013112.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013112.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013112.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013112.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_dk9l3th509ZaSNlGgfz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013005.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013005.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013005.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013005.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-013005.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_dQosB-Hu2O-M_4lgeQk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012815.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012815.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012815.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012815.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012815.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_cvR3sW1fYPJDCPN9WiW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012651.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012651.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012651.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012651.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012651.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_cXFK1B7pxf0_4pXe3fN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012539.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012539.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012539.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012539.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012539.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_cCd5rrXuhbxG_DbHrOw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012403.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012403.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012403.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012403.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012403.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_bmd7eFT_DowLVJKZqYL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012242.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012242.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012242.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012242.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012242.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_bNopGYxoTkFGulunrSC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 8 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012142.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012142.jpg 320w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012142.jpg 640w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012142.jpg 1920w, https://townhall-8.clashbases.de/08_Farm/Townhall-08-Base-Layouts-20221017-012142.jpg 2430w"
                          title="Townhall 8 Farm Base" alt="Townhall 8 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AHV%3AAAAABwAAAAJY_a7nhHq_B--XZm_OSpIf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 8 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012010.jpg"
                          srcSet="https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012010.jpg 320w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012010.jpg 640w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012010.jpg 1920w, https://townhall-8.clashbases.de/08_War/Townhall-08-Base-Layouts-20221017-012010.jpg 2430w"
                          title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAABwAAAAJY_afjpjiKkdIrwT717UGD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">08-001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2022-10-10" data-title="Townhall 8 | The Archive">
                <a href="https://ClashBases.de/Archive-Townhall-8" target="_blank" rel="noopener noreferrer">
                  <div className="picture-item__inner townhall-showcase-13">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <img
                            src="https://assets.clashbases.de/Backgrounds/02Archive_1024w.png"
                            srcSet="https://assets.clashbases.de/Backgrounds/02Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/02Archive_1024w.png 1024w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 1920w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 2430w"
                            title="Townhall 8 | The Archive" alt="Townhall 8 | The Archive" />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><div className="link-color font-size-picture-descriptions">Visit the ClashBases archive</div><p className="index-number font-size-picture-descriptions">for all old TH 8 Base Layouts</p></figcaption>
                    </div>
                  </div>
                </a>
              </figure>

            </div>
          </div>

          <div className="homebase-paragraph-container">

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="video-ad-container" adID="cb5c0bc7-f4be-4ce9-90e4-f22d34d43e50" />
            </figure>

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">The Best Townhall 8 Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              A huge thank you to my dear friend Naxos for lending me his townhall 8 account in order to build allof the base layouts! Make sure to join the discord and ive him a shoutout! <br /><br /><br />
              Browse through our huge collection of clash of clans townhall 8 base layouts with links!<br />
              War base, farm base, pushing base or just a casual base for aesthetics, we got them all. Find your favorite th 8 base build and import it directly into your game. <br /><br /><br />
              Townhall 8 is a steppingstone to unlocking your true potential as a clasher. And as such it deserves a proper base layout! It's been many years since it came out, and because of that, most base layouts online don't offer links. We fixed that!<br /><br />

              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default Townhalleight;
