import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import ArchiveTownhallfourteen from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class ArchiveTownhalleight extends Component {

  componentDidMount () {
    window.ArchiveTownhallfourteen = new ArchiveTownhallfourteen(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 8</title>
            <meta name="description" content="Welcome to the ClashBases TH 8 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">

              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(142, 84, 84)'}}>8</span></h1>

                  <div className="switch-container-archive">
                    <LinkButton to="/Townhall-8" className="switch-buttons-archive switch-to-archive">
                      <div>Switch back to Current TH 8 Base Layouts</div>
                    </LinkButton>
                  </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">
            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2021-04-19" data-title="Townhall 8 War Base by Noobmaster69">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-BaseLayout-20210420-025324_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-BaseLayout-20210420-025324_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-BaseLayout-20210420-025324_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-BaseLayout-20210420-025324_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-BaseLayout-20210420-025324.jpg 2430w"
                        title="Townhall 8 War Base by Noobmaster69" alt="Townhall 8 War Base by Noobmaster69" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH8%3AWB%3AAAAAHQAAAAHJr6cBhkrcZRRLLj3moSpt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G052</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=PLJUQ9YCY" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Noobmaster69</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230915_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230915_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230915_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230915_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230915.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2M2l2JPHUt-NamsOA6f6z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230704_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230704_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230704_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230704_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-230704.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2M2EmSdv4wdQqzbPy9SMA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230523_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230523_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230523_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230523_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230523.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2M1pG-vhmoAKtpSVwL32m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230248_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230248_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230248_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230248_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-230248.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2M0sFm8YjdgB8PWTk7IaM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225826_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225826_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225826_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225826_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225826.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2Mz7DqesCGKO4L14yydjW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225618_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225618_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225618_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225618_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225618.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MzYzrh1zGAYGiXDNwx8k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225442_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225442_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225442_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225442_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225442.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2My_w8Y69AtFH3DGfr7bU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225244_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225244_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225244_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225244_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-225244.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MyfVzec-eK1FGui2yMiT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225145_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225145_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225145_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225145_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-225145.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MyOs_dfcxMyipDgUcouu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224212_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224212_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224212_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224212_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224212.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2Mvxtgn93qRxIq8HiUYI-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224021_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224021_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224021_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224021_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-224021.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MvUYRaNNH-t3Ia4Ii8W8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221543_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221543_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221543_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221543_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221543.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2Mo03BFlf6MSD1iTHyfW7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221420_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221420_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221420_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221420_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221420.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MobvLPa2gzfzziZ9IWf1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221246_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221246_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221246_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221246_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221246.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MoAeZ9r_BcZPvfOywVzy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221030_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221030_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221030_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221030_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-221030.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MnZbfrtShkqHYwllCEdu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220705_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220705_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220705_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220705_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220705.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MmhGgOkJH6jsoS5gOhlf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220442_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220442_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220442_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220442_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-220442.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2Ml4cT9GDMTwef3HvpICq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-220126_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-220126_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-220126_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-220126_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-220126.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2Mk-_E7K_YOJrYvERDClg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215958_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215958_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215958_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215958_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215958.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MkmrLkwJWxGwIotcXzxw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215608_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215608_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215608_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215608_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215608.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2Mjf8IUF7RY1qUISV5YAw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215332_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215332_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215332_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215332_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215332.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2Miypl-h6FK9qzkmTQkiS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215228_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215228_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215228_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215228_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-215228.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2Migao1XTSnhTJw2LoIie" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215054_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215054_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215054_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215054_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-215054.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MiEm7EDFsS-i69lpENsw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-214815_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-214815_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-214815_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-214815_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-214815.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MhWTvU_zouWTM8n7P5kP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214710_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214710_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214710_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214710_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214710.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MhCORt6b3_vbIkYF5sgW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214336_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214336_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214336_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214336_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-214336.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MgCqcnZVtEYo7FoIMrPw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213843_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213843_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213843_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213843_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213843.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MeuEidgFHBqj5zEp3BCx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213349_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213349_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213349_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213349_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-213349.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MdYlSgf_0Xi8VJzkHCH7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-213219_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-213219_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-213219_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-213219_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-213219.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2Mc9qWAoFEbRPhKZZtjCt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212625_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212625_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212625_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212625_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212625.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MbUIeX2jcBmyFJk3b3P_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212534_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212534_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212534_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212534_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212534.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MbDSYWzQi-SMUgbDEhzu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212436_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212436_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212436_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212436_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212436.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MaylNDBqcKWJvn96eWly" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212401_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212401_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212401_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212401_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212401.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2Man_H3JtA-M9LF7M6d7N" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212218_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212218_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212218_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212218_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-212218.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MaJT4s_eoTRDEUfKfROZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211907_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211907_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211907_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211907_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211907.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MZQsCgsyuDrNMRlNoEvs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211752_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211752_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211752_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211752_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211752.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MY49GpXjfmDHB3sAP3Fo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211344_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211344_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211344_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211344_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-211344.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MXvZOywtuMsHsz_ZkcJL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211114_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211114_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211114_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211114_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-211114.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MXAM8RAAlnui8BtpCmLU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210905_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210905_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210905_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210905_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210905.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MWXZ37zDPW7yS3_xL4SM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210459_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210459_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210459_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210459_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210459.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MVMMX2gKO43JcNPhS2yQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210303_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210303_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210303_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210303_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-210303.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MUpS_VZFinRsVrmkPY21" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-210149_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-210149_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-210149_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-210149_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-210149.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MUQ5uj0Qv7nf_lfw5Vzt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-205959_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-205959_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-205959_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-205959_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-205959.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MTutGsZ-0z9MXVSwY0KL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-204824_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-204824_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-204824_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-204824_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-204824.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MQP4zQO1fcnz_mYMepT9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204543_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204543_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204543_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204543_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204543.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MPd81WvzwmD62LNsk8dQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204441_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204441_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204441_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204441_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204441.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MPKl_tyRD8r-5eKykCdt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204011_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204011_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204011_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204011_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-204011.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MN0oWjt0b0bx8X8dshpr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203749_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203749_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203749_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203749_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203749.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MNHdo8m3T4zG6vV0DrI-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203414_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203414_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203414_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203414_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203414.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MMDxzY_JmP1CCxEUrQmv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 8 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203133_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203133_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203133_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203133_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-Farm/Townhall-8-Base-Layout-20200127-203133.jpg 2430w"
                        title="Townhall 8 Farm Base." alt="Townhall 8 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AHV%3AAAAARAAAAAE2MK2TK8cdPzbSrMJF4fL_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-27" data-title="Townhall 8 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-203124_640w.jpg"
                        srcSet="https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-203124_640w.jpg 320w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-203124_1024w.jpg 640w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-203124_1920w.jpg 1920w, https://townhall-8.clashbases.de/Townhall-8-War/Townhall-8-Base-Layout-20200127-203124.jpg 2430w"
                        title="Townhall 8 War Base" alt="Townhall 8 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH8%3AWB%3AAAAARAAAAAE2MGgaxNEuHZt5Z9iM0Nnt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#G001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 8</p>
                  </div>
                </div>
              </div>
            </figure>

            </div>
          </div>

          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 14 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhalleight;
