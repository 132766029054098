import React from 'react';
import './../Wiki.css';
import { Link as LinkToAnchor } from "react-scroll";
import Collapsible from 'react-collapsible';
import LazyLoad from 'react-lazyload';
import Ad from "../../Ad/ad";


// SPELLS - ELIXIR

const TriggerLightningSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon lightningspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Lightning Spell</span></div>
</div>;
const TriggerHealingSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon healingspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Healing Spell</span></div>
</div>;
const TriggerRageSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon ragespell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Rage Spell</span></div>
</div>;
const TriggerJumpSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon jumpspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Jump Spell</span></div>
</div>;
const TriggerFreezeSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon freezespell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Freeze Spell</span></div>
</div>;
const TriggerCloneSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon clonespell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Clone Spell</span></div>
</div>;
const TriggerInvisibilitySpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon invisibilityspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Invisibility Spell</span></div>
</div>;

// SPELLS - DARK ELIXIR

const TriggerPoisonSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon poisonspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Poison Spell</span></div>
</div>;
const TriggerEarthquakeSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon earthquakespell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Earthquake Spell</span></div>
</div>;
const TriggerHasteSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon hastespell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Haste Spell</span></div>
</div>;
const TriggerSkeletonSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon skeletonspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Skeleton Spell</span></div>
</div>;
const TriggerBatSpell = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon batspell"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Bat Spell</span></div>
</div>;


const homespells  = props => (

      <section className="homebasewiki">

        <div className="hometext-container">
          <div className="hometext-info-container">
            <div className="hometext-about">

            </div>
          </div>
        </div>

        <div className="homebasewiki-container">
            <div className="homebasewiki-buildings-container">

                <div className="homespellsbackground">
                  <h1 className="homebasewiki-buildings-maintitel" id="Spells">Spells</h1>
                </div>

                <h2 className="homebasewiki-buildings-titel" id="ElixirSpells">Elixir Spells</h2>
                {/* NAVBAR - SPELLS - NORMAL */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="ElixirSpells" smooth={true} offset={-70}>Elixir Spells</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="DarkSpells" smooth={true} offset={-70}>Dark Spells</LinkToAnchor></li>
                    </div>
                </nav>

                {/* LIGHNING SPELL */}
                <Collapsible trigger={<TriggerLightningSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>150</td><td>7 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>180</td><td>8 k</td><td>85 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>2</td><td>210</td><td>9 k</td><td>225 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>3</td><td>240</td><td>10 k</td><td>450 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>270</td><td>11 k</td><td>900 k</td><td>3 d</td></tr>
                        <tr><td>6</td><td>7</td><td>320</td><td>12 k</td><td>2 m</td><td>5 d</td></tr>
                        <tr><td>7</td><td>8</td><td>400</td><td>13 k</td><td>4 m</td><td>8 d</td></tr>
                        <tr><td>8</td><td>9</td><td>480</td><td>14 k</td><td>8 m</td><td>10,5 d</td></tr>
                        <tr><td>9</td><td>10</td><td>560</td><td>15 k</td><td>10 m</td><td>13 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* HEALING SPELL */}
                <Collapsible trigger={<TriggerHealingSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Healing</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>600</td><td>15 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>800</td><td>16,5 k</td><td>75 k</td><td>6 h</td></tr>
                        <tr><td>3</td><td>4</td><td>1000</td><td>18 k</td><td>300 k</td><td>18 h</td></tr>
                        <tr><td>4</td><td>5</td><td>1200</td><td>19 k</td><td>600 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>6</td><td>1400</td><td>21 k</td><td>1,2 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>7</td><td>1600</td><td>23 k</td><td>2,5 m</td><td>5 d</td></tr>
                        <tr><td>7</td><td>8</td><td>1800</td><td>25 k</td><td>4,5 m</td><td>9 d</td></tr>
                        <tr><td>8</td><td>11</td><td>2000</td><td>27 k</td><td>14 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* RAGE SPELL */}
                <Collapsible trigger={<TriggerRageSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Dmg +</td><td className="orange-font">Speed +</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>130 %</td><td>20</td><td>20 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>3</td><td>140 %</td><td>22</td><td>22 k</td><td>450 k</td><td>18 h</td></tr>
                        <tr><td>3</td><td>4</td><td>150 %</td><td>24</td><td>24 k</td><td>900 k</td><td>1,5 d</td></tr>
                        <tr><td>4</td><td>5</td><td>160 %</td><td>26</td><td>26 k</td><td>1,8 m</td><td>3 d</td></tr>
                        <tr><td>5</td><td>6</td><td>170 %</td><td>28</td><td>28 k</td><td>3 m</td><td>5 d</td></tr>
                        <tr><td>6</td><td>10</td><td>180 %</td><td>30</td><td>30 k</td><td>11 m</td><td>11,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* JUMP SPELL */}
                <Collapsible trigger={<TriggerJumpSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>20 s</td><td>23 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>40 s</td><td>27 k</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>3</td><td>8</td><td>60 s</td><td>31 k</td><td>6 m</td><td>7 d</td></tr>
                        <tr><td>4</td><td>11</td><td>80 s</td><td>32 k</td><td>13 m</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* FREEZE SPELL */}
                <Collapsible trigger={<TriggerFreezeSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>2,5 s</td><td>6 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>3 s</td><td>7 k</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>3</td><td>8</td><td>3,5 s</td><td>8 k</td><td>2,5 m</td><td>4 d</td></tr>
                        <tr><td>4</td><td>8</td><td>4 s</td><td>9 k</td><td>4,2 m</td><td>6 d</td></tr>
                        <tr><td>5</td><td>8</td><td>4,5 s</td><td>10 k</td><td>6 m</td><td>7,5 d</td></tr>
                        <tr><td>6</td><td>9</td><td>5 s</td><td>11 k</td><td>8,5 m</td><td>9 d</td></tr>
                        <tr><td>7</td><td>10</td><td>5,5 s</td><td>12 k</td><td>11 m</td><td>11,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* CLONE SPELL */}
                <Collapsible trigger={<TriggerCloneSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Capacity</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>18</td><td>28 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>21</td><td>29,5 k</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>3</td><td>8</td><td>24</td><td>31 k</td><td>4,5 m</td><td>4,5 d</td></tr>
                        <tr><td>4</td><td>9</td><td>27</td><td>32,5 k</td><td>7 m</td><td>6,5 d</td></tr>
                        <tr><td>5</td><td>9</td><td>30</td><td>34 k</td><td>9 m</td><td>11,5 d</td></tr>
                        <tr><td>6</td><td>11</td><td>34</td><td>35 k</td><td>14 m</td><td>15 d</td></tr>
                        <tr><td>7</td><td>12</td><td>38</td><td>37,5 k</td><td>16,5 m</td><td>16,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* INVISIBILITY SPELL */}
                <Collapsible trigger={<TriggerInvisibilitySpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>3,75</td><td>11 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>4</td><td>12 k</td><td>9 m</td><td>9 d</td></tr>
                        <tr><td>3</td><td>10</td><td>4,25</td><td>13 k</td><td>12 m</td><td>11,5 d</td></tr>
                        <tr><td>4</td><td>11</td><td>4,5</td><td>14 k</td><td>15 m</td><td>15,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                <h2 className="homebasewiki-buildings-titel" id="DarkSpells">Dark Spells</h2>
                {/* NAVBAR - SPELLS - NORMAL */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="ElixirSpells" smooth={true} offset={-70}>Elixir Spells</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="DarkSpells" smooth={true} offset={-70}>Dark Spells</LinkToAnchor></li>
                    </div>
                </nav>

                {/* POISON SPELL */}
                <Collapsible trigger={<TriggerPoisonSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>95</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>110</td><td>20 k</td><td>1 d</td></tr>
                        <tr><td>3</td><td>7</td><td>125</td><td>40 k</td><td>2 d</td></tr>
                        <tr><td>4</td><td>8</td><td>140</td><td>75 k</td><td>4 d</td></tr>
                        <tr><td>5</td><td>9</td><td>155</td><td>150 k</td><td>9 d</td></tr>
                        <tr><td>6</td><td>10</td><td>170</td><td>200 k</td><td>11 d</td></tr>
                        <tr><td>7</td><td>11</td><td>185</td><td>260 k</td><td>15,5 d</td></tr>
                        <tr><td>8</td><td>12</td><td>200</td><td>300 k</td><td>17,5 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="4">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg/s</td><td className="orange-font">Speed -</td><td className="orange-font">Attack Rate -</td></tr>
                        <tr><td>1</td><td>90</td><td>26 %</td><td>35 %</td></tr>
                        <tr><td>2</td><td>115</td><td>30 %</td><td>40 %</td></tr>
                        <tr><td>3</td><td>145</td><td>34 %</td><td>45 %</td></tr>
                        <tr><td>4</td><td>180</td><td>38 %</td><td>50 %</td></tr>
                        <tr><td>5</td><td>220</td><td>40 %</td><td>55 %</td></tr>
                        <tr><td>6</td><td>260</td><td>42 %</td><td>60 %</td></tr>
                        <tr><td>7</td><td>280</td><td>44 %</td><td>65 %</td></tr>
                        <tr><td>8</td><td>300</td><td>46 %</td><td>68 %</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* EARTHQUAKE SPELL */}
                <Collapsible trigger={<TriggerEarthquakeSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Dmg</td><td className="orange-font">Radius</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>14 %</td><td>3,5</td><td>125</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>17 %</td><td>3,8</td><td>140</td><td>20 k</td><td>2 d</td></tr>
                        <tr><td>3</td><td>7</td><td>21 %</td><td>4,1</td><td>160</td><td>40 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>8</td><td>25 %</td><td>4,4</td><td>180</td><td>75 k</td><td>7,5 d</td></tr>
                        <tr><td>5</td><td>9</td><td>29 %</td><td>4,7</td><td>200</td><td>120 k</td><td>11 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* HASTE SPELL */}
                <Collapsible trigger={<TriggerHasteSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Speed +</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>28</td><td>10 s</td><td>80</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>34</td><td>15 s</td><td>100</td><td>30 k</td><td>2,5 d</td></tr>
                        <tr><td>3</td><td>8</td><td>40</td><td>20 s</td><td>120</td><td>50 k</td><td>5 d</td></tr>
                        <tr><td>4</td><td>8</td><td>46</td><td>25 s</td><td>140</td><td>80 k</td><td>8 d</td></tr>
                        <tr><td>5</td><td>9</td><td>52</td><td>30 s</td><td>160</td><td>120 k</td><td>11 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SKELETON SPELL */}
                <Collapsible trigger={<TriggerSkeletonSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Skeletons</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>11</td><td>110</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>12</td><td>120</td><td>25 k</td><td>2,5 d</td></tr>
                        <tr><td>3</td><td>8</td><td>13</td><td>130</td><td>40 k</td><td>4 d</td></tr>
                        <tr><td>4</td><td>9</td><td>14</td><td>140</td><td>70 k</td><td>6,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>15</td><td>150</td><td>125 k</td><td>8,5 d</td></tr>
                        <tr><td>6</td><td>10</td><td>16</td><td>160</td><td>150 k</td><td>10,5 d</td></tr>
                        <tr><td>7</td><td>11</td><td>18</td><td>170</td><td>250 k</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BAT SPELL */}
                <Collapsible trigger={<TriggerBatSpell />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Bats</td><td className="orange-font">Cost</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>-</td><td>7</td><td>110</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>9</td><td>120</td><td>30 k</td><td>3 d</td></tr>
                        <tr><td>3</td><td>8</td><td>11</td><td>130</td><td>60 k</td><td>5,5 d</td></tr>
                        <tr><td>4</td><td>9</td><td>16</td><td>140</td><td>120 k</td><td>7,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>21</td><td>150</td><td>160 k</td><td>9 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

            </div>

        </div>

      </section>

);

export default homespells;
