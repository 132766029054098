import React, { Component } from 'react';
import './TownhallThirteenAds.scss';
import Townhallthirteendemoads from './TownhallThirteenScriptAds.js';
import { Link } from 'react-router-dom';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class Townhallthirteenads extends Component {

  componentDidMount () {
    window.townhallthirteendemoads = new Townhallthirteendemoads(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="th13-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 13 Base Layouts and Links</title>
            <meta name="description" content="NEW Townhall 13 Base Layouts with Import Links! Browse through our Gallery and import your favorite TH 13 War Bases, Farm Bases, Trophy Bases and Legends League Bases directly into your Game!" />
            <meta name="keywords" content="th 13, ths 13, th thirteen, townhall 13, townhall 13 base, th 13 links, links, th 13 base design, th 13 bases, th 13 warbase, warbase, base for war, clan war base, clanwar base th 13, war base th 13, townhall 10 war base, townhall 10 base for war, th 13 trophybase, trophybase, base for trophys, trophy base th 13, townhall 10 trophy base, townhall 10 base for trophy, th 13 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 13, townhall 10 push base, townhall 10 base for pushing, th 13 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 13, townhall 13 farm base, townhall 13 base for farming, troll bases, th 13 troll base, th 13 fun base, th 13 funny base, th 13 trolling, funny th 13 base, best th 13 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 13 island base, ring base, th 13 ring base, teaser, th 13 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 13 links, th 13 base link, th 13 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
            <Ad adType="video-ad-container" adID="fe0fe7bc-90cd-ac31-2d7b-fe67761085b5" />
          </figure>


          <div className="blackcanvas">

            <div className="blackcanvastext">
              <h1> Archives  </h1>

              <h1> Click Here to Visit all Older Base Layouts  </h1>

              <h1> Every Single Base Layout Reworked  </h1>

              <h1> for the <span className="purple">TH 15</span> Update ! </h1>

              <h1>  </h1>
            </div>

          </div>



          <div className="th13-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th13-container-about">
            <div className="th13-row-about">
              <div className="th13-title-container">
                <h1 className="th13-about-title">Townhall <span style={{color: 'rgb(145, 27, 209)'}}>13</span> Base Layouts</h1>

                <div className="switch-container-th13">
                  <LinkButton to="/Townhall-9" className="switch-buttons-th13 switch-to-th9-13">
                    <div className="switch-buttons-text-10">Lower</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-10" className="switch-buttons-th13 switch-to-th10-13">
                    <div className="switch-buttons-text-10">TH 10</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-11" className="switch-buttons-th13 switch-to-th11-13">
                    <div className="switch-buttons-text-10">TH 11</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-12" className="switch-buttons-th13 switch-to-th12-13">
                    <div className="switch-buttons-text-10">TH 12</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-13" className="switch-buttons-th13 switch-to-th13-13">
                    <div className="switch-buttons-text-10">TH 13</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th13-container-about">
            <div className="th13-row-about">
              <div className="col-6@sm th13-filters-group-wrap">
                <div className="th13-filters-group filters-group-right">
                  <p className="th13-filter-label th13-filter-color">Filter</p>
                  <div className="btn-group th13-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                    <button className="btn btn--primary" data-group="contributor">Contributor</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="/Pictures/Icons/Patreon/FlyingGiants_PatreonAD_640w.jpg"
                    srcSet="/Pictures/Icons/Patreon/FlyingGiants_PatreonAD_320w.jpg 320w, /Pictures/Icons/Patreon/FlyingGiants_PatreonAD_640w.jpg 640w, /Pictures/Icons/Patreon/FlyingGiants_PatreonAD_1024w.jpg 1600w, /Pictures/Icons/Patreon/FlyingGiants_PatreonAD_1200w.jpg 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de" zoom={this.zoom}  />
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th13-container-about">
            <div className="page-button-container">
              <LinkButton to="/Townhall-13" className="page-button-current">
                <div>New - 201</div>
              </LinkButton>
              <LinkButton to="/Townhall-13-2" className="page-button">
                <div>200 - 101</div>
              </LinkButton>
              <LinkButton to="/Townhall-13-3" className="page-button">
                <div>100 - 1</div>
              </LinkButton>
            </div>

            <div id="grid" className="th13-row-about my-shuffle-th13-container-about">

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["showcase"]' data-date-created="2019-12-10" data-title="Townhall 12 | Base Layouts and Links">
              <div className="picture-item__inner townhall-showcase-13">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/Icons/wallpapers/townhall-13-wallpaper-notext.jpg"
                        srcSet="/Pictures/Icons/wallpapers/townhall-13-wallpaper-notext_320w.jpg 320w, /Pictures/Icons/wallpapers/townhall-13-wallpaper-notext_640w.jpg 640w, /Pictures/Icons/wallpapers/townhall-13-wallpaper-notext_1024w.jpg 1600w, /Pictures/Icons/wallpapers/townhall-13-wallpaper-notext_1600w.jpg 2430w"
                        title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 13</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234121_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234121_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234121_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234121_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234121_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj5ZsMlg8zpr-qO9zEkrxO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P297</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-222322_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-222322_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-222322_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-222322_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-222322_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj4_Hrd2lxqQvzawiZL7xu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P296</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-05-14" data-title="Townhall 13 Trophy Base by Espejo humeante">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200513-215723_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200513-215723_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200513-215723_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200513-215723_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200513-215723_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base by Espejo humeante" alt="Townhall 13 Trophy Base by Espejo humeante" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGj42FAzV95o9Ku0eWLuNsA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P295</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=280CY2808" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Espejo humeante</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-215403_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-215403_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-215403_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-215403_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-215403_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj405TXL2OpADd91JNKYBP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P294</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-174148_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-174148_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-174148_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-174148_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-174148_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhtvG_h4AlE--KtV9_uX-C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P293</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-171144_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-171144_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-171144_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-171144_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-171144_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhte3MLe-btUjsP6i-PDyx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P292</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-163557_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-163557_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-163557_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-163557_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-163557_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhtLYcj32DPqZB-mqU_izx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P291</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-161318_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-161318_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-161318_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-161318_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-161318_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhs_HNMZcMCjdXHIlbQHg_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P290</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-154514_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-154514_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-154514_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-154514_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200429-154514_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhsvLj13qviI9ERLpCflrX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P289</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-160945_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-160945_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-160945_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-160945_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-160945_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhXipbjzr8lGwKLdj1bdug" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P288</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="th13-container-about">
            <div className="th13-row-about">
              <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
                <Ad adType="ad-container" adID="a0f64c28-feca-480a-b8b6-761d5790a801" />
              </figure>
            </div>
            </div>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-165216_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-165216_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-165216_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-165216_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-165216_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhX8xZupUvC0De71JfxuTf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P287</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-172000_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-172000_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-172000_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-172000_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-172000_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhYOEwOUfVnsxOZxTe_0lV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P286</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-154304_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-154304_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-154304_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-154304_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200427-154304_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhXSGjB99EHwX7Zct6At11" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P285</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-29" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200427-150621_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200427-150621_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200427-150621_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200427-150621_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200427-150621_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhW7bHl-0N1wRUKf4UXj3n" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P284</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-232323_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-232323_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-232323_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-232323_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-232323_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPx2BeXB-xCJzEieM-S3I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P283</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-28" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200426-223907_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200426-223907_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200426-223907_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200426-223907_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200426-223907_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base" alt="Townhall 13 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhPfPvbdCBx_CEtL29AO3h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P282</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-221313_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-221313_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-221313_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-221313_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-221313_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPUMmsa1-Qv55h6Br0hcS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P281</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-213013_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-213013_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-213013_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-213013_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200426-213013_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPBGzk5MR50_kbGnEzEad" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P280</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-28" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200426-210832_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200426-210832_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200426-210832_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200426-210832_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200426-210832_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhO3NfyJCF27qbd9Ps-IY0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P279</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-005316_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-005316_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-005316_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-005316_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-005316_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMlFRU06QhS1Oa92y9hcm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P278</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-011709_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-011709_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-011709_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-011709_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-011709_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMseIXq_unM577IQ0LA4h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P277</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-003010_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-003010_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-003010_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-003010_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-003010_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMdzQrq2UA7eRXw-lBV-y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P276</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-000608_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-000608_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-000608_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-000608_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-000608_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMWpEnBkI7Jh0dWHDlSXp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P275</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200421-232822_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200421-232822_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200421-232822_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200421-232822_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200421-232822_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMLp1I_hZilusObDcMmCW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P274</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-063524_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-063524_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-063524_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-063524_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-063524_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOmlFYN5STecONfCxD80A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P273</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>


            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-055151_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-055151_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-055151_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-055151_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-055151_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOUGdKilEMyuMmUraAu7D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P272</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-052202_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-052202_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-052202_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-052202_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-052202_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOHyluCo942fi7exQWBMp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P271</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-040653_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-040653_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-040653_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-040653_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-040653_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgNp6oBxWq_RQ9jtaCKVkm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P270</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-033517_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-033517_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-033517_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-033517_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200422-033517_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgNd2pZXmYfQC8wkt7L49J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P269</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-185342_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-185342_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-185342_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-185342_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-185342_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgAo18D3fFtcY_dTtkTMnh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P268</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-182407_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-182407_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-182407_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-182407_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-182407_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgAaFVArbjQaVqvbtDXpFD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P267</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-173935_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-173935_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-173935_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-173935_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-173935_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgACt-y79DEfp-zRbD0r6d" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P266</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-170626_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-170626_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-170626_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-170626_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-170626_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_wh5gAHiXI3InZbRiLeU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P265</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-163652_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-163652_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-163652_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-163652_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-163652_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_gFdawNt7eElWy5Oryxt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P264</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="th13-container-about">
            <div className="th13-row-about">
              <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
                <Ad adType="ad-container" adID="a0f64c28-feca-480a-b8b6-761d5790a801" />
              </figure>
            </div>
            </div>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-162455_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-162455_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-162455_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-162455_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-162455_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_ZbXrH4IJVmY-WggiTo2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P263</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-160132_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-160132_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-160132_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-160132_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-160132_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_MTo01w0m_mjejUrpffH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P262</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-153530_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-153530_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-153530_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-153530_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-153530_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf-92NYWAFebYXWWAsomlM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P261</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-24" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200420-153425_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200420-153425_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200420-153425_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200420-153425_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200420-153425_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGf-9Sk08UtHpy1rEyrNA5I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P260</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-152449_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-152449_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-152449_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-152449_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200420-152449_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf-39uDvCIwbfN8y7FY2Yr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P259</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-154259_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-154259_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-154259_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-154259_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-154259_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdF2fhvCy5Gk-0QuqS8wff" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P258</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>
            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-02" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-142717_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-142717_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-142717_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-142717_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-142717_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdFJ8hqg8YJpQfWtqxO1DC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P257</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134300_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134300_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134300_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134300_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134300_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEwJzXU02nsNiMvfA48bC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P256</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134208_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134208_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134208_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134208_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134208_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEvpgmCX-Y-2sve9MB5-h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P255</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134139_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134139_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134139_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134139_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134139_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEvW-I6NB_10vHedAXct3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P254</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134035_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-134035_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134035_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134035_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-134035_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEutP2XbrD7XzkIKhYil1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P253</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-133656_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-133656_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133656_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133656_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133656_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEskcv5J2HrpHETBHchO4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P252</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-133531_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-133531_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133531_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133531_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-133531_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdErwjpX71mhwaWH5LZ2hI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P251</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-02" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133426_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133426_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133426_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133426_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133426_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdErM-cCWzDo4rzp1E4LJd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P250</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091757_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091757_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091757_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091757_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091757_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq0H7j6S2-F_XUsI-SD2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P249</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091614_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091614_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091614_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091614_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091614_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCqMluMhcCCuNn3UIB-Fc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P248</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091536_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091536_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091536_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091536_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091536_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCp6ZGgtHLc4cb8BJsyVN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P247</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091448_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091448_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091448_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091448_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091448_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCpi2VK0VlJ8092M-Wgh-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P246</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-02" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133052_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133052_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133052_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133052_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Screenshot_20200402-133052_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdEpUaJ9vf0MvCFs8UpNYv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P245</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132854_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132854_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132854_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132854_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132854_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEn-Z7k1u0MoOZg9sU3D3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P244</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132816_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132816_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132816_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132816_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132816_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEnkSC-WmkRSWkiLWjO2v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P243</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132629_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132629_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132629_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132629_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132629_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEmhP4HxbL2YaeY4oPAHZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P242</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132313_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132313_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132313_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132313_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132313_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEknS202nhUp7bF_MmJnd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P241</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132218_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-132218_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132218_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132218_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-132218_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEkOF6FC4Rm8mtktFwXl6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P240</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="th13-container-about">
            <div className="th13-row-about">
              <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
                <Ad adType="ad-container" adID="a0f64c28-feca-480a-b8b6-761d5790a801" />
              </figure>
            </div>
            </div>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-02" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-123602_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-123602_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-123602_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-123602_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Screenshot_20200402-123602_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdEJuORj9yVzQjNq7TehBu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P239</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-123044_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-123044_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-123044_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-123044_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-123044_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEGmtnSRaCMxyf3lT3Q2r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P238</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122956_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122956_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122956_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122956_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122956_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEGHnb47OqeHkpW9GPxFq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P237</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122919_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122919_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122919_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122919_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122919_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">Expired Link</a><p className="index-number font-size-picture-descriptions">#P236</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122840_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-122840_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122840_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122840_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-122840_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEFx4GdgB7kbla-Jk4nMK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P235</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-092020_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-092020_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-092020_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-092020_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-092020_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEFYxmoReIbmNO_5mxBoP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P234</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>
            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091908_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091908_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091908_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091908_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091908_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq9ADTFgvVMF_ysUk0z_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P233</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091831_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091831_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091831_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091831_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091831_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq55Cm4fvVFaL-Nk5Kbw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P232</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091019_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-091019_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091019_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091019_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-091019_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCnfllx71OszjwzEEz9cy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P231</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Screenshot_20200402-090818_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Screenshot_20200402-090818_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-090818_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-090818_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Screenshot_20200402-090818_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCmgEPmw3YJVtqAFIUGRD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P230</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Poseidon</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122143_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122143_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122143_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122143_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122143_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck6rqS02AuNj-H5rzdIr1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P229</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base by Name Not Found">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-135204_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-135204_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-135204_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-135204_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-135204_1600w.jpg 2430w"
                        title="Townhall 13 War Base by Name Not Found" alt="Townhall 13 War Base by Name Not Found" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGclpPV8jVIDkdM2kZgNyY7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P228</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9QRYRPUYQ" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Name Not Found</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122025_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122025_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122025_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122025_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-122025_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck6B85BG-Om8pKGIxUykS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P227</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>


            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121809_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121809_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121809_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121809_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121809_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck4-SHxkGRFyn_SY1znuf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P226</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121504_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121504_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121504_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121504_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121504_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck3eMr0y6b0QJ2HYl_FrJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P225</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121429_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121429_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121429_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121429_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121429_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck3Mp6gXbNg5Amr8aWmmX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P224</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-121236_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-121236_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-121236_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-121236_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-121236_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGck2PIvKyhbYKDvc0sy07B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P223</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121113_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121113_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121113_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121113_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-121113_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck1lq4U-Ra6rrnxC5DuRC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P222</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120918_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120918_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120918_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120918_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120918_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGck0o90Tq_OKwijXKI-mbr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P221</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120829_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120829_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120829_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120829_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120829_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck0PM9zWnFGjvY1sqAqe2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P220</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120753_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120753_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120753_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120753_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120753_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckz8io0W7oC_UTFQj72Kd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P219</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120708_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120708_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120708_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120708_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120708_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckzlY1raNRKyCqXcwSYPl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P218</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120613_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120613_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120613_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120613_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120613_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckzJGJ-kqNSC-IFdtCyYa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P217</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120345_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120345_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120345_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120345_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120345_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckx-1S6ZQU5hiz0cPPZoB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P216</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <div className="th13-container-about">
            <div className="th13-row-about">
              <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
                <Ad adType="ad-container" adID="a0f64c28-feca-480a-b8b6-761d5790a801" />
              </figure>
            </div>
            </div>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120247_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120247_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120247_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120247_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-120247_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckxo8rzjOTFvHGsDbpA8o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P215</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120139_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120139_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120139_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120139_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-120139_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckw9YlhFIJJnYM9C64coo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P214</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-115956_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-115956_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-115956_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-115956_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-115956_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckwG4fHkdN_XUBzjVzdah" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P213</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-145753_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-145753_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-145753_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-145753_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-145753_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckrYunCDLuXgsIE9t99UI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P212</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114645_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114645_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114645_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114645_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114645_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckpxWV-cRJsWytC2k8_fv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P211</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114531_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114531_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114531_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114531_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-114531_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckpIq1XPuQD70j5Fnl64w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P210</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>
            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-114347_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-114347_320w.jpg 320w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-114347_640w.jpg 640w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-114347_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Farm/Townhall-13-Base-Layout-20200330-114347_1600w.jpg 2430w"
                        title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckoSzo9fqEb4envoAgJc-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P209</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-114048_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-114048_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-114048_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-114048_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-114048_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckm2lfbky8njJFppNHdA3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P208</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113919_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113919_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113919_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113919_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113919_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckmKSyGy8qp6MCtHUsByu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P207</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113753_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113753_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113753_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113753_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113753_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGcklmCpl4FjFnrbjvxpOO4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P206</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113558_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113558_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113558_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113558_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113558_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckkfibEbcu-PnhlU1KxC5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P205</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113151_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113151_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113151_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113151_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-113151_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckijJeIYsJDWwQfyzNJEx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P204</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-112717_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-112717_320w.jpg 320w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-112717_640w.jpg 640w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-112717_1024w.jpg 1600w, /Pictures/HomeBase_Th13/Trophy/Townhall-13-Base-Layout-20200330-112717_1600w.jpg 2430w"
                        title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckgbZG-lvzEN68epUad0e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P203</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112455_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112455_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112455_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112455_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112455_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckfbaNHp4Ars-zGkfmnVo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P202</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112347_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112347_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112347_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112347_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200330-112347_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckeyVlwVMAeowGq_STt1H" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P201</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 13</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["showcase"]' data-date-created="2012-04-23" data-title="Available on Patreon. Coming to ClashBases Soon!">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="/Pictures/Icons/Patreon/Coming_Soon!.jpg"
                        srcSet="/Pictures/Icons/Patreon/Coming_Soon!_640w.jpg 320w, /Pictures/Icons/Patreon/Coming_Soon!_1024w.jpg 640w, /Pictures/Icons/Patreon/Coming_Soon!_1920w.jpg 1920w, /Pictures/Icons/Patreon/Coming_Soon!.jpg 2430w"
                        title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Coming June 25th</a><p className="index-number font-size-picture-descriptions">#P313 - #P309</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Qualifiers</p>
                    <p className="index-number font-size-picture-descriptions yellow">Clash World</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["showcase"]' data-date-created="2012-04-23" data-title="Available on Patreon. Coming to ClashBases Soon!">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="/Pictures/Icons/Patreon/Coming_Soon!.jpg"
                        srcSet="/Pictures/Icons/Patreon/Coming_Soon!_640w.jpg 320w, /Pictures/Icons/Patreon/Coming_Soon!_1024w.jpg 640w, /Pictures/Icons/Patreon/Coming_Soon!_1920w.jpg 1920w, /Pictures/Icons/Patreon/Coming_Soon!.jpg 2430w"
                        title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Coming June 25th</a><p className="index-number font-size-picture-descriptions">#P313 - #P309</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Qualifiers</p>
                    <p className="index-number font-size-picture-descriptions yellow">Clash World</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["showcase"]' data-date-created="2012-04-23" data-title="Available on Patreon. Coming to ClashBases Soon!">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="/Pictures/Icons/Patreon/Coming_Soon!.jpg"
                        srcSet="/Pictures/Icons/Patreon/Coming_Soon!_640w.jpg 320w, /Pictures/Icons/Patreon/Coming_Soon!_1024w.jpg 640w, /Pictures/Icons/Patreon/Coming_Soon!_1920w.jpg 1920w, /Pictures/Icons/Patreon/Coming_Soon!.jpg 2430w"
                        title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Coming June 25th</a><p className="index-number font-size-picture-descriptions">#P313 - #P309</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Qualifiers</p>
                    <p className="index-number font-size-picture-descriptions yellow">Clash World</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["showcase"]' data-date-created="2012-04-23" data-title="Available on Patreon. Coming to ClashBases Soon!">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="/Pictures/Icons/Patreon/Coming_Soon!.jpg"
                        srcSet="/Pictures/Icons/Patreon/Coming_Soon!_640w.jpg 320w, /Pictures/Icons/Patreon/Coming_Soon!_1024w.jpg 640w, /Pictures/Icons/Patreon/Coming_Soon!_1920w.jpg 1920w, /Pictures/Icons/Patreon/Coming_Soon!.jpg 2430w"
                        title="Townhall 12 | Base Layouts and Links" alt="Townhall 12 | Base Layouts and Links" zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Coming June 25th</a><p className="index-number font-size-picture-descriptions">#P313 - #P309</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Qualifiers</p>
                    <p className="index-number font-size-picture-descriptions yellow">Clash World</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/test2.jpg"

                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGmLcGGlfXycO3lPjoe2qKc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">Click ONCE after 15 days</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Test 1</p>
                    <p className="index-number font-size-picture-descriptions">May 28th 2020</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/test2.jpg"

                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGmLczbb5mF8cTeOKc8NkMr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">Click ONCE after 28 days</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Test 2</p>
                    <p className="index-number font-size-picture-descriptions">May 28th 2020</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/test3.jpg"

                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGmLdI5AWUwb5RExnQdbVB7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">Click ONCE after 32 days</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Test 3</p>
                    <p className="index-number font-size-picture-descriptions">May 28th 2020</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/test4.jpg"

                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGmLdYmUYyTAOr0CKN7SGGx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">Click after 35 days</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Test 4</p>
                    <p className="index-number font-size-picture-descriptions">May 28th 2020</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-4@sm col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234159_640w.jpg"
                        srcSet="/Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234159_320w.jpg 320w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234159_640w.jpg 640w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234159_1024w.jpg 1600w, /Pictures/HomeBase_Th13/War/Townhall-13-Base-Layout-20200513-234159_1600w.jpg 2430w"
                        title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj5Z7Qe77z92B27XtQdATl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P298</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions yellow">New</p>
                  </div>
                </div>
              </div>
            </figure>

              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>

          <div className="page-button-container">
            <LinkButton to="/Townhall-13" className="page-button-current">
              <div>New - 201</div>
            </LinkButton>
            <LinkButton to="/Townhall-13-2" className="page-button">
              <div>200 - 101</div>
            </LinkButton>
            <LinkButton to="/Townhall-13-3" className="page-button">
              <div>100 - 1</div>
            </LinkButton>
          </div>

          <div className="homebase-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="/Pictures/Icons/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">All the best Townhall 13 Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              Browse through our new collection of clash of clans townhall 13 base layouts with links!<br />
              War base, Trophy base, farm base or just a casual base for aesthetics, we got them all. Find your favorite th 13 base build and import it directly into your game. <br /><br /><br />
              We will keep adding to this collection of th 13 base layouts with links over the next couple of weeks, so make sure to keep on checking in!<br /><br />

              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
            <p className="Blob">
              30 Days<br /><br /><br />
              Exclusive Access<br /><br /><br />
              All Clash World Content<br /><br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default Townhallthirteenads;
