import React, { Component } from 'react';
import './TownhallFourteen.scss';
import Townhallfourteendemo from './TownhallFourteenScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class Townhallfourteen extends Component {

  componentDidMount () {
    window.townhallthirteendemo = new Townhallfourteendemo(document.getElementById('grid'));
  }

  zoom = mediumZoom ({background:"rgba(0, 0, 0, 0.5)"})


  render() {
    return (

      <section className="th14-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall 14 Base Layouts and Links</title>
            <meta name="description" content="NEW Townhall 14 Base Layouts with Import Links! Browse through our Gallery and import your favorite TH 14 War Bases, Farm Bases, Trophy Bases and Legends League Bases directly into your Game!" />
            <meta name="keywords" content="th 14, ths 14, th thirteen, townhall 14, townhall 14 base, th 14 links, links, th 14 base design, th 14 bases, th 14 warbase, warbase, base for war, clan war base, clanwar base th 14, war base th 14, townhall 10 war base, townhall 10 base for war, th 14 trophybase, trophybase, base for trophys, trophy base th 14, townhall 10 trophy base, townhall 10 base for trophy, th 14 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 14, townhall 10 push base, townhall 10 base for pushing, th 14 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 14, townhall 14 farm base, townhall 14 base for farming, troll bases, th 14 troll base, th 14 fun base, th 14 funny base, th 14 trolling, funny th 14 base, best th 14 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 14 island base, ring base, th 14 ring base, teaser, th 14 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 14 links, th 14 base link, th 14 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="th14-about-page-container" id="back-to-top">

          <div className="homebase-mobile-overlay">

          <div className="th14-container-about">
            <div className="th14-row-about">
              <div className="th14-title-container">
                <h1 className="th14-about-title">Townhall <span style={{color: 'rgb(43, 143, 13)'}}>14</span> Base Layouts</h1>

                <div className="switch-container-th14">
                  <LinkButton to="/Townhall-11" className="switch-buttons-th14 switch-to-th11-14">
                    <div className="switch-buttons-text-14">Lower</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-12" className="switch-buttons-th14 switch-to-th12-14">
                    <div className="switch-buttons-text-14">TH 12</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-13" className="switch-buttons-th14 switch-to-th13-14">
                    <div className="switch-buttons-text-14">TH 13</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-14" className="switch-buttons-th14 switch-to-th14-14">
                    <div className="switch-buttons-text-14">TH 14</div>
                  </LinkButton>
                  <LinkButton to="/Townhall-15" className="switch-buttons-th14 switch-to-th15-14">
                    <div className="switch-buttons-text-14">TH 15</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="th14-container-about">
            <div className="th14-row-about">
              <div className="col-6@sm th14-filters-group-wrap">
                <div className="th14-filters-group filters-group-right">
                  <p className="th14-filter-label th14-filter-color">Filter</p>
                <div className="btn-group th14-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="th14-container-about">

            <div id="grid" className="th14-row-about my-shuffle-th14-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2019-12-10" data-title="Townhall 14 | Base Layouts and Links">
                <div className="picture-item__inner townhall-showcase-13">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg"
                          srcSet="https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_320w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1024w.jpg 1024w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 1920w, https://assets.clashbases.de/Backgrounds/townhall-14-wallpaper-notext_1600w.jpg 2430w"
                          title="Townhall 14 | Base Layouts and Links" alt="Townhall 14 | Base Layouts and Links" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 14</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191249.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191249.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191249.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191249.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191249.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbSBuSnEhO0zpqf6U_GKEP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-176</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191111.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191111.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191111.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191111.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-191111.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbSBIdFQ00Um2l-izH6OHO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-175</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190842.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190842.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190842.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190842.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190842.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbSAPqOM0gPFJDMDvBwRi-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-174</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190711.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190711.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190711.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190711.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190711.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR_ulBl54BMrBwPCFAzbE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-173</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190542.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190542.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190542.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190542.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190542.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR_OPcxbVIZUKCusjKeDf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-172</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190109.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190109.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190109.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190109.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-190109.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR9p0Ye6owawamjg3DvXU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-171</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185942.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185942.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185942.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185942.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185942.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR9KkH0lqjkE3z2U7cCMR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-170</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185806.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185806.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185806.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185806.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185806.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbR8lAXVqKl77Mr2wy39WQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-169</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185628.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185628.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185628.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185628.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185628.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR8Ay6Rkchi-7cIw-nuDY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-168</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185429.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185429.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185429.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185429.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185429.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR7S8aWIzbARwQJdmiZ0V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-167</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185152.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185152.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185152.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185152.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-185152.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbR6YNubBjaRno48GUSsec" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-166</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185005.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185005.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185005.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185005.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-185005.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR5xg_iv2AQ01b7hOB2kN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-165</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184754.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184754.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184754.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184754.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184754.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR4_8PGHOHFSnG8EByd8J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-164</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>


              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184523.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184523.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184523.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184523.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-184523.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbR4EMZZRe9p-FuOFLD2T0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-163</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161839.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161839.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161839.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161839.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161839.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ-PilpQ89Cxa6gNUJ7YR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-162</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161525.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161525.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161525.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161525.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161525.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ88aGhhFDO3iCjBK3Ouy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-161</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>


              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161013.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161013.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161013.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161013.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-161013.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ65eL0jv4Et42xyFFw5l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-160</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160634.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160634.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160634.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160634.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160634.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ5XsVmjpzeDTPtvTiU4U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-159</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160107.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160107.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160107.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160107.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-160107.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ3Qr7sAHp7O1NdWVEKJw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-158</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155331.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155331.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155331.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155331.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155331.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQ04prfBFJBMKOVttIT2A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-157</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155053.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155053.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155053.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155053.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-155053.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQzBVR_DhMjM01CJEiUz1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-156</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154732.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154732.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154732.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154732.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154732.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQxrB-yJ_aaA4IZnLBP3p" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-155</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154338.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154338.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154338.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154338.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154338.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQwJ7swM53hacMvFXF2KD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-154</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154004.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154004.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154004.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154004.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-154004.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQul8ZQKhnK6gCLyCxfyc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-153</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-153901.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-153901.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-153901.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-153901.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-153901.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbQuKNgXlKZ13jUyNuDtVV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-152</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153641.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153641.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153641.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153641.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153641.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQtOoYZhnEcGtsQqzA8GO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-151</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153514.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153514.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153514.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153514.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153514.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQspb2CiDiCHCQBysJeWX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-150</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153323.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153323.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153323.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153323.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153323.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQr4-LgtzxbdFK905ocC6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-149</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153122.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153122.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153122.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153122.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-153122.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQrCM-nRrIun8QJta0PxM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-148</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152434.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152434.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152434.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152434.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152434.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQoQTS5uJE0qf4CbaBJwQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-147</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152327.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152327.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152327.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152327.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152327.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQn0vY5b23q9HI4nM6qWU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-146</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152220.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152220.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152220.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152220.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152220.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQnVafqVOMtqGnXZFknsD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-145</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152041.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152041.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152041.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152041.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-152041.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQmtG299OYXFVmAbQ5-NZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-144</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-151910.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-151910.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-151910.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-151910.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-151910.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbQmDzPZgEuLFpiw0hNJEO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-143</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151551.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151551.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151551.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151551.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151551.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQkwzLuiKynQu3AIQ6etG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-142</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151329.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151329.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151329.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151329.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151329.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQjy-up16nmk4xOFR_6Z0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-141</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151141.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151141.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151141.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151141.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-151141.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQjF45KLwPgxyZRdgBZZ2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-140</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-150934.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-150934.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-150934.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-150934.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221019-150934.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbQiPf93kQeayhHio-nWrZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-139</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-134502.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-134502.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-134502.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-134502.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221019-134502.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbQAnb1BmHqnR68C5bC09n" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-138</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-004115.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-004115.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-004115.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-004115.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-004115.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFVtU6M0uN9YvkhEovs4Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-137</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003918.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003918.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003918.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003918.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003918.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFVSiJH4s89szrtwA60BQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-136</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003755.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003755.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003755.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003755.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003755.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFU_Yacco_s5Anfvc0bJO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-135</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003525.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003525.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003525.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003525.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003525.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFUcs1NolEA7-l-6w18ig" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-134</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003323.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003323.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003323.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003323.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003323.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFUCbb35XBX5NkPDyjsYA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-133</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003158.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003158.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003158.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003158.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-003158.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFTv0_NYI5sVV3z0fnXUs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-132</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003048.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003048.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003048.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003048.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-003048.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFTgAsVZILJYhKg4u81en" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-131</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002651.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002651.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002651.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002651.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002651.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFSlx0QfvFo5A-vXtHCoY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-130</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002441.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002441.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002441.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002441.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002441.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFSHyM04wCn2w0tur4dIC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-129</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002214.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002214.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002214.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002214.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-002214.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFRmIcS1GaiRbxBDeBSBa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-128</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-001945.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-001945.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-001945.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-001945.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221018-001945.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFRDjsSKBHEiqJQT7x8of" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-127</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001648.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001648.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001648.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001648.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001648.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFQbi9LYyk_p3rDMo0Ej6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-126</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001430.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001430.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001430.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001430.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001430.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFP6Vq8Gead43RDc-vxsn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-125</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001318.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001318.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001318.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001318.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221018-001318.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFPqgoKkxeZIssyshKGvN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-124</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-235203.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-235203.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-235203.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-235203.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-235203.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFLD57hQzlh1xtVMAaq9Z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-123</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234530.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234530.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234530.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234530.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234530.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFJrbUmVeVvoYUmAEyEYa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-122</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-234154.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-234154.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-234154.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-234154.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-234154.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFI5GrKZR01VEYvWLe3sA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-121</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234021.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234021.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234021.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234021.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-234021.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFIgXSIN4LOJb5wMKrqC4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-120</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233852.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233852.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233852.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233852.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233852.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFIKdWaQewQyBYa2ALn2c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-119</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233444.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233444.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233444.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233444.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-233444.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFHNnPN1xnIBq748YF2F8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-118</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232912.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232912.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232912.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232912.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232912.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFF8XMSnFt3eb5httWwFW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-117</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232611.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232611.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232611.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232611.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232611.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFFTaYj8zhtfd_TmoMFWm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-116</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232427.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232427.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232427.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232427.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-232427.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFE5yK5s1J_swqAnopfyH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-115</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232134.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232134.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232134.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232134.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-232134.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFESJ0QOVV6o8j53Lbb0j" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-114</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-231851.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-231851.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-231851.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-231851.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-231851.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFDoTCpPLB-6Wjqpd_wnY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-113</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231522.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231522.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231522.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231522.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231522.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFC3ES4jAz7mn8wfurqeb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-112</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231336.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231336.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231336.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231336.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-231336.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFCc-ETq6LBzrwSEjd6Ko" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-111</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230944.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230944.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230944.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230944.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230944.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbFBkW0_nPAlExnt8Lh9X4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-110</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-230509.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-230509.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-230509.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-230509.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-230509.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbFAhpsevGFVEQ-10-t1rX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-109</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230207.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230207.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230207.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230207.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-230207.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbE_0D2c33VE6hUda-yASr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-108</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225813.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225813.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225813.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225813.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225813.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE-5X4iMdeiYIwUh6br9F" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-107</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225558.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225558.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225558.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225558.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225558.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE-V7OrYNvHhyIKDrnbsT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-106</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-225219.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-225219.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-225219.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-225219.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-225219.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIbE9fek0R2ogGmmhhh92Wa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-105</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225027.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225027.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225027.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225027.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-225027.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE9C1m1h-FJ8TlNJfLdO0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-104</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224831.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224831.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224831.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224831.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224831.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE8lj0TO0n3MZKVH71wyL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-103</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224607.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224607.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224607.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224607.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224607.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE7_8fE0V-hX1aqdCbGWB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-102</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224354.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224354.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224354.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224354.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224354.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE7bclyLrDLxQ-I-Zo7Eg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-101</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224135.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224135.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224135.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224135.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224135.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE61Q00FeToo8I2xdMIb5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-100</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224005.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224005.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224005.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224005.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-224005.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE6d2wbMN-wBPK9CM33hn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-099</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223816.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223816.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223816.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223816.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223816.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE6CS8EZkaGS3_kpAo1-c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-098</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223616.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223616.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223616.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223616.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-223616.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIbE5f1ZQR-4uvT4IMU3fZa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-097</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005735.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005735.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005735.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005735.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005735.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa91MGQ4os4kqgUliTMfBR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-096</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005626.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005626.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005626.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005626.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005626.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa90aAsF36e2JkXdT4jo19" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-095</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005222.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005222.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005222.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005222.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005222.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9zNvtSdGpIUOvLPhAaWA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-094</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005118.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005118.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005118.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005118.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-005118.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9y4XGySFQZwmi5aIY855" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-093</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005006.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005006.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005006.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005006.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-005006.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9yh7xXEHv9pWFZV4Cisv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-092</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004853.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004853.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004853.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004853.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004853.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9yLNdnb7qPXvDvMxvSMJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-091</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004730.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004730.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004730.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004730.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004730.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9xyABbzl9_nfKV64d9bL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-090</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-004607.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-004607.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-004607.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-004607.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-004607.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9xXKioyURA1Gr-_NguV-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-089</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004507.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004507.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004507.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004507.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004507.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9xEJlAw5BJG4SSW-ccH2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-088</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004313.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004313.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004313.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004313.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004313.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9wh7xSkPclRHSt_XT8wk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-087</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004141.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004141.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004141.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004141.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-004141.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9wE9I45ELxMtJqQD4Ivx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-086</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003718.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003718.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003718.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003718.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003718.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9u0qtr2jpLNNPTSQEPo3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-085</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003555.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003555.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003555.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003555.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-003555.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9ua2vE0DaGSRZB-cwGMB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-084</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003410.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003410.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003410.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003410.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003410.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9t5zW-AXIzHwi_zMi0zD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-083</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003201.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003201.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003201.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003201.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-003201.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9tSedHcOrRxH21tvo7W-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-082</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002939.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002939.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002939.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002939.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002939.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9sm0Gdgs1PaLK_7zWbv5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-081</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002812.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002812.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002812.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002812.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002812.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9sMNhLEAs4BsdBxqcNV2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-080</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-002532.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-002532.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-002532.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-002532.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-002532.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9rafk0MBhwA0sicQDnYE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-079</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002300.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002300.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002300.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002300.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002300.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9qtCzjStn3lcHAr3RUbe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-078</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002126.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002126.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002126.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002126.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002126.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9qNGtkJXJ1nEq8MMXu6_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-077</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002013.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002013.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002013.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002013.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-002013.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9p3_4G2yvNS1xeoXdVOE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-076</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001913.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001913.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001913.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001913.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001913.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9plfKVUfQeRoKbLa6Fpt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-075</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001805.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001805.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001805.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001805.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001805.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9pRRS95laKiExFX1WIsM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-074</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001626.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001626.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001626.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001626.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001626.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9oy00QBjaxUy0ZbRWYzw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-073</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001517.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001517.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001517.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001517.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001517.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9ocF9av8-sg3H4I35w7v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-072</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001355.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001355.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001355.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001355.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001355.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9oBKdd8wah85Jfslb9yf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-071</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001158.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001158.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001158.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001158.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-001158.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9neWpl_UQrB-XszIf3mc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-070</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001049.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001049.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001049.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001049.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-001049.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9nIt5q4fRw2E6wn5q1L6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-069</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000920.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000920.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000920.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000920.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000920.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9mupE128PQkd6Q5whEUG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-068</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000807.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000807.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000807.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000807.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221017-000807.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9mYio5DIkCh_3VsqitYE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-067</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000546.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000546.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000546.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000546.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000546.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9lvyBWUTFUumrZkFEmtJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-066</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000437.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000437.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000437.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000437.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000437.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9laY7Ouvaq2vbw3Di8iD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-065</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000321.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000321.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000321.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000321.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000321.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9lELPDl14zD0o1yzuLTj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-064</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000206.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000206.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000206.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000206.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000206.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9krlwCzul2382f0zVniV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-063</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000050.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000050.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000050.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000050.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221017-000050.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9kUkPz01zg5CIqR38gY7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-062</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-235906.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-235906.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-235906.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-235906.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-235906.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9jzMp6Qyipp8lMXmE8pe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-061</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235713.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235713.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235713.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235713.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235713.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9jQ6EQ4JB5_mjdWr4jfx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-060</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235528.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235528.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235528.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235528.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-235528.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9iwy3xe8zyUO2jv35Ob_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-059</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-234307.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-234307.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-234307.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-234307.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-234307.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9fKKGuAxzcGVNzoGZ0Sw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-058</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-234133.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-234133.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-234133.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-234133.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-234133.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9erUoipj6IsahoefdexV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-057</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233941.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233941.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233941.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233941.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233941.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9eGX-LKtzT5oGAWJHG53" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-056</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233814.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233814.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233814.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233814.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233814.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9doQD5_OhkbyrD_yNMv1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-055</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-233709.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-233709.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-233709.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-233709.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-233709.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9dTRFU0XXMoK48LCf6NJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-054</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233611.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233611.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233611.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233611.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233611.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9dAoiI3G1Plrx8enTpRl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-053</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233432.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233432.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233432.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233432.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-233432.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9chCW-hXX6VouWFxsYpe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-052</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232338.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232338.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232338.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232338.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232338.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9ZGSYcCJHPDINmy0YvS-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-051</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232147.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232147.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232147.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232147.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232147.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9Yhx7MfG9fTb2eBxHS9j" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-050</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232025.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232025.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232025.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232025.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-232025.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9YE9ihOnsVupd2SlPRaq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-049</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-231834.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-231834.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-231834.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-231834.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-231834.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9Xe9B9auCude6TRN59m5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-048</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-230856.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-230856.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-230856.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-230856.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-230856.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9UVRSHg873UyFGEubkPZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-047</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230726.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230726.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230726.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230726.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230726.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9T27jSv-5HKnkTw7PWeb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-046</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230540.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230540.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230540.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230540.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-230540.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9TSZg5SQHDsssBTuhMp2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-045</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221658.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221658.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221658.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221658.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221658.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9DEH7d3kfjt3BvJRxnIc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-044</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-221230.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-221230.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-221230.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-221230.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-221230.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa9COnbmwI0bVF9Wm4PciF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-043</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221120.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221120.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221120.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221120.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-221120.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9BJEqKXgUB0iyD45ch-6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-042</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220937.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220937.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220937.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220937.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220937.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9Ai9XPX3zAwuaBTMwIi-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-041</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220823.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220823.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220823.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220823.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220823.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa9AGQy-E75YkAYGKLAbrj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-040</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220702.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220702.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220702.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220702.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220702.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8_odEFyY-9J-FdfBWFz_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-039</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220450.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220450.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220450.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220450.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220450.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8-08CLjsSGmg0Iq7PIJV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-038</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220304.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220304.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220304.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220304.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-220304.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8-MOQ7m8Sr8-BvTy_s3E" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-037</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220137.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220137.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220137.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220137.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220137.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa89p33z8Q_J27bboVGjVk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-036</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220041.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220041.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220041.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220041.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-220041.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa89V1UfB3tn9DiLIwMw5L" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-035</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215910.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215910.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215910.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215910.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215910.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa88zVTyBG-fjIMYxRqbW3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-034</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215755.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215755.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215755.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215755.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215755.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa88W9oJHzDjNlyVX7kVm3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-033</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215620.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215620.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215620.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215620.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215620.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa87zQBMrzh7Zo4t_8tjwm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-032</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215425.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215425.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215425.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215425.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215425.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa87HXceGkkOWMYUUs9VnP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-031</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215323.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215323.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215323.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215323.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-215323.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa86vfFKB2nZaPHCqh3owL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-030</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215158.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215158.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215158.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215158.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215158.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa86PHjkpRTZDJxzd3DMMH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-029</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215017.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215017.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215017.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215017.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-215017.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa85nIy2iNj7BrBVKmdCkd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-028</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214756.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214756.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214756.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214756.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214756.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa84qUF8Bzr5X6jGgZQdqC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-027</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214435.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214435.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214435.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214435.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214435.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa83YsGlaSnYYbc48SAPyz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-026</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214236.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214236.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214236.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214236.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-214236.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa82tTCgE24Qpai8D_FJyk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-025</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213924.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213924.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213924.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213924.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213924.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa81b_9gTv25RP6yGn6yU3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-024</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213731.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213731.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213731.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213731.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213731.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa80uil8hEBCUnPSBa3f4p" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-023</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213626.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213626.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213626.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213626.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213626.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa80TGesDErLIXf-1XaF5e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-022</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213433.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213433.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213433.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213433.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213433.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8zkfjEuvXGoUrh58eFtG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-021</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213335.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213335.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213335.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213335.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213335.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8zNy4ZuDxDbf-spYJzEH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-020</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213156.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213156.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213156.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213156.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213156.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8ynG1_d2BiM4-c8zt1BC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-019</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213019.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213019.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213019.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213019.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-213019.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8yBGv5vrtXflZX_bdHpV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-018</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212915.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212915.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212915.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212915.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212915.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8xnbNCWB0QpI1UkJRbtK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-017</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212738.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212738.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212738.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212738.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212738.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8w_EPBKDQpEjLU3BuC9T" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-016</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212600.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212600.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212600.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212600.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212600.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8wWG5IFN6HT8rh0tz1dx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-015</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212423.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212423.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212423.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212423.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212423.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8vuWWh_UN57tol9QjvkO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-014</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212256.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212256.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212256.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212256.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212256.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8vKrv45Ck0Fr-kIFXuyW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-013</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212056.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212056.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212056.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212056.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-212056.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8uXgGdqRIKBKNRbIVw2n" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-012</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211927.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211927.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211927.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211927.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211927.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8tw8Dp_YuZnX-VEpnr9k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-011</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211758.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211758.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211758.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211758.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211758.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8tMSNMLBWlF4EeS-XxBV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211611.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211611.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211611.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211611.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-211611.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8sc9L5q6a51Ns0TglxpQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-211119.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-211119.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-211119.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-211119.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-211119.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa8rRQLI_Y7ZS17Aws1_sx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210857.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210857.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210857.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210857.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210857.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8pqk6qJ6-HfM9bSyvoLk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210747.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210747.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210747.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210747.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210747.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8pLdIWxk_xXIrrYOq_Qv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210549.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210549.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210549.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210549.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210549.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa8oYf7m91TRd4xYoB3rRT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210341.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210341.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210341.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210341.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210341.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8niVKkKfYzRkb_inSdva" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2022-10-15" data-title="Townhall 14 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210143.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210143.jpg 320w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210143.jpg 640w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210143.jpg 1920w, https://townhall-14.clashbases.de/14_War/Townhall-14-Base-Layouts-20221016-210143.jpg 2430w"
                          title="Townhall 14 War Base" alt="Townhall 14 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AWB%3AAAAAGQAAAAIa8mw72apNWBAcjHOKfn5s" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">War</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210027.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210027.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210027.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210027.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-210027.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa8mPM-Hh4RGlXGRdhJAS_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2022-10-15" data-title="Townhall 14 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-205914.jpg"
                          srcSet="https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-205914.jpg 320w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-205914.jpg 640w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-205914.jpg 1920w, https://townhall-14.clashbases.de/14_Farm/Townhall-14-Base-Layouts-20221016-205914.jpg 2430w"
                          title="Townhall 14 Farm Base" alt="Townhall 14 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH14%3AHV%3AAAAAGQAAAAIa8lvFmKi3wdARoE5UbBV7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">14-001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">Farm</p>
                      <p className="index-number font-size-picture-descriptions yellow">New & Updated</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2022-10-10" data-title="Townhall 14 | The Archive">
                <a href="https://ClashBases.de/Archive-Townhall-14" target="_blank" rel="noopener noreferrer">
                  <div className="picture-item__inner townhall-showcase-13">
                    <div className="aspect aspect--16x9">
                      <div className="aspect__inner">
                        <LazyLoad height={200} offset={100} once>
                          <img
                            src="https://assets.clashbases.de/Backgrounds/02Archive_1024w.png"
                            srcSet="https://assets.clashbases.de/Backgrounds/02Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/02Archive_1024w.png 1024w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 1920w, https://assets.clashbases.de/Backgrounds/02Archive_1600w.png 2430w"
                            title="Townhall 14 | The Archive" alt="Townhall 14 | The Archive" />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="picture-item__details">
                      <figcaption className="picture-item__title"><div className="link-color font-size-picture-descriptions">Visit the ClashBases archive</div><p className="index-number font-size-picture-descriptions">for all old TH 14 Base Layouts</p></figcaption>
                    </div>
                  </div>
                </a>
              </figure>

            <div className="col-1@sm col-1@xs my-sizer-element"></div>
          </div>
        </div>



        <div className="homebase-paragraph-container">

          <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
            <div className="discord-container">
              <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
              <div className="discord-message-container">
                <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
              </div>
            </div>
          </a>

          <h1 className="homebase-paragraph-title">All the best Townhall 14 Base Layouts with Links<br /></h1>
          <p className="homebase-paragraph">
            Browse through our new collection of clash of clans townhall 14 base layouts with links!<br />
            War base, Trophy base, farm base or just a casual base for aesthetics, we got them all. Find your favorite th 14 base build and import it directly into your game. <br /><br /><br />
            We will keep adding to this collection of th 14 base layouts with links over the next couple of weeks, so make sure to keep on checking in!<br /><br />

            You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
          </p>
        </div>



        </div>

      </section>

    );
  };
};

export default Townhallfourteen;
