import React, { Component } from 'react';
import './TownhallSelection.scss';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";


class Townhallselection extends Component {

  render() {
    return (

      <section className="selection-about-page">


          <Helmet>
            <meta charSet="utf-8" />
            <title>Townhall Selection</title>
            <meta name="description" content="1500+ Base Layouts and Links for Townhalls 9, 10, 11, 12 and 13!" />
            <meta name="keywords" content="th 9, ths 9, th ten, townhall 9, townhall 9 base, th 9 links, links, th 9 base design, th 9 bases, th 9 warbase, warbase, base for war, clan war base, clanwar base th 9, war base th 9, townhall 9 war base, townhall 9 base for war, th 9 trophybase, trophybase, base for trophys, trophy base th 9, townhall 9 trophy base, townhall 9 base for trophy, th 9 pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base th 9, townhall 9 push base, townhall 9 base for pushing, th 9 farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base th 9, townhall 9 farm base, townhall 9 base for farming, troll bases, th 9 troll base, th 9 fun base, th 9 funny base, th 9 trolling, funny th 9 base, best th 9 base, anti bats, anti pekkasmash, anti laloon, anti witches, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, th 9 island base, ring base, th 9 ring base, teaser, th 9 teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, shared base base, link, th 11 links, th 12 base link, th 11 link, war base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="selection-about-page-container" id="back-to-top">

            <div className="selection-container-outer">
              <div className="selection-container-middle">
                <div className="selection-container-inner">

                  <div className="selection-container-title">
                    <h1 className="selection-title">Townhall Selection</h1>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-15"><button className="get-started-townhalls get-started-townhalls-15">Townhall 15</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-14"><button className="get-started-townhalls get-started-townhalls-14">Townhall 14</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-13"><button className="get-started-townhalls get-started-townhalls-13">Townhall 13</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-12"><button className="get-started-townhalls get-started-townhalls-12">Townhall 12</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-11"><button className="get-started-townhalls get-started-townhalls-11">Townhall 11</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-10"><button className="get-started-townhalls get-started-townhalls-10">Townhall 10</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-9"><button className="get-started-townhalls get-started-townhalls-9">Townhall 9</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-8"><button className="get-started-townhalls get-started-townhalls-8">Townhall 8</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-7"><button className="get-started-townhalls get-started-townhalls-7">Townhall 7</button></Link>
                  </div>

                  <div className="get-started-containers">
                    <Link to="/Townhall-6"><button className="get-started-townhalls get-started-townhalls-6">Townhall 6</button></Link>
                  </div>
                  <div className="get-started-containers">
                    <Link to="/BuilderBase"><button className="get-started-townhalls">Builder Hall</button></Link>
                  </div>
                  <div className="get-started-containers">
                    <Link to="/Just-For-Fun"><button className="get-started-townhalls">Fun Bases</button></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </section>

    );
  };
};

export default Townhallselection;
