import React from 'react';
import './About.css';

const aboutme = props => (
  <section className="aboutMe-page">


          <div className="aboutMe-container-inner-c">
            <h1 className="aboutMe-w-title">Who am I?</h1>

            <p className="aboutMe-w-text">
              Just a guy taking it one day at a time. Originally from Greece, currently a student living in Germany. Hobby programmer and avid clash enthusiast with the goal:<br /><br /> To bring you the best damn base collection out there!
            </p>

            <div className="avatar-pic"></div>

            <h1 className="aboutMe-w-title">Disclaimer</h1>
            <p className="aboutMe-w-text">
            The content on this website is not affiliated with, endorsed, sponsored, or specifically approved by Supercell and Supercell is not responsible for it. For more information see Supercell's Fan Content Policy:< br/>< br/> <a className="privacy-link orange-text" href="https://supercell.com/en/fan-content-policy/" target="_blank" rel="noopener noreferrer">www.supercell.com/fan-content-policy</a>.<br />
            </p>

          </div>

  </section>
);

export default aboutme;
