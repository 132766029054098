import React from 'react';
import './../Wiki.css';
import { Link as LinkToAnchor } from "react-scroll";
import Collapsible from 'react-collapsible';
import LazyLoad from 'react-lazyload';
import './carousel.css';
import { Carousel } from 'react-responsive-carousel';
import Ad from "../../Ad/ad";


// TROOPS - ELIXIR TROOPS

const TriggerBattleMachine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon battlemachine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Battle Machine</span></div>
</div>;
const TriggerRagedBarbarian = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-ragedbarbarian"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Raged Barbarian</span></div>
</div>;
const TriggerSneakyArcher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-sneakyarcher"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Sneaky Archer</span></div>
</div>;
const TriggerBoxerGiant = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-boxergiant"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Boxer Giant</span></div>
</div>;
const TriggerBetaMinion = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-betaminion"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Beta Minion</span></div>
</div>;
const TriggerBomber = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-bomber"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Bomber</span></div>
</div>;
const TriggerBabyDragon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-babydragon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Baby Dragon</span></div>
</div>;
const TriggerCannonCart = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-cannoncart"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Cannon Cart</span></div>
</div>;
const TriggerNightWitch = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-nightwitch"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Night Witch</span></div>
</div>;
const TriggerDropShip = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-dropship"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Drop Ship</span></div>
</div>;
const TriggerSuperPekka = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-superpekka"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Super P.E.K.K.A</span></div>
</div>;
const TriggerHogGlider = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-hogglider"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Hog Glider</span></div>
</div>;




const Buildertroops  = props => (

      <section className="homebasewiki-b">

        <div className="hometext-container">
          <div className="hometext-info-container">
            <div className="hometext-about">

            </div>
          </div>
        </div>

        <div className="homebasewiki-container">

            <div className="homebasewiki-buildings-container">

                <div className="basetroopsbackground">
                  <h1 className="homebasewiki-buildings-maintitel" id="B-Troops">Troops</h1>
                </div>

                <h2 className="homebasewiki-buildings-titel" id="BuilderTroops">Builder Troops</h2>
                {/* NAVBAR - TROOPS - ELIXIR */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="BuilderTroops" smooth={true} offset={-70}>Troops</LinkToAnchor></li>
                    </div>
                </nav>

                {/* Battle Machine */}
                <Collapsible trigger={<TriggerBattleMachine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-Ruin.png'/><p className="legend">Ruins</p></div>
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv01-09.png'/><p className="legend">Level 1-9</p></div>
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv10-19.png'/><p className="legend">Level 10-19</p></div>
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv20-25.png'/><p className="legend">Level 20-25</p></div>
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv26-29.png'/><p className="legend">Level 26-29</p></div>
                    <div><img alt='Battle Machine' src='https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv30.png'/><p className="legend">Level 30</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Ability</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>5</td><td>3600</td><td>125</td><td>-</td><td>900 k</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>3672</td><td>127</td><td>-</td><td>1 m</td><td>12 h</td></tr>
                        <tr><td>3</td><td>5</td><td>3745</td><td>130</td><td>-</td><td>1,1 m</td><td>12 h</td></tr>
                        <tr><td>4</td><td>5</td><td>3820</td><td>132</td><td>-</td><td>1,2 m</td><td>12 h</td></tr>
                        <tr><td>5</td><td>5</td><td>3896</td><td>135</td><td>1</td><td>1,3 m</td><td>1 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>6</td><td>6</td><td>3954</td><td>137</td><td>1</td><td>1,5 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>6</td><td>4013</td><td>140</td><td>1</td><td>1,6 m</td><td>1 d</td></tr>
                        <tr><td>8</td><td>6</td><td>4073</td><td>142</td><td>1</td><td>1,7 m</td><td>1 d</td></tr>
                        <tr><td>9</td><td>6</td><td>4134</td><td>145</td><td>1</td><td>1,8 m</td><td>1 d</td></tr>
                        <tr><td>10</td><td>6</td><td>4196</td><td>147</td><td>2</td><td>1,9 m</td><td>1 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>11</td><td>7</td><td>4238</td><td>150</td><td>2</td><td>2,1 m</td><td>1 d</td></tr>
                        <tr><td>12</td><td>7</td><td>4280</td><td>154</td><td>2</td><td>2,2 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>4323</td><td>157</td><td>2</td><td>2,3 m</td><td>2 d</td></tr>
                        <tr><td>14</td><td>7</td><td>4366</td><td>160</td><td>2</td><td>2,4 m</td><td>2 d</td></tr>
                        <tr><td>15</td><td>7</td><td>4410</td><td>164</td><td>3</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>16</td><td>7</td><td>4454</td><td>167</td><td>3</td><td>2,6 m</td><td>2 d</td></tr>
                        <tr><td>17</td><td>7</td><td>4499</td><td>170</td><td>3</td><td>2,7 m</td><td>3 d</td></tr>
                        <tr><td>18</td><td>7</td><td>4544</td><td>174</td><td>3</td><td>2,8 m</td><td>3 d</td></tr>
                        <tr><td>19</td><td>7</td><td>4589</td><td>177</td><td>3</td><td>2,9 m</td><td>3 d</td></tr>
                        <tr><td>20</td><td>7</td><td>4635</td><td>180</td><td>4</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>21</td><td>8</td><td>4681</td><td>186</td><td>4</td><td>3,1 m</td><td>3 d</td></tr>
                        <tr><td>22</td><td>8</td><td>4728</td><td>192</td><td>4</td><td>3,2 m</td><td>3 d</td></tr>
                        <tr><td>23</td><td>8</td><td>4775</td><td>198</td><td>4</td><td>3,3 m</td><td>3 d</td></tr>
                        <tr><td>24</td><td>8</td><td>4823</td><td>204</td><td>4</td><td>3,4 m</td><td>3 d</td></tr>
                        <tr><td>25</td><td>8</td><td>4871</td><td>210</td><td>5</td><td>3,5 m</td><td>3 d</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>26</td><td>9</td><td>4920</td><td>218</td><td>5</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>27</td><td>9</td><td>4970</td><td>226</td><td>5</td><td>3,7 m</td><td>4 d</td></tr>
                        <tr><td>28</td><td>9</td><td>5020</td><td>234</td><td>5</td><td>3,8 m</td><td>4 d</td></tr>
                        <tr><td>29</td><td>9</td><td>5071</td><td>242</td><td>5</td><td>3,9 m</td><td>4 d</td></tr>
                        <tr><td>30</td><td>9</td><td>5122</td><td>250</td><td>6</td><td>4 m</td><td>4 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="2">Electric Hammer (ability)</th></tr>
                        <tr><td className="orange-font">Ability Cooldown</td><td className="orange-font">Speed Increase</td></tr>
                        <tr><td>14 s</td><td>4</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="6">Electric Hammer Stats</th></tr>
                        <tr><td className="orange-font">Ability</td><td className="orange-font">Damage</td><td className="orange-font">Health</td></tr>
                        <tr><td>Level</td><td>Increase</td><td>Recovery</td></tr>
                        <tr><td>1</td><td>270</td><td>800</td></tr>
                        <tr><td>2</td><td>294</td><td>850</td></tr>
                        <tr><td>3</td><td>328</td><td>900</td></tr>
                        <tr><td>4</td><td>360</td><td>950</td></tr>
                        <tr><td>5</td><td>400</td><td>1000</td></tr>
                        <tr><td>6</td><td>450</td><td>1050</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Raged Barbarian */}
                <Collapsible trigger={<TriggerRagedBarbarian />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Raged Barbarian' src='https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Raged Barbarian' src='https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Raged Barbarian' src='https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Raged Barbarian' src='https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Raged Barbarian' src='https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Units</td><td className="orange-font">Rage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(per camp)</td><td>(duration)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>150</td><td>50</td><td>4</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>150</td><td>50</td><td>4</td><td>4 s</td><td>3,5 k</td><td>30 s</td></tr>
                        <tr><td>3</td><td>2</td><td>165</td><td>55</td><td>4</td><td>4 s</td><td>6 k</td><td>5 m</td></tr>
                        <tr><td>4</td><td>2</td><td>165</td><td>55</td><td>6</td><td>4 s</td><td>9 k</td><td>15 m</td></tr>
                        <tr><td>5</td><td>3</td><td>182</td><td>61</td><td>6</td><td>4 s</td><td>50 k</td><td>3 h</td></tr>
                        <tr><td>6</td><td>3</td><td>182</td><td>61</td><td>10</td><td>4 s</td><td>100 k</td><td>6 h</td></tr>
                        <tr><td>7</td><td>4</td><td>200</td><td>67</td><td>10</td><td>4 s</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>200</td><td>67</td><td>10</td><td>8 s</td><td>330 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>220</td><td>74</td><td>10</td><td>8 s</td><td>700 k</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>220</td><td>74</td><td>15</td><td>8 s</td><td>900 k</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>242</td><td>81</td><td>15</td><td>8 s</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>242</td><td>81</td><td>15</td><td>12 s</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>266</td><td>89</td><td>15</td><td>12 s</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>266</td><td>89</td><td>20</td><td>12 s</td><td>2,2 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>293</td><td>98</td><td>20</td><td>12 s</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>293</td><td>98</td><td>20</td><td>16 s</td><td>3,2 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>322</td><td>108</td><td>20</td><td>16 s</td><td>3,8 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>322</td><td>108</td><td>20</td><td>20 s</td><td>4 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Sneaky Archer */}
                <Collapsible trigger={<TriggerSneakyArcher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Sneaky Archer' src='https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Sneaky Archer' src='https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Sneaky Archer' src='https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Sneaky Archer' src='https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Sneaky Archer' src='https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Units</td><td className="orange-font">Cloak</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(per camp)</td><td>(duration)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>100</td><td>36</td><td>4</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1</td><td>100</td><td>36</td><td>4</td><td>3 s</td><td>5 k</td><td>3 m</td></tr>
                        <tr><td>3</td><td>2</td><td>110</td><td>40</td><td>4</td><td>3 s</td><td>8 k</td><td>10 m</td></tr>
                        <tr><td>4</td><td>2</td><td>110</td><td>40</td><td>6</td><td>3 s</td><td>12 k</td><td>30 m</td></tr>
                        <tr><td>5</td><td>3</td><td>121</td><td>44</td><td>6</td><td>3 s</td><td>60 k</td><td>4 h</td></tr>
                        <tr><td>6</td><td>3</td><td>121</td><td>44</td><td>6</td><td>5 s</td><td>120 k</td><td>6 h</td></tr>
                        <tr><td>7</td><td>4</td><td>133</td><td>48</td><td>6</td><td>5 s</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>133</td><td>48</td><td>6</td><td>7 s</td><td>350 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>146</td><td>53</td><td>6</td><td>7 s</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>146</td><td>53</td><td>8</td><td>7 s</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>161</td><td>58</td><td>8</td><td>7 s</td><td>1,1 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>161</td><td>58</td><td>8</td><td>8 s</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>177</td><td>64</td><td>8</td><td>8 s</td><td>2,1 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>177</td><td>64</td><td>10</td><td>8 s</td><td>2,3 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>195</td><td>70</td><td>10</td><td>8 s</td><td>3,1 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>195</td><td>70</td><td>12</td><td>8 s</td><td>3,3 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>215</td><td>77</td><td>12</td><td>8 s</td><td>3,9 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>215</td><td>77</td><td>14</td><td>8 s</td><td>4,1 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Boxer Giant */}
                <Collapsible trigger={<TriggerBoxerGiant />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Boxer Giant' src='https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Boxer Giant' src='https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Boxer Giant' src='https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Boxer Giant' src='https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Boxer Giant' src='https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Dmg</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(power punch)</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>1200</td><td>36</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>1200</td><td>36</td><td>180</td><td>2</td><td>20 k</td><td>30 m</td></tr>
                        <tr><td>3</td><td>3</td><td>1320</td><td>40</td><td>200</td><td>2</td><td>40 k</td><td>1 h</td></tr>
                        <tr><td>4</td><td>3</td><td>1520</td><td>40</td><td>200</td><td>2</td><td>60 k</td><td>2 h</td></tr>
                        <tr><td>5</td><td>4</td><td>1652</td><td>44</td><td>220</td><td>2</td><td>300 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>4</td><td>1652</td><td>44</td><td>264</td><td>2</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>4</td><td>1797</td><td>48</td><td>288</td><td>2</td><td>340 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>1797</td><td>48</td><td>288</td><td>3</td><td>380 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>1957</td><td>53</td><td>318</td><td>3</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>1957</td><td>53</td><td>424</td><td>3</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>2133</td><td>58</td><td>464</td><td>3</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>2383</td><td>58</td><td>464</td><td>3</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>2576</td><td>64</td><td>512</td><td>3</td><td>2,3 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>2576</td><td>64</td><td>640</td><td>3</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>2789</td><td>70</td><td>700</td><td>3</td><td>3,3 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>2789</td><td>70</td><td>700</td><td>4</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>3068</td><td>77</td><td>770</td><td>4</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>3375</td><td>85</td><td>850</td><td>4</td><td>4,2 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Beta Minion */}
                <Collapsible trigger={<TriggerBetaMinion />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Beta Minion' src='https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Beta Minion' src='https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Beta Minion' src='https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Beta Minion' src='https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Beta Minion' src='https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Num</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(long shots)</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>100</td><td>36</td><td>-</td><td>4</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>100</td><td>36</td><td>2</td><td>4</td><td>50 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>3</td><td>110</td><td>40</td><td>2</td><td>4</td><td>80 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>3</td><td>110</td><td>40</td><td>3</td><td>4</td><td>120 k</td><td>5 h</td></tr>
                        <tr><td>5</td><td>4</td><td>121</td><td>44</td><td>3</td><td>4</td><td>250 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>4</td><td>121</td><td>44</td><td>3</td><td>6</td><td>280 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>4</td><td>133</td><td>48</td><td>3</td><td>6</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>133</td><td>48</td><td>4</td><td>6</td><td>360 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>146</td><td>53</td><td>4</td><td>6</td><td>9 k</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>146</td><td>53</td><td>4</td><td>8</td><td>1,1 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>161</td><td>58</td><td>4</td><td>8</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>161</td><td>58</td><td>4</td><td>10</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>177</td><td>64</td><td>4</td><td>10</td><td>2,3 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>177</td><td>64</td><td>5</td><td>10</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>195</td><td>70</td><td>5</td><td>10</td><td>3,3 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>195</td><td>70</td><td>6</td><td>10</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>215</td><td>77</td><td>6</td><td>10</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>215</td><td>77</td><td>6</td><td>12</td><td>4,2 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Bomber */}
                <Collapsible trigger={<TriggerBomber />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv09-10.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv11-12.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv13-16.png'/><p className="legend">Level 17-18</p></div>
                        <div><img alt='Bomber' src='https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td colspan="2" className="orange-font">Dmg (Walls)</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>normal</td><td>big boy</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>100</td><td>36 (1080)</td><td>-</td><td>4</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>100</td><td>36 (1080)</td><td>144 (2160)</td><td>4</td><td>50 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>3</td><td>110</td><td>40 (1200)</td><td>160 (2400)</td><td>4</td><td>80 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>3</td><td>110</td><td>40 (1200)</td><td>160 (2400)</td><td>4</td><td>120 k</td><td>5 h</td></tr>
                        <tr><td>5</td><td>4</td><td>121</td><td>44 (1320)</td><td>176 (2640)</td><td>4</td><td>250 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>4</td><td>121</td><td>44 (1320)</td><td>220 (3200)</td><td>6</td><td>280 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>4</td><td>133</td><td>48 (1440)</td><td>240 (3600)</td><td>6</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>133</td><td>48 (1440)</td><td>288 (4320)</td><td>6</td><td>360 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>146</td><td>53 (1590)</td><td>318 (4770)</td><td>6</td><td>9 k</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>146</td><td>53 (1590)</td><td>318 (4770)</td><td>8</td><td>1,1 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>161</td><td>58 (1740)</td><td>348 (5220)</td><td>8</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>161</td><td>58 (1740)</td><td>406 (6090)</td><td>10</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>177</td><td>64 (1920)</td><td>448 (6720)</td><td>10</td><td>2,3 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>177</td><td>64 (1920)</td><td>512 (7680)</td><td>10</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>195</td><td>70 (2100)</td><td>560 (8400)</td><td>10</td><td>3,3 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>195</td><td>70 (2100)</td><td>560 (8400)</td><td>10</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>215</td><td>77 (2310)</td><td>616 (9240)</td><td>10</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>215</td><td>77 (2310)</td><td>693 (10395)</td><td>12</td><td>4,2 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Baby Dragon */}
                <Collapsible trigger={<TriggerBabyDragon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Baby Dragon' src='https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv01-08.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Baby Dragon' src='https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv09-11.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Baby Dragon' src='https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv12-14.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Baby Dragon' src='https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv15-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Baby Dragon' src='https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Num</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>Tantrum</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>645</td><td>40</td><td>-</td><td>2</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>3</td><td>645</td><td>40</td><td>56</td><td>2</td><td>200 k</td><td>5 h</td></tr>
                        <tr><td>3</td><td>4</td><td>710</td><td>44</td><td>61,6</td><td>2</td><td>240 k</td><td>8 h</td></tr>
                        <tr><td>4</td><td>4</td><td>710</td><td>44</td><td>70,4</td><td>2</td><td>280 k</td><td>12 h</td></tr>
                        <tr><td>5</td><td>4</td><td>781</td><td>48</td><td>76,8</td><td>2</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>6</td><td>4</td><td>781</td><td>48</td><td>86,4</td><td>2</td><td>360 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>4</td><td>859</td><td>53</td><td>95,4</td><td>2</td><td>380 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>4</td><td>859</td><td>53</td><td>106</td><td>2</td><td>400 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>5</td><td>950</td><td>58</td><td>116</td><td>2</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>950</td><td>58</td><td>116</td><td>3</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>1045</td><td>64</td><td>128</td><td>3</td><td>1,4 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>1045</td><td>64</td><td>140,8</td><td>3</td><td>1,6 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>1150</td><td>70</td><td>154</td><td>3</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>1150</td><td>70</td><td>168</td><td>3</td><td>2,6 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>1265</td><td>77</td><td>184,8</td><td>3</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>1265</td><td>77</td><td>200,2</td><td>3</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>1392</td><td>85</td><td>221</td><td>3</td><td>4,1 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>1392</td><td>85</td><td>221</td><td>4</td><td>4,3 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Cannon Cart */}
                <Collapsible trigger={<TriggerCannonCart />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Cannon Cart' src='https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Cannon Cart' src='https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Cannon Cart' src='https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Cannon Cart' src='https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Cannon Cart' src='https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td colspan="2" className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>Last Stand</td><td>-</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>200</td><td>-</td><td>85</td><td>2</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>4</td><td>200</td><td>100</td><td>85</td><td>2</td><td>400 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>5</td><td>220</td><td>100</td><td>94</td><td>2</td><td>500 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>5</td><td>220</td><td>150</td><td>94</td><td>2</td><td>600 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>5</td><td>242</td><td>150</td><td>103</td><td>2</td><td>700 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>5</td><td>242</td><td>150</td><td>120</td><td>2</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>7</td><td>5</td><td>266</td><td>150</td><td>130</td><td>2</td><td>900 k</td><td>1 d</td></tr>
                        <tr><td>8</td><td>5</td><td>266</td><td>200</td><td>130</td><td>2</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>9</td><td>5</td><td>293</td><td>200</td><td>141</td><td>2</td><td>1,1 m</td><td>1 d</td></tr>
                        <tr><td>10</td><td>5</td><td>293</td><td>200</td><td>141</td><td>3</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>6</td><td>322</td><td>200</td><td>153</td><td>3</td><td>1,4 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>322</td><td>300</td><td>153</td><td>3</td><td>1,6 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>354</td><td>300</td><td>167</td><td>3</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>354</td><td>300</td><td>184</td><td>3</td><td>2,6 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>389</td><td>300</td><td>199</td><td>3</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>389</td><td>300</td><td>216</td><td>3</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>428</td><td>300</td><td>233</td><td>3</td><td>4,1 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>471</td><td>300</td><td>251</td><td>4</td><td>4,3 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Night Witch */}
                <Collapsible trigger={<TriggerNightWitch />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Night Witch' src='https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Night Witch' src='https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Night Witch' src='https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Night Witch' src='https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Night Witch' src='https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Units</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>200</td><td>90</td><td>2</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>200</td><td>90</td><td>2</td><td>600 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>6</td><td>220</td><td>99</td><td>2</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>6</td><td>220</td><td>99</td><td>2</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>6</td><td>242</td><td>109</td><td>2</td><td>900 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>242</td><td>109</td><td>2</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>7</td><td>6</td><td>266</td><td>120</td><td>2</td><td>1,1 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>6</td><td>266</td><td>120</td><td>2</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>9</td><td>6</td><td>293</td><td>132</td><td>2</td><td>1,3 m</td><td>2 d</td></tr>
                        <tr><td>10</td><td>6</td><td>293</td><td>132</td><td>2</td><td>1,4 m</td><td>2 d</td></tr>
                        <tr><td>11</td><td>6</td><td>322</td><td>145</td><td>2</td><td>1,6 m</td><td>2 d</td></tr>
                        <tr><td>12</td><td>6</td><td>322</td><td>145</td><td>2</td><td>1,8 m</td><td>2 d</td></tr>
                        <tr><td>13</td><td>7</td><td>354</td><td>160</td><td>2</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>354</td><td>160</td><td>2</td><td>2,7 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>389</td><td>176</td><td>2</td><td>3,5 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>389</td><td>176</td><td>2</td><td>3,7 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>428</td><td>194</td><td>2</td><td>4,2 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>471</td><td>213</td><td>2</td><td>4,4 m</td><td>5 d</td></tr>
                    </table>
                    <table className="homebasewiki-buildings-item-table2">
                        <tr><th className="orange-font" colspan="8">Bats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Summon</td><td className="orange-font">Summon</td><td className="orange-font">Bat Swarm</td></tr>
                        <tr><td>-</td><td>(cooldown)</td><td>(bats)</td><td>(on death)</td></tr>
                        <tr><td>1</td><td>6,8 s</td><td>2 (3 max)</td><td>-</td></tr>
                        <tr><td>2</td><td>5,6 s</td><td>2 (3 max)</td><td>-</td></tr>
                        <tr><td>3</td><td>5,6 s</td><td>2 (3 max)</td><td>-</td></tr>
                        <tr><td>4</td><td>5,6 s</td><td>2 (3 max)</td><td>2</td></tr>
                        <tr><td>5</td><td>5,6 s</td><td>2 (3 max)</td><td>2</td></tr>
                        <tr><td>6</td><td>6,8 s</td><td>3 (4 max)</td><td>2</td></tr>
                        <tr><td>7</td><td>6,8 s</td><td>3 (4 max)</td><td>2</td></tr>
                        <tr><td>8</td><td>5,6 s</td><td>3 (4 max)</td><td>2</td></tr>
                        <tr><td>9</td><td>5,6 s</td><td>3 (4 max)</td><td>2</td></tr>
                        <tr><td>10</td><td>5,6 s</td><td>3 (4 max)</td><td>4</td></tr>
                        <tr><td>11</td><td>5,6 s</td><td>3 (4 max)</td><td>4</td></tr>
                        <tr><td>12</td><td>6,8 s</td><td>4 (5 max)</td><td>4</td></tr>
                        <tr><td>13</td><td>6,8 s</td><td>4 (5 max)</td><td>4</td></tr>
                        <tr><td>14</td><td>5,6 s</td><td>4 (5 max)</td><td>4</td></tr>
                        <tr><td>15</td><td>5,6 s</td><td>4 (5 max)</td><td>4</td></tr>
                        <tr><td>16</td><td>5,6 s</td><td>4 (5 max)</td><td>6</td></tr>
                        <tr><td>17</td><td>5,6 s</td><td>4 (5 max)</td><td>6</td></tr>
                        <tr><td>18</td><td>5,6 s</td><td>4 (5 max)</td><td>6</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Drop Ship */}
                <Collapsible trigger={<TriggerDropShip />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Drop Ship' src='https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Drop Ship' src='https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Drop Ship' src='https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Drop Ship' src='https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv13-16.png'/><p className="legend">Level 13-16</p></div>
                        <div><img alt='Drop Ship' src='https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">CD</td><td colspan="2" className="orange-font">Skellie Burst</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>Skellies</td><td>Damage</td><td>Summon</td><td>Research</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>1320</td><td>6 s</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>1320</td><td>6 s</td><td>100</td><td>4</td><td>1,1 m</td><td>12 h</td></tr>
                        <tr><td>3</td><td>7</td><td>1452</td><td>6 s</td><td>100</td><td>4</td><td>1,2 m</td><td>12 h</td></tr>
                        <tr><td>4</td><td>7</td><td>1452</td><td>5,2 s</td><td>100</td><td>4</td><td>1,3 m</td><td>1 d</td></tr>
                        <tr><td>5</td><td>7</td><td>1597</td><td>5,2 s</td><td>100</td><td>4</td><td>1,4 m</td><td>1 d</td></tr>
                        <tr><td>6</td><td>7</td><td>1597</td><td>5,2 s</td><td>150</td><td>6</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1757</td><td>5,2 s</td><td>150</td><td>6</td><td>1,6 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>7</td><td>1757</td><td>4,5 s</td><td>150</td><td>6</td><td>1,7 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>7</td><td>1933</td><td>4,5 s</td><td>150</td><td>6</td><td>1,8 m</td><td>3 d</td></tr>
                        <tr><td>10</td><td>7</td><td>1933</td><td>4,5 s</td><td>225</td><td>9</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>11</td><td>7</td><td>2126</td><td>4,5 s</td><td>225</td><td>9</td><td>2,2 m</td><td>3 d</td></tr>
                        <tr><td>12</td><td>7</td><td>2126</td><td>3,9 s</td><td>225</td><td>9</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>13</td><td>7</td><td>2339</td><td>3,9 s</td><td>225</td><td>9</td><td>2,6 m</td><td>3 d</td></tr>
                        <tr><td>14</td><td>7</td><td>2339</td><td>3,9 s</td><td>300</td><td>12</td><td>2,8 m</td><td>3 d</td></tr>
                        <tr><td>15</td><td>8</td><td>2573</td><td>3,9 s</td><td>300</td><td>12</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>2573</td><td>3,4 s</td><td>300</td><td>12</td><td>3,8 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>2830</td><td>3,4 s</td><td>300</td><td>12</td><td>4,3 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>3113</td><td>3,4 s</td><td>300</td><td>12</td><td>4,5 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Super Pekka */}
                <Collapsible trigger={<TriggerSuperPekka />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Super P.E.E.K.A' src='https://assets.clashbases.de/Builder-Village/Troops/Super-Pekka/SuperPekka-lv01-07.png'/><p className="legend">Level 1-7</p></div>
                        <div><img alt='Super P.E.E.K.A' src='https://assets.clashbases.de/Builder-Village/Troops/Super-Pekka/SuperPekka-lv08-13.png'/><p className="legend">Level 8-13</p></div>
                        <div><img alt='Super P.E.E.K.A' src='https://assets.clashbases.de/Builder-Village/Troops/Super-Pekka/SuperPekka-lv14-16.png'/><p className="legend">Level 14-16</p></div>
                        <div><img alt='Super P.E.E.K.A' src='https://assets.clashbases.de/Builder-Village/Troops/Super-Pekka/SuperPekka-lv17-18.png'/><p className="legend">Level 17-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td colspan="2" className="orange-font">Damage</td><td className="orange-font">Unit</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(on death)</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>3135</td><td>305</td><td>-</td><td>1</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>3135</td><td>305</td><td>400</td><td>1</td><td>1,6 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>8</td><td>3449</td><td>336</td><td>400</td><td>1</td><td>1,7 m</td><td>1 d</td></tr>
                        <tr><td>4</td><td>8</td><td>3449</td><td>336</td><td>480</td><td>1</td><td>1,8 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>8</td><td>3794</td><td>369</td><td>480</td><td>1</td><td>1,9 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>8</td><td>3794</td><td>369</td><td>580</td><td>1</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>8</td><td>4173</td><td>406</td><td>580</td><td>1</td><td>2,2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>8</td><td>4173</td><td>406</td><td>680</td><td>1</td><td>2,4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>8</td><td>4590</td><td>447</td><td>680</td><td>1</td><td>2,6 m</td><td>4 d</td></tr>
                        <tr><td>10</td><td>8</td><td>4590</td><td>447</td><td>800</td><td>1</td><td>2,8 m</td><td>4 d</td></tr>
                        <tr><td>11</td><td>8</td><td>5049</td><td>491</td><td>800</td><td>1</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>12</td><td>8</td><td>5049</td><td>491</td><td>920</td><td>1</td><td>3,2 m</td><td>4 d</td></tr>
                        <tr><td>13</td><td>8</td><td>5554</td><td>540</td><td>920</td><td>1</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>14</td><td>8</td><td>5554</td><td>540</td><td>1060</td><td>1</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>15</td><td>8</td><td>6109</td><td>594</td><td>1060</td><td>1</td><td>3,8 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>8</td><td>6109</td><td>594</td><td>1200</td><td>1</td><td>4 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>6720</td><td>653</td><td>1200</td><td>1</td><td>4,6 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>7392</td><td>718</td><td>1200</td><td>1</td><td>4,8 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Hog Glider */}
                <Collapsible trigger={<TriggerHogGlider />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Hog Glider' src='https://assets.clashbases.de/Builder-Village/Troops/Hog-Glider/HogGlider-lv01-04.png'/><p className="legend">Level 1-4</p></div>
                        <div><img alt='Hog Glider' src='https://assets.clashbases.de/Builder-Village/Troops/Hog-Glider/HogGlider-lv05-08.png'/><p className="legend">Level 5-8</p></div>
                        <div><img alt='Hog Glider' src='https://assets.clashbases.de/Builder-Village/Troops/Hog-Glider/HogGlider-lv09-12.png'/><p className="legend">Level 9-12</p></div>
                        <div><img alt='Hog Glider' src='https://assets.clashbases.de/Builder-Village/Troops/Hog-Glider/HogGlider-lv13-18.png'/><p className="legend">Level 13-18</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colspan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Lab</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Stun</td><td className="orange-font">Unit</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>(duration)</td><td>(per camp)</td><td>(research)</td><td>-</td></tr>
                        <tr><td>1</td><td>-</td><td>450</td><td>140</td><td>-</td><td>2</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>450</td><td>140</td><td>4 s</td><td>2</td><td>1,6 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>9</td><td>450</td><td>140</td><td>4 s</td><td>2</td><td>1,7 m</td><td>1 d</td></tr>
                        <tr><td>4</td><td>9</td><td>450</td><td>140</td><td>4 s</td><td>2</td><td>1,8 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>9</td><td>450</td><td>140</td><td>4 s</td><td>2</td><td>1,9 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>9</td><td>450</td><td>140</td><td>5 s</td><td>2</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>9</td><td>450</td><td>140</td><td>5 s</td><td>2</td><td>2,2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>9</td><td>450</td><td>140</td><td>5 s</td><td>2</td><td>2,4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>9</td><td>450</td><td>140</td><td>5 s</td><td>2</td><td>2,6 m</td><td>4 d</td></tr>
                        <tr><td>10</td><td>9</td><td>550</td><td>180</td><td>5 s</td><td>2</td><td>2,8 m</td><td>4 d</td></tr>
                        <tr><td>11</td><td>9</td><td>550</td><td>180</td><td>5 s</td><td>2</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>12</td><td>9</td><td>550</td><td>180</td><td>6 s</td><td>2</td><td>3,2 m</td><td>4 d</td></tr>
                        <tr><td>13</td><td>9</td><td>550</td><td>180</td><td>6 s</td><td>2</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>14</td><td>9</td><td>650</td><td>220</td><td>6 s</td><td>2</td><td>3,6 m</td><td>4 d</td></tr>
                        <tr><td>15</td><td>9</td><td>650</td><td>220</td><td>6 s</td><td>2</td><td>3,8 m</td><td>4 d</td></tr>
                        <tr><td>16</td><td>9</td><td>650</td><td>220</td><td>7 s</td><td>2</td><td>4 m</td><td>4 d</td></tr>
                        <tr><td>17</td><td>9</td><td>650</td><td>220</td><td>7 s</td><td>2</td><td>4,2 m</td><td>5 d</td></tr>
                        <tr><td>18</td><td>9</td><td>650</td><td>220</td><td>7 s</td><td>2</td><td>4,4 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>


            </div>

        </div>

        <div className="hometext-container">
          <div className="hometext-info-container">
            <div className="hometext-about">

            </div>
          </div>
        </div>

      </section>

);

export default Buildertroops;
