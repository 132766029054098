import React from 'react';
import './../Wiki.css';
import { Link as LinkToAnchor } from "react-scroll";
import Collapsible from 'react-collapsible';
import LazyLoad from 'react-lazyload';
import './carousel.css';
import { Carousel } from 'react-responsive-carousel';



// Buildings - Resources

const TriggerBuilderHall = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-builderhall"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Builder Hall</span></div>
</div>;
const TriggerClockTower = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-clocktower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Clock Tower</span></div>
</div>;
const TriggerOtto = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-otto"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Otto</span></div>
</div>;
const TriggerGoldMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-goldmine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Gold Mine</span></div>
</div>;
const TriggerElixirCollector = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-elixircollector"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Elixir Collector</span></div>
</div>;
const TriggerGoldStorage = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-goldstorage"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Gold Storage</span></div>
</div>;
const TriggerElixirStorage = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-elixirstorage"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Elixir Storage</span></div>
</div>;
const TriggerGemMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-gemmine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Gem Mine</span></div>
</div>;

// Buildings - Army

const TriggerBuilderBarracks = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-builderbarracks"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Builder Barracks</span></div>
</div>;
const TriggerArmyCamp = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-armycamp"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Army Camp</span></div>
</div>;
const TriggerStarLaboratory = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-starlaboratory"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Star Laboratory</span></div>
</div>;

// Buildings - Army

const TriggerBuilderCannon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-cannon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Cannon</span></div>
</div>;
const TriggerDoubleCannon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-doublecannon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Double Cannon</span></div>
</div>;
const TriggerArcherTower = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-archertower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Archer Tower</span></div>
</div>;
const TriggerHiddenTesla = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-hiddentesla"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Hidden Tesla</span></div>
</div>;
const TriggerFirecrackers = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-firecrackers"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Firecrackers</span></div>
</div>;
const TriggerCrusher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-crusher"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Crusher</span></div>
</div>;
const TriggerGuardPost = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-guardpost"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Guard Post</span></div>
</div>;
const TriggerAirBombs = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-airbombs"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Air Bombs</span></div>
</div>;
const TriggerMultiMortar = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-multimortar"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Multi Mortar</span></div>
</div>;
const TriggerRoaster = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-roaster"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Roaster</span></div>
</div>;
const TriggerGiantCannon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-giantcannon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Giant Cannon</span></div>
</div>;
const TriggerMegaTesla = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-megatesla"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Mega Tesla</span></div>
</div>;
const TriggerLavaLauncher = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-lavalauncher"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Lava Launcher</span></div>
</div>;
const TriggerWalls = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-walls"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Walls</span></div>
</div>;

// Buildings - Traps

const TriggerPushTrap = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-pushtrap"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Push Traps</span></div>
</div>;
const TriggerSpringTrap = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-springtrap"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Spring Traps</span></div>
</div>;
const TriggerMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-mine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Mine</span></div>
</div>;
const TriggerMegaMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon b-megamine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Mega Mine</span></div>
</div>;





const Builderbuildings  = props => (

      <section className="homebasewiki-b">

        <div className="hometext-container-building">
          <div className="hometext-info-container">
            <div className="hometext-about">
              <div className="hometext-about-container">
                <h3 className="hometext-about-title">Builder Village</h3>
              </div>
              <p className="hometext-about-text">Below you will find <span className="orange-color">information and stats</span> about the Builder village.< br/>< br/>Just <span className="orange-color">click</span> on the items to expand, and navigate through the page by clicking on the buttons! < br/>< br/> It's still a <span className="orange-color">work in progress</span>, so there will be more changes over the next weeks.</p>
            </div>
          </div>
        </div>

        <div className="homebasewiki-container">

            <div className="homebasewiki-buildings-container">

                <div className="basebuildingsbackground">
                  <h1 className="homebasewiki-buildings-maintitel" id="B-Buildings">Buildings</h1>
                </div>

                <h2 className="homebasewiki-buildings-titel" id="B-Resource">Resource</h2>
                {/* NAVBAR - BUILDINGS - RESOURCES */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="B-Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Traps" smooth={true} offset={-70}>Traps</LinkToAnchor></li>
                    </div>
                </nav>

                {/* Builder Hall */}
                <Collapsible trigger={<TriggerBuilderHall />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Builder Hall' src='https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td colSpan="2" className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>(gold)</td><td>(elixir)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>100</td><td>50 k</td><td>50 k</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>800</td><td>75 k</td><td>75 k</td><td>3,5 k</td><td>5 s</td></tr>
                        <tr><td>3</td><td>960</td><td>100 k</td><td>100 k</td><td>30 k</td><td>1 h</td></tr>
                        <tr><td>4</td><td>1152</td><td>150 k</td><td>150 k</td><td>200 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>1382</td><td>300 k</td><td>300 k</td><td>400 k</td><td>2 d</td></tr>
                        <tr><td>6</td><td>1658</td><td>400 k</td><td>400 k</td><td>1,2 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>1990</td><td>500 k</td><td>500 k</td><td>1,8 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>2388</td><td>800 k</td><td>800 k</td><td>2,8 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>2507</td><td>1 m</td><td>1 m</td><td>3,8 m</td><td>6 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Clock Tower */}
                <Collapsible trigger={<TriggerClockTower />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-Ruins.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Clock Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Boost</td><td className="orange-font">Time</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(duration)</td><td>(gained)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>4</td><td>650</td><td>14 m</td><td>2h 6m</td><td>150 k</td><td>2 h</td></tr>
                        <tr><td>2</td><td>4</td><td>800</td><td>16 m</td><td>2h 24m</td><td>180 k</td><td>3 h</td></tr>
                        <tr><td>3</td><td>4</td><td>960</td><td>18 m</td><td>2h 42m</td><td>229 k</td><td>4 h</td></tr>
                        <tr><td>4</td><td>4</td><td>1152</td><td>20 m</td><td>3h</td><td>300 k</td><td>6 h</td></tr>
                        <tr><td>5</td><td>5</td><td>1382</td><td>22 m</td><td>3h 18m</td><td>700 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>6</td><td>1658</td><td>24 m</td><td>3h 36m</td><td>1,2 m</td><td>10 h</td></tr>
                        <tr><td>7</td><td>7</td><td>1990</td><td>26 m</td><td>3h 54m</td><td>1,7 m</td><td>12 h</td></tr>
                        <tr><td>8</td><td>8</td><td>2388</td><td>28 m</td><td>4h 12m</td><td>2,2 m</td><td>1 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2507</td><td>30 m</td><td>4h 30m</td><td>2,7 m</td><td>1,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Otto */}
                <Collapsible trigger={<TriggerOtto />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='O.T.T.O' src='https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='O.T.T.O' src='https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='O.T.T.O' src='https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='O.T.T.O' src='https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='O.T.T.O' src='https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Upgrade Requirements</td><td>Health</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>100 k</td><td>250</td><td>-</td></tr>
                        <tr><td>2</td><td>All 3 Gear Ups</td><td>250</td><td>-</td></tr>
                        <tr><td>3</td><td>Cannon Cart lv 18</td><td>250</td><td>-</td></tr>
                        <tr><td>4</td><td>Mega Tesla lv 9</td><td>250</td><td>-</td></tr>
                        <tr><td>5</td><td>Battle Machine lv 30</td><td>250</td><td>-</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Gold Mine */}
                <Collapsible trigger={<TriggerGoldMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-Ruin.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(per hour)</td><td>(gold)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>300</td><td>1 k</td><td>-</td><td>1 k</td><td>10 m</td></tr>
                        <tr><td>2</td><td>3</td><td>345</td><td>1,2 k</td><td>24 k</td><td>5 k</td><td>20 m</td></tr>
                        <tr><td>3</td><td>3</td><td>397</td><td>1,5 k</td><td>28,8 k</td><td>10 k</td><td>40 m</td></tr>
                        <tr><td>4</td><td>4</td><td>457</td><td>1,8 k</td><td>36 k</td><td>30 k</td><td>2 h</td></tr>
                        <tr><td>5</td><td>5</td><td>526</td><td>2,1 k</td><td>43,2 k</td><td>60 k</td><td>5 h</td></tr>
                        <tr><td>6</td><td>6</td><td>605</td><td>2,5 k</td><td>50,4 k</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>7</td><td>7</td><td>696</td><td>3 k</td><td>60 k</td><td>200 k</td><td>10 h</td></tr>
                        <tr><td>8</td><td>8</td><td>800</td><td>2,5 k</td><td>84 k</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>9</td><td>840</td><td>4 k</td><td>96 k</td><td>400 k</td><td>14 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Gold Storage */}
                <Collapsible trigger={<TriggerGoldStorage />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(gold)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>650</td><td>70 k</td><td>20 k</td><td>30 m</td></tr>
                        <tr><td>2</td><td>3</td><td>805</td><td>150 k</td><td>80 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>3</td><td>960</td><td>250 k</td><td>200 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>4</td><td>1152</td><td>350 k</td><td>300 k</td><td>6 h</td></tr>
                        <tr><td>5</td><td>5</td><td>1382</td><td>600 k</td><td>600 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>6</td><td>1658</td><td>800 k</td><td>1 m</td><td>10 h</td></tr>
                        <tr><td>7</td><td>7</td><td>1990</td><td>1,2 m</td><td>1,5 m</td><td>12 h</td></tr>
                        <tr><td>8</td><td>8</td><td>2388</td><td>1,6 m</td><td>2 m</td><td>1 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2507</td><td>2 m</td><td>2,5 m</td><td>1,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Elixir Collector */}
                <Collapsible trigger={<TriggerElixirCollector />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-Ruin.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(per hour)</td><td>(gold)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>300</td><td>1 k</td><td>-</td><td>1 k</td><td>10 m</td></tr>
                        <tr><td>2</td><td>3</td><td>345</td><td>1,2 k</td><td>24 k</td><td>5 k</td><td>20 m</td></tr>
                        <tr><td>3</td><td>3</td><td>397</td><td>1,5 k</td><td>28,8 k</td><td>10 k</td><td>40 m</td></tr>
                        <tr><td>4</td><td>4</td><td>457</td><td>1,8 k</td><td>36 k</td><td>30 k</td><td>2 h</td></tr>
                        <tr><td>5</td><td>5</td><td>526</td><td>2,1 k</td><td>43,2 k</td><td>60 k</td><td>5 h</td></tr>
                        <tr><td>6</td><td>6</td><td>605</td><td>2,5 k</td><td>50,4 k</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>7</td><td>7</td><td>696</td><td>3 k</td><td>60 k</td><td>200 k</td><td>10 h</td></tr>
                        <tr><td>8</td><td>8</td><td>800</td><td>2,5 k</td><td>84 k</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>9</td><td>840</td><td>4 k</td><td>96 k</td><td>400 k</td><td>14 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Elixir Storage */}
                <Collapsible trigger={<TriggerElixirStorage />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(gold)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>650</td><td>70 k</td><td>20 k</td><td>30 m</td></tr>
                        <tr><td>2</td><td>3</td><td>805</td><td>150 k</td><td>80 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>3</td><td>960</td><td>250 k</td><td>200 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>4</td><td>1152</td><td>350 k</td><td>300 k</td><td>6 h</td></tr>
                        <tr><td>5</td><td>5</td><td>1382</td><td>600 k</td><td>600 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>6</td><td>1658</td><td>800 k</td><td>1 m</td><td>10 h</td></tr>
                        <tr><td>7</td><td>7</td><td>1990</td><td>1,2 m</td><td>1,5 m</td><td>12 h</td></tr>
                        <tr><td>8</td><td>8</td><td>2388</td><td>1,6 m</td><td>2 m</td><td>1 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2507</td><td>2 m</td><td>2,5 m</td><td>1,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Gem Mine */}
                <Collapsible trigger={<TriggerGemMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-Ruin.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Gem Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(per hour)</td><td>(gems)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>3</td><td>300</td><td>2,1</td><td>10</td><td>120 k</td><td>1 h</td></tr>
                        <tr><td>2</td><td>3</td><td>345</td><td>2,4</td><td>11</td><td>5 k</td><td>2 h</td></tr>
                        <tr><td>3</td><td>3</td><td>397</td><td>2,6</td><td>12</td><td>180 k</td><td>6 h</td></tr>
                        <tr><td>4</td><td>4</td><td>457</td><td>2,8</td><td>13</td><td>240 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>5</td><td>526</td><td>3,1</td><td>14</td><td>450 k</td><td>10 h</td></tr>
                        <tr><td>6</td><td>6</td><td>605</td><td>3,3</td><td>16</td><td>1 m</td><td>12 h</td></tr>
                        <tr><td>7</td><td>7</td><td>696</td><td>3,8</td><td>18</td><td>2,5 m</td><td>1 d</td></tr>
                        <tr><td>8</td><td>8</td><td>800</td><td>4,3</td><td>20</td><td>3,5 m</td><td>2 d</td></tr>
                        <tr><td>9</td><td>9</td><td>840</td><td>4,8</td><td>22</td><td>4,5 m</td><td>3 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="B-Army">Army</h2>
                {/* NAVBAR - BUILDINGS - ARMY */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="B-Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Traps" smooth={true} offset={-70}>Traps</LinkToAnchor></li>
                  </div>
                </nav>

                {/* Builder Barracks */}
                <Collapsible trigger={<TriggerBuilderBarracks />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-Ruin.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv11.png'/><p className="legend">Level 11</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>300</td><td>Raged Barbarian</td><td>1 k</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>345</td><td>Sneaky Archer</td><td>4 k</td><td>1 m</td></tr>
                        <tr><td>3</td><td>3</td><td>397</td><td>Boxer Giant</td><td>10 k</td><td>10 m</td></tr>
                        <tr><td>4</td><td>3</td><td>457</td><td>Beta Minion</td><td>25 k</td><td>30 m</td></tr>
                        <tr><td>5</td><td>4</td><td>526</td><td>Bomber</td><td>100 k</td><td>3 h</td></tr>
                        <tr><td>6</td><td>4</td><td>605</td><td>Baby Dragon</td><td>150 k</td><td>6 h</td></tr>
                        <tr><td>7</td><td>5</td><td>696</td><td>Cannon Cart</td><td>300 k</td><td>8 h</td></tr>
                        <tr><td>8</td><td>6</td><td>800</td><td>Night Witch</td><td>500 k</td><td>10 h</td></tr>
                        <tr><td>9</td><td>7</td><td>920</td><td>Drop Ship</td><td>1 m</td><td>12 h</td></tr>
                        <tr><td>10</td><td>8</td><td>1058</td><td>Super Pekka</td><td>1,5 m</td><td>1 d</td></tr>
                        <tr><td>11</td><td>9</td><td>1111</td><td>Hog Glider</td><td>2 m</td><td>1,5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Star Laboratory */}
                <Collapsible trigger={<TriggerStarLaboratory />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-Ruin.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Star Laboratory' src='https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>650</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>800</td><td>15 k</td><td>10 m</td></tr>
                        <tr><td>3</td><td>3</td><td>960</td><td>50 k</td><td>30 m</td></tr>
                        <tr><td>4</td><td>4</td><td>1152</td><td>300 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>5</td><td>1382</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>6</td><td>6</td><td>1658</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1990</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>2388</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2507</td><td>4 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Army Camp */}
                <Collapsible trigger={<TriggerArmyCamp />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Builder-Village/Buildings/Army-Camp/ArmyCamp-lv01.png'/><p className="legend">Level 1</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Bh</td><td className="orange-font">Health</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>1</td><td>300</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>300</td><td>12 k</td><td>5 m</td></tr>
                        <tr><td>3</td><td>3</td><td>300</td><td>180 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>4</td><td>300</td><td>350 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>7</td><td>300</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>8</td><td>300</td><td>2,5 m</td><td>2 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="B-Defense">Defense</h2>
                {/* NAVBAR - BUILDINGS - DEFENSE */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="B-Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Traps" smooth={true} offset={-70}>Traps</LinkToAnchor></li>
                  </div>
                </nav>

                {/* CANNON */}
                <Collapsible trigger={<TriggerBuilderCannon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Builder Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>500</td><td>62</td><td>10 k</td><td>1 m</td></tr>
                        <tr><td>2</td><td>2</td><td>575</td><td>68</td><td>20 k</td><td>5 m</td></tr>
                        <tr><td>3</td><td>3</td><td>661</td><td>76</td><td>50 k</td><td>2 h</td></tr>
                        <tr><td>4</td><td>4</td><td>760</td><td>83</td><td>200 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>5</td><td>874</td><td>92</td><td>600 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>1005</td><td>101</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1156</td><td>111</td><td>1,8 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1329</td><td>122</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1395</td><td>135</td><td>3,3 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Double Cannon */}
                <Collapsible trigger={<TriggerDoubleCannon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Double Canon' src='https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>600</td><td>84</td><td>20 k</td><td>10 m</td></tr>
                        <tr><td>2</td><td>2</td><td>690</td><td>92</td><td>50 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>3</td><td>794</td><td>103</td><td>80 k</td><td>3 h</td></tr>
                        <tr><td>4</td><td>4</td><td>913</td><td>113</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>5</td><td>5</td><td>1050</td><td>125</td><td>900 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>1208</td><td>136</td><td>1,4 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1389</td><td>150</td><td>2,2 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1597</td><td>165</td><td>3,2 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1677</td><td>182</td><td>4,2 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Archer Tower */}
                <Collapsible trigger={<TriggerArcherTower />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv01.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv01-A.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv02.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv02-A.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv03.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv03-A.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv04.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv04-A.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv05.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv05-A.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv06.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv06-A.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv07.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv07-A.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv08.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv08-A.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv09.png'/><img alt='Archer Tower' src='https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv09-A.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>2</td><td>500</td><td>35/70</td><td>12 k</td><td>5 m</td></tr>
                        <tr><td>2</td><td>3</td><td>575</td><td>40/80</td><td>30 k</td><td>15 m</td></tr>
                        <tr><td>3</td><td>3</td><td>661</td><td>44/88</td><td>60 k</td><td>2 h</td></tr>
                        <tr><td>4</td><td>4</td><td>760</td><td>48/96</td><td>250 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>5</td><td>874</td><td>53/106</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>1005</td><td>58/116</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1156</td><td>64/128</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1329</td><td>70/140</td><td>2,8 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1395</td><td>77/154</td><td>3,6 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Hidden Tesla */}
                <Collapsible trigger={<TriggerHiddenTesla />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>300</td><td>41</td><td>50 k</td><td>30 m</td></tr>
                        <tr><td>2</td><td>3</td><td>345</td><td>46</td><td>100 k</td><td>3 h</td></tr>
                        <tr><td>3</td><td>3</td><td>397</td><td>51</td><td>150 k</td><td>5 h</td></tr>
                        <tr><td>4</td><td>4</td><td>457</td><td>56</td><td>280 k</td><td>10 h</td></tr>
                        <tr><td>5</td><td>5</td><td>526</td><td>61</td><td>700 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>605</td><td>68</td><td>1,3 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>696</td><td>75</td><td>2,1 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>800</td><td>83</td><td>3,1 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>840</td><td>91</td><td>4,1 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Firecrackers */}
                <Collapsible trigger={<TriggerFirecrackers />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Firecrackers' src='https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>400</td><td>49</td><td>30 k</td><td>15 m</td></tr>
                        <tr><td>2</td><td>3</td><td>460</td><td>55</td><td>80 k</td><td>2 h</td></tr>
                        <tr><td>3</td><td>3</td><td>529</td><td>61</td><td>120 k</td><td>4 h</td></tr>
                        <tr><td>4</td><td>4</td><td>608</td><td>67</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>5</td><td>5</td><td>699</td><td>73</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>6</td><td>6</td><td>804</td><td>82</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>7</td><td>7</td><td>925</td><td>90</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1064</td><td>99</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1117</td><td>108</td><td>4 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Crusher */}
                <Collapsible trigger={<TriggerCrusher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Crusher' src='https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>1000</td><td>142</td><td>120 k</td><td>2 h</td></tr>
                        <tr><td>2</td><td>3</td><td>1150</td><td>157</td><td>180 k</td><td>5 h</td></tr>
                        <tr><td>3</td><td>4</td><td>1323</td><td>172</td><td>220 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>4</td><td>1521</td><td>190</td><td>350 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>5</td><td>1749</td><td>209</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>6</td><td>2011</td><td>230</td><td>1,5 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>2313</td><td>253</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>8</td><td>2660</td><td>278</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2793</td><td>306</td><td>4,4 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Guard Post */}
                <Collapsible trigger={<TriggerGuardPost />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Guard Post' src='https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td colSpan="3" className="orange-font">Troops</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>Barbs</td><td>Archers</td><td>Level</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>4</td><td>300</td><td>1</td><td>1</td><td>2</td><td>120 k</td><td>2 h</td></tr>
                        <tr><td>2</td><td>4</td><td>345</td><td>1</td><td>1</td><td>4</td><td>180 k</td><td>5 h</td></tr>
                        <tr><td>3</td><td>4</td><td>397</td><td>2</td><td>1</td><td>6</td><td>220 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>4</td><td>457</td><td>2</td><td>1</td><td>8</td><td>350 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>5</td><td>526</td><td>2</td><td>2</td><td>10</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>6</td><td>605</td><td>2</td><td>2</td><td>12</td><td>1,5 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>696</td><td>3</td><td>2</td><td>14</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>8</td><td>800</td><td>3</td><td>2</td><td>16</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>9</td><td>840</td><td>3</td><td>2</td><td>18</td><td>4,4 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Air Bombs */}
                <Collapsible trigger={<TriggerAirBombs />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Air Bombs' src='https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>4</td><td>1000</td><td>83</td><td>300 k</td><td>6 h</td></tr>
                        <tr><td>2</td><td>4</td><td>1150</td><td>91</td><td>320 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>4</td><td>1323</td><td>101</td><td>340 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>4</td><td>1521</td><td>111</td><td>360 k</td><td>2 d</td></tr>
                        <tr><td>5</td><td>5</td><td>1749</td><td>122</td><td>1,2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>6</td><td>2011</td><td>134</td><td>1,5 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>2313</td><td>147</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>8</td><td>2660</td><td>162</td><td>3,4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2793</td><td>178</td><td>4,4 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Multi Mortar */}
                <Collapsible trigger={<TriggerMultiMortar />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Multi Mortar' src='https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>5</td><td>500</td><td>14</td><td>600 k</td><td>8 h</td></tr>
                        <tr><td>2</td><td>5</td><td>575</td><td>15</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>5</td><td>661</td><td>117</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>5</td><td>760</td><td>19</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>5</td><td>874</td><td>20</td><td>1,2 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>6</td><td>1005</td><td>23</td><td>1,6 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1156</td><td>25</td><td>2,5 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1329</td><td>28</td><td>3,5 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1395</td><td>31</td><td>4,5 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Roaster */}
                <Collapsible trigger={<TriggerRoaster />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Roaster' src='https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>700</td><td>25</td><td>2 m</td><td>12 h</td></tr>
                        <tr><td>2</td><td>7</td><td>805</td><td>27</td><td>2,1 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>7</td><td>926</td><td>30</td><td>2,2 m</td><td>1 d</td></tr>
                        <tr><td>4</td><td>7</td><td>1065</td><td>33</td><td>2,3 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>7</td><td>1225</td><td>36</td><td>2,4 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>7</td><td>1409</td><td>40</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1620</td><td>44</td><td>2,7 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1863</td><td>48</td><td>3,8 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1956</td><td>53</td><td>4,7 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Giant Cannon */}
                <Collapsible trigger={<TriggerGiantCannon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Giant Cannon' src='https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>6</td><td>800</td><td>37</td><td>1 m</td><td>10 h</td></tr>
                        <tr><td>2</td><td>6</td><td>920</td><td>41</td><td>1,2 m</td><td>12 h</td></tr>
                        <tr><td>3</td><td>6</td><td>1058</td><td>45</td><td>1,4 m</td><td>1 d</td></tr>
                        <tr><td>4</td><td>6</td><td>1217</td><td>49</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>6</td><td>1400</td><td>53</td><td>1,6 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>6</td><td>1610</td><td>60</td><td>1,7 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>7</td><td>1852</td><td>68</td><td>2,6 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>8</td><td>2130</td><td>75</td><td>3,6 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>9</td><td>2237</td><td>83</td><td>4,6 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Mega Tesla */}
                <Collapsible trigger={<TriggerMegaTesla />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Mega Tesla' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>8</td><td>700</td><td>110</td><td>3 m</td><td>1 d</td></tr>
                        <tr><td>2</td><td>8</td><td>805</td><td>121</td><td>3,1 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>8</td><td>926</td><td>133</td><td>3,2 m</td><td>2 d</td></tr>
                        <tr><td>4</td><td>8</td><td>1065</td><td>146</td><td>3,3 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>8</td><td>1225</td><td>161</td><td>3,4 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>8</td><td>1409</td><td>177</td><td>3,6 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>8</td><td>1620</td><td>194</td><td>3,8 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>8</td><td>1863</td><td>214</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1956</td><td>235</td><td>4,8 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Lava Launcher */}
                <Collapsible trigger={<TriggerLavaLauncher />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv07-08.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv07-08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Lava Launcher' src='https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td colSpan="2" className="orange-font">Damage</td><td className="orange-font">max Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(per s)</td><td>(per shot)</td><td>(per s)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>9</td><td>500</td><td>3</td><td>25</td><td>160</td><td>3 m</td><td>1 d</td></tr>
                        <tr><td>2</td><td>9</td><td>575</td><td>4</td><td>33</td><td>165</td><td>3,1 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>9</td><td>661</td><td>5</td><td>41</td><td>170</td><td>3,2 m</td><td>2 d</td></tr>
                        <tr><td>4</td><td>9</td><td>760</td><td>7</td><td>49</td><td>175</td><td>3,4 m</td><td>2 d</td></tr>
                        <tr><td>5</td><td>9</td><td>874</td><td>8</td><td>57</td><td>180</td><td>3,7 m</td><td>3 d</td></tr>
                        <tr><td>6</td><td>9</td><td>1005</td><td>9</td><td>65</td><td>185</td><td>4 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>9</td><td>1156</td><td>10</td><td>73</td><td>190</td><td>4,3 m</td><td>4 d</td></tr>
                        <tr><td>8</td><td>9</td><td>1329</td><td>11</td><td>81</td><td>195</td><td>4,6 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>9</td><td>1395</td><td>12</td><td>89</td><td>200</td><td>4,9 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Walls */}
                <Collapsible trigger={<TriggerWalls />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Wall' src='https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Wall</td><td className="orange-font">Cost</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>Rings</td><td>-</td></tr>
                        <tr><td>1</td><td>2</td><td>1300</td><td>-</td><td>4 k</td></tr>
                        <tr><td>2</td><td>3</td><td>1600</td><td>1</td><td>10 k</td></tr>
                        <tr><td>3</td><td>3</td><td>1920</td><td>1</td><td>100 k</td></tr>
                        <tr><td>4</td><td>4</td><td>2304</td><td>1</td><td>300 k</td></tr>
                        <tr><td>5</td><td>5</td><td>2764</td><td>2</td><td>800 k</td></tr>
                        <tr><td>6</td><td>6</td><td>3316</td><td>3</td><td>1,2 m</td></tr>
                        <tr><td>7</td><td>7</td><td>3980</td><td>4</td><td>2 m</td></tr>
                        <tr><td>8</td><td>8</td><td>4776</td><td>6</td><td>3 m</td></tr>
                        <tr><td>9</td><td>9</td><td>5014</td><td>8</td><td>4 m</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="B-Traps">Traps</h2>
                {/* NAVBAR - BUILDINGS - DEFENSE */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="B-Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="B-Traps" smooth={true} offset={-70}>Traps</LinkToAnchor></li>
                  </div>
                </nav>

                {/* Push Trap */}
                <Collapsible trigger={<TriggerPushTrap />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Push Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Capacity</td><td className="orange-font">Push</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>2</td><td>6</td><td>6</td><td>1 k</td><td>-</td></tr>
                        <tr><td>2</td><td>2</td><td>8</td><td>6</td><td>3 k</td><td>5 m</td></tr>
                        <tr><td>3</td><td>3</td><td>10</td><td>6</td><td>10 k</td><td>20 m</td></tr>
                        <tr><td>4</td><td>4</td><td>15</td><td>6</td><td>20 k</td><td>2 h</td></tr>
                        <tr><td>5</td><td>5</td><td>20</td><td>6</td><td>40 k</td><td>4 h</td></tr>
                        <tr><td>6</td><td>6</td><td>25</td><td>6</td><td>60 k</td><td>6 h</td></tr>
                        <tr><td>7</td><td>7</td><td>30</td><td>6</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>8</td><td>8</td><td>35</td><td>6</td><td>200 k</td><td>10 h</td></tr>
                        <tr><td>9</td><td>9</td><td>40</td><td>6</td><td>300 k</td><td>12 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Push Trap */}
                <Collapsible trigger={<TriggerSpringTrap />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Spring-Trap/SpringTrap-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Spring-Trap/SpringTrap-lv02-03.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Spring-Trap/SpringTrap-lv02-03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Builder-Village/Buildings/Spring-Trap/SpringTrap-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Capacity</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>8</td><td>10 k</td><td>-</td></tr>
                        <tr><td>2</td><td>4</td><td>12</td><td>30 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>6</td><td>16</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>4</td><td>8</td><td>20</td><td>300 k</td><td>12 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Mine */}
                <Collapsible trigger={<TriggerMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv01.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv02.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv03.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv04.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv05.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv06.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv07.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv08.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv09.png'/><img alt='Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-A-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td colSpan="6" className="orange-font">Damage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(radius)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>3</td><td>50</td><td>5</td><td>5 k</td><td>-</td></tr>
                        <tr><td>2</td><td>3</td><td>55</td><td>5</td><td>8 k</td><td>20 m</td></tr>
                        <tr><td>3</td><td>3</td><td>61</td><td>5</td><td>12 k</td><td>30 m</td></tr>
                        <tr><td>4</td><td>4</td><td>67</td><td>5</td><td>25 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>5</td><td>74</td><td>5</td><td>50 k</td><td>2 h</td></tr>
                        <tr><td>6</td><td>6</td><td>81</td><td>5</td><td>80 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>7</td><td>89</td><td>5</td><td>120 k</td><td>4 h</td></tr>
                        <tr><td>8</td><td>8</td><td>98</td><td>5</td><td>250 k</td><td>5 h</td></tr>
                        <tr><td>9</td><td>9</td><td>110</td><td>5</td><td>500 k</td><td>6 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Mega Mine */}
                <Collapsible trigger={<TriggerMegaMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv01.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv02.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv03.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv04.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv05.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv06.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv07.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv08.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Mega Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv09.png'/><img alt='Mega Air Mine' src='https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-A-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td colSpan="6" className="orange-font">Damage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>-</td><td>(radius)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>4</td><td>200</td><td>4</td><td>30 k</td><td>-</td></tr>
                        <tr><td>2</td><td>4</td><td>220</td><td>4</td><td>40 k</td><td>20 m</td></tr>
                        <tr><td>3</td><td>4</td><td>244</td><td>4</td><td>50 k</td><td>30 m</td></tr>
                        <tr><td>4</td><td>4</td><td>268</td><td>4</td><td>80 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>5</td><td>296</td><td>4</td><td>120 k</td><td>2 h</td></tr>
                        <tr><td>6</td><td>6</td><td>324</td><td>4</td><td>300 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>7</td><td>356</td><td>4</td><td>600 k</td><td>4 h</td></tr>
                        <tr><td>8</td><td>8</td><td>392</td><td>4</td><td>1 m</td><td>5 h</td></tr>
                        <tr><td>9</td><td>9</td><td>431</td><td>4</td><td>1,5 m</td><td>6 h</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

            </div>

        </div>

      </section>

);

export default Builderbuildings;
