import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";



class ArchiveTownhallnine extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 9</title>
            <meta name="description" content="Welcome to the ClashBases TH 9 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">Townhall <span style={{color: 'rgb(0, 0, 0)'}}>9</span> Base Layouts</h1>

                <div className="switch-container-archive">
                  <LinkButton to="/Townhall-9" className="switch-buttons-archive switch-to-archive">
                    <div>Switch back to Current TH 9 Base Layouts</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">
            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-004031_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-004031_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-004031_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-004031_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-004031.jpg 2430w"
                          title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoZaoEHSz1R42aK7GCmR3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F095</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 9 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-003915_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-003915_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-003915_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-003915_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-003915.jpg 2430w"
                          title="Townhall 9 Trophy Base" alt="Townhall 9 Trophy Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoZFLoBWnkil8t1yTfGy4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B094</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 9 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003800_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003800_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003800_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003800_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003800.jpg 2430w"
                          title="Townhall 9 Farm Base" alt="Townhall 9 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoYxTJeHI9hUdi30ndT_y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B093</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-003451_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-003451_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-003451_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-003451_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-003451.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Link Expired'}</a><p className="index-number font-size-picture-descriptions">#F092</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003234_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003234_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003234_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003234_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003234.jpg 2430w"
                        title="Townhall 9 Farm Base" alt="Townhall 9 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoXbHHgJKBED_XIXO6150" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B091</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003056_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003056_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003056_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003056_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-003056.jpg 2430w"
                        title="Townhall 9 Farm Base" alt="Townhall 9 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Link Expired'}</a><p className="index-number font-size-picture-descriptions">#B090</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-002854_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-002854_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-002854_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-002854_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-002854.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoWht3oVGte5Dq44KFWrE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F089</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001719_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001719_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001719_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001719_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001719.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoTgr-YnlNNw2q9wa5Qli" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F088</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001528_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001528_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001528_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001528_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001528.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoTD0GccY9bUNTwU4bOQ7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F087</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001255_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001255_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001255_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001255_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-001255.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoSZuRzcDkszN2z8Imr7J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F086</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-000819_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-000819_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-000819_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-000819_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210417-000819.jpg 2430w"
                        title="Townhall 9 Farm Base" alt="Townhall 9 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoRTLIdTtRVFzTwSPUtpw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B085</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-000625_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-000625_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-000625_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-000625_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210417-000625.jpg 2430w"
                        title="Townhall 9 Trophy Base" alt="Townhall 9 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoQw0JU2HXqFKBD6pEtZI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B084</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-000136_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-000136_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-000136_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-000136_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210417-000136.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoPgwCXIyQXtfU8PVMa0e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F083</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235645_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235645_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235645_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235645_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235645.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoOSro5hvKCTu7c-T8GbN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F082</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2021-05-24" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235444_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235444_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235444_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235444_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210416-235444.jpg 2430w"
                        title="Townhall 9 War Base" alt="Townhall 9 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAVgAAAAEpoNxxphPS9bqtZzrMWBh9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#F081</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234957_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234957_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234957_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234957_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234957.jpg 2430w"
                        title="Townhall 9 Trophy Base" alt="Townhall 9 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoMitmu6BMFHANZQlcf-S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B080</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2021-05-24" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234843_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234843_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234843_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234843_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-BaseLayout-20210416-234843.jpg 2430w"
                        title="Townhall 9 Trophy Base" alt="Townhall 9 Trophy Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAVgAAAAEpoMPVJ_yM-LGuYFFGsiTx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B079</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2021-05-24" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={150} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210416-234517_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210416-234517_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210416-234517_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210416-234517_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-BaseLayout-20210416-234517.jpg 2430w"
                        title="Townhall 9 Farm Base" alt="Townhall 9 Farm Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Link Expired'}</a><p className="index-number font-size-picture-descriptions">#B078</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2021-04-19" data-title="Townhall 9 War Base by Noobmaster69">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025325_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025325_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025325_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025325_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025325.jpg 2430w"
                        title="Townhall 9 War Base by Noobmaster69" alt="Townhall 9 War Base by Noobmaster69" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAHQAAAAHJsDEwHUAsr385dsTUFKLV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B077</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=PLJUQ9YCY" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Noobmaster69</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2021-04-19" data-title="Townhall 9 War Base by Noobmaster69">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025324_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025324_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025324_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025324_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-BaseLayout-20210420-025324.jpg 2430w"
                        title="Townhall 9 War Base by Noobmaster69" alt="Townhall 9 War Base by Noobmaster69" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAHQAAAAHJsCTwclFS2qWxLOFJxBGZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B076</p></figcaption>
                  <div>
                    <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=PLJUQ9YCY" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                    <p className="index-number font-size-picture-descriptions">Noobmaster69</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024852_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024852_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024852_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024852_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024852.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fV7B-zjqk1-BKvwmvDdK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B075</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024749_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024749_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024749_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024749_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024749.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fVg44T25nEXnABUeT2wT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B074</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-05-01" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-024505_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-024505_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-024505_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-024505_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-024505.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fUah1VChjwbUGuCIyTc4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B074</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">Th 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024329_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024329_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024329_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024329_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024329.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fT57AS04-l-2vNl_0xME" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B073</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024015_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024015_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024015_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024015_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-024015.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fSe4Mw9qRQvUxwY2o9X4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B072</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">Th 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-05-01" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023852_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023852_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023852_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023852_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023852.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fR7r20bPx2yHJKreYhpy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B071</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-05-01" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023529_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023529_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023529_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023529_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-023529.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fQoJ1gBpFNuBLmW60nAy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B070</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-023331_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-023331_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-023331_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-023331_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-023331.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fP8TbuaGy59seM8m0WPy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B069</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022834_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022834_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022834_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022834_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022834.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fN_LO1Ja0OqrCFdVBLGl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B068</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022540_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022540_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022540_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022540_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022540.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fM4v9n8g896u5aI5mRLO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B067</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022234_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022234_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022234_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022234_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022234.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fLtbFGavHkqLKFeGGRYK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B066</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022014_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022014_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022014_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022014_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-022014.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fK0W4khXzIykzykMIEFF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B065</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-05-01" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-021841_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-021841_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-021841_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-021841_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-021841.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fKMx-XVe888B45E489SM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B064</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021652_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021652_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021652_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021652_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021652.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fJfbCjWREyH2bIXCuTwj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B063</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021403_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021403_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021403_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021403_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021403.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fIbNBYOeGn5AXBrkOm3t" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B062</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021051_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021051_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021051_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021051_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-021051.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fHMNtufjYy2QbDKF1NEd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B061</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020755_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020755_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020755_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020755_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020755.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fGE-ChwVx7X1To61T8Kx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B060</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020547_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020547_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020547_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020547_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020547.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fFSmrnlMsrPJV33nc6y4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B059</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020257_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020257_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020257_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020257_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-020257.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fEPRav68xx6CVP9U7sbj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B058</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-05-01" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-015849_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-015849_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-015849_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-015849_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Base-Layout-20200501-015849.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fCsv7Y4RdiEFzo2C8eGD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B057</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-05-01" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Base-Layout-20200501-015559_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Base-Layout-20200501-015559_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Base-Layout-20200501-015559_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Base-Layout-20200501-015559_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Base-Layout-20200501-015559.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAAMQAAAAF4fBoHAnoknFntL_ILHurK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B056</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015330_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015330_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015330_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015330_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015330.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4fAty5ZpdLexmWa2pXOGK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B055</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015110_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015110_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015110_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015110_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-015110.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4e_2PHHwiKTT1DBGEeEbL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B054</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014733_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014733_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014733_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014733_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014733.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4e-hhkX_3FBRYpe_Js0Ec" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B053</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-05-01" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014037_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014037_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014037_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014037_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-Base-Layout-20200501-014037.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAAMQAAAAF4e7-M8cerQH6tsxpGGlQG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B052</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-225756_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-225756_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-225756_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-225756_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-225756.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUpETdq2VdlroTU01JB7E" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B051</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225516_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225516_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225516_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225516_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225516.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUoWRKQxOdiolsiNmnw8T" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B050</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225318_640px.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225318_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225318_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225318_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-225318.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUnxvKUCqVoUYWN5_MOGt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B049</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-224106_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-224106_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-224106_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-224106_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-224106.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUkr9nROQQy9OgcbPGiA1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B048</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223659_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223659_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223659_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223659_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223659.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUkHCDQ7mHgTcif8Sn82W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B047</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223555_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223555_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223555_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223555_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223555.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUjDfNyO1a6r3fFvZMmhS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223415_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223415_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223415_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223415_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-223415.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUilnXA12NioJd6nczqGe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222745_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222745_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222745_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222745_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222745.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUiJP0BlHJXtTTYqMMGmT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222704_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222704_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222704_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222704_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222704.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUgj2GpRrSfticj-TP5Bf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222551_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222551_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222551_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222551_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-222551.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUgMhXI1--CNJWlVE9RkQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222453_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222453_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222453_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222453_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222453.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUf6ivm1QNlKFXmWyoUsP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222333_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222333_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222333_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222333_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222333.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUficzjnNowMlDcVAHyMg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222230_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222230_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222230_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222230_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-222230.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUfN2GPZ-ySgRuuNrz4aQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222022_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222022_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222022_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222022_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-222022.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUem2QjcmtHo4CSPGQwuI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221853_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221853_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221853_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221853_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221853.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUeLXZ7eno3vALIo1Rqyl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221358_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221358_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221358_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221358_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-221358.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUcxjgj1v7SgGPnXLg8RX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220748_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220748_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220748_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220748_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220748.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUa88Bw8k1Zvm65rExP74" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220651_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220651_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220651_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220651_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220651.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUar3r8SPHN2QMtx-wUFA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220458_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220458_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220458_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220458_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220458.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUaFDggcjSCkp8rIa8TUb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220330_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220330_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220330_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220330_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220330.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUZo_kHIwlaVgNUxw1T5r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220246_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220246_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220246_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220246_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220246.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUZZG-yLRPdlm4_zKSIku" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220154_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220154_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220154_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220154_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220154.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUZIbVbWa2KcfRuVZL9tn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220114_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220114_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220114_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220114_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220114.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUY8WXy8AZy-Fj7HZcT8H" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220008_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220008_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220008_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220008_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-220008.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUYl5X9dDEio4aJXKmhe4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215843_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215843_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215843_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215843_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215843.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUYL1XLWbhLu5AECR7yVu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215743_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215743_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215743_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215743_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215743.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUX5arcBGm6TQINTIMXMr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-215533_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-215533_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-215533_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-215533_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-215533.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUXPA_UsB3qJh3ODCXWcr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-215424_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-215424_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-215424_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-215424_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-215424.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUW72AkO9dWP_MUVM3274" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215324_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215324_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215324_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215324_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215324.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUWpVKg7ao_MRYMpnVLLE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215138_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215138_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215138_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215138_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-215138.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUWHsTGVSNnuhDNNeRki8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214937_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214937_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214937_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214937_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214937.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUVeZj5UB5sXYUoJfppGN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214836_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214836_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214836_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214836_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214836.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUVK2M3Uy0oLXdF_aqwKL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214723_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214723_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214723_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214723_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214723.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUUy90251Mgu63yCTzm2t" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214612_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214612_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214612_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214612_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-214612.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUUbyh-Pytw7fd_GUO3ZK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214430_640_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214430_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214430_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214430_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214430.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUT7dZA4MFt3ecIpgBSoD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214354_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214354_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214354_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214354_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214354.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUTvmGsELwl4MaEKokr3M" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214258_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214258_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214258_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214258_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-214258.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUTctouuic5Obi4uR7pVY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214211_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214211_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214211_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214211_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-214211.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUTJhftSbIpU-RcfASlGR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-10-12" data-title="Townhall 9 Trophy Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-213401_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-213401_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-213401_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-213401_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Trophy/Townhall-9-Trophy-Base-ClashofClans-20191012-213401.jpg 2430w"
                        title="Townhall 9 | Trophy Base." alt="Townhall 9 | Trophy Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUQs1YZmFNUeADBg82oRt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212641_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212641_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212641_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212641_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212641.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUOZTYE_NgvkeN7wP1_vS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212549_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212549_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212549_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212549_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212549.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUOHi57zyyf6lvfj-Ny9J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212502_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212502_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212502_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212502_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212502.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUN2rlIpDQgnUMvCwW62I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212348_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212348_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212348_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212348_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212348.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUNc5g3ciTkU5hhwwHbOy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-10-12" data-title="Townhall 9 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212226_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212226_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212226_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212226_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Townhall-9-War-Base-ClashofClans-20191012-212226.jpg 2430w"
                        title="Townhall 9 | War Base." alt="Townhall 9 | War Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAADgAAAAGbUNAMsCvJA8FXqO9Gu4x6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212145_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212145_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212145_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212145_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212145.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUMxhyUDi18hreJKF1bjk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributors"]' data-date-created="2019-10-12" data-title="Townhall 9 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad throttle={250} offset={500}>
                      <Image
                        src="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212020_640w.jpg"
                        srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212020_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212020_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212020_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Townhall-9-Farm-Base-ClashofClans-20191012-212020.jpg 2430w"
                        title="Townhall 9 | Farm Base." alt="Townhall 9 | Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AHV%3AAAAADgAAAAGbUMXcbZlGd-tXcHXb_tGP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 9</p>
                  </div>
                </div>
              </div>
            </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributors"]' data-date-created="2019-08-01" data-title="Standard Farm Base by Caden">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-FarmBase-Screenshot_20190727-205803_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-FarmBase-Screenshot_20190727-205803_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-FarmBase-Screenshot_20190727-205803_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-FarmBase-Screenshot_20190727-205803_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-FarmBase-Screenshot_20190727-205803.jpg 2430w"
                          title="Townhall 9 | Farm Base by Caden." alt="Townhall 9 | Farm Base by Caden." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AHV%3AAAAABgAAAAG16C0zGiHA0V3krqZQNb18" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributors"]' data-date-created="2019-08-01" data-title="Standard War Base by Marvin">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot829829749_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot829829749_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot829829749_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot829829749_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot829829749.jpg 2430w"
                          title="Townhall 9 | War Base by Marvin." alt="Townhall 9 | War Base by Marvin." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAACwAAAAGQI9o2bdsY8E8nRrgkV97r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributors"]' data-date-created="2019-07-30" data-title="Standard War Base by Marvin">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot93020193_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot93020193_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot93020193_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot93020193_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/Th9-Warbase-Screenshot93020193.jpg 2430w"
                          title="Townhall 9 | War Base by Marvin." alt="Townhall 9 | War Base by Marvin." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3AWB%3AAAAACwAAAAGQI-535j16LCTeiC2ACH2Q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributors"]' data-date-created="2019-07-30" data-title="Standard Farm Base by Caden">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-Farmbase-Screenshot_20190727-210539_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-Farmbase-Screenshot_20190727-210539_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-Farmbase-Screenshot_20190727-210539_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-Farmbase-Screenshot_20190727-210539_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-Farm/Th9-Farmbase-Screenshot_20190727-210539.jpg 2430w"
                          title="Townhall 9 | Farm Base by Caden." alt="Townhall 9 | Farm Base by Caden." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAABgAAAAG16EMYRBlki5Us28zXZHWg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributors"]' data-date-created="2019-07-18" data-title="Anti 3 Star">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://townhall-9.clashbases.de/Townhall-9-War/townhall-9-warbase-anti-3.collaborator1_640w.jpg"
                          srcSet="https://townhall-9.clashbases.de/Townhall-9-War/townhall-9-warbase-anti-3.collaborator1_640w.jpg 320w, https://townhall-9.clashbases.de/Townhall-9-War/townhall-9-warbase-anti-3.collaborator1_1024w.jpg 640w, https://townhall-9.clashbases.de/Townhall-9-War/townhall-9-warbase-anti-3.collaborator1_1920w.jpg 1920w, https://townhall-9.clashbases.de/Townhall-9-War/townhall-9-warbase-anti-3.collaborator1.jpg 2430w"
                          title="Townhall 9 | War Base Anti 3 Star." alt="Townhall 9 | War Base Anti 3 Star." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3AWB%3AAAAABAAAAAHEovAnYe5hTWwRhNfTC1qG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 9</p>
                    </div>
                  </div>
                </div>
              </figure>

            </div>
          </div>



          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 9 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhallnine;
