import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTopAtStart from './components/ScrollToTopAtStart';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const renderLoader = () => <div className="page-loading-outer"><div className="page-loading-middle"><div className="page-loading-inner"><p className="page-loading-text">Loading...</p></div></div></div>;

ReactDOM.render(
  <Router>
    <Suspense fallback={renderLoader()}>
      <ScrollToTopAtStart />
      <App />
    </Suspense>
  </Router>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
