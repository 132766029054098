import React, { Component } from 'react';
import "./ad.scss";
  /**
  * Ad Component
  * Current Props
  * @prop adID: the ID the delivery script will reference
  * @prop adType: Currently just used to dictate styles
  *
  * Example Usage:
    Display Ads
  * <Ad adType="ad-container" adID="332bf474-f980-4f6b-a573-a62fca491a52" />
  *
  * Video Ad
  * <Ad adType="video-ad-container" adID="fe0fe7bc-90cd-ac31-2d7b-fe67761085b5" />
  */
class Ad extends Component {
  componentDidMount() {
        if(this.props.adType === 'video-ad-container') {
          let placementID = this.props.adID;

          let voltContainer = document.createElement('div');
          let targetDiv = document.createElement('div');
          let script1 = document.createElement('script');
          let script2 = document.createElement('script');
          let script3 = document.createElement('script');

          voltContainer.setAttribute('class', 'mmt-volt');

          targetDiv.id = "mm-volt";

          script1.innerHTML = '!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-PL4PD49",m.parentNode.insertBefore(r,m)}(window.parent,window.parent.document,"script","dataLayer"); var iframe=window.parent.document.createElement("iframe");iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-PL4PD49";iframe.height="0";iframe.width="0";iframe.style="display:none;visibility:hidden";window.parent.document.body.appendChild(iframe); window.parent["$$mm-analytics"] = window.parent["$$mm-analytics"] || {};if (typeof window.parent["$$mm-analytics"].firstEmbed === "undefined") {window.parent["$$mm-analytics"].firstEmbed = Date.now();}';
          script1.type = "text/javascript";

          script2.src = "https://bucket1.mm-syringe.com/prod/configs/" + placementID + ".js";
          script2.type = "text/javascript";

          script3.src = "https://bucket1.mm-syringe.com/prod/injector/injector.1.5.3.js";
          script3.type = "text/javascript";

          voltContainer.insertAdjacentElement('afterbegin', targetDiv);
          voltContainer.insertAdjacentElement('beforeend', script1);
          voltContainer.insertAdjacentElement('beforeend', script2);
          voltContainer.insertAdjacentElement('beforeend', script3);

          setTimeout(() => {
            this.div.insertAdjacentElement('afterbegin', voltContainer);
          },1000)

      } else {
          /* Build ad script and place it after the element */
          const script = document.createElement('script');
          script.type = 'text/javascript';
          /** TODO: Remove ***TESTING SCRIPT*** log before pushing to production
          script.innerHTML = `$MMT = window.$MMT || {}; $MMT.cmd = $MMT.cmd || [];$MMT.cmd.push(function(){ console.log("***TESTING SCRIPT***"); $MMT.display.slots.push(["${this.props.adID}"]); })`;
          setTimeout(() => {
            this.div.after(script);
          },1000) */
      }
  }
  render() {
    return (
      <div id={`mmt-${this.props.adID}`} className={this.props.adType} ref={el => (this.div = el)}></div>
    )
  }
}
export default Ad;
