import React from 'react';
import './../Wiki.css';
import { Link as LinkToAnchor } from "react-scroll";
import Collapsible from 'react-collapsible';
import LazyLoad from 'react-lazyload';
import './carousel.css';
import { Carousel } from 'react-responsive-carousel';



// BUILDINGS - RESOURCES

const TriggerTownhall = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon townhall"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Townhall</span></div>
</div>;
const TriggerClanCastle = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon clancastle"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Clan Castle</span></div>
</div>;
const TriggerElixirCollector = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon elixircollector"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Elixir Collector</span></div>
</div>;
const TriggerElixirStorage = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon elixirstorage"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Elixir Storage</span></div>
</div>;
const TriggerGoldMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon goldmine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Gold Mine</span></div>
</div>;
const TriggerGoldStorage = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon goldstorage"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Gold Storage</span></div>
</div>;
const TriggerDarkElixirDrill = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon darkelixirdrill"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Dark Elixir Drill</span></div>
</div>;
const TriggerDarkElixirStorage = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon darkelixirstorage"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Dark Elixir Storage</span></div>
</div>;

// BUILDINGS - DEFENSES

const TriggerBuildersHut = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon buildershut"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Builder's Hut</span></div>
</div>;
const TriggerCannon = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon cannon"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Cannon</span></div>
</div>;
const TriggerArcherTower = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon archertower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Archer Tower</span></div>
</div>;
const TriggerMortar = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon mortar"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Mortar</span></div>
</div>;
const TriggerAirDefense = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon airdefense"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Air Defense</span></div>
</div>;
const TriggerWizardTower = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon wizardtower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Wizard Tower</span></div>
</div>;
const TriggerAirSweeper = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon airsweeper"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Air Sweeper</span></div>
</div>;
const TriggerHiddenTesla = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon hiddentesla"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Hidden Tesla</span></div>
</div>;
const TriggerBombTower = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon bombtower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Bomb Tower</span></div>
</div>;
const TriggerXBow = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon xbow"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">X-Bow</span></div>
</div>;
const TriggerInferno = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon infernotower"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Inferno Tower</span></div>
</div>;
const TriggerEagleArtillery = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon eagleartillery"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Eagle Artillery</span></div>
</div>;
const TriggerScattershot = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon scattershot"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Scattershot</span></div>
</div>;
const TriggerGigaTesla = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon gigatesla"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Giga Tesla</span></div>
</div>;
const TriggerGigaInferno13 = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon gigainferno13"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Giga Inferno 13</span></div>
</div>;
const TriggerGigaInferno14 = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon gigainferno14"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Giga Inferno 14</span></div>
</div>;
const TriggerWall = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon wall"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Wall</span></div>
</div>;

// BUILDINGS - ARMY

const TriggerArmyCamp = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon armycamp"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Army Camp</span></div>
</div>;
const TriggerBarracks = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon barracks"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Barracks</span></div>
</div>;
const TriggerDarkBarracks = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon darkbarracks"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Dark Barracks</span></div>
</div>;
const TriggerLaboratory = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon laboratory"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Laboratory</span></div>
</div>;
const TriggerSpellFactory = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon spellfactory"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Spell Factory</span></div>
</div>;
const TriggerDarkSpellFactory = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon darkspellfactory"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Dark Spell Factory</span></div>
</div>;
const TriggerWorkshop = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon workshop"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Workshop</span></div>
</div>;
const TriggerPetHouse = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon pethouse"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Pet House</span></div>
</div>;

// BUILDINGS - TRAPS

const TriggerBomb = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon bomb"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Bomb</span></div>
</div>;
const TriggerGiantBomb = () =>
<div className="homebasewiki-buildings-item-container">
  <div className="homebasewiki-buildings-item-icon giantbomb"></div>
  <div className="homebasewiki-buildings-item-name"><span className="center-name">Giant Bomb</span></div>
</div>;
const TriggerSpringTrap = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon springtrap"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Spring Trap</span></div>
</div>;
const TriggerAirBomb = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon airbomb"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Air Bomb</span></div>
</div>;
const TriggerSeekingAirMine = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon seekingairmine"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Seeking Air Mine</span></div>
</div>;
const TriggerSkeletonTrap = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon skeletontrap"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Skeleton Trap</span></div>
</div>;
const TriggerTornadotrap = () =>
<div className="homebasewiki-buildings-item-container">
    <div className="homebasewiki-buildings-item-icon tornadotrap"></div>
    <div className="homebasewiki-buildings-item-name"><span className="center-name">Tornado Trap</span></div>
</div>;





const homebuildings  = props => (

      <section className="homebasewiki">

        <div className="hometext-container-building">
          <div className="hometext-info-container">
            <div className="hometext-about">
              <div className="hometext-about-container">
                <h3 className="hometext-about-title">Home Village</h3>
              </div>
              <p className="hometext-about-text">Below you will find <span className="orange-color">information and stats</span> about the home village.< br/>< br/>Just <span className="orange-color">click</span> on the items to expand, and navigate through the page by clicking on the buttons! < br/>< br/> It's still a <span className="orange-color">work in progress</span>, so there will be more changes over the next weeks.</p>
            </div>
          </div>
        </div>

        <div className="homebasewiki-container">

            <div className="homebasewiki-buildings-container">

                <div className="homebuildingsbackground">
                  <h1 className="homebasewiki-buildings-maintitel" id="Buildings">Buildings</h1>
                </div>

                <h2 className="homebasewiki-buildings-titel" id="Resource">Resource</h2>
                {/* NAVBAR - BUILDINGS - RESOURCES */}
                <nav className="homebasewiki-nav">
                    <div className="homebasewiki-nav-content">
                        <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                        <li className="homebasewiki-nav-item"><LinkToAnchor to="Trap" smooth={true} offset={-70}>Trap</LinkToAnchor></li>
                    </div>
                </nav>

                {/* TOWNHALL */}
                <Collapsible trigger={<TriggerTownhall />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv12-5.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv13-5.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Townhall' src='https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv14-5.png'/><p className="legend">Level 14</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td colSpan="3" className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>-</td><td>-</td><td>(gold)</td><td>(elixir)</td><td>(dark elixir)</td><td>-</td><td>-</td></tr>
                        <tr><td>1</td><td>450</td><td>1 k</td><td>1 k</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1600</td><td>1 k</td><td>1 k</td><td>-</td><td>1 k</td><td>10 s</td></tr>
                        <tr><td>3</td><td>1850</td><td>10 k</td><td>10 k</td><td>-</td><td>4 k</td><td>1 h</td></tr>
                        <tr><td>4</td><td>2100</td><td>50 k</td><td>50 k</td><td>-</td><td>25 k</td><td>8 h</td></tr>
                        <tr><td>5</td><td>2400</td><td>100 k</td><td>100 k</td><td>-</td><td>150 k</td><td>12 h</td></tr>
                        <tr><td>6</td><td>2800</td><td>300 k</td><td>300 k</td><td>-</td><td>750 k</td><td>1 d</td></tr>
                        <tr><td>7</td><td>3300</td><td>500 k</td><td>500 k</td><td>2,5 k</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>3900</td><td>750 k</td><td>750 k</td><td>5 k</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>9</td><td>4600</td><td>1 m</td><td>1 m</td><td>10 k</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>10</td><td>5500</td><td>1,5 m</td><td>1,5 m</td><td>20 k</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>11</td><td>6800</td><td>2 m</td><td>2 m</td><td>20 k</td><td>6,5 m</td><td>8 d</td></tr>
                        <tr><td>12</td><td>7500</td><td>2 m</td><td>2 m</td><td>20 k</td><td>9,5 m</td><td>14 d</td></tr>
                        <tr><td>13</td><td>8200</td><td>2 m</td><td>2 m</td><td>20 k</td><td>12 m</td><td>18 d</td></tr>
                        <tr><td>14</td><td>8900</td><td>2 m</td><td>2 m</td><td>20 k</td><td>16 m</td><td>20 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* CLAN CASTLE */}
                <Collapsible trigger={<TriggerClanCastle />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv00.png'/><p className="legend">Ruins</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Clan Castle' src='https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="8">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Troops</td><td className="orange-font">Spells</td><td className="orange-font">Siege</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>1000</td><td>10</td><td>-</td><td>-</td><td>10 k</td><td>-</td></tr>
                        <tr><td>2</td><td>4</td><td>1400</td><td>15</td><td>-</td><td>-</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>3</td><td>6</td><td>2000</td><td>20</td><td>-</td><td>-</td><td>800 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>8</td><td>2600</td><td>25</td><td>1</td><td>-</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>5</td><td>9</td><td>3000</td><td>30</td><td>1</td><td>-</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>10</td><td>3400</td><td>35</td><td>1</td><td>1</td><td>5 m</td><td>6 d</td></tr>
                        <tr><td>7</td><td>11</td><td>4000</td><td>35</td><td>2</td><td>1</td><td>8 m</td><td>9 d</td></tr>
                        <tr><td>8</td><td>12</td><td>4400</td><td>40</td><td>2</td><td>1</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>9</td><td>13</td><td>4800</td><td>45</td><td>2</td><td>1</td><td>16 m</td><td>18 d</td></tr>
                        <tr><td>10</td><td>14</td><td>5200</td><td>45</td><td>3</td><td>1</td><td>19 m</td><td>20 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ELIXIR COLLECTOR */}
                <Collapsible trigger={<TriggerElixirCollector />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Elixir Collector' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv14.png'/><p className="legend">Level 14</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>400</td><td>200</td><td>1 k</td><td>150</td><td>10 s</td></tr>
                        <tr><td>2</td><td>1</td><td>440</td><td>400</td><td>2 k</td><td>300</td><td>1 m</td></tr>
                        <tr><td>3</td><td>2</td><td>480</td><td>600</td><td>3 k</td><td>700</td><td>4 m</td></tr>
                        <tr><td>4</td><td>2</td><td>520</td><td>800</td><td>5 k</td><td>1,4 k</td><td>10 m</td></tr>
                        <tr><td>5</td><td>3</td><td>560</td><td>1 k</td><td>10 k</td><td>3,5 k</td><td>40 m</td></tr>
                        <tr><td>6</td><td>3</td><td>600</td><td>1,3 k</td><td>20 k</td><td>7 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>4</td><td>640</td><td>1,6 k</td><td>30 k</td><td>14 k</td><td>6 h</td></tr>
                        <tr><td>8</td><td>4</td><td>680</td><td>1,9 k</td><td>50 k</td><td>28 k</td><td>8 h</td></tr>
                        <tr><td>9</td><td>5</td><td>720</td><td>2,2 k</td><td>75 k</td><td>56 k</td><td>10 h</td></tr>
                        <tr><td>10</td><td>5</td><td>780</td><td>2,8 k</td><td>100 k</td><td>75 k</td><td>12 h</td></tr>
                        <tr><td>11</td><td>7</td><td>860</td><td>3,5 k</td><td>150 k</td><td>100 k</td><td>16 h</td></tr>
                        <tr><td>12</td><td>8</td><td>960</td><td>4,2 k</td><td>200 k</td><td>200 k</td><td>20 h</td></tr>
                        <tr><td>13</td><td>10</td><td>1080</td><td>4,9 k</td><td>250 k</td><td>400 k</td><td>1,7 d</td></tr>
                        <tr><td>14</td><td>11</td><td>1180</td><td>5,6 k</td><td>300 k</td><td>800 k</td><td>3,3 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ELIXIR STORAGE */}
                <Collapsible trigger={<TriggerElixirStorage />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv14.png'/><p className="legend">Level 14</p></div>
                        <div><img alt='Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv15.png'/><p className="legend">Level 15</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>400</td><td>1,5 k</td><td>300</td><td>10 s</td></tr>
                        <tr><td>2</td><td>2</td><td>600</td><td>3 k</td><td>750</td><td>5 m</td></tr>
                        <tr><td>3</td><td>2</td><td>800</td><td>6 k</td><td>1,5 k</td><td>20 m</td></tr>
                        <tr><td>4</td><td>3</td><td>1000</td><td>12 k</td><td>3 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>3</td><td>1200</td><td>25 k</td><td>6 k</td><td>2 h</td></tr>
                        <tr><td>6</td><td>3</td><td>1400</td><td>45 k</td><td>12 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>4</td><td>1600</td><td>100 k</td><td>25 k</td><td>4 h</td></tr>
                        <tr><td>8</td><td>4</td><td>1700</td><td>225 k</td><td>50 k</td><td>5 h</td></tr>
                        <tr><td>9</td><td>5</td><td>1800</td><td>450 k</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>10</td><td>6</td><td>1900</td><td>850 k</td><td>250 k</td><td>12 h</td></tr>
                        <tr><td>11</td><td>7</td><td>2100</td><td>1,75 m</td><td>500 k</td><td>16 h</td></tr>
                        <tr><td>12</td><td>11</td><td>2500</td><td>2 m</td><td>1,5 m</td><td>3 d</td></tr>
                        <tr><td>13</td><td>12</td><td>2900</td><td>3 m</td><td>3 m</td><td>6 d</td></tr>
                        <tr><td>14</td><td>13</td><td>3300</td><td>4 m</td><td>4,5 m</td><td>9 d</td></tr>
                        <tr><td>15</td><td>14</td><td>3700</td><td>4,5 m</td><td>6 m</td><td>12 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GOLD Mine */}
                <Collapsible trigger={<TriggerGoldMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Gold Mine' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv14.png'/><p className="legend">Level 14</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>400</td><td>200</td><td>1 k</td><td>150</td><td>10 s</td></tr>
                        <tr><td>2</td><td>1</td><td>440</td><td>400</td><td>2 k</td><td>300</td><td>1 m</td></tr>
                        <tr><td>3</td><td>2</td><td>480</td><td>600</td><td>3 k</td><td>700</td><td>4 m</td></tr>
                        <tr><td>4</td><td>2</td><td>520</td><td>800</td><td>5 k</td><td>1,4 k</td><td>10 m</td></tr>
                        <tr><td>5</td><td>3</td><td>560</td><td>1 k</td><td>10 k</td><td>3,5 k</td><td>40 m</td></tr>
                        <tr><td>6</td><td>3</td><td>600</td><td>1,3 k</td><td>20 k</td><td>7 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>4</td><td>640</td><td>1,6 k</td><td>30 k</td><td>14 k</td><td>6 h</td></tr>
                        <tr><td>8</td><td>4</td><td>680</td><td>1,9 k</td><td>50 k</td><td>28 k</td><td>8 h</td></tr>
                        <tr><td>9</td><td>5</td><td>720</td><td>2,2 k</td><td>75 k</td><td>56 k</td><td>10 h</td></tr>
                        <tr><td>10</td><td>5</td><td>780</td><td>2,8 k</td><td>100 k</td><td>75 k</td><td>12 h</td></tr>
                        <tr><td>11</td><td>7</td><td>860</td><td>3,5 k</td><td>150 k</td><td>100 k</td><td>16 h</td></tr>
                        <tr><td>12</td><td>8</td><td>960</td><td>4,2 k</td><td>200 k</td><td>200 k</td><td>20 h</td></tr>
                        <tr><td>13</td><td>10</td><td>1080</td><td>4,9 k</td><td>250 k</td><td>400 k</td><td>1,7 d</td></tr>
                        <tr><td>14</td><td>11</td><td>1180</td><td>5,6 k</td><td>300 k</td><td>800 k</td><td>3,3 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GOLD STORAGE */}
                <Collapsible trigger={<TriggerGoldStorage />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv14.png'/><p className="legend">Level 14</p></div>
                        <div><img alt='Gold Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv15.png'/><p className="legend">Level 15</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>400</td><td>1,5 k</td><td>300</td><td>10 s</td></tr>
                        <tr><td>2</td><td>2</td><td>600</td><td>3 k</td><td>750</td><td>5 m</td></tr>
                        <tr><td>3</td><td>2</td><td>800</td><td>6 k</td><td>1,5 k</td><td>20 m</td></tr>
                        <tr><td>4</td><td>3</td><td>1000</td><td>12 k</td><td>3 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>3</td><td>1200</td><td>25 k</td><td>6 k</td><td>2 h</td></tr>
                        <tr><td>6</td><td>3</td><td>1400</td><td>45 k</td><td>12 k</td><td>3 h</td></tr>
                        <tr><td>7</td><td>4</td><td>1600</td><td>100 k</td><td>25 k</td><td>4 h</td></tr>
                        <tr><td>8</td><td>4</td><td>1700</td><td>225 k</td><td>50 k</td><td>5 h</td></tr>
                        <tr><td>9</td><td>5</td><td>1800</td><td>450 k</td><td>100 k</td><td>8 h</td></tr>
                        <tr><td>10</td><td>6</td><td>1900</td><td>850 k</td><td>250 k</td><td>12 h</td></tr>
                        <tr><td>11</td><td>7</td><td>2100</td><td>1,75 m</td><td>500 k</td><td>16 h</td></tr>
                        <tr><td>12</td><td>11</td><td>2500</td><td>2 m</td><td>1,5 m</td><td>3 d</td></tr>
                        <tr><td>13</td><td>12</td><td>2900</td><td>3 m</td><td>3 m</td><td>6 d</td></tr>
                        <tr><td>14</td><td>13</td><td>3300</td><td>4 m</td><td>4,5 m</td><td>9 d</td></tr>
                        <tr><td>15</td><td>14</td><td>3700</td><td>4,5 m</td><td>6 m</td><td>12 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* DARK ELIXIR DRILL */}
                <Collapsible trigger={<TriggerDarkElixirDrill />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Dark Elixir Drill' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv08.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Prod</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>800</td><td>20</td><td>160</td><td>500 k</td><td>6 h</td></tr>
                        <tr><td>2</td><td>7</td><td>860</td><td>30</td><td>300</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>7</td><td>920</td><td>45</td><td>540</td><td>900 k</td><td>18 h</td></tr>
                        <tr><td>4</td><td>9</td><td>980</td><td>60</td><td>840</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>5</td><td>9</td><td>1060</td><td>80</td><td>1280</td><td>1,5 m</td><td>1,5 d</td></tr>
                        <tr><td>6</td><td>9</td><td>1160</td><td>100</td><td>1800</td><td>1,8 m</td><td>2 d</td></tr>
                        <tr><td>7</td><td>10</td><td>1280</td><td>120</td><td>2400</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>11</td><td>1380</td><td>140</td><td>3000</td><td>3 m</td><td>4 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* DARK ELIXIR STORAGE */}
                <Collapsible trigger={<TriggerDarkElixirStorage />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Dark Elixir Storage' src='https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Storage</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>2000</td><td>10 k</td><td>250 k</td><td>8 h</td></tr>
                        <tr><td>2</td><td>7</td><td>2200</td><td>17,5 k</td><td>500 k</td><td>16 h</td></tr>
                        <tr><td>3</td><td>8</td><td>2400</td><td>40 k</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>4</td><td>8</td><td>2600</td><td>75 k</td><td>1,5 m</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>9</td><td>2900</td><td>140 k</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>9</td><td>3200</td><td>180 k</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>12</td><td>3500</td><td>220 k</td><td>6 m</td><td>5 d</td></tr>
                        <tr><td>8</td><td>13</td><td>3800</td><td>280 k</td><td>9 m</td><td>10 d</td></tr>
                        <tr><td>9</td><td>14</td><td>4100</td><td>330 k</td><td>12 m</td><td>15 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="Army">Army</h2>
                {/* NAVBAR - BUILDINGS - ARMY */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Trap" smooth={true} offset={-70}>Trap</LinkToAnchor></li>
                  </div>
                </nav>

                {/* ARMY CAMP */}
                <Collapsible trigger={<TriggerArmyCamp />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Army Camp' src='https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv11.png'/><p className="legend">Level 11</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Capacity</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>250</td><td>20</td><td>200</td><td>5 m</td></tr>
                        <tr><td>2</td><td>2</td><td>270</td><td>30</td><td>2 k</td><td>15 m</td></tr>
                        <tr><td>3</td><td>3</td><td>290</td><td>35</td><td>10 k</td><td>2 h</td></tr>
                        <tr><td>4</td><td>4</td><td>310</td><td>40</td><td>100 k</td><td>5 h</td></tr>
                        <tr><td>5</td><td>5</td><td>330</td><td>45</td><td>250 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>6</td><td>350</td><td>50</td><td>750 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>9</td><td>400</td><td>55</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>10</td><td>500</td><td>60</td><td>3 m</td><td>3,5 d</td></tr>
                        <tr><td>9</td><td>11</td><td>600</td><td>65</td><td>6 m</td><td>9 d</td></tr>
                        <tr><td>10</td><td>12</td><td>700</td><td>70</td><td>9 m</td><td>14 d</td></tr>
                        <tr><td>11</td><td>13</td><td>800</td><td>75</td><td>16 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BARRACKS */}
                <Collapsible trigger={<TriggerBarracks />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv14.png'/><p className="legend">Level 14</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>250</td><td>Barbarian</td><td>100</td><td>10 s</td></tr>
                        <tr><td>2</td><td>1</td><td>290</td><td>Archer</td><td>500</td><td>1 m</td></tr>
                        <tr><td>3</td><td>1</td><td>330</td><td>Giant</td><td>2,5 k</td><td>10 m</td></tr>
                        <tr><td>4</td><td>2</td><td>370</td><td>Goblin</td><td>5 k</td><td>1 h</td></tr>
                        <tr><td>5</td><td>3</td><td>420</td><td>Wallbreaker</td><td>10 k</td><td>4 h</td></tr>
                        <tr><td>6</td><td>4</td><td>470</td><td>Baloon</td><td>80 k</td><td>8 h</td></tr>
                        <tr><td>7</td><td>5</td><td>520</td><td>Wizard</td><td>240 k</td><td>12 h</td></tr>
                        <tr><td>8</td><td>6</td><td>600</td><td>Healer</td><td>700 k</td><td>16 h</td></tr>
                        <tr><td>9</td><td>7</td><td>720</td><td>Dragon</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>10</td><td>8</td><td>730</td><td>P.E.K.K.A</td><td>1,5 m</td><td>1,5 d</td></tr>
                        <tr><td>11</td><td>9</td><td>810</td><td>Baby Dragon</td><td>2 m</td><td>2,5 d</td></tr>
                        <tr><td>12</td><td>10</td><td>900</td><td>Miner</td><td>3 m</td><td>4 d</td></tr>
                        <tr><td>13</td><td>11</td><td>980</td><td>E. Dragon</td><td>4 m</td><td>6 d</td></tr>
                        <tr><td>14</td><td>12</td><td>1050</td><td>Yeti</td><td>5 m</td><td>9 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* DARK BARRACKS */}
                <Collapsible trigger={<TriggerDarkBarracks />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Dark Barracks' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Barracks/DarkBarracks-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>500</td><td>Minion</td><td>300 k</td><td>4 h</td></tr>
                        <tr><td>2</td><td>7</td><td>550</td><td>Hog Rider</td><td>600 k</td><td>12 d</td></tr>
                        <tr><td>3</td><td>8</td><td>600</td><td>Valkyrie</td><td>900 k</td><td>18 d</td></tr>
                        <tr><td>4</td><td>8</td><td>650</td><td>Golem</td><td>1,3 m</td><td>1 d</td></tr>
                        <tr><td>5</td><td>9</td><td>700</td><td>Witch</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>9</td><td>750</td><td>Lavahound</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>10</td><td>800</td><td>Bowler</td><td>3 m</td><td>5 d</td></tr>
                        <tr><td>8</td><td>11</td><td>850</td><td>Ice Golem</td><td>4 m</td><td>9 d</td></tr>
                        <tr><td>9</td><td>12</td><td>900</td><td>Headhunter</td><td>7,5 m</td><td>13 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* LABORATORY */}
                <Collapsible trigger={<TriggerLaboratory />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Laboratory' src='https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv12.png'/><p className="legend">Level 12</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="5">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>500</td><td>5 k</td><td>1 m</td></tr>
                        <tr><td>2</td><td>4</td><td>550</td><td>25 k</td><td>1 h</td></tr>
                        <tr><td>3</td><td>5</td><td>600</td><td>75 k</td><td>2 h</td></tr>
                        <tr><td>4</td><td>6</td><td>650</td><td>150 k</td><td>4 h</td></tr>
                        <tr><td>5</td><td>7</td><td>700</td><td>300 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>8</td><td>750</td><td>600 k</td><td>16 h</td></tr>
                        <tr><td>7</td><td>9</td><td>830</td><td>1.2 m</td><td>1 d</td></tr>
                        <tr><td>8</td><td>10</td><td>950</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>9</td><td>11</td><td>1070</td><td>5 m</td><td>4 d</td></tr>
                        <tr><td>10</td><td>12</td><td>1140</td><td>8 m</td><td>6 d</td></tr>
                        <tr><td>11</td><td>13</td><td>1210</td><td>10 m</td><td>9 d</td></tr>
                        <tr><td>12</td><td>14</td><td>1280</td><td>12 m</td><td>12 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SPELL FACTORY */}
                <Collapsible trigger={<TriggerSpellFactory />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv06.png'/><p className="legend">Level 6</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>5</td><td>425</td><td>Lightning</td><td>200 k</td><td>8 h</td></tr>
                        <tr><td>2</td><td>6</td><td>470</td><td>Healing</td><td>400 k</td><td>1 d</td></tr>
                        <tr><td>3</td><td>7</td><td>520</td><td>Rage</td><td>800 k</td><td>2 d</td></tr>
                        <tr><td>4</td><td>9</td><td>600</td><td>Ice & Jump</td><td>1,2 m</td><td>3,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>720</td><td>Clone</td><td>2,4 m</td><td>5 d</td></tr>
                        <tr><td>6</td><td>11</td><td>840</td><td>Invisibility</td><td>4,8 m</td><td>7 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* DARK SPELL FACTORY */}
                <Collapsible trigger={<TriggerDarkSpellFactory />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Dark Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Dark Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Dark Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Dark Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Dark Spell Factory' src='https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>8</td><td>600</td><td>Poison</td><td>250 k</td><td>6 h</td></tr>
                        <tr><td>2</td><td>8</td><td>660</td><td>Earthquake</td><td>500 k</td><td>18 h</td></tr>
                        <tr><td>3</td><td>9</td><td>720</td><td>Haste</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>4</td><td>9</td><td>780</td><td>Skeleton</td><td>2 m</td><td>4 d</td></tr>
                        <tr><td>5</td><td>10</td><td>840</td><td>Bat</td><td>3 m</td><td>6 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* WORKSHOP */}
                <Collapsible trigger={<TriggerWorkshop />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Workshop' src='https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Workshop' src='https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Workshop' src='https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Workshop' src='https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Workshop' src='https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>12</td><td>1000</td><td>Wall Wrecker</td><td>7.5 m</td><td>6 d</td></tr>
                        <tr><td>2</td><td>12</td><td>1100</td><td>Battle Blimp</td><td>9 m</td><td>8 d</td></tr>
                        <tr><td>3</td><td>12</td><td>1200</td><td>Stone Slammer</td><td>10,5 m</td><td>10 d</td></tr>
                        <tr><td>4</td><td>13</td><td>1300</td><td>Siege Barracks</td><td>14,5 m</td><td>14 d</td></tr>
                        <tr><td>5</td><td>13</td><td>1400</td><td>Log Launcher</td><td>16 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* PET HOUSE */}
                <Collapsible trigger={<TriggerPetHouse />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Pet House' src='https://assets.clashbases.de/Building-Icons/Army/Pet-House/PetHouse-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Pet House' src='https://assets.clashbases.de/Building-Icons/Army/Pet-House/PetHouse-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Pet House' src='https://assets.clashbases.de/Building-Icons/Army/Pet-House/PetHouse-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Pet House' src='https://assets.clashbases.de/Building-Icons/Army/Pet-House/PetHouse-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Unlock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>14</td><td>700</td><td>L.A.S.S.I.</td><td>15 m</td><td>13 d</td></tr>
                        <tr><td>2</td><td>14</td><td>800</td><td>Electro Owl</td><td>17.5 m</td><td>15 d</td></tr>
                        <tr><td>3</td><td>14</td><td>900</td><td>Mighty Yak</td><td>18.5 m</td><td>17 d</td></tr>
                        <tr><td>4</td><td>14</td><td>1000</td><td>Unicorn</td><td>19,5 m</td><td>19 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="Defense">Defense</h2>
                {/* NAVBAR - BUILDINGS - DEFENSE */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Trap" smooth={true} offset={-70}>Trap</LinkToAnchor></li>
                  </div>
                </nav>

                {/* BUILDER'S HUT */}
                <Collapsible trigger={<TriggerBuildersHut />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv02-A.png'/><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv03-A.png'/><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv04-A.png'/><img alt='builders hut' src='https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Repair/s</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>250</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>1000</td><td>80</td><td>50</td><td>9,5 m</td><td>10 d</td></tr>
                        <tr><td>3</td><td>1300</td><td>100</td><td>50</td><td>12,5 m</td><td>12 d</td></tr>
                        <tr><td>4</td><td>1600</td><td>120</td><td>50</td><td>15.5 m</td><td>14 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* CANNON */}

                <Collapsible trigger={<TriggerCannon />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv14.png'/><p className="legend">Level 14</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv15.png'/><p className="legend">Level 15</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv16.png'/><p className="legend">Level 16</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv17.png'/><p className="legend">Level 17</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv18.png'/><p className="legend">Level 18</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv19.png'/><p className="legend">Level 19</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv20.png'/><p className="legend">Level 20</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>1</td><td>420</td><td>9</td><td>250</td><td>10 s</td></tr>
                        <tr><td>2</td><td>1</td><td>470</td><td>11</td><td>1 k</td><td>2 m</td></tr>
                        <tr><td>3</td><td>2</td><td>520</td><td>15</td><td>4 k</td><td>10 m</td></tr>
                        <tr><td>4</td><td>3</td><td>570</td><td>19</td><td>16 k</td><td>45 m</td></tr>
                        <tr><td>5</td><td>4</td><td>620</td><td>25</td><td>50 k</td><td>2 h</td></tr>
                        <tr><td>6</td><td>5</td><td>670</td><td>31</td><td>100 k</td><td>4 h</td></tr>
                        <tr><td>7</td><td>6</td><td>730</td><td>40</td><td>200 k</td><td>8 h</td></tr>
                        <tr><td>8</td><td>7</td><td>800</td><td>48</td><td>400 k</td><td>10 h</td></tr>
                        <tr><td>9</td><td>8</td><td>880</td><td>56</td><td>600 k</td><td>12 h</td></tr>
                        <tr><td>10</td><td>8</td><td>960</td><td>64</td><td>800 k</td><td>18 h</td></tr>
                        <tr><td>11</td><td>9</td><td>1060</td><td>74</td><td>1.25 m</td><td>1 d</td></tr>
                        <tr><td>12</td><td>10</td><td>1160</td><td>87</td><td>2 m</td><td>1,5 d</td></tr>
                        <tr><td>13</td><td>10</td><td>1260</td><td>100</td><td>3 m</td><td>2,5 d</td></tr>
                        <tr><td>14</td><td>11</td><td>1380</td><td>110</td><td>5 m</td><td>4,5 d</td></tr>
                        <tr><td>15</td><td>11</td><td>1500</td><td>118</td><td>7 m</td><td>6 d</td></tr>
                        <tr><td>16</td><td>12</td><td>1620</td><td>124</td><td>9 m</td><td>9 d</td></tr>
                        <tr><td>17</td><td>12</td><td>1740</td><td>130</td><td>12 m</td><td>12 d</td></tr>
                        <tr><td>18</td><td>13</td><td>1870</td><td>139</td><td>14 m</td><td>15 d</td></tr>
                        <tr><td>19</td><td>13</td><td>2000</td><td>146</td><td>16 m</td><td>16 d</td></tr>
                        <tr><td>20</td><td>14</td><td>2150</td><td>154</td><td>17,5 m</td><td>17 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* ARCHER TOWER */}
                <Collapsible trigger={<TriggerArcherTower />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv10-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv11-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv12-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv13-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv14-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv14.png'/><p className="legend">Level 14</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv15-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv15.png'/><p className="legend">Level 15</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv16-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv16.png'/><p className="legend">Level 16</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv17-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv17.png'/><p className="legend">Level 17</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv18-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv18.png'/><p className="legend">Level 18</p></div>
                        <div><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv19-G.png'/><img alt='archertower' src='https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv19.png'/><p className="legend">Level 19</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>2</td><td>380</td><td>11</td><td>1 k</td><td>1 m</td></tr>
                        <tr><td>2</td><td>2</td><td>420</td><td>15</td><td>2 k</td><td>15 m</td></tr>
                        <tr><td>3</td><td>3</td><td>460</td><td>19</td><td>5 k</td><td>45 m</td></tr>
                        <tr><td>4</td><td>4</td><td>500</td><td>25</td><td>20 k</td><td>3 h</td></tr>
                        <tr><td>5</td><td>5</td><td>540</td><td>30</td><td>80 k</td><td>5 h</td></tr>
                        <tr><td>6</td><td>5</td><td>580</td><td>35</td><td>180 k</td><td>8 h</td></tr>
                        <tr><td>7</td><td>6</td><td>630</td><td>42</td><td>360 k</td><td>10 h</td></tr>
                        <tr><td>8</td><td>7</td><td>690</td><td>48</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>9</td><td>8</td><td>750</td><td>56</td><td>900 k</td><td>14 h</td></tr>
                        <tr><td>10</td><td>8</td><td>810</td><td>63</td><td>1.2 m</td><td>18 h</td></tr>
                        <tr><td>11</td><td>9</td><td>890</td><td>70</td><td>1.5 m</td><td>1 d</td></tr>
                        <tr><td>12</td><td>10</td><td>970</td><td>75</td><td>2.5 m</td><td>1,5 d</td></tr>
                        <tr><td>13</td><td>10</td><td>1050</td><td>80</td><td>3.2 m</td><td>2,5 d</td></tr>
                        <tr><td>14</td><td>11</td><td>1130</td><td>92</td><td>5.5 m</td><td>5 d</td></tr>
                        <tr><td>15</td><td>11</td><td>1230</td><td>104</td><td>7 m</td><td>7 d</td></tr>
                        <tr><td>16</td><td>12</td><td>1330</td><td>116</td><td>10 m</td><td>9 d</td></tr>
                        <tr><td>17</td><td>12</td><td>1410</td><td>122</td><td>12 m</td><td>12 d</td></tr>
                        <tr><td>18</td><td>13</td><td>1510</td><td>128</td><td>14 m</td><td>15 d</td></tr>
                        <tr><td>19</td><td>13</td><td>1600</td><td>134</td><td>16 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* MORTAR */}
                <Collapsible trigger={<TriggerMortar />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv08-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv09-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv10-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv11-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv12-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv13-G.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv13.png'/><p className="legend">Level 13</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>400</td><td>4</td><td>5 k</td><td>3 h</td></tr>
                        <tr><td>2</td><td>4</td><td>450</td><td>5</td><td>25 k</td><td>6 h</td></tr>
                        <tr><td>3</td><td>5</td><td>500</td><td>6</td><td>100 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>6</td><td>550</td><td>7</td><td>250 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>7</td><td>600</td><td>9</td><td>500 k</td><td>2 d</td></tr>
                        <tr><td>6</td><td>8</td><td>650</td><td>11</td><td>1 m</td><td>2,5 d</td></tr>
                        <tr><td>7</td><td>9</td><td>700</td><td>15</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>10</td><td>750</td><td>20</td><td>4 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>11</td><td>800</td><td>25</td><td>6 m</td><td>6 d</td></tr>
                        <tr><td>10</td><td>11</td><td>850</td><td>30</td><td>8 m</td><td>8 d</td></tr>
                        <tr><td>11</td><td>12</td><td>900</td><td>35</td><td>11 m</td><td>12 d</td></tr>
                        <tr><td>12</td><td>12</td><td>980</td><td>38</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>13</td><td>13</td><td>1100</td><td>42</td><td>14,5 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* AIR DEFENSE */}
                <Collapsible trigger={<TriggerAirDefense />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Air Defense' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv11.png'/><p className="legend">Level 11</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>4</td><td>800</td><td>80</td><td>22 k</td><td>3 h</td></tr>
                        <tr><td>2</td><td>4</td><td>850</td><td>110</td><td>90 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>5</td><td>900</td><td>140</td><td>270 k</td><td>16 h</td></tr>
                        <tr><td>4</td><td>6</td><td>950</td><td>160</td><td>500 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>7</td><td>1000</td><td>190</td><td>1 m</td><td>1,5 d</td></tr>
                        <tr><td>6</td><td>8</td><td>1050</td><td>230</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>7</td><td>9</td><td>1100</td><td>280</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>10</td><td>1210</td><td>320</td><td>4 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>11</td><td>1300</td><td>360</td><td>7 m</td><td>9 d</td></tr>
                        <tr><td>10</td><td>12</td><td>1400</td><td>400</td><td>10 m</td><td>14 d</td></tr>
                        <tr><td>11</td><td>13</td><td>1500</td><td>440</td><td>15 m</td><td>16 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* WIZARD TOWER */}
                <Collapsible trigger={<TriggerWizardTower />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Wizard Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv13.png'/><p className="legend">Level 13</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>5</td><td>620</td><td>11</td><td>170 k</td><td>3 h</td></tr>
                        <tr><td>2</td><td>5</td><td>650</td><td>13</td><td>320 k</td><td>8 h</td></tr>
                        <tr><td>3</td><td>6</td><td>680</td><td>16</td><td>670 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>7</td><td>730</td><td>20</td><td>920 k</td><td>18 h</td></tr>
                        <tr><td>5</td><td>8</td><td>840</td><td>24</td><td>1,2 m</td><td>1 d</td></tr>
                        <tr><td>6</td><td>8</td><td>960</td><td>32</td><td>1,7 m</td><td>1,5 d</td></tr>
                        <tr><td>7</td><td>9</td><td>1200</td><td>40</td><td>2,2 m</td><td>2 d</td></tr>
                        <tr><td>8</td><td>10</td><td>1440</td><td>45</td><td>3,7 m</td><td>3,5 d</td></tr>
                        <tr><td>9</td><td>10</td><td>1680</td><td>50</td><td>5,2 m</td><td>6 d</td></tr>
                        <tr><td>10</td><td>11</td><td>2000</td><td>62</td><td>8,2 m</td><td>9 d</td></tr>
                        <tr><td>11</td><td>12</td><td>2240</td><td>70</td><td>11,2 m</td><td>14 d</td></tr>
                        <tr><td>12</td><td>13</td><td>2480</td><td>78</td><td>16,2 m</td><td>16 d</td></tr>
                        <tr><td>13</td><td>13</td><td>2700</td><td>84</td><td>17,2 m</td><td>17 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* AIR SWEEPER */}
                <Collapsible trigger={<TriggerAirSweeper />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Air Sweeper' src='https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv07.png'/><p className="legend">Level 7</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Push</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>6</td><td>750</td><td>1,6</td><td>400 k</td><td>6 h</td></tr>
                        <tr><td>2</td><td>6</td><td>800</td><td>2</td><td>700 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>7</td><td>850</td><td>2,4</td><td>1,1 m</td><td>18 h</td></tr>
                        <tr><td>4</td><td>8</td><td>900</td><td>2,8</td><td>1,5 m</td><td>1 d</td></tr>
                        <tr><td>5</td><td>9</td><td>950</td><td>3,2</td><td>2 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>10</td><td>1000</td><td>3,6</td><td>4 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>11</td><td>1050</td><td>4</td><td>7 m</td><td>5 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Hidden Tesla */}
                <Collapsible trigger={<TriggerHiddenTesla />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='Hidden Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv13.png'/><p className="legend">Level 13</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>600</td><td>34</td><td>300 k</td><td>2 h</td></tr>
                        <tr><td>2</td><td>7</td><td>630</td><td>40</td><td>500 k</td><td>5 h</td></tr>
                        <tr><td>3</td><td>7</td><td>660</td><td>48</td><td>750 k</td><td>10 h</td></tr>
                        <tr><td>4</td><td>8</td><td>690</td><td>55</td><td>1 m</td><td>18 h</td></tr>
                        <tr><td>5</td><td>8</td><td>730</td><td>64</td><td>1,3 m</td><td>1 d</td></tr>
                        <tr><td>6</td><td>8</td><td>770</td><td>75</td><td>1,5 m</td><td>2 d</td></tr>
                        <tr><td>7</td><td>9</td><td>810</td><td>87</td><td>2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>10</td><td>850</td><td>99</td><td>3,2 m</td><td>5 d</td></tr>
                        <tr><td>9</td><td>11</td><td>900</td><td>110</td><td>6 m</td><td>8 d</td></tr>
                        <tr><td>10</td><td>12</td><td>980</td><td>120</td><td>10 m</td><td>14 d</td></tr>
                        <tr><td>11</td><td>13</td><td>1100</td><td>130</td><td>15 m</td><td>16 d</td></tr>
                        <tr><td>12</td><td>13</td><td>1200</td><td>140</td><td>16,5 m</td><td>17 d</td></tr>
                        <tr><td>13</td><td>14</td><td>1350</td><td>150</td><td>18 m</td><td>18 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* BOMB TOWER */}
                <Collapsible trigger={<TriggerBombTower />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='Bomb Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv09.png'/><p className="legend">Level 9</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Bomb</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>8</td><td>650</td><td>24</td><td>150</td><td>1 m</td><td>1 d</td></tr>
                        <tr><td>2</td><td>8</td><td>700</td><td>28</td><td>180</td><td>1,5 m</td><td>1,5 d</td></tr>
                        <tr><td>3</td><td>9</td><td>750</td><td>32</td><td>220</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>4</td><td>10</td><td>850</td><td>40</td><td>260</td><td>4 m</td><td>4 d</td></tr>
                        <tr><td>5</td><td>11</td><td>1050</td><td>46</td><td>300</td><td>6 m</td><td>6 d</td></tr>
                        <tr><td>6</td><td>11</td><td>1300</td><td>52</td><td>340</td><td>8 m</td><td>9 d</td></tr>
                        <tr><td>7</td><td>12</td><td>1600</td><td>60</td><td>380</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>8</td><td>13</td><td>1900</td><td>72</td><td>450</td><td>15,5 m</td><td>16 d</td></tr>
                        <tr><td>9</td><td>14</td><td>2300</td><td>84</td><td>500</td><td>18,5 m</td><td>18 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* XBOW */}
                <Collapsible trigger={<TriggerXBow />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv01-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv01-G.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv02-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv02-G.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv03-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv03-G.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv04-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv04-G.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv05-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv05-G.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv06-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv06-G.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv07-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv07-G.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='X-Bow' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv08-A.png'/><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv08-G.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>9</td><td>1500</td><td>60</td><td>1 m</td><td>2 d</td></tr>
                        <tr><td>2</td><td>9</td><td>1900</td><td>70</td><td>2 m</td><td>4 d</td></tr>
                        <tr><td>3</td><td>9</td><td>2300</td><td>80</td><td>3 m</td><td>5 d</td></tr>
                        <tr><td>4</td><td>10</td><td>2700</td><td>95</td><td>5 m</td><td>8 d</td></tr>
                        <tr><td>5</td><td>11</td><td>3100</td><td>125</td><td>8 m</td><td>9 d</td></tr>
                        <tr><td>6</td><td>12</td><td>3500</td><td>155</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>7</td><td>13</td><td>3900</td><td>175</td><td>16 m</td><td>16 d</td></tr>
                        <tr><td>8</td><td>13</td><td>4200</td><td>185</td><td>17 m</td><td>17 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Inferno */}
                <Collapsible trigger={<TriggerInferno />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv01-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv01-M.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv02-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv02-M.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv03-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv03-M.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv04-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv04-M.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv05-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv05-M.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv06-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv06-M.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv07-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv07-M.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Single Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv08-S.png'/><img alt='Multi Inferno Tower' src='https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv08-M.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Single</td><td className="orange-font">Multi</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>10</td><td>1500</td><td>30-1000</td><td>30</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>2</td><td>10</td><td>1800</td><td>36-1150</td><td>36</td><td>4,5 m</td><td>4 d</td></tr>
                        <tr><td>3</td><td>10</td><td>2100</td><td>42-1300</td><td>42</td><td>6 m</td><td>5 d</td></tr>
                        <tr><td>4</td><td>11</td><td>2400</td><td>58-1550</td><td>58</td><td>7,5 m</td><td>7 d</td></tr>
                        <tr><td>5</td><td>11</td><td>2700</td><td>70-1750</td><td>70</td><td>9 m</td><td>9 d</td></tr>
                        <tr><td>6</td><td>12</td><td>3000</td><td>81-2000</td><td>81</td><td>11 m</td><td>14 d</td></tr>
                        <tr><td>7</td><td>13</td><td>3300</td><td>93-2150</td><td>93</td><td>17 m</td><td>17 d</td></tr>
                        <tr><td>8</td><td>14</td><td>3700</td><td>105-2300</td><td>105</td><td>19 m</td><td>19 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* EAGLE ARTILLERY */}
                <Collapsible trigger={<TriggerEagleArtillery />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Eagle Artillery Activated' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv01-A.png'/><img alt='Eagle Artillery' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Eagle Artillery Activated' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv02-A.png'/><img alt='Eagle Artillery' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Eagle Artillery Activated' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv03-A.png'/><img alt='Eagle Artillery' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Eagle Artillery Activated' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv04-A.png'/><img alt='Eagle Artillery' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Eagle Artillery Activated' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv05-A.png'/><img alt='Eagle Artillery' src='https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Shock</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>11</td><td>4000</td><td>300</td><td>20</td><td>8 m</td><td>7 d</td></tr>
                        <tr><td>2</td><td>11</td><td>4400</td><td>350</td><td>25</td><td>10 m</td><td>10 d</td></tr>
                        <tr><td>3</td><td>12</td><td>4800</td><td>400</td><td>30</td><td>12 m</td><td>14 d</td></tr>
                        <tr><td>4</td><td>13</td><td>5200</td><td>450</td><td>35</td><td>18 m</td><td>18 d</td></tr>
                        <tr><td>5</td><td>14</td><td>5600</td><td>500</td><td>40</td><td>20 m</td><td>20 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* Scattershot */}
                <Collapsible trigger={<TriggerScattershot />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Scattershot' src='https://assets.clashbases.de/Building-Icons/Defenses/Scattershot/Scattershot-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Scattershot' src='https://assets.clashbases.de/Building-Icons/Defenses/Scattershot/Scattershot-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Scattershot' src='https://assets.clashbases.de/Building-Icons/Defenses/Scattershot/Scattershot-lv03.png'/><p className="legend">Level 3</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>13</td><td>3600</td><td>140</td><td>15 m</td><td>15 d</td></tr>
                        <tr><td>2</td><td>13</td><td>4200</td><td>170</td><td>17 m</td><td>17 d</td></tr>
                        <tr><td>3</td><td>14</td><td>4800</td><td>190</td><td>19 m</td><td>19 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GIGA TESLA */}
                <Collapsible trigger={<TriggerGigaTesla />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Giga Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Giga Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Giga Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Giga Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Giga Tesla' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg</td><td className="orange-font">Targets</td><td className="orange-font">Bomb</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>150</td><td>2</td><td>-</td><td>-</td><td>-</td></tr>
                        <tr><td>2</td><td>175</td><td>2</td><td>-</td><td>6 m</td><td>2 d</td></tr>
                        <tr><td>3</td><td>175</td><td>3</td><td>-</td><td>8 m</td><td>4 d</td></tr>
                        <tr><td>4</td><td>200</td><td>3</td><td>-</td><td>10 m</td><td>6 d</td></tr>
                        <tr><td>5</td><td>200</td><td>4</td><td>1000</td><td>12 m</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GIGA INFERNO 13 */}
                <Collapsible trigger={<TriggerGigaInferno13 />}>
                <LazyLoad>
                  <div className="homebasewiki-buildings-item-detail-container">
                      <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                          <div><img alt='Giga Inferno 13' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv01.png'/><p className="legend">Level 1</p></div>
                          <div><img alt='Giga Inferno 13' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv02.png'/><p className="legend">Level 2</p></div>
                          <div><img alt='Giga Inferno 13' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv03.png'/><p className="legend">Level 3</p></div>
                          <div><img alt='Giga Inferno 13' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv04.png'/><p className="legend">Level 4</p></div>
                          <div><img alt='Giga Inferno 13' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv05.png'/><p className="legend">Level 5</p></div>
                      </Carousel>
                      <table className="homebasewiki-buildings-item-table">
                          <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                          <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg</td><td className="orange-font">Bomb</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                          <tr><td>1</td><td>200</td><td>1000</td><td>-</td><td>-</td><td>-</td></tr>
                          <tr><td>2</td><td>225</td><td>1000</td><td>-</td><td>12 m</td><td>10 d</td></tr>
                          <tr><td>3</td><td>250</td><td>1000</td><td>4 s</td><td>14 m</td><td>12 d</td></tr>
                          <tr><td>4</td><td>275</td><td>1000</td><td>6 s</td><td>16 m</td><td>14 d</td></tr>
                          <tr><td>5</td><td>300</td><td>1000</td><td>8 s</td><td>18 m</td><td>16 d</td></tr>
                      </table>
                  </div>
                  </LazyLoad>
                </Collapsible>

                {/* GIGA INFERNO 14 */}
                <Collapsible trigger={<TriggerGigaInferno14 />}>
                <LazyLoad>
                  <div className="homebasewiki-buildings-item-detail-container">
                      <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                          <div><img alt='Giga Inferno 14' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv01.png'/><p className="legend">Level 1</p></div>
                          <div><img alt='Giga Inferno 14' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv02.png'/><p className="legend">Level 2</p></div>
                          <div><img alt='Giga Inferno 14' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv03.png'/><p className="legend">Level 3</p></div>
                          <div><img alt='Giga Inferno 14' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv04.png'/><p className="legend">Level 4</p></div>
                          <div><img alt='Giga Inferno 14' src='https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv05.png'/><p className="legend">Level 5</p></div>
                      </Carousel>
                      <table className="homebasewiki-buildings-item-table">
                          <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                          <tr><td className="orange-font">Lv</td><td className="orange-font">Dmg</td><td className="orange-font">Bomb</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                          <tr><td>1</td><td>300</td><td>1000</td><td>8 s</td><td>-</td><td>-</td></tr>
                          <tr><td>2</td><td>300</td><td>1000</td><td>8 s</td><td>9 m</td><td>9 d</td></tr>
                          <tr><td>3</td><td>300</td><td>1000</td><td>8 s</td><td>11 m</td><td>11 d</td></tr>
                          <tr><td>4</td><td>300</td><td>1000</td><td>8 s</td><td>13 m</td><td>13 d</td></tr>
                          <tr><td>5</td><td>300</td><td>1000</td><td>8 s</td><td>15 m</td><td>14 d</td></tr>
                      </table>
                  </div>
                  </LazyLoad>
                </Collapsible>

                {/* WALLS */}
                <Collapsible trigger={<TriggerWall />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv03.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv05.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv07.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv09.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv10.png'/><p className="legend">Level 10</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv11.png'/><p className="legend">Level 11</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv12.png'/><p className="legend">Level 12</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv13.png'/><p className="legend">Level 13</p></div>
                        <div><img alt='canon' src='https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv14.png'/><p className="legend">Level 14</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Health</td><td className="orange-font">Cost</td><td className="orange-font">Ring</td><td className="orange-font">Full Cost</td></tr>
                        <tr><td>1</td><td>2</td><td>300</td><td>50</td><td>1</td><td>16,25 k</td></tr>
                        <tr><td>2</td><td>2</td><td>500</td><td>1 k</td><td>1</td><td>325 k</td></tr>
                        <tr><td>3</td><td>3</td><td>700</td><td>5 k</td><td>1</td><td>1,625 m</td></tr>
                        <tr><td>4</td><td>4</td><td>900</td><td>10 k</td><td>1</td><td>3,250 m</td></tr>
                        <tr><td>5</td><td>5</td><td>1,4 k</td><td>20 k</td><td>1</td><td>6,5 m</td></tr>
                        <tr><td>6</td><td>6</td><td>2 k</td><td>40 k</td><td>1</td><td>13 m</td></tr>
                        <tr><td>7</td><td>7</td><td>2,5 k</td><td>80 k</td><td>1</td><td>26 m</td></tr>
                        <tr><td>8</td><td>8</td><td>3 k</td><td>150 k</td><td>1</td><td>48,750 m</td></tr>
                        <tr><td>9</td><td>9</td><td>4 k</td><td>250 k</td><td>1</td><td>81,250 m</td></tr>
                        <tr><td>10</td><td>9</td><td>5,5 k</td><td>500 k</td><td>1</td><td>162,5 m</td></tr>
                        <tr><td>11</td><td>10</td><td>7 k</td><td>1 m</td><td>1</td><td>325 m</td></tr>
                        <tr><td>12</td><td>11</td><td>8,5 k</td><td>2 m</td><td>2</td><td>650 m</td></tr>
                        <tr><td>13</td><td>12</td><td>10 k</td><td>4 m</td><td>4</td><td>1,3 b</td></tr>
                        <tr><td>14</td><td>13</td><td>11,5 k</td><td>6 m</td><td>6</td><td>1,950 b</td></tr>
                        <tr><td>15</td><td>14</td><td>12,5 k</td><td>7 m</td><td>7</td><td>2,275 b</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>



                <h2 className="homebasewiki-buildings-titel" id="Trap">Trap</h2>
                {/* NAVBAR - BUILDINGS - TRAPS */}
                <nav className="homebasewiki-nav">
                  <div className="homebasewiki-nav-content">
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Resource" smooth={true} offset={-70}>Resource</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Army" smooth={true} offset={-70}>Army</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item"><LinkToAnchor to="Defense" smooth={true} offset={-70}>Defense</LinkToAnchor></li>
                  <li className="homebasewiki-nav-item orange-underline"><LinkToAnchor to="Trap" smooth={true} offset={-70}>Trap</LinkToAnchor></li>
                  </div>
                </nav>

                {/* BOMB */}
                <Collapsible trigger={<TriggerBomb />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv05-06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv07-08.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv07-08.png'/><p className="legend">Level 8</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv09-10.png'/><p className="legend">Level 9</p></div>
                        <div><img alt='bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv09-10.png'/><p className="legend">Level 10</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Dmg</td><td className="orange-font">Range</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>3</td><td>20</td><td>3</td><td>400</td><td>-</td></tr>
                        <tr><td>2</td><td>3</td><td>24</td><td>3</td><td>1 k</td><td>6 m</td></tr>
                        <tr><td>3</td><td>5</td><td>29</td><td>3</td><td>10 k</td><td>2 h</td></tr>
                        <tr><td>4</td><td>7</td><td>35</td><td>3</td><td>100 k</td><td>5 h</td></tr>
                        <tr><td>5</td><td>8</td><td>42</td><td>3</td><td>300 k</td><td>8 h</td></tr>
                        <tr><td>6</td><td>9</td><td>54</td><td>3</td><td>600 k</td><td>12 h</td></tr>
                        <tr><td>7</td><td>10</td><td>72</td><td>3</td><td>1,2 m</td><td>3 d</td></tr>
                        <tr><td>8</td><td>11</td><td>92</td><td>3</td><td>2,5 m</td><td>4 d</td></tr>
                        <tr><td>9</td><td>13</td><td>125</td><td>3</td><td>4 m</td><td>6 d</td></tr>
                        <tr><td>10</td><td>14</td><td>140</td><td>3</td><td>6 m</td><td>8 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* GIANT BOMB */}
                <Collapsible trigger={<TriggerGiantBomb />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv05-06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Giant Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv07.png'/><p className="legend">Level 7</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Dmg</td><td className="orange-font">Range</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>6</td><td>175</td><td>3</td><td>12,5 k</td><td>-</td></tr>
                        <tr><td>2</td><td>6</td><td>200</td><td>3,5</td><td>75 k</td><td>6 h</td></tr>
                        <tr><td>3</td><td>8</td><td>225</td><td>3,5</td><td>600 k</td><td>1 d</td></tr>
                        <tr><td>4</td><td>10</td><td>250</td><td>4</td><td>2,4 m</td><td>3 d</td></tr>
                        <tr><td>5</td><td>11</td><td>275</td><td>4</td><td>3,5 m</td><td>5 d</td></tr>
                        <tr><td>6</td><td>13</td><td>325</td><td>4</td><td>5 m</td><td>7 d</td></tr>
                        <tr><td>7</td><td>13</td><td>385</td><td>4</td><td>7 m</td><td>9 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SPRING TRAP */}
                <Collapsible trigger={<TriggerSpringTrap />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Spring Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv05.png'/><p className="legend">Level 5</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Capacity</td><td className="orange-font">Range</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>4</td><td>10</td><td>0,8</td><td>2 k</td><td>-</td></tr>
                        <tr><td>2</td><td>7</td><td>12</td><td>0,8</td><td>300 k</td><td>12 h</td></tr>
                        <tr><td>3</td><td>8</td><td>14</td><td>0,8</td><td>500 k</td><td>18 h</td></tr>
                        <tr><td>4</td><td>9</td><td>16</td><td>0,8</td><td>900 k</td><td>1,5 d</td></tr>
                        <tr><td>5</td><td>10</td><td>18</td><td>0,8</td><td>1,5 m</td><td>3 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* AIR BOMB */}
                <Collapsible trigger={<TriggerAirBomb />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv03-04.png'/><p className="legend">Level 4</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv05-06.png'/><p className="legend">Level 5</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv05-06.png'/><p className="legend">Level 6</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv07-08.png'/><p className="legend">Level 7</p></div>
                        <div><img alt='Air Bomb' src='https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv07-08.png'/><p className="legend">Level 8</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Dmg</td><td className="orange-font">Range</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>5</td><td>100</td><td>3</td><td>4 k</td><td>-</td></tr>
                        <tr><td>2</td><td>5</td><td>120</td><td>3</td><td>20 k</td><td>4 h</td></tr>
                        <tr><td>3</td><td>7</td><td>144</td><td>3</td><td>200 k</td><td>12 h</td></tr>
                        <tr><td>4</td><td>9</td><td>173</td><td>3</td><td>800 k</td><td>1 d</td></tr>
                        <tr><td>5</td><td>11</td><td>200</td><td>3</td><td>2,5 m</td><td>2 d</td></tr>
                        <tr><td>6</td><td>12</td><td>228</td><td>3</td><td>3 m</td><td>3 d</td></tr>
                        <tr><td>7</td><td>13</td><td>248</td><td>3</td><td>4 m</td><td>6 d</td></tr>
                        <tr><td>8</td><td>13</td><td>270</td><td>3</td><td>5 m</td><td>7 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SEEKING AIR MINE */}
                <Collapsible trigger={<TriggerSeekingAirMine />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Seeking Air Mine' src='https://assets.clashbases.de/Building-Icons/Traps/Seeking-Air-Mine/SeekingAirMine-lv01-02.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Seeking Air Mine' src='https://assets.clashbases.de/Building-Icons/Traps/Seeking-Air-Mine/SeekingAirMine-lv01-02.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Seeking Air Mine' src='https://assets.clashbases.de/Building-Icons/Traps/Seeking-Air-Mine/SeekingAirMine-lv03-04.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Seeking Air Mine' src='https://assets.clashbases.de/Building-Icons/Traps/Seeking-Air-Mine/SeekingAirMine-lv03-04.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="6">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Dmg</td><td className="orange-font">Range</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>7</td><td>1500</td><td>4</td><td>15 k</td><td>-</td></tr>
                        <tr><td>2</td><td>9</td><td>1800</td><td>4</td><td>1,5 m</td><td>1 d</td></tr>
                        <tr><td>3</td><td>10</td><td>2100</td><td>4</td><td>2,5 m</td><td>3 d</td></tr>
                        <tr><td>4</td><td>13</td><td>2400</td><td>4</td><td>6 m</td><td>7 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* SKELETON TRAP */}
                <Collapsible trigger={<TriggerSkeletonTrap />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Skeleton Trap Air' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv01-02-A.png'/><img alt='Skeleton Trap Ground' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv01-02-G.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Skeleton Trap Air' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv01-02-A.png'/><img alt='Skeleton Trap Ground' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv01-02-G.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Skeleton Trap Air' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv03-04-A.png'/><img alt='Skeleton Trap Ground' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv03-04-G.png'/><p className="legend">Level 3</p></div>
                        <div><img alt='Skeleton Trap Air' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv03-04-A.png'/><img alt='Skeleton Trap Ground' src='https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv03-04-G.png'/><p className="legend">Level 4</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Skeletons</td><td className="orange-font">Health</td><td className="orange-font">Dmg</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>8</td><td>2</td><td>60</td><td>50</td><td>6 k</td><td>-</td></tr>
                        <tr><td>2</td><td>8</td><td>3</td><td>90</td><td>75</td><td>450 k</td><td>5 h</td></tr>
                        <tr><td>3</td><td>9</td><td>4</td><td>120</td><td>100</td><td>1,2 m</td><td>20 h</td></tr>
                        <tr><td>4</td><td>10</td><td>5</td><td>150</td><td>125</td><td>2 m</td><td>1,7 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

                {/* TORNADO TRAP */}
                <Collapsible trigger={<TriggerTornadotrap />}>
                <LazyLoad>
                <div className="homebasewiki-buildings-item-detail-container">
                    <Carousel showIndicators={true} showStatus={true} showThumbs={false} infiniteLoop={true} centerMode={true} centerSlidePercentage={90} swipeScrollTolerance={5} emulateTouch={true} autoPlay={false} >
                        <div><img alt='Tornado Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Tornado-Trap/TornadoTrap-lv01.png'/><p className="legend">Level 1</p></div>
                        <div><img alt='Tornado Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Tornado-Trap/TornadoTrap-lv02-03.png'/><p className="legend">Level 2</p></div>
                        <div><img alt='Tornado Trap' src='https://assets.clashbases.de/Building-Icons/Traps/Tornado-Trap/TornadoTrap-lv02-03.png'/><p className="legend">Level 3</p></div>
                    </Carousel>
                    <table className="homebasewiki-buildings-item-table">
                        <tr><th className="orange-font" colSpan="7">Stats</th></tr>
                        <tr><td className="orange-font">Lv</td><td className="orange-font">Th</td><td className="orange-font">Dmg</td><td className="orange-font">Range</td><td className="orange-font">Duration</td><td className="orange-font">Cost</td><td className="orange-font">Time</td></tr>
                        <tr><td>1</td><td>11</td><td>47</td><td>3</td><td>5 s</td><td>3 m</td><td>-</td></tr>
                        <tr><td>2</td><td>11</td><td>62</td><td>3</td><td>6 s</td><td>4 m</td><td>2 d</td></tr>
                        <tr><td>3</td><td>12</td><td>78</td><td>3</td><td>7 s</td><td>5 m</td><td>3 d</td></tr>
                    </table>
                </div>
                </LazyLoad>
                </Collapsible>

            </div>

        </div>

      </section>

);

export default homebuildings;
