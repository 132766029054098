import React, { Component } from 'react';
import './Archive.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton'
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class ArchiveTownhallsix extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }



  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-6" />
            <title>The Archive - Townhall 6</title>
            <meta name="description" content="Welcome to the ClashBases TH 6 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(255, 134, 233)'}}>6</span></h1>

                  <div className="switch-container-archive">
                    <LinkButton to="/Townhall-6" className="switch-buttons-archive switch-to-archive">
                      <div>Switch back to Current TH 6 Base Layouts</div>
                    </LinkButton>
                  </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">
            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-010710_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-010710_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-010710_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-010710_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-010710.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vXmyDMsWvTaIlEqjV5WT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X046</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-005046_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-005046_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-005046_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-005046_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-005046.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vT7P2rVhaZFQT6dS99nK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X045</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-004120_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-004120_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-004120_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-004120_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-004120.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vR5zS_Hg0SZEDMD9Js18" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X044</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-003055_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-003055_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-003055_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-003055_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-003055.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vPzErMtNh1PEu4AQMTon" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X043</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vGKa2dTGApd4bejvGqfu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X042</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002730_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002730_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002730_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002730_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002730.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vPIPuUtxmc4mr_sK8XAY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X041</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002444_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002444_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002444_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002444_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002444.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vOkCr09f4llVXfai0_T-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X040</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002151_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002151_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002151_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002151_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-002151.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vOAi1ZgUgXWKe931ZDgH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X039</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000957_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000957_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000957_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000957_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000957.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vLnUD5Xsjkwi9Ymeq_Wj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X038</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000547_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000547_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000547_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000547_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000547.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vK0iCKkvPUh2Hx9h4LZS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X037</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000259_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000259_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000259_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000259_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200208-000259.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vKMmUu7_9BZceTBuc5yl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X036</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235658_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235658_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235658_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235658_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235658.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vJBgo0V6Kn3sIbLfhGoE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X035</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235612_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235612_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235612_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235612_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235612.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vI4PThx_tT8FH--KoxNb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X034</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235511_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235511_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235511_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235511_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-235511.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vIs40CJ58uwkOJKC3JA9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X033</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235127_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235127_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235127_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235127_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-235127.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vH4YkIt_63X1TNPpkcqL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X032</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234532_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234532_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234532_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234532_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234532.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vGsWmaORm0tTMShPhrCf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X031</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-234447_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-234447_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-234447_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-234447_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-234447.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vGllUYPVwskqpPRIC3iW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X030</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234341_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234341_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234341_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234341_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234341.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vGTbCGf8_c9gJ4i5pLwt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X029</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234306.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vGKa2dTGApd4bejvGqfu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X028</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234219_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234219_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234219_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234219_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-234219.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vGCEJ6bTU913-T30Klrg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X027</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-232604_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-232604_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-232604_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-232604_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-232604.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vCmF2DxVNtRO-ehwJj3S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X026</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232517_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232517_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232517_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232517_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232517.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vCWVZJvNw8dYOM8Ah1o4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X025</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232434_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232434_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232434_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232434_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232434.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vCLwQx-_oVxAw8dZNM1I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X024</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232006_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232006_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232006_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232006_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-232006.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0vBTi4r_afNsrdaBsidhy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X023</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231829_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231829_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231829_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231829_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231829.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vA-StdnTrzyGL7TfP-nj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X022</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231737_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231737_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231737_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231737_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-231737.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0vA18sO69xTS9N_OJo3cA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X021</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-231025_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-231025_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-231025_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-231025_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-231025.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0u_XTukQpRRGrpsesNjHZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X020</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-225134_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-225134_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-225134_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-225134_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-225134.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0u7rkiL9dzEc6UuYeNZ_f" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X019</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224936_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224936_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224936_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224936_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224936.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u7RAp4O5-wr1kaGhG_6y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X018</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224600_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224600_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224600_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224600_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224600.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u6kItmCVfopV2SwR9NLr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X017</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224116_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224116_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224116_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224116_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-224116.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u5oIMIUbll6-Q-6L4CFm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X016</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223646_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223646_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223646_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223646_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223646.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u4u4ZWgQV0RLwvOiK-Ww" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X015</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-223608_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-223608_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-223608_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-223608_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-223608.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0u4lGTcH9tHzWaZ9hguEV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X014</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223455_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223455_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223455_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223455_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223455.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u4U886H7_l6h3CNJ6e9D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X013</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223306_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223306_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223306_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223306_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-223306.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u39zFbNxUk6O49QFZhnc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X012</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221612_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221612_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221612_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221612_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221612.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0u0bcdObnHGCiwMpYbyYI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X011</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-221353_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-221353_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-221353_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-221353_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-221353.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uz7Nfey08ibmWMOMeF1z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X010</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221256_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221256_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221256_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221256_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-221256.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0uzuuKMxi0wSxO44pnZ3z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X009</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220705_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220705_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220705_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220705_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220705.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uyd0WJqHKMM0BXCibSbG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X008</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220529_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220529_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220529_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220529_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220529.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uyIGkpxzr_zm5trL9KFz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X007</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220320_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220320_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220320_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220320_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220320.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uxlcyLQoF3JquLfzqSZx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#GS006</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220145_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220145_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220145_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220145_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-220145.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uxRL2TR9HAZ_p-00JG22" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X005</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-215923_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-215923_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-215923_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-215923_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-215923.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uwsR3t0mwlQZ87SbVCRz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X004</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215804_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215804_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215804_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215804_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215804.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0uwa2IZEvjpaJlQh5Jmvx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X003</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 6 War Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215555_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215555_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215555_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215555_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-War/Townhall-6-Base-Layout-20200207-215555.jpg 2430w"
                        title="Townhall 6 War Base" alt="Townhall 6 War Base" zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AWB%3AAAAAVgAAAAD0uv4-Qzjm1CO4K19TiEri" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X002</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 6 Farm Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-214911_640w.jpg"
                        srcSet="https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-214911_640w.jpg 320w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-214911_1024w.jpg 640w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-214911_1920w.jpg 1920w, https://townhall-6.clashbases.de/Archive/Townhall-6-Farm/Townhall-6-Base-Layout-20200207-214911.jpg 2430w"
                        title="Townhall 6 Farm Base." alt="Townhall 6 Farm Base." zoom={this.zoom}  />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH6%3AHV%3AAAAAVgAAAAD0uudm27kzpVdxILSjZzPv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#X001</p></figcaption>
                  <div>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                    <p className="index-number font-size-picture-descriptions">TH 6</p>
                  </div>
                </div>
              </div>
            </figure>

            </div>
          </div>



          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 6 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhallsix;
