import React, { Component } from 'react';
import './Archive.scss';
import Archivedemo from './ArchiveScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LinkButton from '../../LinkButton';
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";




class ArchiveTownhallthirteen extends Component {

  componentDidMount () {
    window.Archivedemo = new Archivedemo(document.getElementById('grid'));
  }

  zoom = mediumZoom ({background:"rgba(0, 0, 0, 0.5)"})


  render() {
    return (

      <section className="archive-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>The Archive - Townhall 13</title>
            <meta name="description" content="Welcome to the ClashBases TH 13 Archives. All pre-th-15 base layouts that are now outdated can be found in the base layout archives!" />
            <meta name="keywords" content="" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="archive-about-page-container" id="back-to-top">

          <div className="archive-mobile-overlay">

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="archive-title-container">
                <h1 className="archive-about-title">The Archive - Townhall <span style={{color: 'rgb(145, 27, 209)'}}>13</span></h1>

                <div className="switch-container-archive">
                  <LinkButton to="/Townhall-13" className="switch-buttons-archive switch-to-archive">
                    <div>Switch back to Current TH 13 Base Layouts</div>
                  </LinkButton>
                </div>

              </div>
            </div>
          </div>

          <div className="archive-container-about">
            <div className="archive-row-about">
              <div className="col-6@sm archive-filters-group-wrap">
                <div className="archive-filters-group filters-group-right">
                  <p className="archive-filter-label archive-filter-color">Filter</p>
                  <div className="btn-group archive-filter-options">
                    <button className="btn btn--primary" data-group="war">War</button>
                    <button className="btn btn--primary" data-group="trophy">Trophy</button>
                    <button className="btn btn--primary" data-group="farm">Farm</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/01Archive_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/01Archive_320w.png 320w, https://assets.clashbases.de/Backgrounds/01Archive_640w.png 640w, https://assets.clashbases.de/Backgrounds/01Archive_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/01Archive_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>

          </div>

          <div className="archive-container-about">

            <div id="grid" className="archive-row-about my-shuffle-archive-container-about">

              <figure className="col-3@xs col-3@md picture-item" data-groups='["showcase"]' data-date-created="2019-12-10" data-title="Townhall 13 | Base Layouts and Links">
                <div className="picture-item__inner townhall-showcase-13">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://assets.clashbases.de/Backgrounds/townhall-13-wallpaper-notext.jpg"
                          srcSet="https://assets.clashbases.de/Backgrounds/townhall-13-wallpaper-notext_640w.jpg 320w, https://assets.clashbases.de/Backgrounds/townhall-13-wallpaper-notext_1024w.jpg 640w, https://assets.clashbases.de/Backgrounds/townhall-13-wallpaper-notext_1920w.jpg 1920w"
                          title="Townhall 13 | Base Layouts and Links" alt="Townhall 13 | Base Layouts and Links" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Townhall 13</a><p className="index-number font-size-picture-descriptions">Base Layouts</p></figcaption>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165639_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165639_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165639_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165639_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165639.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAAgAAAAIX5extacFnratDGh7b24L_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P673</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165638_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165638_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165638_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165638_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165638.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAKwAAAAGkgE7YjVeuAbIo1lFbmpZe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P672</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165637_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165637_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165637_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165637_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165637.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAPQAAAAF6KKgH-RjuqzQqLUENSiSg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P671</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165636_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165636_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165636_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165636_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165636.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAQQAAAAFqUzo_d3-0yeWGoN2hRigO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P670</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165635_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165635_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165635_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165635_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-BaseLayout-20210417-165635.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAABgAAAAH9voVQCVY1IUmOeLpjbM-r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P669</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-155227_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-155227_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-155227_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-155227_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-155227.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHRaYmXOiqFzPU2CC1w-PcE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P663</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-153140_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-153140_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-153140_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-153140_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-153140.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHRaO40LhtATpv0EENi5LLf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P662</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-152905_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-152905_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-152905_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-152905_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-152905.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHRaNqm9uSszZtcH5QaEMck" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P661</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-145145_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-145145_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-145145_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-145145_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-145145.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHRZ8lvv4hiMdJFMLeheGfh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P660</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={150} offset={500}>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-142038_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-142038_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-142038_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-142038_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210401-142038.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHRZuSre5Ag-tkJAfCkYyI3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P659</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025434_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025434_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025434_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025434_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025434.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCh4HQQCCyxYtrzvzQFWo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P658</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025238_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025238_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025238_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025238_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-025238.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNChLmufu8FfHHVq2IUOFc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P657</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023449_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023449_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023449_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023449_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023449.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCatsrEEpRn9sd5YCtFT4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P656</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023241_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023241_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023241_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023241_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-023241.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCZ9tznlLTNNtPTVPv0xU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P655</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-021236_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-021236_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-021236_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-021236_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-021236.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCS0fB7yFjIaPjudhoXxw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P654</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-015900_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-015900_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-015900_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-015900_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-015900.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCOHY07QYGG9YUjCFJyqG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P653</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-014110_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-014110_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-014110_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-014110_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-014110.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCIF5xj_acXpUOL0yYYox" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P652</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-013926_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-013926_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-013926_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-013926_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-013926.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCHhDVSNe2txRm5Lj0G5i" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P651</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011817_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011817_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011817_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011817_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011817.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNCAgCnVjhOs80fzbM-B_1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P650</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011534_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011534_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011534_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011534_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-011534.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNB_pAXV1kizgVE-ioN08L" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P649</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-010912_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-010912_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-010912_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-010912_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-010912.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNB9k5h8kyP7L-HA4hhSzL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P648</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004624_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004624_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004624_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004624_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004624.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNB2mdYdMVysavMVoPEED-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P647</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004322_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004322_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004322_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004322_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004322.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNB1pNIdM2qHQ4QOw5R71b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P646</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004020_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004020_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004020_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004020_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-004020.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNB0uBpgZQDTEbaavFxcoX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P645</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003632_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003632_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003632_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003632_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003632.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBzkGKNXTDZ7aBRkgDN3D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P644</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003223_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003223_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003223_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003223_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-003223.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNByTTV7WldYevz_iADh7S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P643</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-002909_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-002909_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-002909_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-002909_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-002909.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBxVUAbVq6riZ3m70EZNY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P642</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000627_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000627_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000627_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000627_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000627.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBqjWYvIRKkYV6nNiLNAP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P641</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000131_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000131_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000131_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000131_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210228-000131.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBpGoBvCQbmvBJcxRC2xq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P640</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235709_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235709_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235709_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235709_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235709.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBn1JDZO7hIpvBGjPl88x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P639</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235259_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235259_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235259_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235259_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-235259.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBmm_a48xe7FLF7bULBLb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P638</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-234835_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-234835_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-234835_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-234835_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-234835.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBlVlaNzNOU5m92-nh4f2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P637</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-233052_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-233052_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-233052_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-233052_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-233052.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBgFkdJGvLJrj5P2xt9Lp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P636</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-232639_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-232639_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-232639_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-232639_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-232639.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBe4A2PnZjgiObdIchJWZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P635</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Finals</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-230921_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-230921_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-230921_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-230921_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210227-230921.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHNBZxJLJMOTyegMwyw4V02" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P634</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">World Finals</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062835_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062835_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062835_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062835_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062835.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI76hIvomMnxmpFCPR9c2R" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P633</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062250_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062250_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062250_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062250_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-062250.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI74VLdPCCmh9eZnGdFT8u" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P632</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-061649_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-061649_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-061649_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-061649_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-061649.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI72F3rZG-hGf8xdkYYUsJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P631</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060958_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060958_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060958_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060958_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060958.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAEAAAAAHNzDWwRk4Taxrjp8EWuwzQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P630</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060249_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060249_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060249_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060249_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-060249.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/jp?action=OpenLayout&id=TH13%3AWB%3AAAAAFwAAAAG9jjIRrvo9G44bOAJaHLIn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P629</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-055237_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-055237_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-055237_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-055237_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-055237.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI7svmBPFbdWAiZSA8tb22" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P628</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-053420_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-053420_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-053420_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-053420_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-053420.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI7l4MKoJv85V_nF9HfsEX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P627</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-051232_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-051232_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-051232_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-051232_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-051232.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI7dsqzN7TFEhLJI7ayaPc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P626</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-045331_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-045331_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-045331_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-045331_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-045331.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI7W9K-LaPEgPkz_18ZI2-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P625</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-043114_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-043114_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-043114_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-043114_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-043114.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI7O-TGIe7aMqzZ68SmnQD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P624</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-032745_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-032745_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-032745_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-032745_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-032745.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P623</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-030459_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-030459_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-030459_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-030459_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-030459.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI6xX096fr176Q8Bba6vUn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P622</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-024556_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-024556_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-024556_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-024556_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-024556.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHI6rPh3fhfRH682iWZGMbZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P621</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-022326_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-022326_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-022326_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-022326_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-022326.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P620</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-020442_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-020442_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-020442_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-020442_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210129-020442.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P619</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifiers</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-025540_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-025540_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-025540_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-025540_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-025540.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHEzKrjTaZzzgPueckuc1b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P618</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-023219_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-023219_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-023219_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-023219_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-023219.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P617</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHEkgMKASHYb3UJmfck3H_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P616</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-014630_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-014630_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-014630_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-014630_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-014630.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P615</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-012344_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-012344_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-012344_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-012344_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-012344.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P614</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-005841_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-005841_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-005841_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-005841_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-005841.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHENyJglIEUl8zv4pmYV95" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P613</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-003527_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-003527_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-003527_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-003527_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-003527.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHEHUubhOferOLq0yy0Ja1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P612</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-001648_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-001648_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-001648_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-001648_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210117-001648.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P611</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-235241_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-235241_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-235241_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-235241_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-235241.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHD79YGfvyQMYGKeKSFY9X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P610</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-231328_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-231328_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-231328_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-231328_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210116-231328.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHHDx73GgQEuQWem98POJAM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P609</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-022203_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-022203_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-022203_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-022203_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-022203.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGT5KJWNpfIjwL-8JfpIZz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P608</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-020209_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-020209_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-020209_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-020209_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-020209.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P607</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-014525_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-014525_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-014525_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-014525_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-014525.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTvAioLU8r3x90B890V7c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P606</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-012543.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTpqmCe76orYOWiM3cXV5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P605</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-010113_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-010113_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-010113_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-010113_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-010113.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P604</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">QueeN Walkers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-003747_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-003747_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-003747_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-003747_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-003747.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTc_smVnyKvvjKbd8Tdnj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P603</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-001923_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-001923_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-001923_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-001923_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-001923.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P602</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-000011_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-000011_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-000011_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-000011_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210111-000011.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTTX3PThu4M4S87qkClRt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P601</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-233324_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-233324_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-233324_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-233324_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-233324.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTMl3xo3VbZZ5spD-hVr3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P600</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-15" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-231400_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-231400_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-231400_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-231400_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20210110-231400.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHGTHigSO9Hw030KmB5qi2g" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P599</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-030459_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-030459_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-030459_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-030459_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-030459.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdzDIXqp4lDmoswMbdWxb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P598</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024739_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024739_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024739_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024739_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024739.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAds9c69NQ2wjTT0dAG39Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P597</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024428_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024428_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024428_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024428_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024428.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdr2I5gAXhcjN3guTGhLZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P596</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024158_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024158_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024158_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024158_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-024158.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdq_McFWZXi0kvyKgQo8U" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P595</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-023903_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-023903_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-023903_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-023903_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-023903.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdp-6pHDhoc3cBs-MaHhu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P594</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-020744_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-020744_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-020744_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-020744_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-020744.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdfnFlOgLMZcBbKUlfHIH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P593</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Sky Stars</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-015023_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-015023_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-015023_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-015023_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-015023.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdaDHbM60RCWyr8awhNr3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P592</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Sky Stars</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014622_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014622_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014622_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014622_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014622.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdYwzEMD-M59YcuH9uL6H" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P591</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Sky Stars</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014123_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014123_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014123_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014123_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-014123.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdXKe5HnAKC_wV6gC-jYC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P590</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Sky Stars</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-013554_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-013554_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-013554_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-013554_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-013554.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdVcx-Km4yA2fUHAAp-_m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P589</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Sky Stars</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-002539_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-002539_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-002539_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-002539_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-002539.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAdBKBYL_cFdnrTYP786sj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P588</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Death Dealers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001807_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001807_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001807_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001807_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001807.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAc_LDGHIssMuBnc4gqhDc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P587</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Death Dealers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001351_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001351_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001351_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001351_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-001351.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAc-DQOtoo70QMfsiWtqRi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P586</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Death Dealers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-000807_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-000807_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-000807_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-000807_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201129-000807.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAc8jXERujhlsdf7Po64em" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P585</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Death Dealers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-235827_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-235827_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-235827_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-235827_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-235827.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAc6CMTl2rqFP3wqerlsXx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P584</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Death Dealers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-233124_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-233124_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-233124_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-233124_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-233124.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAczMKEPqx9RZiOhQd0037" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P583</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">MCES</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232331_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232331_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232331_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232331_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232331.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAcxIafKxXQNXTviULmmz9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P582</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">MCES</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232020_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232020_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232020_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232020_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-232020.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAcwWVIhk_8ltvlMtPPOau" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P581</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">MCES</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-231725_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-231725_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-231725_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-231725_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-231725.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAcohr3a8-_sFNxqB3x7WA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P580</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">MCES</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-230826_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-230826_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-230826_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-230826_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201128-230826.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHActY79-2uzDisJhGOXwd6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P579</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">6.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">MCES</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211933_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211933_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211933_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211933_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211933.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIrpHBmzaz3d-_LbkY1Yj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P578</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211841_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211841_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211841_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211841_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211841.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIrWn2o6HBEiIfW2u8EhE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P577</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211732_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211732_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211732_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211732_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211732.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIq9ZOHUsJiUExp8VEtpx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P576</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211640_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211640_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211640_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211640_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211640.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAEgAAAAHKWdA6L-n2OwhTiPIN7Frd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P575</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211556_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211556_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211556_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211556_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211556.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAJQAAAAGxfAJ64ObT5QY1vZQzZPgk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P574</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211005_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211005_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211005_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211005_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-211005.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P573</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210725_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210725_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210725_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210725_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210725.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P572</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210532_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210532_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210532_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210532_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-210532.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIm18buu9Me7_SwyEl6VB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P571</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-202602_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-202602_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-202602_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-202602_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-202602.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIYsQ3lrz6n19pYJmh4rM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P570</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194642_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194642_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194642_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194642_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194642.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P569</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194145_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194145_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194145_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194145_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-194145.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIHoHQpOPYlYabIl01mjC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P568</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193953_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193953_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193953_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193953_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193953.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIG3VpSrT62VXqElP-kl5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P567</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193621_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193621_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193621_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193621_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-193621.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAIFeCDP7AtUfGEgE8gu3z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P566</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-191844_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-191844_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-191844_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-191844_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-191844.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAH-Kq5TlUnFV_u8RkW0sl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P565</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-185605_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-185605_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-185605_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-185605_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-185605.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAH0k6K7B5AOotGhr-4pbi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P564</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-181642_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-181642_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-181642_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-181642_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-181642.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAHjHDvlkEZfYo9BL-yWj2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P563</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-175941_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-175941_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-175941_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-175941_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-175941.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAHbNtpF7F4NSHFkxn0Pc4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P562</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-173912_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-173912_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-173912_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-173912_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-173912.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAHRRIZ_OGosz-f6GSQQQK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P561</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-172139_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-172139_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-172139_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-172139_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-172139.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAHIdNtjQhSfBq3qs-fne1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P560</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-164438_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-164438_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-164438_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-164438_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-164438.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAG0_y1Mm817mdgkUWHvfO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P559</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-034012_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-034012_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-034012_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-034012_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-034012.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAA_DqEZjKCBfjouElxGT1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P558</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-033821_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-033821_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-033821_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-033821_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-033821.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P557</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-031933_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-031933_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-031933_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-031933_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-031933.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAA3SYatiuoLP186RaqiZI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P556</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025747_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025747_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025747_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025747_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025747.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAvQmiVla6FqcVjjJARUj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P555</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025543_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025543_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025543_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025543_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-025543.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAufDeK-HmzFrwJJFcPz1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P554</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest MuZhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024934_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024934_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024934_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024934_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024934.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P553</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024801_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024801_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024801_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024801_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024801.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P552</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024244_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024244_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024244_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024244_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024244.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P551</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024034_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024034_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024034_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024034_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-024034.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P550</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-023837_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-023837_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-023837_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-023837_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-023837.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAoiC07YQnkecObz-edWL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P549</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-015319_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-015319_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-015319_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-015319_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-015319.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P548</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-013219_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-013219_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-013219_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-013219_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-013219.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAASJ3d0vszKU3oSlWUc1O" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P547</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-011044_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-011044_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-011044_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-011044_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-011044.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P546</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010852_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010852_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010852_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010852_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010852.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P545</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010028_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010028_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010028_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010028_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-010028.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P544</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005615_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005615_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005615_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005615_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005615.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAGWPlnxoN9p-Ujrfxhma" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P543</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005359_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005359_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005359_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005359_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005359.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAFoLASu6UQBnDTMJQTRX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P542</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005201_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005201_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005201_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005201_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-005201.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAHAAFExCRGUXDiIrr5_KR1D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P541</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004948_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004948_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004948_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004948_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004948.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P540</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004528_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004528_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004528_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004528_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-004528.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P539</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003329_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003329_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003329_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003329_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003329.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG___g20LvjhX2E9irrE97O" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P538</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003100_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003100_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003100_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003100_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-003100.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P537</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-002753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-002753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-002753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-002753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-002753.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P536</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001533_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001533_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001533_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001533_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001533.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG__6FcUykqdJqEtyi0egjj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P535</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001106_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001106_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001106_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001106_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201126-001106.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG__4teA_HT_ekHpKfGuU7S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P534</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Maodou</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-234626_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-234626_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-234626_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-234626_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-234626.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG__xPnyeuyDKGfCE0SCJSh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P533</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-232420_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-232420_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-232420_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-232420_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-232420.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P532</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220319_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220319_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220319_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220319_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220319.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG__RpX4v5ERSwye88zT9qz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P531</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220100_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220100_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220100_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220100_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-220100.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P530</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-215729_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-215729_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-215729_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-215729_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201125-215729.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG__Pv710QBYQm0h0VkxQjQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P529</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205755_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205755_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205755_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205755_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205755.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P528</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205551_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205551_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205551_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205551_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205551.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P527</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205252_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205252_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205252_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205252_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-205252.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gnGVtvFqJx8eBnbmpgRY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P526</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202701_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202701_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202701_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202701_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202701.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P525</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202432_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202432_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202432_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202432_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-202432.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P524</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">5.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">M.K.M.A</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195456_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195456_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195456_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195456_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195456.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P523</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195411_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195411_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195411_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195411_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195411.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P522</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195334_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195334_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195334_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195334_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195334.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P521</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195259_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195259_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195259_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195259_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195259.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gTQftHSM1Ta-Os9PInX3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P520</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195201_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195201_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195201_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195201_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-195201.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">{'Expired'}</a><p className="index-number font-size-picture-descriptions">#P519</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-193710_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-193710_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-193710_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-193710_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-193710.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gNz1kciH3jS72k02KU55" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P518</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191439_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191439_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191439_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191439_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191439.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gF1E8BvQxRs8kFYKvm6l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P517</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191147_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191147_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191147_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191147_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191147.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gEzfChL4XmrQMicEmWR2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P516</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Dark Tangent</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191021_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191021_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191021_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191021_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-191021.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gEROnrNpNrBsXkeikF6K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P515</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Dark Tangent</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-190312_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-190312_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-190312_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-190312_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-190312.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-gBoMfuZUy5iZrROzUTar" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P514</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Dark Tangent</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185505_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185505_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185505_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185505_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185505.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f-iWv9iQIPkqlHv4cl5Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P513</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Imperium</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185310_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185310_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185310_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185310_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185310.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f9y7TdT9DP3Ex7yguCo6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P512</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Imperium</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185008_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185008_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185008_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185008_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-185008.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f8ohGvWqvo7oChyFWjn1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P511</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184716_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184716_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184716_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184716_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184716.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f7hlmbsPqXdEfShBTDNU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P510</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Indian Clashers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184446_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184446_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184446_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184446_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-184446.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f6vLInUlYu_mfG2Sl19D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P509</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Indian Clashers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183728_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183728_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183728_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183728_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183728.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f3y0FOnVTjLycQSFF7tw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P508</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183604_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183604_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183604_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183604_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183604.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f3NVmL0JZ-F6ydIq9k1V" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P507</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183347_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183347_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183347_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183347_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-183347.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-f2VKmNWhP086EP1zBbUX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P506</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181344_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181344_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181344_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181344_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181344.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-fviBkoUHO-DJV6CCJMLb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P505</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181343_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181343_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181343_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181343_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201115-181343.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG-fuRpl6wvdAbLp_ZqKd2B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P504</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-130214_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-130214_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-130214_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-130214_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-130214.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8O1AjSmvoeCqE_bip8bvi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P503</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125958_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125958_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125958_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125958_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125958.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8Oz--C28oUeiwijqYkTu2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P502</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125255_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125255_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125255_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125255_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-125255.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8Ow84NhMpc_7c4F498CxX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P501</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Imperium</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123741_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123741_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123741_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123741_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123741.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OqhECsAU2Uig2AlHKBnd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P500</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Imperium</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123535_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123535_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123535_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123535_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-123535.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OpjANIAvrrAWHhwCRX_1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P499</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Nova Imperium</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122634_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122634_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122634_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122634_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122634.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OlrA1XqjnyhP9XUBKujH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P498</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Dark Tangent</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122415_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122415_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122415_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122415_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122415.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8Okqs1TMAR1tffbBjZo8b" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P497</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Dark Tangent</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122136_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122136_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122136_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122136_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-122136.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OjhpBgYo-V5nqhAWGYjx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P496</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120331_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120331_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120331_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120331_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120331.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8Ob38q0SH1ZSw84kb2vqj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P495</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120137_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120137_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120137_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120137_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-120137.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8ObFQEXmmk087NKz39JMl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P494</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">4.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-115210_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-115210_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-115210_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-115210_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-115210.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OXMvuqLLSsYJUi-aeBN_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P493</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Silent Snipers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112904_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112904_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112904_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112904_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112904.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8ONqGrxHNEt_QWt1TbJow" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P492</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Silent Snipers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112436_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112436_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112436_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112436_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112436.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OL06-VgkTwJI5G1NHLE6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P491</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Indian Clashers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112255_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112255_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112255_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112255_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-112255.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OLIA2onIDRdqZOdwGMbH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P490</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Indian Clashers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-111254_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-111254_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-111254_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-111254_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201029-111254.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG8OHEWH_f-rC-xZNmcy2VV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P489</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Indian Clashers</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203733_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203733_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203733_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203733_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203733.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4r0uNxQCPwos0MmBTbMSA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P488</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203039_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203039_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203039_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203039_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-203039.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4ryaXokW3O4sJ2ED3slis" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P487</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-202524_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-202524_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-202524_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-202524_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-202524.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rwmoznAzjE5aXo0sMs3B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P486</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200929_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200929_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200929_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200929_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200929.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rq_ol4ZhEk6PcX33ojAy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P485</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200213_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200213_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200213_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200213_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-200213.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rohhVKyh3b_RjyWDCOcq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P484</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194833_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194833_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194833_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194833_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194833.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rjmHKFGCgejHvFOb9n2c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P483</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194346_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194346_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194346_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194346_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-194346.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rh3iXzn1JlEG1x1wgfHH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P482</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193718_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193718_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193718_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193718_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193718.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rfc1RZjRNsk6HxZo45HA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P481</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193159_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193159_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193159_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193159_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-193159.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rdbGHjvG5j_U2cWV9zjA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P480</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-192553_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-192553_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-192553_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-192553_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-192553.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rbCv8VPyp2Fj5tTAkUVy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P479</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-191619_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-191619_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-191619_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-191619_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-191619.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rXJW2IvUmN1PUmwWzW_q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P478</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190819_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190819_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190819_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190819_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190819.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rT6p97PKhESzU_Gf2k_s" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P477</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190240_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190240_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190240_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190240_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-190240.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rRlNbCArSXQet7fUyv7N" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P476</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185715_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185715_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185715_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185715_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185715.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rPaL89TT8K0QZeQFMp5X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P475</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-03" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185019_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185019_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185019_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185019_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-185019.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rMfgR_ABkeS9D3zmzQeS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P474</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-183807_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-183807_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-183807_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-183807_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-183807.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4rHQoSZZk14kR1KUgAH01" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P473</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181849_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181849_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181849_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181849_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181849.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4q-xwYPECUpyVyl9xMOXl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P472</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181057_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181057_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181057_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181057_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-181057.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4q7WVOvCUWxtgSZg34v82" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P471</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-180354_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-180354_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-180354_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-180354_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-180354.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4q4Oz7r_kH9lQI3xjDXhI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P470</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-11-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-175622_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-175622_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-175622_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-175622_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20201003-175622.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG4q0vgqN2ZrMxwjEaUaC7h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P469</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Ni Chang Dance</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061702_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061702_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061702_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061702_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061702.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JihkRpWUevf6L-wzr6gF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P468</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061003_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061003_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061003_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061003_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-061003.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3Jeg9dZxl2leZkJ2W5h6v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P467</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060554_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060554_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060554_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060554_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060554.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JcQSXOH9kFx7OJ5Q8-ZH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P466</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060111_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060111_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060111_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060111_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-060111.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JZxCUEqPr5tt_RledLfd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P465</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-055347_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-055347_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-055347_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-055347_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-055347.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JVyzJbz95KYWu79FeEhv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P464</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054607_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054607_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054607_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054607_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054607.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JRyXI0d5PsuZRTJu2-_2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P463</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054114_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054114_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054114_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054114_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-054114.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JPQptmG86aix7oWReg2W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P462</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-053518_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-053518_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-053518_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-053518_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-053518.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JMNyi_TOdgO-WnIRe6SB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P461</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052921_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052921_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052921_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052921_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052921.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JJIb1QE7g7p_XO3V7yDR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P460</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-10-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052244_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052244_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052244_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052244_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200923-052244.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG3JFvyxGlIeJWTX4kS8pk8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P459</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">H.T Family</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-055017_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-055017_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-055017_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-055017_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-055017.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/cn?action=OpenLayout&id=TH13%3AWB%3AAAAADgAAAAHIe5jaO40O50sbNWDQ9upb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P458</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Goethe</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-054307_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-054307_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-054307_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-054307_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-054307.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/cn?action=OpenLayout&id=TH13%3AWB%3AAAAAVgAAAAEOXruEV4rY-00-iL2P64VR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P457</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Goethe</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052957_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052957_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052957_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052957_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052957.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/cn?action=OpenLayout&id=TH13%3AWB%3AAAAAVgAAAAEOXrPv_X-IPI2G1xkZZQ2G" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P456</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Goethe</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052027_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052027_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052027_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052027_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-052027.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0oFVJpkEmJ49pyVG7aNO7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P455</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Goethe</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-051201_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-051201_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-051201_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-051201_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-051201.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0oChtaKMWT_UgV9lFZmCo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P454</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Goethe</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045850_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045850_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045850_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045850_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045850.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0n-cdxL6qwCuBO7BPfT0q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P453</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045123_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045123_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045123_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045123_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-045123.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0n769l0QgkkW-HFZJBb4l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P452</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-044410_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-044410_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-044410_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-044410_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-044410.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0n5oYU4q9NQodoAMgk0m2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P451</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043808_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043808_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043808_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043808_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043808.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0n3tyUw4-jHsBEFhIpsbZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P450</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043033_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043033_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043033_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043033_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-043033.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0n1TniPTKdnkzwJNLnSKj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P449</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">3.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Activit-E</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035925_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035925_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035925_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035925_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035925.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nroXQFwVkQhLZBhIug4m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P448</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035212_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035212_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035212_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035212_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-035212.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0npZYulrzGTYNZgR6Le2w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P447</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034730_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034730_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034730_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034730_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034730.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nn7r5fLKb718bbH3Jn2W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P446</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034141_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034141_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034141_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034141_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-034141.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nmK7jQIfaBOnvkKNhe9z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P445</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-033136_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-033136_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-033136_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-033136_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-033136.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0njJGVP_c_7wZ3GUFdjzG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P444</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031420_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031420_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031420_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031420_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031420.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0neMbYMiDpplk2C9__3op" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P443</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031002_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031002_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031002_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031002_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-031002.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nc9PmbEgif2y-GNIskci" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P442</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-030143_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-030143_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-030143_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-030143_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-030143.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nal9HOOScA8gfdya_CCL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P441</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-025117_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-025117_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-025117_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-025117_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-025117.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nXwPlfNTxltqBzhm3b6Z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P440</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-024305_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-024305_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-024305_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-024305_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200904-024305.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAG0nVg_OcSD-a6a-W8WeTrY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P439</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-013528_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-013528_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-013528_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-013528_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-013528.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhg8jAXVEXiXxM2C3UqyA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P438</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-011951_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-011951_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-011951_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-011951_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-011951.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhbcPZk1U_JHVW4N12XFQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P437</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-010033_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-010033_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-010033_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-010033_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-010033.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhU0SLSM_PAeDlmvU7SBR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P436</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-005227_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-005227_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-005227_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-005227_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-005227.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhSFf82cXy_0UmxhFMdYr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P435</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-002026_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-002026_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-002026_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-002026_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-002026.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhHgu7sUTtuzaLP2B8SPZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P434</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-000713_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-000713_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-000713_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-000713_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200827-000713.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzhDXMay2IIOb56XshsYsz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P433</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233909_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233909_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233909_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233909_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233909.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzg6Ylj2UA6BmB_OTSIzTG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P432</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233335_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233335_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233335_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233335_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-233335.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzg4lSdkL7JXkqAwHGhfEy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P431</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleVen Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-232707_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-232707_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-232707_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-232707_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-232707.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzg2dx6LhkM_3XDbQWQvPs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P430</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">UnicornsOfLove</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-230910_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-230910_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-230910_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-230910_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-230910.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzgwhvmGDCFvfRsQG4oLax" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P429</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">UnicornsOfLove</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-225113_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-225113_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-225113_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-225113_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-225113.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzgqe_tlN91BTVfbidWsvW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P428</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Euronics Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-223039_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-223039_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-223039_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-223039_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-223039.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzgjISspJ7gbyQjXI6oBXK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P427</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Euronics Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-221233_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-221233_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-221233_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-221233_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-221233.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzgcaBfx5cNHKY6uccZ13c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P426</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Euronics Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220750_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220750_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220750_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220750_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220750.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzganTJX7v176q2sWTykPd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P425</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Euronics Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-09-22" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220106_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220106_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220106_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220106_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200826-220106.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGzgX8j9lVvHmGsTt4cvK3g " target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P424</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Euronics Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-194706_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-194706_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-194706_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-194706_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-194706.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjhDvE-EoFTu6vuGspHy7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P423</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Penta</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-192445_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-192445_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-192445_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-192445_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-192445.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjYhMX-jLK5lU9gwTj6tK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P422</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Penta</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-191253_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-191253_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-191253_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-191253_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-191253.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjTn9hMFZd5voIE1o6-yJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P421</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Penta</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-190616_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-190616_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-190616_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-190616_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-190616.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjQ3spDEAqpoGWH24Inpm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P420</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Penta</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-185955_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-185955_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-185955_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-185955_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-185955.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjORL0culDr461sqS1X5j" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P419</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Penta</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-183747_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-183747_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-183747_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-183747_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-183747.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwjEk6gcqbxdJOQrKxtZp1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P418</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-180342_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-180342_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-180342_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-180342_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-180342.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwi1ImQ-SkOwqSACi2q2ei" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P417</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175757_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175757_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175757_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175757_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175757.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwiyU0DAyQnn_ICHacWNub" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P416</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175122_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175122_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175122_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175122_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-175122.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwivH8w8mLHVHiIzpWIQ0l" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P415</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-174502_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-174502_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-174502_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-174502_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-174502.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwisDD1itBIveyl-wNufWn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P414</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">NOVA Esports</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-172753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-172753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-172753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-172753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-172753.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwij3U1iY3qIKfpXuNeQYZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P413</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171936_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171936_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171936_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171936_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171936.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwif8QYpr3JIZsymeCPvr0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P412</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171338_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171338_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171338_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171338_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-171338.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwidETPkdSk6uIU63tN0f9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P411</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170645_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170645_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170645_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170645_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170645.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwiZwhjY337QeUn_4EnhPa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P410</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170035_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170035_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170035_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170035_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200807-170035.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGwiWkRzqu4K03i9AMJDJs0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P409</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Vatang</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-215638_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-215638_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-215638_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-215638_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-215638.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLkoSzSlgAwc0as8LwkId" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P408</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleven Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-212254_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-212254_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-212254_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-212254_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-212254.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLakFL33itQ8Iv_Zl0wrV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P407</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleven Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-211305_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-211305_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-211305_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-211305_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-211305.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLXicK8PghCHqFhsfiqTw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P406</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleven Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-210516_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-210516_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-210516_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-210516_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-210516.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLVInJWlduWAHGpMJo91I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P405</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleven Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-205124_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-205124_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-205124_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-205124_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-205124.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLQvs0wBZIuTNQPwTWiY7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P404</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">eleven Original</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-203919_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-203919_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-203919_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-203919_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-203919.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLM0UKw6zc1czuWbP4xPH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P403</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">RTK</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-202904_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-202904_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-202904_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-202904_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-202904.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLJgxPVQlvu9U9doMJ2Wu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P402</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">RTK</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-201125_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-201125_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-201125_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-201125_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-201125.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvLDiXedJhoqGVotmiU7tC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P401</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">RTK</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-194356_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-194356_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-194356_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-194356_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-194356.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvK5s2nDHk-aXbBfa7R91C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P400</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">RTK</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-193302_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-193302_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-193302_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-193302_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200728-193302.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGvK1nGQl9tuliaaNHMKWrJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P399</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">2.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">RTK</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194830_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194830_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194830_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194830_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194830.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsegq396Izwho_FTk7CvZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P398</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194153_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194153_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194153_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194153_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-194153.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsb_y6GvOrFIXSQgVvPZ7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P397</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-193252_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-193252_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-193252_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-193252_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-193252.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsYlU-eK_behPJzaV7ZBX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P396</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192713_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192713_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192713_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192713_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192713.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsWc2duGuWUGtJwWawgJ3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P395</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192123_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192123_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192123_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192123_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-192123.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsUKZ2dVhyWz0rMKxN6aR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P394</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-181029_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-181029_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-181029_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-181029_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-181029.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtr2d5psKqdqJZd_ZLBr3k" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P393</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-180229_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-180229_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-180229_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-180229_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-180229.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtry2tuaamMrfhLg8szi0w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P392</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174918_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174918_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174918_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174918_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174918.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtrs95TjorZLAJI14_5cZz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P391</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174105_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174105_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174105_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174105_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-174105.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtrpP30UlJ-X20ONbJmSVe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P390</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-172747_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-172747_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-172747_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-172747_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-172747.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtrjMyvKZMwzUn6vfPGGG7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P389</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-190333_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-190333_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-190333_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-190333_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-190333.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsND_y2Jaf8bzmyXZLxqf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P388</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest Muzhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185923_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185923_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185923_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185923_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185923.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsLTaKKxoXHWfnj_AKu1Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P387</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest Muzhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185053_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185053_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185053_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185053_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-185053.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsH0si0xpz-NyYmaJoPxp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P386</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest Muzhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-184307_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-184307_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-184307_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-184307_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-184307.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsEoM8T5aBIp3DBtGTjgs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P385</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest Muzhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-183605_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-183605_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-183605_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-183605_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200719-183605.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGtsBpQpFNfBmAad6CU6o4J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P384</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Darkest Muzhan</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-195022_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-195022_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-195022_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-195022_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-195022.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvb13f5jiaRM951wGXuf6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P383</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-194236_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-194236_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-194236_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-194236_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-194236.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvY1BibrI1FK3OrhMSn99" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P382</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-193224_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-193224_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-193224_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-193224_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-193224.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvU0ifdYW0UGBTh2Eyl0C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P381</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-192317_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-192317_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-192317_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-192317_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-192317.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvRJvgoMnpNfCbvgtcaMP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P380</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-191646_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-191646_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-191646_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-191646_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-191646.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvOfysJMR10XoLd5_Oogl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P379</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-185528_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-185528_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-185528_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-185528_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-185528.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsvFe8o_VOIkpk7SqxY6xi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P378</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-184242_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-184242_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-184242_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-184242_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-184242.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsu_-6iK4nrf5rXY-2qPKh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P377</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-183231_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-183231_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-183231_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-183231_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-183231.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsu7jwrWxUwBtmxxaCwlbm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P376</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-182045_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-182045_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-182045_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-182045_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-182045.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsu2PxFbBQJQDxWEq8fp3Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P375</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-181054_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-181054_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-181054_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-181054_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-181054.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsuxxJ36IPtCYapNbHT3iO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P374</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-170943_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-170943_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-170943_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-170943_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-170943.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsuUwr7IjztHylic3lqLuO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P373</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-165549_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-165549_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-165549_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-165549_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-165549.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsuOA6Y3Ornd3LyrKru4mk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P372</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163646_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163646_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163646_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163646_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163646.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsuErB2290kh41TtPDS4Ey" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P371</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163011_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163011_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163011_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163011_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-163011.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGsuBcl69BhHeAO_4nMXhNo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P370</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-161755_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-161755_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-161755_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-161755_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200712-161755.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGst7K43oIC1w_Ww9F323R3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P369</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-130312_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-130312_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-130312_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-130312_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-130312.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTcjNoKRF_Bssd4iP-Dtc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P368</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-125809_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-125809_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-125809_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-125809_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-125809.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTaW8m_cU_uLLGmkcWbwl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P367</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-124429_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-124429_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-124429_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-124429_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-124429.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTUj9Qvznfa72RjR3EIGm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P366</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-123647_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-123647_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-123647_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-123647_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-123647.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTQvW4C7Yw_r2rVOdoOPK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P365</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-122434_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-122434_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-122434_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-122434_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-122434.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTLRSWVbWI8yA-exP7kQ9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P364</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-142755_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-142755_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-142755_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-142755_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-142755.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrUC0qk0LayKU0-oobUql4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P363</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-141649_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-141649_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-141649_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-141649_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-141649.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrT9t2pdQlW0gATe7q0ZwC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P362</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-135727_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-135727_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-135727_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-135727_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-135727.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrT0yqxYBYvuT14aoLZFOJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P361</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-134022_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-134022_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-134022_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-134022_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-134022.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTtKh_r9Zb8IagMMUm0EC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P360</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-133022_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-133022_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-133022_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-133022_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200702-133022.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrTouQ2CGRDLGM0Zlthx1m" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P359</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-210720_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-210720_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-210720_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-210720_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-210720.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrFNjzVWy3ZBZEOQXSVYlN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P358</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-205709_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-205709_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-205709_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-205709_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-205709.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrFJ9g1OYRA7S0NBIm4Gju" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P357</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204841_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204841_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204841_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204841_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204841.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrFG9I-E0_-g2Twpv-8STV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P356</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204006_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204006_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204006_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204006_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-204006.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrFD1p6fG8vpo3p2H438gn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P355</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-203148_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-203148_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-203148_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-203148_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200630-203148.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGrE-5RkRGtcxO_oatl_Lji" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P354</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190613_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190613_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190613_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190613_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190613.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpPCusl3ognMjnsS7Q6TQR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P353</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190134_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190134_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190134_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190134_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-190134.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpPA4Bzv9B6_uXMqKFGzzY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P352</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185751_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185751_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185751_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185751_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185751.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpO_atN_f6Sm09LFb-0hXr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P351</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185119_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185119_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185119_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185119_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-185119.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpO8rhKSmBR6bfCYr-PtCc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P350</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-184602_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-184602_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-184602_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-184602_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-184602.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpO6bgkKbp6zu_swnb7v-4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P349</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-183048_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-183048_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-183048_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-183048_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-183048.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOz_zu7HeVTlNqmbofFIh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P348</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182625_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182625_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182625_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182625_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182625.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOyHo-jhDex3Ao-aWNapi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P347</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182317_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182317_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182317_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182317_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182317.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOwxU7WH75GhYWg6jvrpJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P346</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182032_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182032_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182032_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182032_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-182032.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOvm3sVY1yBcpBarOp7LV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P345</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181709_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181709_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181709_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181709_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181709.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOuB68HUNxA2zOX0Llrg9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P344</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181301_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181301_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181301_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181301_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-181301.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOsO39SdqIW4KYp0uje9e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P343</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180906_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180906_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180906_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180906_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180906.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOqiThYIyEekCU2SiAeve" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P342</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180628_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180628_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180628_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180628_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180628.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOpWp4-_XXVNM-KJG0IW9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P341</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180009_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180009_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180009_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180009_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-180009.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOmlfx6pOS6VlyidPf-dE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P340</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-18" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-175651_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-175651_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-175651_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-175651_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200618-175651.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGpOlDfm6gUzc6XgqAu2pcO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P339</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">ATN.aTTaX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195452_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195452_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195452_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195452_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195452.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGoHYzWlfss41bczYavUWuw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P338</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195154_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195154_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195154_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195154_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-195154.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGoHXpVM0Wc7e4j5kkN-B68" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P337</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194839_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194839_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194839_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194839_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194839.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGoHWbLqJIviutveQwxcEXj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P336</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194426_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194426_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194426_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194426_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194426.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGoHUyPBwcasUNeIllFaKni" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P335</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194048_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194048_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194048_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194048_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200610-194048.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGoHTY0c_a5NKe9wGBqKrEq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P334</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-185458_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-185458_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-185458_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-185458_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-185458.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGn-Q-K4y5tK-s4IaUzuazc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P333</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184958_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184958_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184958_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184958_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184958.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGn-O2ebTEXYCFD5-9pk7eD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P332</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184717_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184717_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184717_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184717_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184717.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGn-NtcxgLHzOpMy6NGzPLD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P331</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184351_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184351_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184351_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184351_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-184351.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGn-MQRjM03a_djWIHHinzK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P330</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-183959_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-183959_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-183959_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-183959_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200609-183959.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGn-Klmym4GLdpK4JoHZ_e1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P329</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154958_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154958_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154958_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154958_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154958.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmh95vb1k1vQBnKj9UrmIV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P328</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154411_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154411_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154411_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154411_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154411.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmh67ha9KTGWpEZ6Jo-Gpw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P327</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154103_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154103_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154103_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154103_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-154103.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmh5Y75mSKWlEDkvkdqFlb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P326</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153658_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153658_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153658_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153658_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153658.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmh3T7aSbPrQRm-cDhHb6X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P325</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153007_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153007_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153007_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153007_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-153007.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhzt9ZClt2agPtVgUY1vY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P324</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">X6tence</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-152234_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-152234_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-152234_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-152234_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-152234.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhv8DibKXHwBZA5URRTaD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P323</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151658_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151658_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151658_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151658_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151658.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhttMX1orkQfm5WzsZHop" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P322</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151209_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151209_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151209_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151209_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-151209.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhqpocaCzgrIa7Rc_ZyKR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P321</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150748_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150748_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150748_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150748_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150748.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhof1q3mdhYdngbHMKCkK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P320</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150222_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150222_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150222_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150222_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-150222.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhlvMzFzMFPjCO2OI_OmN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P319</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">INTZ</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145904_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145904_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145904_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145904_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145904.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhkExoLS_XQn3jfbGKumc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P318</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Team NOVA</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145612_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145612_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145612_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145612_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145612.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhinqlb8vYYEWCK8GvJLP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P317</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Team NOVA</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145226_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145226_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145226_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145226_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-145226.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmhguzW7MaWn-MQ0EiUP0-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P316</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Team NOVA</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-144758_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-144758_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-144758_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-144758_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-144758.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmheg4Wk6e2Rgj3BHrWhys" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P315</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Team NOVA</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-141848_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-141848_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-141848_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-141848_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200530-141848.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmha-3xQQNeoEisuOFPd5v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P314</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Team NOVA</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-032639_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-032639_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-032639_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-032639_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-032639.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmK8Wbg4EnZ3CRT5oB08XN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P313</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-031727_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-031727_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-031727_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-031727_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-031727.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmK48kQLW5sHon_LFvYC-r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P312</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-030814_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-030814_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-030814_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-030814_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-030814.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmK1hyhZIqNRvwqbOfmAXg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P311</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-025514_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-025514_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-025514_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-025514_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-025514.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKwyHT1IwWeECYAjGpjv0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P310</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-024602_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-024602_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-024602_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-024602_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-024602.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKtVfsBFAkc43xtDdSKIU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P309</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">1.Qualifier</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022938_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022938_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022938_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022938_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022938.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKnaYXllhF1ctwGqww_1Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P308</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Team Queso</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022203_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022203_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022203_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022203_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-022203.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKktnUv6TQOZpklJ9klPu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P307</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Team Queso</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-021602_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-021602_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-021602_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-021602_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-021602.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKimZWC6XX611T_FaFGCt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P306</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Team Queso</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020945_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020945_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020945_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020945_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020945.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKgfkn7VfYuEQGybzJ4vd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P305</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Team Queso</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020437_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020437_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020437_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020437_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200528-020437.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmKelDrTaZoZlSmAqhnnEE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P304</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Team Queso</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112933_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112933_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112933_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112933_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112933.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmERI0jsJTPrPr_osUXi6J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P303</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112304_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112304_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112304_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112304_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-112304.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmENgx8KYFvSdsnQGQW4p8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P302</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111703_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111703_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111703_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111703_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111703.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmEKpLMY8-xngYRS6tuT8y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P301</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111319_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111319_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111319_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111319_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-111319.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmEI48VzgXo6-nx2_t9ClQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P300</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-06-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-110938_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-110938_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-110938_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-110938_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200527-110938.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGmEHJxnNV7tYnO-ARRbK9R" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P299</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Qualifiers #1</p>
                      <p className="index-number font-size-picture-descriptions">Tribe Gaming</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234159_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234159_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234159_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234159_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234159.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj5Z7Qe77z92B27XtQdATl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P298</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234121_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234121_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234121_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234121_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-234121.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj5ZsMlg8zpr-qO9zEkrxO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P297</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-222322_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-222322_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-222322_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-222322_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-222322.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj4_Hrd2lxqQvzawiZL7xu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P296</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-05-14" data-title="Townhall 13 Trophy Base by Espejo humeante">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200513-215723_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200513-215723_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200513-215723_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200513-215723_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200513-215723.jpg 2430w"
                          title="Townhall 13 Trophy Base by Espejo humeante" alt="Townhall 13 Trophy Base by Espejo humeante" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGj42FAzV95o9Ku0eWLuNsA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P295</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=280CY2808" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Espejo humeante</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-14" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-215403_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-215403_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-215403_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-215403_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200513-215403.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGj405TXL2OpADd91JNKYBP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P294</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-174148_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-174148_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-174148_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-174148_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-174148.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhtvG_h4AlE--KtV9_uX-C" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P293</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-171144_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-171144_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-171144_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-171144_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-171144.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhte3MLe-btUjsP6i-PDyx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P292</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-163557_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-163557_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-163557_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-163557_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-163557.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhtLYcj32DPqZB-mqU_izx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P291</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-161318_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-161318_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-161318_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-161318_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-161318.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhs_HNMZcMCjdXHIlbQHg_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P290</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-05-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-154514_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-154514_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-154514_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-154514_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200429-154514.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhsvLj13qviI9ERLpCflrX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P289</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-160945_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-160945_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-160945_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-160945_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-160945.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhXipbjzr8lGwKLdj1bdug" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P288</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-165216_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-165216_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-165216_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-165216_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-165216.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhX8xZupUvC0De71JfxuTf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P287</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-172000_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-172000_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-172000_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-172000_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-172000.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhYOEwOUfVnsxOZxTe_0lV" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P286</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-29" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-154304_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-154304_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-154304_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-154304_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200427-154304.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhXSGjB99EHwX7Zct6At11" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P285</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-29" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200427-150621_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200427-150621_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200427-150621_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200427-150621_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200427-150621.jpg 2430w"
                          title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhW7bHl-0N1wRUKf4UXj3n" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P284</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-232323_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-232323_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-232323_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-232323_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-232323.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPx2BeXB-xCJzEieM-S3I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P283</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-28" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200426-223907_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200426-223907_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200426-223907_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200426-223907_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200426-223907.jpg 2430w"
                          title="Townhall 13 Farm Base" alt="Townhall 13 Farm Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhPfPvbdCBx_CEtL29AO3h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P282</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-221313_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-221313_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-221313_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-221313_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-221313.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPUMmsa1-Qv55h6Br0hcS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P281</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-28" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-213013_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-213013_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-213013_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-213013_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200426-213013.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGhPBGzk5MR50_kbGnEzEad" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P280</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-28" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200426-210832_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200426-210832_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200426-210832_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200426-210832_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200426-210832.jpg 2430w"
                          title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGhO3NfyJCF27qbd9Ps-IY0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P279</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-005316_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-005316_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-005316_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-005316_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-005316.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMlFRU06QhS1Oa92y9hcm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P278</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-011709_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-011709_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-011709_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-011709_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-011709.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMseIXq_unM577IQ0LA4h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P277</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-003010_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-003010_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-003010_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-003010_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-003010.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMdzQrq2UA7eRXw-lBV-y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P276</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-000608_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-000608_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-000608_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-000608_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-000608.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMWpEnBkI7Jh0dWHDlSXp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P275</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200421-232822_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200421-232822_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200421-232822_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200421-232822_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200421-232822.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgMLp1I_hZilusObDcMmCW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P274</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-063524_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-063524_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-063524_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-063524_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-063524.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOmlFYN5STecONfCxD80A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P273</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-055151_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-055151_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-055151_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-055151_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-055151.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOUGdKilEMyuMmUraAu7D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P272</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-052202_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-052202_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-052202_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-052202_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-052202.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgOHyluCo942fi7exQWBMp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P271</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-040653_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-040653_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-040653_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-040653_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-040653.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgNp6oBxWq_RQ9jtaCKVkm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P270</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-25" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-033517_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-033517_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-033517_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-033517_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200422-033517.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgNd2pZXmYfQC8wkt7L49J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P269</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-185342_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-185342_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-185342_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-185342_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-185342.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgAo18D3fFtcY_dTtkTMnh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P268</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-182407_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-182407_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-182407_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-182407_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-182407.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgAaFVArbjQaVqvbtDXpFD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P267</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-173935_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-173935_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-173935_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-173935_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-173935.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGgACt-y79DEfp-zRbD0r6d" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P266</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-170626_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-170626_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-170626_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-170626_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-170626.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_wh5gAHiXI3InZbRiLeU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P265</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-163652_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-163652_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-163652_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-163652_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-163652.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_gFdawNt7eElWy5Oryxt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P264</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-162455_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-162455_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-162455_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-162455_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-162455.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_ZbXrH4IJVmY-WggiTo2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P263</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-160132_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-160132_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-160132_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-160132_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-160132.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf_MTo01w0m_mjejUrpffH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P262</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-153530_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-153530_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-153530_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-153530_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-153530.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf-92NYWAFebYXWWAsomlM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P261</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-24" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200420-153425_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200420-153425_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200420-153425_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200420-153425_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200420-153425.jpg 2430w"
                          title="Townhall 13 Trophy Base" alt="Townhall 13 Trophy Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGf-9Sk08UtHpy1rEyrNA5I" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P260</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-24" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-152449_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-152449_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-152449_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-152449_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200420-152449.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGf-39uDvCIwbfN8y7FY2Yr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P259</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-154259_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-154259_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-154259_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-154259_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-154259.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdF2fhvCy5Gk-0QuqS8wff" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P258</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-02" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-142717_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-142717_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-142717_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-142717_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-142717.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdFJ8hqg8YJpQfWtqxO1DC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P257</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134300_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134300_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134300_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134300_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134300.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEwJzXU02nsNiMvfA48bC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P256</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134208_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134208_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134208_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134208_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134208.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEvpgmCX-Y-2sve9MB5-h" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P255</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134139_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134139_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134139_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134139_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134139.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEvW-I6NB_10vHedAXct3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P254</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134035_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134035_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134035_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134035_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-134035.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEutP2XbrD7XzkIKhYil1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P253</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133656_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133656_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133656_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133656_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133656.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEskcv5J2HrpHETBHchO4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P252</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133531_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133531_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133531_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133531_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-133531.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdErwjpX71mhwaWH5LZ2hI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P251</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-02" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133426_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133426_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133426_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133426_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133426.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdErM-cCWzDo4rzp1E4LJd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P250</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091757_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091757_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091757_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091757_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091757.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq0H7j6S2-F_XUsI-SD2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P249</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091614_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091614_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091614_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091614_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091614.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCqMluMhcCCuNn3UIB-Fc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P248</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091536_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091536_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091536_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091536_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091536.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCp6ZGgtHLc4cb8BJsyVN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P247</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091448_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091448_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091448_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091448_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091448.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCpi2VK0VlJ8092M-Wgh-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P246</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-04-02" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133052_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133052_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133052_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133052_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Screenshot_20200402-133052.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdEpUaJ9vf0MvCFs8UpNYv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P245</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132854_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132854_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132854_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132854_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132854.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEn-Z7k1u0MoOZg9sU3D3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P244</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132816_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132816_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132816_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132816_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132816.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEnkSC-WmkRSWkiLWjO2v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P243</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132629_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132629_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132629_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132629_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132629.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEmhP4HxbL2YaeY4oPAHZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P242</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132313_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132313_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132313_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132313_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132313.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEknS202nhUp7bF_MmJnd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P241</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132218_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132218_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132218_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132218_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-132218.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEkOF6FC4Rm8mtktFwXl6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P240</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-04-02" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-123602_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-123602_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-123602_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-123602_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Screenshot_20200402-123602.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGdEJuORj9yVzQjNq7TehBu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P239</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-123044_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-123044_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-123044_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-123044_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-123044.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEGmtnSRaCMxyf3lT3Q2r" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P238</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122956_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122956_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122956_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122956_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122956.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEGHnb47OqeHkpW9GPxFq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P237</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122919_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122919_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122919_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122919_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122919.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions">Expired</a><p className="index-number font-size-picture-descriptions">#P236</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122840_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122840_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122840_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122840_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-122840.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEFx4GdgB7kbla-Jk4nMK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P235</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-092020_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-092020_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-092020_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-092020_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-092020.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdEFYxmoReIbmNO_5mxBoP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P234</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091908_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091908_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091908_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091908_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091908.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq9ADTFgvVMF_ysUk0z_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P233</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091831_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091831_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091831_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091831_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091831.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq55Cm4fvVFaL-Nk5Kbw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P232</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091019_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091019_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091019_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091019_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091019.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCnfllx71OszjwzEEz9cy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P231</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-090818_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-090818_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-090818_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-090818_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-090818.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCmgEPmw3YJVtqAFIUGRD" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P230</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122143_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122143_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122143_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122143_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122143.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck6rqS02AuNj-H5rzdIr1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P229</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base by Name Not Found">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-135204_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-135204_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-135204_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-135204_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-135204.jpg 2430w"
                          title="Townhall 13 War Base by Name Not Found" alt="Townhall 13 War Base by Name Not Found" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGclpPV8jVIDkdM2kZgNyY7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P228</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9QRYRPUYQ" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Name Not Found</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122025_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122025_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122025_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122025_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-122025.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck6B85BG-Om8pKGIxUykS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P227</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121809_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121809_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121809_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121809_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121809.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck4-SHxkGRFyn_SY1znuf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P226</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121504_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121504_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121504_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121504_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121504.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck3eMr0y6b0QJ2HYl_FrJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P225</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121429_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121429_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121429_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121429_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121429.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck3Mp6gXbNg5Amr8aWmmX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P224</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-121236_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-121236_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-121236_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-121236_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-121236.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGck2PIvKyhbYKDvc0sy07B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P223</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121113_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121113_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121113_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121113_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-121113.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck1lq4U-Ra6rrnxC5DuRC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P222</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120918_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120918_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120918_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120918_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120918.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGck0o90Tq_OKwijXKI-mbr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P221</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120829_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120829_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120829_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120829_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120829.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGck0PM9zWnFGjvY1sqAqe2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P220</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120753.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckz8io0W7oC_UTFQj72Kd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P219</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120708_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120708_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120708_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120708_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120708.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckzlY1raNRKyCqXcwSYPl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P218</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120613_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120613_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120613_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120613_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120613.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckzJGJ-kqNSC-IFdtCyYa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P217</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120345_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120345_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120345_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120345_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120345.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckx-1S6ZQU5hiz0cPPZoB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P216</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120247_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120247_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120247_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120247_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-120247.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckxo8rzjOTFvHGsDbpA8o" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P215</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120139_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120139_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120139_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120139_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-120139.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckw9YlhFIJJnYM9C64coo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P214</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-115956_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-115956_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-115956_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-115956_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-115956.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckwG4fHkdN_XUBzjVzdah" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P213</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-145753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-145753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-145753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-145753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-145753.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckrYunCDLuXgsIE9t99UI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P212</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114645_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114645_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114645_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114645_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114645.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckpxWV-cRJsWytC2k8_fv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P211</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114531_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114531_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114531_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114531_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-114531.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckpIq1XPuQD70j5Fnl64w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P210</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-114347_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-114347_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-114347_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-114347_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-114347.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckoSzo9fqEb4envoAgJc-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P209</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-114048_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-114048_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-114048_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-114048_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-114048.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckm2lfbky8njJFppNHdA3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P208</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113919_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113919_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113919_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113919_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113919.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckmKSyGy8qp6MCtHUsByu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P207</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113753.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGcklmCpl4FjFnrbjvxpOO4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P206</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113558_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113558_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113558_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113558_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113558.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckkfibEbcu-PnhlU1KxC5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P205</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113151_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113151_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113151_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113151_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-113151.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckijJeIYsJDWwQfyzNJEx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P204</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-112717_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-112717_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-112717_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-112717_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-112717.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckgbZG-lvzEN68epUad0e" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P203</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112455_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112455_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112455_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112455_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112455.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckfbaNHp4Ars-zGkfmnVo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P202</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112347_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112347_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112347_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112347_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112347.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckeyVlwVMAeowGq_STt1H" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P201</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112038_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112038_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112038_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112038_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-112038.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckdeCeyMLIhnVtzk-SJSs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P200</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111723_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111723_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111723_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111723_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111723.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckbFZvEw5rxAZULt7XDVy" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P199</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111212_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111212_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111212_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111212_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-111212.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckY1DI8OTWoNAuRi4mWsm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P198</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-111048_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-111048_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-111048_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-111048_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-111048.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckYOAdJpPxGk_sNZ5Nj_z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P197</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-110837_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-110837_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-110837_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-110837_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-110837.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckXU7W1HHXwwrRqvGzmTS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P196</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-110701_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-110701_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-110701_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-110701_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-110701.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckWr-sgKB5O1Ifrrt8LYP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P195</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-110318_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-110318_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-110318_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-110318_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-110318.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckVORLxCmLKJSA2Ln_5iw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P194</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105930_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105930_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105930_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105930_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105930.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckTsf9O_CN-1PnFCuw1ZG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P193</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105636_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105636_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105636_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105636_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105636.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckSlOZ5mzur4O50CbCuJ6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P192</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105459_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105459_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105459_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105459_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105459.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckR9mV8bKiV7OYwBaVrCL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P191</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105347_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105347_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105347_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105347_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105347.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckRhpTzasUeQVI666Mxbj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P190</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105305_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105305_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105305_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105305_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-105305.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckRRhDdoj5hfTxxV4dEsR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P189</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-105145_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-105145_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-105145_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-105145_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-105145.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckQygzyLnEbsVQcIvhGWs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P188</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105044_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105044_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105044_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105044_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-105044.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckQcQ88qApmEToez0Xd88" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P187</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104947_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104947_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104947_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104947_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104947.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckQHFdhqKKE25Hwv8FzTw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P186</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-03-30" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-104842_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-104842_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-104842_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-104842_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200330-104842.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckPvEwHB_HYp0fzM0zvjW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P185</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-03-30" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104608_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104608_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104608_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104608_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200330-104608.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGckO5e2Br5O0gNdITppRd6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P184</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104404_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104404_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104404_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104404_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104404.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckOQDeeApF-HnOvRJspb5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P183</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-03-30" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104222_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104222_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104222_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104222_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200330-104222.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGckNwI6fx7Aq-gkTXzOwip" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P182</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base by Name Not Found">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Contributor-20200208-044009_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Contributor-20200208-044009_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Contributor-20200208-044009_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Contributor-20200208-044009_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Contributor-20200208-044009.jpg 2430w"
                          title="Townhall 13 War Base by Name Not Found" alt="Townhall 13 War Base by Name Not Found" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVGLBLuqKNFELbc8buoxee" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P181</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9QRYRPUYQ" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Name Not Found</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-013013_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-013013_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-013013_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-013013_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-013013.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFJHo0tTG07ms5DTArM1B" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P180</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012736_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012736_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012736_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012736_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012736.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFIWTS8wEY-nooieaFnHr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P179</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012857_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012857_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012857_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012857_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200208-012857.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFIwNgek8XF5w50DoZOMo" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P178</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-04-02" data-title="Townhall 13 War Base by Poseidon">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091944_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091944_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091944_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091944_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Screenshot_20200402-091944.jpg 2430w"
                          title="Townhall 13 War Base by Poseidon" alt="Townhall 13 War Base by Poseidon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGdCq-KQHnlb84K7_aepXDx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P177</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2OVULCG2G" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Poseidon</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012333_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012333_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012333_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012333_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012333.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFHMMcR2wkuNcmpxj_MRJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P176</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012454_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012454_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012454_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012454_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012454.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFHlkdNfPKrjA7rOsooXO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P175</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-02-08" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012927_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012927_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012927_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012927_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012927.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFI5prbIbtBZn4mrMUweX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P174</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012248_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012248_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012248_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012248_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-012248.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFG-hFgwEKeLhAiK6WV1c" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P173</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-02-08" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032734_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032734_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032734_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032734_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032734.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFvjKzU6hpDiD-jXmgmk6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P172</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-02-08" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012103_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012103_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012103_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012103_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012103.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFGelL1YcG5Cj0B25vpXd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P171</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011945_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011945_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011945_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011945_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011945.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFGIYkPd6eb7anhlKkus6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P170</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-02-08" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011712_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011712_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011712_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011712_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011712.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFFUx1TuhwCMCeq3oBCxP" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P169</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011542_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011542_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011542_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011542_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011542.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFE4QsbpWikuAw0LbJe7n" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P168</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011449_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011449_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011449_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011449_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011449.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFEpcvnMIWF9y2_EXLbju" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P167</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-02-08" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011404_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011404_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011404_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011404_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-011404.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFEb1em7GnS8Ifp-58X_z" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P166</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011302_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011302_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011302_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011302_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011302.jpg 2430w"
                          title="Townhall 13 War Base" alt="Townhall 13 War Base" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFEJG0tMUiZjPY8OqvnCl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P165</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base by Goblin.M.Peti">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032909_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032909_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032909_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032909_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032909.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by Goblin.M.Peti" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFwNOW-YsSExusbyy70vc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P164</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=LCR20GLV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Goblin.M.Peti</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011214_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011214_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011214_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011214_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200208-011214.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGVFD5fmOHqFI9MiND5NxAx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P163</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 13 Farm Base by KP.BARU006/001">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032822_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032822_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032822_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032822_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contributor-20200208-032822.jpg 2430w"
                          title="Townhall 13 Farm Base by KP.BARU006/001" alt="Townhall 13 Farm Base by ☆ SteelFixer ☆" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFv12c6VxFX5vkbMqVMu0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P162</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=29P8YJRLR" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">KP.BARU006/001</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-02-08" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012408_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012408_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012408_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012408_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200208-012408.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFHXttzD7s6TEBPGR6Sg6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P161</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-01-28" data-title="Townhall 13 War Base by ☆ SteelFixer ☆">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-431337_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-431337_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-431337_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-431337_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-431337.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by ☆ SteelFixer ☆" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/nl?action=OpenLayout&id=TH13%3AHV%3AAAAAHwAAAAGNBa1hioikcsW-tTMr2J9K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P160</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9JLPRYYV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">☆ SteelFixer ☆</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2020-01-28" data-title="Townhall 13 Farm Base by ☆ SteelFixer ☆">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200125-441337_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200125-441337_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200125-441337_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200125-441337_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200125-441337.jpg 2430w"
                          title="Townhall 13 Farm Base by ☆ SteelFixer ☆" alt="Townhall 13 Farm Base by ☆ SteelFixer ☆" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/nl?action=OpenLayout&id=TH13%3AHV%3AAAAAHwAAAAGNBcEOs_fxHqaCo-aawIXE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P159</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9JLPRYYV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">☆ SteelFixer ☆</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-01-28" data-title="Townhall 13 War Base by Justin">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Collaborator-Base-Layout-20200125-421337_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Collaborator-Base-Layout-20200125-421337_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Collaborator-Base-Layout-20200125-421337_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Collaborator-Base-Layout-20200125-421337_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Collaborator-Base-Layout-20200125-421337.jpg 2430w"
                          title="Townhall 13 War Base by Justin" alt="Townhall 13 War Base by Justin" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAADwAAAAGqPVlxzHEFgZop1u_hoeSi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P158</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=U8CYLYGC" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Justin</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-28" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200127-233658_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200127-233658_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200127-233658_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200127-233658_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Collaborator-Base-Layout-20200127-233658.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGToS1OpV2Z-Kf9tjE8ttZ3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P157</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-01-28" data-title="Townhall 13 War Base by Kevin">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-411337_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-411337_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-411337_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-411337_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200125-411337.jpg 2430w"
                          title="Townhall 13 War Base by Kevin" alt="Townhall 13 War Base by Kevin" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/vi?action=OpenLayout&id=TH13%3AHV%3AAAAAIgAAAAGHLThOHSbZgS2NJWRKarWK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P156</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=82UJ29CPL" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Kevin</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-01-28" data-title="Townhall 13 War Base by Ale Espi 307">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200127-231750_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200127-231750_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200127-231750_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200127-231750_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Collaborator-Base-Layout-20200127-231750.jpg 2430w"
                          title="Townhall 13 War Base by Ale Espi 307" alt="Townhall 13 War Base by Ale Espi 307" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAAEAAAAAGjRgywem2SOA_71r3yxFA2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P155</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=YPVYY2RL" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Ale Espi 307</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145449_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145449_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145449_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145449_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145449.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLzoB9paJZoqJOSz8OiIi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P154</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base by ☆ SteelFixer ☆">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-Collaborator-20200108-205531_Clash_of_Clans.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by ☆ SteelFixer ☆" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/nl?action=OpenLayout&id=TH13%3AWB%3AAAAAHwAAAAGKqum3_YO9Izd1E9sqNZNd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P153</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9JLPRYYV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">☆ SteelFixer ☆</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145241_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145241_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145241_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145241_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-145241.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLydC4vPzDpIkD2zRWR_u" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P152</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-02-28" data-title="Townhall 13 War Base by ❗️markehmus ❗️">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-032834_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-032834_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-032834_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-032834_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-032834.jpg 2430w"
                          title="Townhall 13 War Base by ❗️markehmus ❗️" alt="Townhall 13 War Base by ❗️markehmus ❗️" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AWB%3AAAAACQAAAAG_k5x3gXEwFbbpHK2CO_Pz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P151</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=Y8Q2VYUY" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">❗️markehmus ❗️</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144821_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144821_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144821_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144821_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144821.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLv_gqRFkF8YuN_YSpQL5" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P150</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144526_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144526_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144526_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144526_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144526.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLueealqnQBOlWagU5HIZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P149</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144024_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144024_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144024_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144024_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-144024.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLrp0op9n-HrGXS_nmGq3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P148</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-143656_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-143656_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-143656_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-143656_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-143656.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLpu5BiFCbY-UtLuvFYUz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P147</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-143610_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-143610_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-143610_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-143610_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-143610.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLpUm_y824DYcVy62Oc6a" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P146</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fa./Tr.</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-143037_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-143037_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-143037_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-143037_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-143037.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLmTB-SHj1P_KHQbkx4H4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P145</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142518_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142518_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142518_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142518_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142518.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLjYCtXqYpNpmaHFOZGnt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P144</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142259_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142259_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142259_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142259_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-142259.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLiG2aI9qwY7JGIESf_aG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P143</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141939_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141939_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141939_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141939_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141939.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLgUG02GvDxiS6gYYCZB-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P142</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141829_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141829_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141829_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141829_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141829.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLfrU6KB9JhMbq_Ht0yNi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P141</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141701_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141701_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141701_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141701_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141701.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLe5hUi9YYWUqhJap56wO" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P140</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141540_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141540_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141540_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141540_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141540.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLeKr9ZhqFdaHi0TugNC1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P139</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141439_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141439_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141439_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141439_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-141439.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLdogwbUOxt04SUpNDIJa" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P138</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141317_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141317_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141317_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141317_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-141317.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLc7syC5UIgD97wbUeApq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P137</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-140553_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-140553_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-140553_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-140553_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-140553.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLZE08sWoIIuwFUszVOdt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P136</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-134531_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-134531_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-134531_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-134531_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-134531.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLOatFUsqDJPov9-FqeQS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P135</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base by ☆ SteelFixer ☆">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Collaborator-2020-01-11-08-48-20_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Collaborator-2020-01-11-08-48-20_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Collaborator-2020-01-11-08-48-20_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Collaborator-2020-01-11-08-48-20_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Collaborator-2020-01-11-08-48-20.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by ☆ SteelFixer ☆" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/nl?action=OpenLayout&id=TH13%3AHV%3AAAAAHwAAAAGK-x2YY17lM6TCRJWHvMwb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P134</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=9JLPRYYV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">☆ SteelFixer ☆</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-134221_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-134221_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-134221_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-134221_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-134221.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLMv0HX5UCOmiHkrCbC-a" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P133</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133954_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133954_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133954_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133954_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133954.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLLeyxbvtmHLM4iVY5g8K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P132</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-133855_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-133855_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-133855_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-133855_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-133855.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLK9tNb8wgy8Yrk3aKczv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P131</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133642_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133642_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133642_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133642_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133642.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLJ8BtdzDKY0PAhPDk-g-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P130</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133340_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133340_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133340_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133340_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-133340.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLIQdvJ4iE4KxqxNa66eX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P129</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-19" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-132959_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-132959_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-132959_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-132959_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200118-132959.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGSLGXgwjjesCscnsCdkHba" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P128</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132844_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132844_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132844_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132844_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132844.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLFtOcDTs-hkvWCSWswPY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P127</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-19" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-132717_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-132717_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-132717_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-132717_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200118-132717.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLE4qCNuQZP-VHb7k_8KR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P126</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-19" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132437_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132437_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132437_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132437_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200118-132437.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGSLDhjZI-AXLd6Eol9TXtF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P125</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2020-01-19" data-title="Townhall 13 Farm Base by Raicha">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Collaborator-20200118-151246_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Collaborator-20200118-151246_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Collaborator-20200118-151246_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Collaborator-20200118-151246_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Collaborator-20200118-151246.jpg 2430w"
                          title="Townhall 13 Farm Base by Raicha" alt="Townhall 13 Farm Base by Raicha" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZIlpPvtYFhW5USZ2aObY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P124</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=Q9U9UUL0" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Raicha</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021647_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021647_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021647_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021647_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021647.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7eofmQU2EWfqgXIBnPUM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P123</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021532_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021532_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021532_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021532_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021532.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7eQ1yY8BIhYVuGcs6JgZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P122</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021357_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021357_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021357_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021357_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021357.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7dxzOUjqSL02YtJDYDEs" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P121</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021255_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021255_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021255_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021255_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021255.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7deKBtAygVMcFaXLg8oL" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P120</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021137_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021137_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021137_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021137_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-021137.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7dDyOtMc9sNHn7qXjEOw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P119</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-021042_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-021042_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-021042_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-021042_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-021042.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7cxM757bhTzV8FjixlA6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P118</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base by Itzu">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-020827_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-020827_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-020827_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-020827_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-020827.jpg 2430w"
                          title="Townhall 13 War Base by Itzu" alt="Townhall 13 War Base by Itzu" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7cGOSkkDjKqPMKNUq-e7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P117</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=92GJJ99L" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Itzu</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-020445_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-020445_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-020445_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-020445_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-020445.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7a6fRzCpEQjp6FYfOc_x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P116</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015944_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015944_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015944_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015944_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015944.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 35 Archers BabyDragon" alt="Townhall 13 War Base. ClanCastle 35 Archers BabyDragon" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7ZYFaNnT1bN1tkNTYssb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P115</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015753_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015753_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015753_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015753_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015753.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 3 IceGolem" alt="Townhall 13 War Base. ClanCastle 3 IceGolem" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7Y0KW6A-Txqtom1G0-U9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P114</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015648_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015648_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015648_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015648_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015648.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle IceGolem LavaHound" alt="Townhall 13 War Base. ClanCastle IceGolem Lavahound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7YfjwkLwoXOowutUEvOb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P113</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015532_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015532_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015532_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015532_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-015532.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 45 Archers" alt="Townhall 13 War Base. ClanCastle 45 Archers" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7YGKusimIyMkrlblD7LJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P112</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015439_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015439_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015439_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015439_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015439.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7X0aFYS6qEn1Ki6T7-o0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P111</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015234_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015234_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015234_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015234_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015234.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7XLdI9yMuT76LwsNlDgM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P110</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015114_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015114_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015114_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015114_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-015114.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7WwsHivndEEsGB2C7r1p" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P109</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014937_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014937_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014937_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014937_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014937.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 3 IceGolems" alt="Townhall 13 War Base. ClanCastle 3 IceGolems" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7WRnNgH1LEvvGgxXE7Y6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P108</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014748_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014748_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014748_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014748_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014748.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 3 IceGolems" alt="Townhall 13 War Base. ClanCastle 3 IceGolems" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7VtJrpgIaq8JnvV4n7Vx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P107</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014621_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014621_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014621_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014621_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014621.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 3 IceGolems" alt="Townhall 13 War Base. ClanCastle 3 IceGolems" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7VQc-CRshH9452N3pucn" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P106</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014519_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014519_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014519_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014519_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014519.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 3 IceGolems" alt="Townhall 13 War Base. ClanCastle 3 IceGolems" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7U8X1PfXBuDPQgqMohy4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P105</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014426_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014426_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014426_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014426_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014426.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle 15 Archers LavaHound" alt="Townhall 13 War Base. ClanCastle 15 Archers LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7Urj4sM1GzeRK4lY5QRR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P104</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014240_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014240_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014240_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014240_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-014240.jpg 2430w"
                          title="Townhall 13 War Base. ClanCastle Icegolem LavaHound" alt="Townhall 13 War Base. ClanCastle Icegolem LavaHound" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7UTv5QXybITk9EWRjJWJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P103</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013840_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013840_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013840_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013840_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013840.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7S5jpurBUQOg2j7Mwoj7" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P102</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2020-01-01" data-title="Townhall 13 War Base by Itzu">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013657_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013657_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013657_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013657_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20200102-013657.jpg 2430w"
                          title="Townhall 13 War Base by Itzu" alt="Townhall 13 War Base by Itzu" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGP7SXssQX0gPQv4z9fv73J" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P101</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=92GJJ99L" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Itzu</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base by Goblin.M.Peti">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032953_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032953_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032953_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032953_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor20200208-032953.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by Goblin.M.Peti" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFwNOW-YsSExusbyy70vc" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P100</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=LCR20GLV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Goblin.M.Peti</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-013330_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-013330_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-013330_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-013330_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-013330.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7RW6lKGyUHsAwSkBh4yW" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P099</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-013120_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-013120_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-013120_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-013120_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-013120.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7Qs6LEmqg7NWAR4ED55X" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P098</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012851_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012851_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012851_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012851_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012851.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7P579l_kfy3fLPFndeXG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P097</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2020-01-01" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012532_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012532_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012532_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012532_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20200102-012532.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7O9_eFBJEZG7qCUBTd5w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P096</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2020-01-01" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-011848_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-011848_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-011848_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-011848_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20200102-011848.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGP7M03AYcp4kaDtDQU8Xz4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P095</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194527_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194527_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194527_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194527_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194527.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZrOnAn8KFnqaY2w9u-h8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P094</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194416_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194416_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194416_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194416_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194416.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZqyiD9-DtRIJvOKxCAxh" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P093</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194325_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194325_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194325_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194325_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-194325.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZqgVrik5wmjsP4MDFSYR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P092</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194233_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194233_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194233_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194233_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-194233.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZqMf0JQuyBGNF1xKzxsj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P091</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-194123_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-194123_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-194123_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-194123_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-194123.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZpzrbeP7JWg02YaWuEiQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P090</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2019-15-09" data-title="Townhall 13 War Base by ...Abaddon...">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193847_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193847_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193847_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193847_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193847.jpg 2430w"
                          title="Townhall 13 War Base by ...Abaddon..." alt="Townhall 13 War Base by ...Abaddon..." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZo7ufDSf4sPDIocBm4RM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P089</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=292YQQYV0" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">...Abaddon...</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193631_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193631_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193631_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193631_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193631.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZoLDKyGq2O2iMY-nSTyQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P088</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193521_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193521_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193521_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193521_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-193521.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZnzuzdp98nrVsJVx2apH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P087</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2019-15-09" data-title="Townhall 13 Farm Base by Karuuzo">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-182722_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-182722_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-182722_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-182722_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-182722.jpg 2430w"
                          title="Townhall 13 Farm Base by Karuuzo" alt="Townhall 13 Farm Base by Karuuzo" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZIlpPvtYFhW5USZ2aObY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P086</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=882RG92J" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Karuuzo</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193343_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193343_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193343_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193343_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193343.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZnQZ5moBLCI73wz5v4S9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P085</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war", "contributor"]' data-date-created="2019-15-09" data-title="Townhall 13 War Base by Berti">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172039_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172039_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172039_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172039_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172039.jpg 2430w"
                          title="Townhall 13 War Base by Berti" alt="Townhall 13 War Base by Berti" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNYzghIcMZYEbp_0mNpRnB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P084</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=JR90V8Y0" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Berti</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193217_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193217_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193217_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193217_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193217.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZmxJPBfhRk4__s4H8l5E" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P083</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fa./Tr.</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193100_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193100_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193100_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193100_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193100.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZmUS9gGd3jaXarVwRl0v" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P082</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fa./Tr.</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-192857_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-192857_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-192857_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-192857_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-192857.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZlnY9Q6GL7zeDdnDnyp3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P081</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-192732_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-192732_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-192732_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-192732_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-192732.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZlJ-ug32K1KqOmITMNEQ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P080</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192556_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192556_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192556_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192556_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192556.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZklST6DIhZUjQ2J0683s" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P079</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192242_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192242_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192242_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192242_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-192242.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZjdj9jHgJEB6TOoQPEEF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P078</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191948_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191948_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191948_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191948_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191948.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZicRVNkWxv-UDxF2XcHE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P077</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-191739_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-191739_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-191739_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-191739_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-191739.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZhp_PPy-bnZLkswqPKZr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P076</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191335_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191335_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191335_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191335_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-191335.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZgNHVjTlfio6U3YZZBbi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P075</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190902_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190902_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190902_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190902_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190902.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZenHYlcUcaOxKEj2AjN9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P074</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190622_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190622_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190622_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190622_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-190622.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZdqy3KEpLVVCvLS0zgXe" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P073</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-190504_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-190504_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-190504_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-190504_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-190504.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZdMAlxecOBnV1bEN5W3w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P072</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185403_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185403_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185403_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185403_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185403.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZZI5yXvbYRMKB18ZHCB9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P071</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185207_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185207_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185207_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185207_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-185207.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZYcAEohFSN_y7YW8gTdX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P070</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184820_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184820_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184820_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184820_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184820.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZXCkIMx3BzkTddswg2B3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P069</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184654_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184654_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184654_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184654_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184654.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZWc_gBTj6fsu9xVlvBy0" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P068</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184359_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184359_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184359_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184359_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184359.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZVZuVux4r_sLeYB8cm5i" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P067</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184216_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184216_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184216_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184216_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-184216.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZUxA0PHO_sIBROBw47My" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P066</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183722_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183722_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183722_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183722_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183722.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZS5-2f0NUPnU-t6zD6KA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P065</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183606_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183606_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183606_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183606_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183606.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZScvPI98LLplkkNxcsRC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P064</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-183449_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-183449_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-183449_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-183449_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-183449.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZR9stclXbmh__olHodNp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P063</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183109_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183109_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183109_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183109_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-183109.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZQlXw8eQmBgTMQxZLaCU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P062</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182817_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182817_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182817_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182817_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182817.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZMPU9MuYYk-bIdQoiMeR" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P061</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182758_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182758_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182758_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182758_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-182758.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZLr8K8rolrB7U03EbLQu" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P060</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193440_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193440_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193440_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193440_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193440.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZnkaU8m-Bl03Bu4umlRU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P059</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fa./Tr.</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-180932_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-180932_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-180932_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-180932_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-180932.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNZISbWI0_GG1h6HolqlLi" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P058</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>


              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-180442_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-180442_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-180442_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-180442_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-180442.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZGLGmKoe5ThMmsdI-BYY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P057</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNY57UFM-MzQEJrg-idYQ1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P056</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-173504.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNY57UFM-MzQEJrg-idYQ1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P055</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-15-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172527_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172527_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172527_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172527_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191215-172527.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGNY1qbj8CnlbV3RsCPtbjd" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P054</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-172348_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-172348_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-172348_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-172348_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-172348.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNY05wDL4YbeTEoErKE7j-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P053</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193259_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193259_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193259_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193259_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-193259.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNZm_sCVRM_ol25N8mCBY_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P052</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Fa./Tr.</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-171531_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-171531_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-171531_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-171531_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-171531.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNYxLCRQP5I1SBxsrw71Lw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P051</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-15-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-171028_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-171028_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-171028_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-171028_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191215-171028.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNYu4gCdxm1w0kBw6s9fd9" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P050</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170746_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170746_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170746_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170746_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170746.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNYtpnqanIfpQyXMdw6cnv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P049</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-15-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170102_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170102_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170102_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170102_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191215-170102.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGNYqnIX4AKNw5CMRht02lq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P048</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-030100_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-030100_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-030100_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-030100_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-030100.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMxMad7CBoCDKB7XMVewOq" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P047</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025706_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025706_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025706_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025706_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025706.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMxLHtJzo_EA2_t9uy_vVK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P046</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025204_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025204_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025204_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025204_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-025204.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMxJggM610F0ezCa2OcbMC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P045</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-024622_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-024622_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-024622_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-024622_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-024622.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMxHpiIgYeKMwETtQGDbpg" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P044</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-024400_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-024400_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-024400_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-024400_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-024400.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMxG22lOCIuaqrhRlj3gWw" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P043</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-023833_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-023833_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-023833_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-023833_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-023833.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMxFHnd6bHbTdMolGl84zA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P042</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-023457_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-023457_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-023457_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-023457_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-023457.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMxD9sjvqB34gpCTzZGIoJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P041</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-022425_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-022425_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-022425_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-022425_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-022425.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMxAqK0wRGejNGhhjAfZ8_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P040</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021958_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021958_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021958_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021958_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021958.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMw_OLofeUaW7CIQpikV7K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P039</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021546_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021546_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021546_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021546_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-021546.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMw97KAMuNOM6nSJAAIaL6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P038</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-021242_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-021242_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-021242_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-021242_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-021242.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMw8-90XSTW92KQj9PB7y4" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P037</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020714_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020714_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020714_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020714_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020714.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMw7Q_Hsj5r5MkO-4LYop3" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P036</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020154_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020154_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020154_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020154_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-020154.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMw5l9FfTY9797PdYrtKlx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P035</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-015745_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-015745_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-015745_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-015745_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-015745.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMw4TwKS4nsopcr6zGJOWj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P034</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-015313_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-015313_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-015313_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-015313_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-015313.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMw23SRCJ1I1oAbxVBSx0K" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P033</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014948_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014948_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014948_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014948_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014948.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMw10q2ufgwlJwTB1gjJFp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P032</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014727_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014727_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014727_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014727_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014727.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMw1H5pOWty9sbAbaTIhJk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P031</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-014416_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-014416_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-014416_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-014416_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-014416.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMw0ICNbPPzci_54XiKJ2D" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P030</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014101_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014101_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014101_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014101_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-014101.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwzHPMz5C-pF774ABG5Ck" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P029</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-013751_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-013751_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-013751_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-013751_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-013751.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwyJ0IVUJsnHpa6Hi3KVr" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P028</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-010003_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-010003_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-010003_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-010003_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-010003.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwmohAJ_0DKAb6bmdgtU2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P027</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-005729_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-005729_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-005729_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-005729_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-005729.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMwl2nXftb4p6jfEt4prIK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P026</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-005527_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-005527_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-005527_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-005527_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-005527.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwlNm5lFaLOxWrMX79AY8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P025</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-005326_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-005326_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-005326_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-005326_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-005326.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMwkmYUQWdHr5gj8K0SuBb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P024</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-10" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-004908_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-004908_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-004908_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-004908_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191211-004908.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMwjSrNZ5nmrjP7T2eUv5Q" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P023</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-10" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-004642_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-004642_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-004642_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-004642_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191211-004642.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMwikAtEcMZBm4AF65B-yY" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P022</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004335_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004335_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004335_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004335_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004335.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwhp4ufuRHRxPyeahw3XJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P021</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-10" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004025_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004025_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004025_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004025_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191211-004025.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMwgrEFSPmmQ6YN3SXB4ra" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P020</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm", "contributor"]' data-date-created="2019-12-09" data-title="Townhall 13 Farm Base by KEVIN•COMET•XIX">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contr-20191210-185500_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contr-20191210-185500_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contr-20191210-185500_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contr-20191210-185500_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-Contr-20191210-185500.jpg 2430w"
                          title="Townhall 13 Farm Base by KEVIN•COMET•XIX" alt="Townhall 13 Farm Base by KEVIN•COMET•XIX" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH13%3AHV%3AAAAANAAAAAFXm9rs4iudFUuG9-cDyeqB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P019</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2Q2RUV0GU" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">KEVIN•COMET•XIX</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-025233_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-025233_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-025233_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-025233_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-025233.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMozmX_2_W1B3Hq-RGZbQ8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P018</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024657_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024657_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024657_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024657_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024657.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMoxumRyLxORBeCjv5Xypt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P017</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024210_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024210_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024210_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024210_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-024210.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMowFXem21Omop6nnzOTRN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P016</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-09" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-023524_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-023524_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-023524_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-023524_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-023524.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMot8zMdXyYuUMOnyFZSPj" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P015</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy", "contributor"]' data-date-created="2020-02-08" data-title="Townhall 13 War Base by Goblin.M.Peti">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-033030_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-033030_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-033030_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-033030_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-Contributor-20200208-033030.jpg 2430w"
                          title="Townhall 13 War Base by ☆ SteelFixer ☆" alt="Townhall 13 War Base by Goblin.M.Peti" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGVFwtcE5witdybdQIArSDk" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P014</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=LCR20GLV" target="_blank" rel="noopener noreferrer">{'Player ID <'}</a></div>
                      <p className="index-number font-size-picture-descriptions">Goblin.M.Peti</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-023134_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-023134_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-023134_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-023134_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-023134.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMospeOJwml13vWHsGHj61" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P013</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-022832_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-022832_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-022832_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-022832_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-022832.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMorrnxXjqitmeEcmfTLkX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P012</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-09" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-022316_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-022316_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-022316_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-022316_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-022316.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMoqBXYqUEJYhUcNAkPQrA" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P011</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-09" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-022118_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-022118_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-022118_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-022118_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-022118.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMopY4TU31ljjVLktC02bX" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P010</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["farm"]' data-date-created="2019-12-09" data-title="Townhall 13 Farm Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-021559_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-021559_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-021559_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-021559_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Farm/Townhall-13-Base-Layout-20191210-021559.jpg 2430w"
                          title="Townhall 13 Farm Base." alt="Townhall 13 Farm Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMonwd6r6lvM69YuCjfHha" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P009</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Farm</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-021010_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-021010_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-021010_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-021010_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-021010.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMol2CjLb738NcKzKVqWLE" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P008</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020604_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020604_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020604_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020604_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020604.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMokc-RVqAQOnxYPP-w3_u" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P007</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020434_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020434_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020434_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020434_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020434.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMoj9KqPINENzNMvlYVpug" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P006</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020250_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020250_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020250_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020250_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020250.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMojZOFZvuDV95fMqkyHs8" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P005</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020051_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020051_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020051_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020051_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-020051.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMoiwMymMkOLCt-Z3aChuK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P004</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015529_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015529_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015529_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015529_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015529.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMohE3NJc9EW_41mharKMx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P003</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["war"]' data-date-created="2019-12-09" data-title="Townhall 13 War Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015306_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015306_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015306_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015306_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-War/Townhall-13-Base-Layout-20191210-015306.jpg 2430w"
                          title="Townhall 13 War Base." alt="Townhall 13 War Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AWB%3AAAAAGQAAAAGMogSgyiOAu_jpYn7u6vOv" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P002</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">War</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["trophy"]' data-date-created="2019-12-09" data-title="Townhall 13 Trophy Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-014732_640w.jpg"
                          srcSet="https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-014732_640w.jpg 320w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-014732_1024w.jpg 640w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-014732_1920w.jpg 1920w, https://townhall-13.clashbases.de/Townhall-13-Trophy/Townhall-13-Base-Layout-20191210-014732.jpg 2430w"
                          title="Townhall 13 Trophy Base." alt="Townhall 13 Trophy Base." zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH13%3AHV%3AAAAAGQAAAAGMofihzIZcAV4hhodyw-zI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#P001</p></figcaption>
                    <div>
                      <p className="picture-item__tags hidden@xs font-size-picture-descriptions">Trophy</p>
                      <p className="index-number font-size-picture-descriptions">TH 13</p>
                    </div>
                  </div>
                </div>
              </figure>

              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>

          <div className="archive-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="archive-paragraph-title">Welcome to the Th 13 Archive<br /></h1>
            <p className="archive-paragraph">
              The Website has been reworked for the TH 15 update of October 2022!<br /><br /><br />

              All of the old and outdated base layouts from before the update were transferred into the archives. Switch back to the non-archived townhall pages for all of our newest additions!<br /><br />

              Base Layout links in the archives are extremely unlikely to be working. Please switch back to the non-archive sites for working links!<br />
            </p>
          </div>



          </div>

      </section>

    );
  };
};

export default ArchiveTownhallthirteen;
