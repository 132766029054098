import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';


const sideDrawer = props => {
    let drawerClasses = 'side_drawer';
    if (props.show) {
        drawerClasses = 'side_drawer open';
    }

    return(

        <section className={drawerClasses}>
            <nav className="side_drawer-menu">

                  <Link className="sidebar-menu" to="/"><li onClick={props.click}>Home Page</li></Link>
                  <Link className="sidebar-menu" to="/Wiki"><li onClick={props.click}>Clash Wiki</li></Link>
                  <Link className="sidebar-submenu" to="/Wiki-HomeVillage"><li onClick={props.click}>Home Village</li></Link>
                  <Link className="sidebar-submenu" to="/Wiki-BuilderVillage"><li onClick={props.click}>Builder Village</li></Link>
                  <Link className="sidebar-submenu" to="/Wiki"><li onClick={props.click}>Know How</li></Link>
                  <Link className="sidebar-menu" to="/Townhall-Selection"><li onClick={props.click}>Base Layouts</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-15"><li onClick={props.click}>Townhall 15</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-14"><li onClick={props.click}>Townhall 14</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-13"><li onClick={props.click}>Townhall 13</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-12"><li onClick={props.click}>Townhall 12</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-11"><li onClick={props.click}>Townhall 11</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-10"><li onClick={props.click}>Townhall 10</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-9"><li onClick={props.click}>Townhall 9</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-8"><li onClick={props.click}>Townhall 8</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-7"><li onClick={props.click}>Townhall 7</li></Link>
                  <Link className="sidebar-submenu" to="/Townhall-6"><li onClick={props.click}>Townhall 6</li></Link>
                  <Link className="sidebar-submenu" to="/BuilderBase"><li onClick={props.click}>Builder Base</li></Link>
                  <Link className="sidebar-menu" to="/Archive-Townhall-14"><li onClick={props.click}>Archive</li></Link>
                  <Link className="sidebar-menu" to="/About"><li onClick={props.click}>About</li></Link>

                  {/*<div className="discord-sidebar-logo-container"><a href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer"><img alt="ClashBases - Base Layout and Link Collection Discord" className="discord-sidebar-logo" src="https://assets.clashbases.de/Logos/discord-toolbar-logo_150w.png" /></a></div>*/}
                  {/*<div className="discord-sidebar-logo-container"><a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer"><img alt="ClashBases - Base Layout and Link Collection Patreon" className="discord-sidebar-logo" src="https://assets.clashbases.de/Logos/patreon-link-button-png_150w.png" /></a></div>*/}

            </nav>
        </section>

    );
};

export default sideDrawer;
