import React, { Component } from 'react';
import './BuilderBase.scss';
import '../A12_TownhallTwelve/TownhallTwelve.scss';
import Builderdemo from './BuilderBaseScript.js';
import mediumZoom from 'medium-zoom';
import Image from '../../Image';
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";
import Ad from "../../Ad/ad";


class builderbase extends Component {

  componentDidMount () {
    window.builderdemo = new Builderdemo(document.getElementById('grid'));
  }

  zoom = mediumZoom({background:"rgba(0, 0, 0, 0.5)"})

  attachZoom = image => {
    this.zoom.attach(image)
  }

  render() {
    return (

      <section className="builder-about-page">

          <Helmet>
            <meta charSet="utf-8" />
            <title>Builder Base Layouts and Links</title>
            <meta name="description" content="All Builder Base Layouts in one big filterable Gallery! Add the best War Bases, Trophy Bases, Farm Bases, Fun Bases and LegendsLeague Bases directly into your game via the import link." />
            <meta name="keywords" content="builder base, builder bases, builder base, night base, night base base, builder base links, links, builder base base design, builder base bases, builder base warbase, warbase, base for war, clan war base, clanwar base builder base, war base builder base, night base war base, night base base for war, builder base trophybase, trophybase, base for trophys, trophy base builder base, night base trophy base, night base base for trophy, builder base pushbase, push base, base for pushing, pushing base, trophy push, trophy pushing, push base builder base, night base push base, night base base for pushing, builder base farmbase, farmbase, base for farming, base for farm, dark elixir protect, protect dark elixir, farm base builder base, night base farm base, night base base for farming, troll bases, builder base troll base, builder base fun base, builder base funny base, builder base trolling, funny builder base base, best builder base base, anti bats, anti anti loon, anti cannon carts, anti pekka, anti everything, anti 2 star, anti 2 star, anti 3 star, island base, builder base island base, ring base, builder base ring base, teaser, builder base teaser, Legends League base, Legends League, war, farm, trophy, fun, Clash of Clans, clashofclans, coc, clash, clash bases, clan, clan games, base layouts, layout, layouts, layout editor, mobile game, gaming, base builder, link, share base base, link, builder base links, builderbase link, night base link, builder base link" />
            <meta name="coverage" content="Worldwide" />
            <meta name="distribution" content="Global" />
          </Helmet>

          <div className="builder-about-page-container" id="back-to-top">
          <div className="clans-mobile-overlay">

          <div className="builder-container-about">
            <div className="builder-row-about">
              <div className="col-12@sm">
                <h1 className="builder-about-title">Builder Base Layouts</h1>
              </div>
            </div>
          </div>

          <div className="builder-container-about">


            <div className="builder-row-about">
              <div className="col-6@sm builder-filters-group-wrap">
                <div className="builder-filters-group builder-filters-group-right">
                  <p className="builder-filter-label builder-filter-color">Filter</p>
                  <div className="btn-group filter-options">
                    <button className="btn btn--primary" data-group="builder8">Bh 8</button>
                    <button className="btn btn--primary" data-group="builder9">Bh 9</button>
                  </div>
                </div>
              </div>

              <div className="col-6@sm Patreon-Ad-container">
                <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">
                <LazyLoad throttle={250} offset={500}>
                  <img
                    src="https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png"
                    srcSet="https://assets.clashbases.de/Backgrounds/New15ClashBases_320w.png 320w, https://assets.clashbases.de/Backgrounds/New15ClashBases_640w.png 640w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1024w.png 1600w, https://assets.clashbases.de/Backgrounds/New15ClashBases_1200w.png 1200w"
                    title="Become A Patreon" alt="Ad to become a Patreon for Clashbases.de"/>
                </LazyLoad>
                </a>
              </div>

            </div>
          </div>
          </div>

          <div className="builder-container-about">
            <div id="grid" className="builder-row-about my-shuffle-builder-container-about">

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142053_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142053_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142053_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142053_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142053.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyti9k3-jnkiUIa-vXS7A" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B054</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142413_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142413_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142413_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142413_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142413.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyv56jQsfhygiX8sxYHu1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B053</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142215_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142215_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142215_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142215_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142215.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyugwbcUi4EodY88Ml6mH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B052</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142457_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142457_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142457_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142457_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-142457.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgywaisPWzd6evIctlO-GU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B051</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141907_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141907_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141907_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141907_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141907.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgysUMpFisCaLdjD4IntaJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#B050</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions yellow">New</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141807_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141807_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141807_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141807_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141807.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyrlUi88Kng9D7bQiKFdT" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L049</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141724_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141724_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141724_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141724_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141724.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyrFOTQceEH3ZEatpOQYt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L048</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141619_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141619_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141619_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141619_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141619.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyqSC7mG15zVQZy8k4ZCp" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L047</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141538_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141538_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141538_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141538_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141538.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgypzAxo48aUFK9tHg09KJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L046</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141254_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141254_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141254_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141254_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141254.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyn1NyVVH-cwxf5NK-wq1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L045</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141108_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141108_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141108_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141108_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-141108.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgym5EILwiigUgtmaFGiMb" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L044</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133958_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133958_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133958_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133958_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133958.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyQefhOsZD6mTUoNPdow1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L043</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
              <Ad adType="ad-container" adID="ccb47432-59d3-485b-a86a-ae8a9fa9a95a" />
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133904_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133904_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133904_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133904_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133904.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyP0rVeOV-S1HJwIBX0_S" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L042</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133628_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133628_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133628_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133628_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133628.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyOEElMXdtQecCXlpHX-t" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L041</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133509_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133509_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133509_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133509_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133509.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyNI0yYmhVvjywz6HUPI_" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L040</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                </div>
              </div>
            </figure>


            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133354_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133354_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133354_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133354_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133354.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyMTiqkEd3reeIYL5YtWz" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L039</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions ">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133313_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133313_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133313_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133313_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133313.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyLy7-A1m_z3lT3HPZVPm" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L038</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions ">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133148_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133148_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133148_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133148_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133148.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyKyAUBtkqVWVz2ctfQAt" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L037</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions ">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133125_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133125_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133125_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133125_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133125.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyKiC9tvr8iEa8sKy28CB" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L036</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions ">BH 9</p>
                </div>
              </div>
            </figure>

            <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2020-04-24" data-title="Builder Hall 9 Base">
              <div className="picture-item__inner">
                <div className="aspect aspect--16x9">
                  <div className="aspect__inner">
                    <LazyLoad height={200} offset={100} once>
                      <Image
                        src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133053_640w.jpg"
                        srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133053_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133053_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133053_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-20200424-133053.jpg 2430w"
                        title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                    </LazyLoad>
                  </div>
                </div>
                <div className="picture-item__details">
                  <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGgyKKH44cpwhmes5l1Xdf2" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L035</p></figcaption>
                  <p className="picture-item__tags hidden@xs font-size-picture-descriptions ">BH 9</p>
                </div>
              </div>
            </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172842_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172842_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172842_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172842_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172842.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3hgA-ZwtZnF7fAqHamXJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L034</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172745_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172745_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172745_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172745_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172745.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3hFYIlJfTTqeddGScq6f" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L033</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172645_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172645_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172645_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172645_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172645.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3gorYyH9vrCd41DHfg5x" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L032</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>


              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172558_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172558_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172558_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172558_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172558.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3gRgIuDisQVrkyt7yr0Y" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L031</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172507_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172507_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172507_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172507_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172507.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3f4B9V1-emq9I0fsmmb1" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L030</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172432_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172432_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172432_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172432_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172432.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3fm944Fz9afYorn93DgG" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L029</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172346_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172346_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172346_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172346_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172346.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3fQvgSmjt3khR5EuM4vF" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L028</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172317_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172317_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172317_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172317_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172317.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3fB0wz6CkpYEyCTKxYaZ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L027</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172151_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172151_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172151_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172151_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172151.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3eZo-2y6sVXPyL1lD45w" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L026</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172056_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172056_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172056_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172056_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172056.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3d-Pptwj9k4q7-GpGuHH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L025</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172014_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172014_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172014_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172014_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-172014.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3dpBvc8K10CPjv-oIpKS" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L024</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9", "contributor"]' data-date-created="2020-03-30" data-title="Builder Hall 9 by TYRΔNT">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad throttle={250} offset={500}>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/BuilderBase-Base-Layout-12343632q145_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/BuilderBase-Base-Layout-12343632q145_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/BuilderBase-Base-Layout-12343632q145_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/BuilderBase-Base-Layout-12343632q145_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/BuilderBase-Base-Layout-12343632q145.jpg 2430w"
                          title="Builder Hall 9 by TYRΔNT" alt="Builder Hall 9 by TYRΔNT" zoom={this.zoom}  />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/en?action=OpenLayout&id=TH9%3ABB%3AAAAALwAAAAFwezoiXSpKlV3v1KNgo-gC" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L023</p></figcaption>
                    <div>
                      <div className="align-right"><a className="picture-item__tags hidden@xs font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenPlayerProfile&tag=2Q8UR92" target="_blank" rel="noopener noreferrer">Player ID</a></div>
                      <p className="index-number font-size-picture-descriptions">TYRΔNT</p>
                    </div>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171745_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171745_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171745_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171745_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171745.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3chFmdKcsfeKZd0T7mrH" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L022</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171915_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171915_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171915_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171915_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171915.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3dNY7ACC4g4-GD8ynREf" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L021</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171532_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171532_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171532_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171532_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171532.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3bjDmybk-qrJK8l890qU" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L020</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171356_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171356_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171356_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171356_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171356.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3a0nWhuZufG7ps-3OIsx" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L019</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-6@xs col-12@sm col-12@md picture-item ad-mobile-display" data-groups='["ads"]'>
                <Ad adType="ad-container" adID="651f41b8-f7b9-4767-b18b-cb93f5a00928" />
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171126_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171126_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171126_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171126_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-171126.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3ZrN9x6uK2DFKJL-Hfz-" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L018</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170917_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170917_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170917_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170917_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170917.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3Yp9lXkwnuMjlpH89xim" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L017</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-10-11" data-title="Builder Hall 9 Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170651_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170651_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170651_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170651_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Builder-Hall-9-ClashofClans-20191011-170651.jpg 2430w"
                          title="Builder Hall 9 | Strong Builder Hall Base Layout." alt="Builder Hall 9 | Strong Builder Hall Base Layout." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3XiLz4K0DHETEQXtuYND" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L016</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-30" data-title="Castle Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-020228_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-020228_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-020228_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-020228_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-020228_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Strong Castle Style Builder Hall." alt="Builder Hall 9 | Strong Castle Style Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAF1dtapGQ4Zuq5Le4CGeGhK" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L015</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-30" data-title="Compact Builder Base 9">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015845_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015845_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015845_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015845_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015845_Clash_of_Clans.jpg 2430w"
                          title="Builder Base 9 | Compact Design with Northern Builder Hall." alt="Builder Base 9 | Compact Design with Northern Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L014</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-30" data-title="Strong Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015653_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015653_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015653_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015653_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015653_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Strong Centralized Builder Hall." alt="Builder Hall 9 | Strong Centralized Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L013</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-30" data-title="Strong Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015438_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015438_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015438_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015438_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015438_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Strong Centralized Builder Hall." alt="Builder Hall 9 | Strong Centralized Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L012</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-30" data-title="Compact Builder Base 9">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015233_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015233_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015233_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015233_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015233_Clash_of_Clans.jpg 2430w"
                          title="Builder Base 9 | Compact Design with Northern Builder Hall." alt="Builder Base 8 | Compact Design with Northern Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAF1dpuvSLkCgv0EQ9v8-57W" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L011</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-29" data-title="Stretched Builder Hall">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015038_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015038_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015038_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015038_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-015038_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Stretched Builder Hall." alt="Builder Hall 9 | Stretched Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAF1dpBDdtKqUreJoA1lbZ1M" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L010</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-29" data-title="Northern Lure Builder Hall Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014928_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014928_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014928_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014928_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014928_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Northern Lure." alt="Builder Hall 9 | Northern Lure." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L009</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-29" data-title="Standard Builder Hall Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014756_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014756_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014756_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014756_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190701-014756_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Standard Box Base." alt="Builder Hall 9 | Standard Box Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAF1doB2x1NJ1IKuqAPDXySI" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L008</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-24" data-title="Standard Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190625-003736_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190625-003736_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190625-003736_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190625-003736_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190625-003736_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Standardized Builder Hall" alt="Builder Hall 9 | Standardized Builder Hall" zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/no?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAF0cTgjn0qjCwQ2eaA4TQBM" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L007</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-24" data-title="Standard Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230843_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230843_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230843_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230843_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230843_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 9 | Standardized Builder Hall." alt="Builder Hall 9 | Standardized Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L006</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder9"]' data-date-created="2019-06-21" data-title="Two Frontiers Builder Base 8">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230905_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230905_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230905_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230905_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-9/Screenshot_20190623-230905_Clash_of_Clans.jpg 2430w"
                          title="Builder Base 9 | Attack on two Frontiers." alt="Builder Base 9 | Attack on two Frontiers." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAGE3aUz0EMKc1dPZobAWmRN" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L005</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 9</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder8"]' data-date-created="2019-06-21" data-title="Compact Builder Base 8">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-8/20190620_151911_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-8/20190620_151911_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151911_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151911_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151911.jpg 2430w"
                          title="Builder Base 8 | Compact Design with Northern Builder Hall." alt="Builder Base 8 | Compact Design with Northern Builder Hall." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" >Expired Link</a><p className="index-number font-size-picture-descriptions">#L004</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 8</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder8"]' data-date-created="2019-06-21" data-title="Standard Builder Hall Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-8/20190620_151838_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-8/20190620_151838_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151838_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151838_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151838.jpg 2430w"
                          title="Builder Hall 8 | Standard Stretched Base." alt="Builder Hall 8 | Standard Stretched Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAFzknJXlV0aOuUf1OAPrrVl" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L003</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 8</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder8"]' data-date-created="2019-06-20" data-title="Compact Single Compartment Builder Base">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-8/Screenshot_20190620-151433_Clash_of_Clans_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-8/Screenshot_20190620-151433_Clash_of_Clans_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-8/Screenshot_20190620-151433_Clash_of_Clans_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-8/Screenshot_20190620-151433_Clash_of_Clans_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-8/Screenshot_20190620-151433_Clash_of_Clans.jpg 2430w"
                          title="Builder Hall 8 | Single Compartment Boxbase." alt="Builder Hall 8 | Single Compartment Boxbase." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAFzkJY1o8e08k-f_KTGJ2qJ" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L002</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 8</p>
                  </div>
                </div>
              </figure>

              <figure className="col-3@xs col-3@md picture-item" data-groups='["builder8"]' data-date-created="2019-06-18" data-title="Northern Lure Builder Base 8">
                <div className="picture-item__inner">
                  <div className="aspect aspect--16x9">
                    <div className="aspect__inner">
                      <LazyLoad height={200} offset={100} once>
                        <Image
                          src="https://builderbase.clashbases.de/BuilderBase-8/20190620_151756_640w.jpg"
                          srcSet="https://builderbase.clashbases.de/BuilderBase-8/20190620_151756_640w.jpg 320w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151756_1024w.jpg 640w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151756_1920w.jpg 1920w, https://builderbase.clashbases.de/BuilderBase-8/20190620_151756.jpg 2430w"
                          title="Builder Base 8 | Northern Lure Box Base." alt="Builder Base 8 | Northern Lure Box Base." zoom={this.zoom} />
                      </LazyLoad>
                    </div>
                  </div>
                  <div className="picture-item__details">
                    <figcaption className="picture-item__title"><a className="link-color font-size-picture-descriptions" href="https://link.clashofclans.com/de?action=OpenLayout&id=TH9%3ABB%3AAAAAGQAAAAFzk593JgDi_B-cTmTBK7d6" target="_blank" rel="noopener noreferrer">{'> Link'}</a><p className="index-number font-size-picture-descriptions">#L001</p></figcaption>
                    <p className="picture-item__tags hidden@xs font-size-picture-descriptions">BH 8</p>
                  </div>
                </div>
              </figure>

              <div className="col-1@sm col-1@xs my-sizer-element"></div>
            </div>
          </div>

          <div className="homebase-paragraph-container">

            <a className="discord-link" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">
              <div className="discord-container">
                <img className="discord-logo-container" src="https://assets.clashbases.de/Logos/discord-logo.png" title="" alt="" />
                <div className="discord-message-container">
                  <h3 className="discord-message">Join Our Official Discord Server to Keep the Discussion Going!</h3>
                </div>
              </div>
            </a>

            <h1 className="homebase-paragraph-title">All the best Builder Hall Base Layouts with Links<br /></h1>
            <p className="homebase-paragraph">
              Browse through our huge collection of clash of clans builder hall base layouts with links!<br />
              An aestetically pleasing night base layout or rather one to help you with the trophy grind, we got them all. Find your favorite builder hall base build and import it directly into your game. <br /><br />

              You might need to refresh the page or hard refresh (clear browser cache) for the newest version of clashbases.de <br />
            </p>
          </div>

          </div>

      </section>

    );
  };
};

export default builderbase;
