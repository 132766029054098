import React, { Component, lazy } from 'react';
import { Route } from "react-router-dom";
import Townhallthirteenads from './components/1_MainBases/TownhallThirteenAds/TownhallThirteenAds';

import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import ScrollToTopBtn from './components/ScrollToTop';
import Footer from './components/Footer/Footer';

import Showcase from './components/Showcase/Showcase';
import Information from './components/Showcase/Information';

import About from './components/About/About';
import Aboutme from './components/About/AboutMe';

import Wiki from './components/Wiki/Wiki';
import Introhomebasewiki from './components/Wiki/IntroHomebasewiki';
import Hometext from './components/Wiki/WikiData/Hometext';
import Homebuildings from './components/Wiki/WikiData/Homebuildings';
import Homespells from './components/Wiki/WikiData/Homespells';
import Hometroops from './components/Wiki/WikiData/Hometroops';
import IntroBuilderbasewiki from './components/Wiki/IntroBuilderbasewiki';
import Builderbuildings from './components/Wiki/WikiData/Builderbuildings';
import Buildertroops from './components/Wiki/WikiData/Buildertroops';

import Townhallselection from './components/1_MainBases/TownhallSelection/TownhallSelection';

import Townhallfifteen from './components/1_MainBases/A15_TownhallFifteen/TownhallFifteen';
import Townhallfourteen from './components/1_MainBases/A14_TownhallFourteen/TownhallFourteen';
import Townhallthirteen from './components/1_MainBases/A13_TownhallThirteen/TownhallThirteen';
import Townhalltwelve from './components/1_MainBases/A12_TownhallTwelve/TownhallTwelve';
import Townhalleleven from './components/1_MainBases/A11_TownhallEleven/TownhallEleven';
import Townhallten from './components/1_MainBases/A10_TownhallTen/TownhallTen';
import Townhallnine from './components/1_MainBases/A09_TownhallNine/TownhallNine';
import Townhalleight from './components/1_MainBases/A08_TownhallEight/TownhallEight';
import Townhallseven from './components/1_MainBases/A07_TownhallSeven/TownhallSeven';
import Townhallsix from './components/1_MainBases/A06_TownhallSix/TownhallSix';
import Justforfun from './components/1_MainBases/JustForFun/JustForFun';
import Builderbase from './components/1_MainBases/BuilderBase/BuilderBase';

import ArchiveTownhallfourteen from './components/1_MainBases/A00_Archive/Archive_14';
import ArchiveTownhallthirteen from './components/1_MainBases/A00_Archive/Archive_13';
import ArchiveTownhalltwelve from './components/1_MainBases/A00_Archive/Archive_12';
import ArchiveTownhalleleven from './components/1_MainBases/A00_Archive/Archive_11';
import ArchiveTownhallten from './components/1_MainBases/A00_Archive/Archive_10';
import ArchiveTownhallnine from './components/1_MainBases/A00_Archive/Archive_09';
import ArchiveTownhalleight from './components/1_MainBases/A00_Archive/Archive_08';
import ArchiveTownhallseven from './components/1_MainBases/A00_Archive/Archive_07';
import ArchiveTownhallsix from './components/1_MainBases/A00_Archive/Archive_06';

const Privacypolicy = lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy'));
const Privacypolicyinformation = lazy(() => import('./components/PrivacyPolicy/PrivacyPolicyInformation'));


class App extends Component {

    state = {
        sideDrawerOpen: false,
        scroll: 0
    };

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        this.setState({scroll: window.scrollY});
    };

    sideDrawerClickHandler = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        });
    };

    backdropClickHandler = () => {
        this.setState({sideDrawerOpen: false});
    };

    render() {

      let { sideDrawerOpen, scroll} = this.state;
      let backdrop;

      if(sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler}/>
          document.querySelector('body').className = 'slide-open';
      } else {
          document.querySelector('body').className = 'slide-close';
      };

      return (
        <div style={{height: '100%'}}>


            {/* Global Components */}
            <Route path="/" render={() => (
                <React.Fragment>
                    <SideDrawer
                    show={sideDrawerOpen}
                    click={this.backdropClickHandler} />
                    {backdrop}
                    {scroll > 250 ?
                        <Toolbar
                        drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor='rgba(246, 237, 225, 0.8)'
                        position='fixed'
                        index='150'
                        scroll={scroll}
                        classN='slide-to-bottom'
                        opacity='0' />
                    : null}
                </React.Fragment>
            )}></Route>


            {/* Home Page */}
                <Route exact path="/" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor='rgba(3, 3, 3, 0)'
                        position= 'absolute' />
                        <Showcase />
                        <Information />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
              )}></Route>


              {/* Privacy Policy Page */}
                <Route exact path="/privacy-policy" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor='rgba(3, 3, 3, 0)'
                        position= 'absolute' />
                        <Privacypolicy />
                        <Privacypolicyinformation />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
              )}></Route>


              {/* ABOUT Page */}
                <Route exact path="/About" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor='rgba(3, 3, 3, 0)'
                        position= 'absolute' />
                        <About />
                        <Aboutme />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


              {/* WIKI */}
                  <Route exact path="/Wiki" render={() => (
                      <React.Fragment>
                          <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                          bgColor='rgba(3, 3, 3, 0)'
                          position= 'absolute' />
                          <Wiki />
                          <Hometext />
                          <Footer />
                          <ScrollToTopBtn />
                      </React.Fragment>
                  )}></Route>

                <Route exact path="/Wiki-HomeVillage" render={() => (
                      <React.Fragment>
                          <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                          bgColor='rgba(3, 3, 3, 0)'
                          position= 'absolute' />
                          <Introhomebasewiki />
                          <Homebuildings />
                          <Homespells />
                          <Hometroops />
                          <Footer />
                          <ScrollToTopBtn />
                      </React.Fragment>
                  )}></Route>
                <Route exact path="/Wiki-BuilderVillage" render={() => (
                      <React.Fragment>
                          <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                          bgColor='rgba(3, 3, 3, 0)'
                          position= 'absolute' />
                          <IntroBuilderbasewiki />
                          <Builderbuildings />
                          <Buildertroops />
                          <Footer />
                          <ScrollToTopBtn />
                      </React.Fragment>
                  )}></Route>


                {/* Townhall Selection Overwiew */}
                <Route path="/Townhall-Selection" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(3, 3, 3, 0))"
                        position= 'absolute' />
                        <Townhallselection />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


              {/* Townhall 15 Base Layouts */}
                  <Route path="/Townhall-15" render={() => (
                      <React.Fragment>
                          <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                          bgColor="linear-gradient(rgb(185, 5, 154, 0.2), rgb(185, 5, 154, 0.6))"
                          position= 'absolute' />
                        <Townhallfifteen />
                          <Footer />
                          <ScrollToTopBtn />
                      </React.Fragment>
                  )}></Route>


              {/* Townhall 14 Base Layouts */}
                <Route path="/Townhall-14" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(43, 143, 13, 0.4), rgb(43, 143, 13, 0.4))"
                        position= 'absolute' />
                        <Townhallfourteen />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 13 Base Layouts */}
                <Route path="/Townhall-13" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(145, 27, 209, 0.4), rgb(145, 27, 209, 0.4))"
                        position= 'absolute' />
                        <Townhallthirteen />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 12 Base Layouts */}
                <Route path="/Townhall-12" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(67, 124, 188, 0.6), rgba(67, 124, 188, 0.6))"
                        position= 'absolute' />
                      <Townhalltwelve />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 11 Base Layouts */}
                <Route path="/Townhall-11" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(251, 251, 251, 0.5), rgba(251, 251, 251, 0.5))"
                        position= 'absolute' />
                        <Townhalleleven />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* TOWNHALL 10 BASE LAYOUTS */}
                <Route path="/Townhall-10" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(255, 10, 10, 0.35), rgba(255, 10, 10, 0.35))"
                        position= 'absolute' />
                        <Townhallten />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 9 Base Layouts */}
                <Route path="/Townhall-9" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(0, 1, 1, 0.5), rgba(0, 1, 1, 0.5))"
                        position= 'absolute' />
                        <Townhallnine />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 8 Base Layouts */}
                <Route path="/Townhall-8" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(142, 84, 84, 0.6), rgb(142, 84, 84, 0.6))"
                        position= 'absolute' />
                        <Townhalleight />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 7 Base Layouts */}
                <Route path="/Townhall-7" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(0, 0, 0, 0), rgb(70, 108, 30, 0.6))"
                        position= 'absolute' />
                        <Townhallseven />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Townhall 6 Base Layouts */}
                <Route path="/Townhall-6" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(255, 134, 233, 0.4), rgb(255, 134, 233, 0.4))"
                        position= 'absolute' />
                        <Townhallsix />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Fun Base Layouts */}
                <Route path="/Just-For-Fun" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(0, 0, 0, 0), #ffc000)"
                        position= 'absolute' />
                        <Justforfun />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>


                {/* Builder BASE LAYOUTS */}
                <Route path="/BuilderBase" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor='rgba(3, 3, 3, 0)'
                        position= 'absolute' />
                        <Builderbase />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>






                {/* ARCHIVE Townhall 14 Base Layouts */}
                <Route path="/Archive-Townhall-14" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallfourteen />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
                {/* ARCHIVE Townhall 13 Base Layouts */}
                <Route path="/Archive-Townhall-13" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallthirteen />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 12 Base Layouts */}
                <Route path="/Archive-Townhall-12" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhalltwelve />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 11 Base Layouts */}
                <Route path="/Archive-Townhall-11" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhalleleven />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 10 Base Layouts */}
                <Route path="/Archive-Townhall-10" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallten />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 9 Base Layouts */}
                <Route path="/Archive-Townhall-9" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallnine />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 8 Base Layouts */}
                <Route path="/Archive-Townhall-8" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhalleight />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 7 Base Layouts */}
                <Route path="/Archive-Townhall-7" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallseven />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>
              {/* ARCHIVE Townhall 6 Base Layouts */}
                <Route path="/Archive-Townhall-6" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgb(53, 69, 92, 1), rgb(53, 69, 92, 1))"
                        position= 'absolute' />
                      <ArchiveTownhallsix />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>







              {/* ADS */}
                <Route path="/Townhall-13-ads" render={() => (
                    <React.Fragment>
                        <Toolbar drawerClickHandler={this.sideDrawerClickHandler}
                        bgColor="linear-gradient(rgba(0, 0, 0, 0), rgb(145, 27, 209))"
                        position= 'absolute' />
                        <Townhallthirteenads />
                        <Footer />
                        <ScrollToTopBtn />
                    </React.Fragment>
                )}></Route>

        </div>
      );
    }
}


export default App;
