import React from 'react';
import './../Wiki.css';

const hometext = props => (

  <section className="homebasewiki-text">
    <div className="hometext-container-main">
      <div className="hometext-info-container">
        <div className="hometext-about">
          <div className="hometext-about-container">
            <h3 className="hometext-about-title">Introducing the Clash Wiki</h3>
          </div>
          <p className="hometext-about-text">I am incredibly proud to present you the new <span className="orange-color">Clash of Clans Wiki</span> here on Clashbases.de.< br/>< br/> No unnecessary redirects and most importantly no long searching.< br/>< br/> All important info and stats at the tip of your finger, obtainable in <span className="orange-color">just a few clicks.</span>< br/>< br/>  Anytime, anywhere.< br/>< br/> I am still actively working on it, and this is the <span className="orange-color">first version</span> so bear with me!</p>
        </div>
      </div>
    </div>
  </section>

);

export default hometext;
