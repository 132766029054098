import React from 'react';
import './Footer.css';


const footer = () => (
    <section className="footer">

      <div className="footer-container">

        <div className="footer-content-container">
            <h3 className="footer-content-container-title">- Final thoughts -</h3>
            <p className="footer-content-container-text">I really appreciate you taking the time to go through my website.<br /><br />Keeping the content up-to-date, bug free, and maintaining the website with its servers, requires a lot of work and money. If you wish to support me, consider becoming a <a className="orange-text" href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">Patron</a>. There will be exclusive early access rewards waiting for you, and it would help me out a ton!<br /><br /> Also, if you want to keep the conversation going, join our community over on <a className="orange-text" href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">Discord</a>. We're a friendly bunch and I promise you we don't bite :) </p>
        </div>

        <div className="footer-link-containers">
            <div className="footer-link-container">
                <h3 className="footer-link-title">Links</h3>

                  <li className="list-unstyled">
                    <a href="/About">About</a>
                  </li>
                  <li className="list-unstyled">
                    <a href="/privacy-policy">Privacy</a>
                  </li>
                  <li className="list-unstyled">
                    <a href="mailto:clashbases.de@gmail.com?" target="_blank" rel="noopener noreferrer">Contact</a>
                  </li>

            </div>
            <div className="footer-link-container">
                <h3 className="footer-link-title">Social</h3>

                  <li className="list-unstyled">
                    <a href="https://www.patreon.com/clashbases" target="_blank" rel="noopener noreferrer">Patreon</a>
                  </li>
                  <li className="list-unstyled">
                    <a href="https://discord.gg/ThbpRkc" target="_blank" rel="noopener noreferrer">Discord</a>
                  </li>
                  <li className="list-unstyled">
                    <a href="https://www.facebook.com/clashbases.de" target="_blank" rel="noopener noreferrer">Facebook</a>
                  </li>

            </div>
        </div>

      </div>

      <div className="copyright">
        <p className="inline">
          © 2021, made with <div className="inline" id="heart"></div> by <a href="mailto:clashbases.de@gmail.com?" target="_blank" rel="noopener noreferrer" className="orange-color"> Aris Protopapas</a> - all rights reserved
        </p>
      </div>

    </section>
);


export default footer;
